<template>
  <div class="page_container" v-if="(userExist['username']) && 
  (connectedUser['a5e789410fd'] === this.rand)">

        <div class="header">
        <img alt="logo digitech" src="../assets/Digitech.jpg" />
        <h1><i class="fas fa-user-circle"></i> {{userName}}</h1>
        <button class="button" @click="this.$router.push('/')">Déconnexion </button>
      </div>

      
      <div v-if="switcher != 'servicesDashboard'">
        <button @click="switcher = 'servicesDashboard'">&#128257; Stock </button>
    <SeeStock
    :refreshCall2="refreshCall2"></SeeStock>
    <ExcelItem
    @refreshing2="refreshData2($event)"></ExcelItem>
    <CreateItem></CreateItem>
    </div>
    <div v-else>
      <button @click="switcher = ''"> &#128257; Prestations </button>
 <BenefitsDashboard
 :refreshCall="refreshCall"
 ></BenefitsDashboard>
 <BenefitsInjection
 @refreshing="refreshData($event)"></BenefitsInjection>
    </div>
  </div>
  <div class="security-info" v-else>Vérification de vos accès ...
    Si les données n'apparaissent pas reconnectez vous.
     <p><router-link to="/" >Retrouner à la connexion</router-link></p>
  </div>
</template>

<script>

import CreateItem from "@/components/Admin/Stock/CreateItem.vue";
import SeeStock from "@/components/Admin/Stock/SeeStock.vue";
import ExcelItem from "@/components/Admin/Stock/ExcelItem.vue";
import BenefitsDashboard from "@/components/Admin/Benefits/BenefitsDashboard.vue";
import BenefitsInjection from "@/components/Admin/Benefits/BenefitsInjection.vue";
import { mapState } from "vuex";
import timerAcces from "./timer_access";


export default {
  name: 'AddItems',
  components: {
    CreateItem, SeeStock, ExcelItem,BenefitsDashboard,BenefitsInjection
  },
  data() {
    return {
      userName : 'utilisateur',
      connectedUser : {}, 
      rand : '',
      switcher : '',
      refreshCall : false,
      refreshCall2 : false,
      interval0 : null,
      tOut : null
    }
  },
  methods: {
    refreshData(event){
      this.refreshCall = event;
      setTimeout(() => {
        this.refreshCall = false;
      }, 1500);
    },
    refreshData2(event){
      this.refreshCall2 = event;
      setTimeout(() => {
        this.refreshCall2 = false;
      }, 1500);
    },
    userExistChecking(){
       if(!this.userExist['username']){
      alert('Utilisateur inexistant');
    }
  }
  },
  created : function (){
    setTimeout(function () { this.userExistChecking(); }.bind(this), 6000);
    
    this.rand = '2f+g2dfg25h2d+f15b1gs5bnj1ki1lo61q1dc16'+JSON.parse(localStorage.getItem('token'))+'1202'+JSON.parse(localStorage.getItem('token')).substr(12, 42);
  },
  computed: {
    ...mapState({
      userExist : "userExist",
      t_Key : "t_Key",
    })
    },
    beforeUnmount() {
    //console.log('beforeUnmount');
    clearInterval(this.interval0);
    clearTimeout(this.tOut);
  },
  mounted() {
    // récuperer l'utilisateur actuel
    const user = JSON.parse(localStorage.getItem('connectedUser'));
    this.userName = user.name;
    this.connectedUser = user;

    //récupérer la liste des dépôts
    this.$store.dispatch("getAllLocations", this.connectedUser.id_user);

   //----------NO useMode ---> EXIT-------------
    if(user.username.includes('USER&MODE')){
      alert('Vous n\'avez pas quitté correctement le mode -USE MODE-; Reconnexion souhaitée');
      localStorage.clear();
      sessionStorage.clear();
      this.$router.push('/')
    }

    // requete permettant de récuperer les users pour Users.vue
    this.$store.dispatch("getAllUsers", this.connectedUser.id_user);

    // rafraichir la page régulièrement (toute les heures)
    setTimeout(function() { location.reload(); }, 3600000);

   // vérifier que l'utilisateur n'a pas été supprimé de la base de donnée
    const ids = user.id_user; + "_" + user.id_user;
    this.$store.dispatch("getOneUser", ids);


    //----------timer access---------
        const special_id = 'tkrqst_'+ user.id_user ;
        //console.log('getTimerAccess');
        this.$store.dispatch('getTimerAccess', special_id );
        this.tOut = setTimeout(() => {
        if(timerAcces.verifyAccess(this.t_Key) === false){
          //console.log("false acces")
          //Quitter la connexion
          this.$router.push('/');
        };
        
        // revérifer toutes les 5 minutes
        this.interval0 = setInterval(() => {
        if(timerAcces.verifyAccess(this.t_Key) === false){
          //console.log("false acces")
          //Quitter la connexion
          this.$router.push('/')
        };
        }, 
        300000
        );
        }, 10000);
    //-------end timer access -----------
        
        
  },

}
</script>
<style>
h2{
  margin : 50px 0 20px 0 ;
}
</style>
<style scoped>
.page_container {
  display: flex;
  margin-top: 0px;
  flex-direction: column;
  align-items: center;
}

.header{
display: grid;
width:80%;
margin: 20px 0 20px 0;
font-weight: 600;
grid-template-columns: 1fr 1fr 1fr;
}
img {
  max-width: 80px;
}
button {
width: 200px;
height: 50px;
font-size: 20px;
justify-self: right;
align-self: center;
background-color: #0d444b;
  color : rgb(238, 130, 26);
}

#logo__title {
  font-size: 27px;
}
.security-info{
  font-size : 22px;
  margin : 30px;
}
@media screen and (max-width: 1100px) {

  #logo__title {
    font-size: 19px;
  }
  img {
  max-width: 40px;
}
.header {
  width: 95%;
}
h1 {
  font-size: 19px;
}
button {
width: 115px;
height: 30px;
font-size: 14px;
}

}
</style>