<template>
  <div class="page_container" v-if="(userExist['username']) && 
  (connectedUser['a5e789410fd'] === this.rand)">

        <div class="header">
        <img alt="logo digitech" src="../assets/Digitech.jpg" />
        <h1><i class="fas fa-user-circle"></i> {{userName}}</h1>
        <button class="button" @click="this.$router.push('/')">Déconnexion </button>
      </div>

      <div class="rapport-message"><h2>Rapport de fonctionnement</h2> 
      <span>Si une erreur apparait, prendre une capture écran avec date du jour 
        pour faciliter la recherche de son origine</span></div>
    


    <Report ></Report>

  </div>
  <div v-else class="security-info" >Vérification de vos accès ...
    Si les données n'apparaissent pas reconnectez vous.
  </div>
</template>

<script>

import Report from "@/components/Admin/Report/Report.vue";

import { mapState } from "vuex";

export default {
  name: 'ReportErr',
  components: {
    Report
  },
  data() {
    return {
      userName : 'utilisateur',
      connectedUser : {},
      rand : ''
    }
  },
  methods: {
    userExistChecking(){
       if(!this.userExist['username']){
      alert('Utilisateur inexistant');
    }
  }
  },
  created : function (){
    setTimeout(function () { this.userExistChecking(); }.bind(this), 6000);
    this.rand = '2f+g2dfg25h2d+f15b1gs5bnj1ki1lo61q1dc16'+JSON.parse(localStorage.getItem('token'))+'1202'+JSON.parse(localStorage.getItem('token')).substr(12, 42);
  },
  computed: {
    ...mapState({
      userExist : "userExist",
    })
    },
  mounted() {
    // récuperer l'utilisateur actuel
    const user = JSON.parse(localStorage.getItem('connectedUser'));
    this.userName = user.name;
    this.connectedUser = user;

    //----------NO useMode ---> EXIT-------------
    if(user.username.includes('USER&MODE')){
      alert('Vous n\'avez pas quitté correctement le mode -USE MODE-; Reconnexion souhaitée');
      localStorage.clear();
      sessionStorage.clear();
      this.$router.push('/')
    }

    // requete permettant de récuperer les users pour Users.vue
    this.$store.dispatch("getAllUsers", this.connectedUser.id_user);

    // rafraichir la page régulièrement (toute les heures)
    setTimeout(function() { location.reload(); }, 3600000);

   // vérifier que l'utilisateur n'a pas été supprimé de la base de donnée
    const ids = user.id_user + "_" + user.id_user;
    this.$store.dispatch("getOneUser", ids);
  },
}
</script>
<style scoped>
.page_container {
  display: flex;
  margin-top: 0px;
  flex-direction: column;
  align-items: center;
}

.header{
display: grid;
width:80%;
margin: 0 0 20px 0;
font-weight: 600;
grid-template-columns: 1fr 1fr 1fr;
}
img {
  max-width: 80px;
}
button {
width: 200px;
height: 50px;
font-size: 20px;
justify-self: right;
align-self: center;
background-color: #0d444b;
  color : rgb(238, 130, 26);
}

#logo__title {
  font-size: 27px;
}
.rapport-message{
margin-bottom: 50px;
grid-column-end: 3;
grid-column-start: 1;
}
.security-info{
  font-size : 22px;
  margin : 30px;
}
@media screen and (max-width: 1100px) {

  #logo__title {
    font-size: 19px;
  }
  img {
  max-width: 40px;
}

.header {
  width: 95%;
}
h1 {
  font-size: 19px;
}
button {
width: 95px;
height: 30px;
font-size: 14px;
}
}
</style>