<template >
    <div >
        <h2>ACTIVITE JOURNALIERE</h2>
            <button class="show-btn" @click="showCards = !showCards">
      Afficher / Masquer
    </button>
        <div class="activity-resume-container" v-if="showCards">
            <span v-if="succesMessage" class="succes-message"> Données enregistrées !</span>
           <!-- {{constructionCards}}
            {{Object.values(constructionCards).length}}
            {{clientPicked}}
            {{activityPicked}}
            {{constructionPicked}}
            {{itemClients}}
            {{town}}
            {{myReelsList}}!-->
            <!--{{inProgressConstructions}}
            {{materialFilterList}}
            {{cableFilterList}}
            {{itemCables}}
            {{notEmptyItemCables}}
            {{cableSet}}
            {{cableArray}}!-->
            
            <h3>Fiches chantier</h3>
        <div class="construction-card" 
        v-for="card,index in this.constructionCards" :key="card" :index="index">
        <button class="delete-button" @click="deleteCard(index)">x</button>
            <h4>Fiche n° {{index}}</h4>
            <div class="flex-container">

            <div class="mini-container mini-container2">
                <label for="">Client
                    <i class="fa fa-light fa-id-card"
                    v-if="!variousClient"
                    @click="variousClient = true"></i>
                    <i class="fa fa-light fa-user-plus"
                    v-else
                    @click="variousClient = false"></i></label>
                <div>
                    <span class="choosen" v-if="card['client'] && card['client'] != ''">
                        {{card['client']}} <span class="crossSpan" @click="cleanCard(card,index)">x</span></span>
                        <select v-else class="selectAct" placeholder="" 
       :size="size2" @focus="onFocusSelect(2)" @blur="onBlurSelect(2)"
       @change="clientSelected($event,index)"
       :style="[firstStyle2 ? {'color' : 'black', 'background-color' : 'white'}:{}]"
       v-model="card['client']">
        <option v-for="choice in clients" :key="choice"
        >{{choice}}</option>
      </select></div>
            </div>

            <div class="mini-container mini-container2">
                <label for="">Type d'activité</label>
                <div>
                    <span class="choosen" v-if="card['activity'] && card['activity'] != ''">
                        {{card['activity']}} <span class="crossSpan" @click="card['activity'] = ''">x</span></span>
                    <select v-else :disabled="this.clientPicked === ''"
                class="selectAct" placeholder="" 
       :size="size" @focus="onFocusSelect(0)" @blur="onBlurSelect(0)"
       @change="activitySelected($event,index)"
       :style="[firstStyle ? {'color' : 'black', 'background-color' : 'white'}:{}]"
       v-model="card['activity']">
        <option v-for="activity in clientsAllActivities" :key="activity"
        >{{activity}}</option>
      </select></div>
            </div>

            <div class="mini-container mini-container2">
                <label for="">Numéro chantier</label>
                <div>
                    <span class="choosen" v-if="card['construction_number'] && card['construction_number'] != ''">
                        {{card['construction_number']}} <span class="crossSpan" @click="cleanConstructionNb(card)">x</span></span>
                    <select v-else :disabled="this.clientPicked === '' || this.activityPicked === ''"
                class="selectAct" placeholder="" id="select1"
       :size="size3" @focus="onFocusSelect(3)" @blur="onBlurSelect(3)"
       @change="constructionSelected($event,index)"
       :style="[firstStyle3 ? {'color' : 'black', 'background-color' : 'white'}:{}]"
       v-model="card['construction_number']">
        <option v-for="choice in constructions" :key="choice"
        >{{choice.date+choice.incremented_nb + ' / '+ choice.town}}</option>
      </select>
      </div>
            </div>

            <div class="mini-container mini-container2">
                <label for="">Ville</label>
                <div><span>{{card['town']}}</span></div>
            </div>
            </div>
            <div class="flex-container adaptable-container">
            <div class="header-button mini-container">
                 <label >Ajouter un matériel </label>
                 <div >
                     <div class="mini-container checkbox">
                     <label>Filtre client + activité</label>
                     <input checked type="checkbox" :ref="'checkBoxFilter'+index" @change="checkBoxState(0,index)">
                     </div>
<!---------------------MATERIAL ---------------------!-->
                    <!-- si checkbox filtre coché -->
                     <select v-if="this.materialFilterList.indexOf('checkBoxFilter'+index) !== -1 "
                class="selectAct" placeholder="" 
       :size="size4" @focus="onFocusSelect(4)" @blur="onBlurSelect(4)"
       @change="materialSelected($event)"
       :style="[firstStyle4 ? {'color' : 'black', 'background-color' : 'white'}:{}]"
       v-model="materialPicked">
        <option v-for="choice in assignements[index]" :key="choice"
        >{{choice.material}}</option>
      </select>
      <!-- si checkbox filtre non coché -->
                     <select v-else
                class="selectAct" placeholder="" 
       :size="size4" @focus="onFocusSelect(4)" @blur="onBlurSelect(4)"
       @change="materialSelected($event)"
       :style="[firstStyle4 ? {'color' : 'black', 'background-color' : 'white'}:{}]"
       v-model="materialPicked">
        <option v-for="choice in materialsFilterFunction(index)" :key="choice"
        >{{choice.item}}</option>
      </select><button class="addBtn" @click="addMaterial(index)">+</button>
            </div></div>
        
            <div class="material-card-container"
            v-for="value,material in card['materials']" :key="value" :index="material"> 
               <div class="material-card">  
               <label >{{material}}</label> 
               <input type="number" class="number" :value="value"
               @change="materialSetQtty($event,index,material)">
               <div class="qtty-div">
               <button class="qtty" @click="materialSetQtty('-',index,material)">-</button> 
               <button class="qtty" @click="materialSetQtty('+',index,material)">+</button>
               
               
               </div>
               <button class="delete-button" @click="deleteMaterial(index,material)"> x</button>
               </div>
               
            </div></div>

<!---------------------CABLE ---------------------!-->
            <div class="flex-container adaptable-container">
            <div class="header-button mini-container ">
                 <label >Ajouter du cable </label>
                  <div class="mini-container checkbox">
                     <label>Filtre mes cables</label>
                     <input checked type="checkbox" :ref="'checkBoxFilterBis'+index" @change="checkBoxState(1,index)">
                     </div>
                     
                     <select v-if="this.cableFilterList.indexOf('checkBoxFilterBis'+index) !== -1"
                class="selectAct" placeholder="" 
       :size="size5" @focus="onFocusSelect(5)" @blur="onBlurSelect(5)"
       @change="materialSelected($event)"
       :style="[firstStyle5 ? {'color' : 'black', 'background-color' : 'white'}:{}]"
       v-model="cablePicked">
        <option v-for="choice in this.myReelsList" :key="choice"
        >{{choice.item +' / ref :' +choice.ref}}</option>
      </select>
                     
      <div v-else class="search-div">
        <div class="search-sub-div"><input class="search-input" type="text" placeholder="n° cable"
        v-model="memorisedWord" > 
    <span style="margin-left : 5px"> &#128269; </span>
     
</div>
    <div class="cable-find"
       @change="cableSelected($event)"
         v-for="choice in this.arraySearch" :key="choice"
        >{{choice}}</div> 
         </div>

        <button class="addBtn" @click="addCable(index)">+</button>
            </div>

            <div class="cable-card-container" v-for="value,cable in card['cables']" 
            :key="value" :index="cable"> 
                <div class="cable-card"> 
                 <div>
                 <label >{{splitRef(cable,0)}}</label>
                 <span>Ref : {{splitRef(cable,1)}}</span>
                 <span v-if="value.last_before">chgt metré début : {{value.last_before}}</span>
                 <span v-else>Ancien metré début : {{value.before}}</span>
                 </div>
                 <div class="margin-top"><div>
                 <label >Metré début :</label> 
                 <!-- DISABLE INPUT IF DOUBLE USE BUT NOT LAST-->
                 <input :disabled="checkUniq(index,cable)" class="number" :ref="'input-meter'+index+cable"
                 :value="value.meter"
               @change="cableSetQtty($event,index,cable)">
               
               </div>
                 <div><span>Quantité utilisé : {{calculQtty(value.before,parseInt(value.meter))}}m</span>
                 
                 </div></div>
                 <button class="no-margin delete-button" @click="deleteCable(index,cable)"> x</button>
                </div>
                
            </div></div>

            <div class="mini-container construction-status"> 
        <span>Statut du chantier ?</span>
         <select class="selectAct construction-select" placeholder="" 
       @change="statusSelected($event,index)"
       v-model="card['status']">
        <option v-for="choice in statusList" :key="choice"
        >{{choice}}</option>
      </select>

        </div></div>
        <div class="mini-container">
            <label >Ajouter une carte chantier </label>
        <button class="addBtn" @click="addConstructionCard()">+</button>
        </div>
        <div class="mini-container"> 
            <button class="validation-button" v-if="!requestWaiting" 
            @click="validateAllResumes()"> Valider les fiches</button>
            <button class="validation-button" v-else> Validation ⌛</button>
        </div>
        </div>
<!-- HISTORY CONT -->
<div class="separator"></div>

        <div class="history-cont">
            <h3>Récap chantier</h3>

              <!----------------- MY constructions RESUME------------------->
              
            <h4 class="h4-colored"> Mon résumé</h4>
            <div class="span-container">
             <span class="span-title"> Total des derniers chantier</span>
             <button class="span-btn" @click="showInfo = !showInfo">?</button>
             <div v-show="showInfo" class="span-info">Ne s'affiche que les deux derniers chantiers surlesquels 
                vous êtes intervenus dans les 30jours</div></div>
         
            <div class="resume-container" v-if="myResumeConstructions.length != 0">
                <div class="resume-sub-container" v-for="nb,index in nbSet" :key="nb" :index="index">
               <div class="history-day-card">
                <div class="flexi">
                  <span class="span-hd"> <span> Chantier :</span> <span>{{nb}}</span></span>
                   <span class="span-hd" v-if="constructionsResumeHistory[index+2] && constructionsResumeHistory[index+2][0]"> 
                    <span>Statut : </span>
                    <span>{{constructionsResumeHistory[index+2][0].status_}}</span></span>
                    <span class="span-hd" v-if="constructionsResumeHistory[index+2] && constructionsResumeHistory[index+2][0]"> 
                    <span>Ville : </span>
                    <span>{{constructionsResumeHistory[index+2][0].town}}</span></span>
                    <span class="span-hd" v-if="constructionsResumeHistory[index+2] && constructionsResumeHistory[index+2][0]"> 
                    <span>Client :</span> 
                    <span>{{constructionsResumeHistory[index+2][0].client}}</span></span>
                    <span class="span-hd" v-if="constructionsResumeHistory[index+2] && constructionsResumeHistory[index+2][0]"> 
                    <span>Activité : </span> 
                        <span>{{constructionsResumeHistory[index+2][0].activity}}</span> </span>
                    </div>
                <div class="history-grid" >
                   
                   <span class="category">Désignation</span>
                    <span class="category">Quantité</span>
                    <span class="category"> Type </span>
                </div>
                <div class="history-grid infos-div" v-for="infos in nbGrouped(nb)" :key="infos">
                
                
                   <span>{{infos[1]}}</span>
                    <span>{{infos[2]}} <span v-if="infos[0] === 'cable'">mètres</span></span>
                    <span>{{typeFormat(infos[0])}}  </span>
                
            </div>
            </div>
            </div>
              </div>
              <div v-else-if="waitingRequest"  class="empty">Recherche ...</div>
            <div v-else class="empty">Vide</div>

            <!----------------- MY history RESUME------------------->

            <!--{{constructionsResumeHistory[0]}}-->
            <h4 class="no-bottom-margin h4-colored"> Mon historique</h4>
            <div>
                <select class="select-date" name="" id=""
                @change="setDateFilter($event)">
                    <option value="1">Aujourd'hui </option>
                    <option value="2">Depuis hier </option>
                    <option value="3">Depuis 7 jours </option>
                    <option value="4">Depuis 30 jours </option>
                </select>
            </div>
            <div v-if="myRecentConstructions.length != 0">
            <div v-for="date in dateSet" :key="date">
                <div class="history-day-card">
                    <span class="span-hd span-date">{{date}}</span>
                <div class="history-grid" >
                   <span class="category"> Chantier  </span>
                   <span class="category">Désignation</span>
                    <span class="category">Quantité</span>
                </div>
                <div class="history-grid infos-div" v-for="infos in datesGrouped(date)" :key="infos">
                
                <span>{{infos[0] +' / '+ infos[3]}}  </span>
                   <span>{{infos[1]}}</span>
                    <span>{{infos[2]}}</span>
                
            </div>
            </div>
            </div></div>
            <div v-else-if="waitingRequest"  class="empty">Recherche ...</div>
            <div v-else  class="empty">Vide</div>

              <!----------------- Global constructions RESUME------------------->
               
               <div class="separator"></div>
               
               <div class="search-nb-container">
                <h4 > Recherche par numéro chantier</h4>
               <div class="input-nb-container">
                <input class="nb_search" ref="input-nb" type="text" placeholder="0000-00-0" v-model="pickedNumber"
                pattern="[0-9]{4}[-][0-9]{2}[-][0-9]{1,4}">
                <button @click="searching(pickedNumber)">chercher</button>
            </div> </div>
            
            <h4 class="h4-colored bottom-margin"> Résumé numéro chantier</h4>
         
            <div v-if="myResumeConstructionsGlobal.length != 0">
                <div v-for="nb,index in nbSet2" :key="nb" :index="index">
                    
               <div class="history-day-card">
                <div class="flexi">
                  <span class="span-hd"> <span>Chantier :</span> <span>{{nb}}</span></span>

                   <span class="span-hd" v-if="constructionsResumeHistoryGlobal[index+2] && constructionsResumeHistoryGlobal[index+2][0]"> 
                    <span>Statut :</span>
                     <span >{{constructionsResumeHistoryGlobal[index+2][0].status_}}</span></span>
                    <span class="span-hd" v-if="constructionsResumeHistoryGlobal[index+2] && constructionsResumeHistoryGlobal[index+2][0]"> 
                    <span>Ville :</span>
                     <span>{{constructionsResumeHistoryGlobal[index+2][0].town}}</span></span>
                    <span class="span-hd" v-if="constructionsResumeHistoryGlobal[index+2] && constructionsResumeHistoryGlobal[index+2][0]"> 
                    <span>Client :</span> 
                    <span>{{constructionsResumeHistoryGlobal[index+2][0].client}}</span></span>
                    <span class="span-hd" v-if="constructionsResumeHistoryGlobal[index+2] && constructionsResumeHistoryGlobal[index+2][0]"> 
                    <span>Activité : </span>
                    <span>{{constructionsResumeHistoryGlobal[index+2][0].activity}}</span></span>
                  </div>
                <div class="history-grid" >
                   
                   <span class="category">Désignation</span>
                    <span class="category">Quantité</span>
                    <span class="category"> Type </span>
                </div>
                <div class="history-grid infos-div" v-for="infos in nbGrouped2(nb)" :key="infos">
                
                
                   <span>{{infos[1]}}</span>
                    <span>{{infos[2]}} <span v-if="infos[0] === 'cable'">mètres</span></span>
                    <span>{{typeFormat(infos[0])}}  </span>
                
            </div>
            </div>
            </div>
              </div>
              <div v-else-if="waitingRequest2"  class="empty">Recherche ...</div>
            <div v-else  class="empty">Vide</div>


            

            <!----------------- HISTORY global BY NUMBER------------------->
            
            <h4 class="no-bottom-margin h4-colored"> Historique numéro chantier</h4>
            <!--{{constructionsResumeHistoryGlobal[0]}}-->
            <div>
                <select name="" id="" class="select-date"
                @change="setDateFilterForGlobal($event)">
                    <option value="1">Aujourd'hui </option>
                    <option value="2">Depuis hier </option>
                    <option value="3">Depuis 7 jours </option>
                    <option selected value="4">Depuis 30 jours </option>
                </select>
                
            </div>

            <div v-if="globalRecentConstructions.length != 0">
            <div v-for="date in dateSetG" :key="date">
                <div class="history-day-card">
                    <span class="span-hd span-date">{{date}}</span>
                <div class="history-grid" >
                   <span class="category"> Nom </span>
                   <span class="category">Désignation</span>
                    <span class="category">Quantité</span>
                </div>
                <div class="history-grid  infos-div" v-for="infos in datesGroupedGlobal(date)" :key="infos">
                
                <span>{{infos[3]}}  </span>
                   <span>{{infos[1]}}</span>
                    <span>{{infos[2]}}</span>
                
            </div>
            </div>
            </div></div>
             <div v-else-if="waitingRequest2"  class="empty">Recherche ...</div>
            <div v-else  class="empty">Vide</div>

          

           

            

        </div>

    </div> 
</template>

<script>

import { mapState } from "vuex";

export default {
    name: "ActivityResume",
    data() {
        return {
            connectedUser : {},
            constructionCards : {
                1 :{ 
                    materials : {
                        
                    },
                    cables : {

                    },
                    status : ''
                }
            },
            materialSet : new Set(),
            cableSet : new Set(),
            cables : [],
            size : 1,
            size2 : 1,
            size3 : 1,
            size4: 1,
            size5: 1,
            firstStyle : false,
            firstStyle2 : false,
            firstStyle3 : false,
            firstStyle4 : false,
            firstStyle5 : false,
            activityPicked : '',
            constructionPicked : '',
            clientPicked : '',
            town : '',
            materialPicked : '',
            cablePicked : '',
            cableFilter : false,
            constructionStatus : '',
            requestWaiting : false,
            succesMessage : false,
            showCards : true,
            statusList : ['En cours','En pause','Terminé','A faire','Annulé'],
            monthNames : ["Janvier", "Février", 
            "Mars", "Avril", "Mai", "Juin",
            "Juillet", "Août", "Septembre",
             "Octobre", "Novembre", "Décembre"],
            materialFilterList : [],
            cableFilterList : [],
            interval : 0,
            intervalG : 2678400000,
            pickedNumber : '',
            showInfo : false,
            cableArray : [],
            cableControlUniq : {},
            arraySearch : [],
            searchFilter : false,
            memorisedWord : '',
            variousClient : false


        }
    },
     mounted() {
    this.$store.commit("isConnected");
    // récuperer l'utilisateur actuel
    this.connectedUser = JSON.parse(localStorage.getItem("connectedUser")); 

     //----------gestion du useMode-------------
    const originalUsername = this.connectedUser.username;
    var connectedUser = this.connectedUser;

    const userMode = JSON.parse(localStorage.getItem('usingMode'));
    const userModeId  = JSON.parse(localStorage.getItem('usingModeI'));
    const userModeUsername = localStorage.getItem('usingModeU');
    if(userMode && userMode === true && userModeId && userModeUsername){
      if(!this.connectedUser.username.includes('USER&MODE')){
      connectedUser.username  = originalUsername + 'USER&MODE' + userModeUsername + '^&^'+userModeId;
      localStorage.setItem('connectedUser',JSON.stringify(connectedUser));
      this.connectedUser = JSON.parse(localStorage.getItem("connectedUser"));}
    }
    //----------END gestion du useMode-------------

    //this.$store.dispatch('getAllActivities',this.connectedUser.id_user);
    this.$store.dispatch('getInProgressConstructions',this.connectedUser.id_user);
    this.$store.dispatch('getAllActivities',this.connectedUser.id_user);
    this.$store.dispatch('getOnlyClientsItems',this.connectedUser.id_user);
    this.$store.dispatch('getClientFromConstructions',this.connectedUser.id_user);
    this.$store.dispatch('getAssignementReport',this.connectedUser.id_user);
    // à modifier pour ne demander que les cables
    this.$store.dispatch("getAllItemsAndDetails",this.connectedUser.id_user);
    const userStr = this.connectedUser.username+'_'+this.connectedUser.id_user ;
    this.$store.dispatch('getConstructionsHistoryResume',userStr);
    //récpérer myReelsList
    const usernameAndId =
          this.connectedUser.username+'GetOnlyCables' + "_" + this.connectedUser.id_user;
    this.$store.dispatch("getAllMyOrders", usernameAndId);
    //activer par defaut les filtres des select fiche chantier
    this.checkBoxState(0,1);
    this.checkBoxState(1,1);
    },
    computed: {
    ...mapState({
      allActivities : 'allActivities',
      itemCables: "itemCablesAndDetails",
      itemClients: "itemClientsAndDetails",
      clientFromConstructions : "clientFromConstructions",
      inProgressConstructions : 'inProgressConstructions',
      constructionsResumeHistory : 'constructionsResumeHistory',
      constructionsResumeHistoryGlobal : 'constructionsResumeHistoryGlobal',
      allAssignements : 'allAssignements',
      myReelsList: "myReelsList",
      constructionResumeResponse : 'constructionResumeResponse',
      waitingRequest : 'waitingRequest',
      waitingRequest2 : 'waitingRequest2'
    }),
    constructions() {
     var miror = [];
      if(Object.values(this.inProgressConstructions).length != 0){
        this.inProgressConstructions.forEach(element => {
          if(element.client === this.clientPicked){
          if(element.activity === this.activityPicked){
              miror.push(element);
          }}
        });}
      return miror;
    },
    clients() {
      var thisSet = new Set();
      if(Object.values(this.clientFromConstructions).length != 0){
        this.clientFromConstructions.forEach(element => {
            if(this.variousClient && element.various === 1){
                thisSet.add(element.client);
            }
            else if (!this.variousClient && element.various === 0) {
                thisSet.add(element.client);
            }
        
        });}
      return thisSet;
    },
    clientsAllActivities() {
      var thisSet = new Set();
      if(Object.values(this.inProgressConstructions).length != 0 ){
        this.inProgressConstructions.forEach(element => {
            // le if permet d'eviter un code activité qui aurait été supprimé
            if(this.clientPicked === element.client){
                if(element.activity){
                thisSet.add(element.activity);}
            }
        });}
      return thisSet;
    },
    assignements(){
        var miror = {};
        for(let i in this.constructionCards){
            const client = this.constructionCards[i].client;
            const activity = this.constructionCards[i].activity;
            miror[i] = [];
        
      if(Object.values(this.allAssignements).length != 0){
        this.allAssignements.forEach(element => {
          if(element.client === client){
          if(element.activity === activity){
              miror[i].push(element);
          }}
        });}}
      return miror;
    },
    notEmptyItemCables(){
        var miror = [];
      if(Object.values(this.itemCables).length != 0){
        this.itemCables.forEach(element => {
          if(element.qtty_in != 0){
              miror.push(element);
          }
        });}
      return miror;
    },
    dayDate(){
        let dayDate0 = new Date();
        // aligner sur le fuseau horaire français 
        var utcEpochSeconds = dayDate0.getTime() - (dayDate0.getTimezoneOffset() * 60000);
        let dayDate = new Date(utcEpochSeconds);
        var interval = dayDate.getTime()-this.interval;
        const startDate = new Date(interval);
  //console.log(_31days);
  //console.log(_31days.toISOString());
        return startDate.toISOString();
    },
    dayDateG(){
        let dayDate0 = new Date();
        // aligner sur le fuseau horaire français 
        var utcEpochSeconds = dayDate0.getTime() - (dayDate0.getTimezoneOffset() * 60000);
        let dayDate = new Date(utcEpochSeconds);
        var interval = dayDate.getTime()-this.intervalG;
        const startDate = new Date(interval);
  //console.log(_31days);
  //console.log(_31days.toISOString());
        return startDate.toISOString();
    },
    myRecentConstructions(){
        // constructionsResumeHistory[0] histories username
        // constructionsResumeHistory[>0] resumes differents nb 
        var miror = [];
        for(let i in this.constructionsResumeHistory[0]){
            const row = this.constructionsResumeHistory[0][i];
            /*console.log(row.date.split('T')[0]);
            console.log(this.dayDate.split('T')[0]);
            console.log(row.date.split('T')[0] === this.dayDate.split('T')[0]);*/
            if(row.date.split('T')[0] >= this.dayDate.split('T')[0]){
                miror.push(row);
            }
        }
        return miror
    },
    globalRecentConstructions(){
        var miror = [];
        for(let i in this.constructionsResumeHistoryGlobal[0]){
            const row = this.constructionsResumeHistoryGlobal[0][i];
            /*console.log(row.date.split('T')[0]);
            console.log(this.dayDate.split('T')[0]);
            console.log(row.date.split('T')[0] === this.dayDate.split('T')[0]);*/
            if(row.date.split('T')[0] >= this.dayDateG.split('T')[0]){
                miror.push(row);
            }
        }
        return miror
    },
    dateSet(){
        var dateSet = new Set();

        for(let i in this.myRecentConstructions){
            dateSet.add(this.dateFormat(this.myRecentConstructions[i].date))
        }
        return dateSet
    },
    dateSetG(){
        var dateSet = new Set();

        for(let i in this.globalRecentConstructions){
            dateSet.add(this.dateFormat(this.globalRecentConstructions[i].date))
        }
        return dateSet
    },
    myResumeConstructions(){
        // constructionsResumeHistory[0] histories username
        // constructionsResumeHistory[>0] resumes differents nb 
        var miror = [];
        if(this.constructionsResumeHistory.length > 1){
            for(let i in this.constructionsResumeHistory[1]){
                
                    
                    const row = this.constructionsResumeHistory[1][i];
                    miror.push(row);
                
            }
        }
        return miror
    },
    myResumeConstructionsGlobal(){
        // constructionsResumeHistory[0] histories username
        // constructionsResumeHistory[>0] resumes differents nb 
        var miror = [];
        if(this.constructionsResumeHistoryGlobal.length > 1){
            for(let i in this.constructionsResumeHistoryGlobal[1]){
                
                    
                    const row = this.constructionsResumeHistoryGlobal[1][i];
                    miror.push(row);
                
            }
        }
        return miror
    },
     nbSet(){
        var nbSet = new Set();
 
        for(let i in this.myResumeConstructions){
            //console.log(this.myResumeConstructions[i]);
            nbSet.add(this.myResumeConstructions[i].construction_nb)
        }
        //console.log(nbSet);
        return nbSet
    },
    nbSet2(){
        var nbSet = new Set();
 
        for(let i in this.myResumeConstructionsGlobal){
            //console.log(this.myResumeConstructions[i]);
            nbSet.add(this.myResumeConstructionsGlobal[i].construction_nb)
        }
        //console.log(nbSet);
        return nbSet
    },
    },
    watch : {
        memorisedWord(newCount, oldCount){
            /* v-for="choice in this.notEmptyItemCables" :key="choice"
        >{{choice.item +' / ref :' +choice.ref}}</option>*/
            if (newCount) {
                this.arraySearch = [];
            for(let i in this.notEmptyItemCables){
                if(this.notEmptyItemCables[i].ref == this.memorisedWord){
                    this.arraySearch.push(
                    this.notEmptyItemCables[i].item +' / ref :'+this.notEmptyItemCables[i].ref)
                }
            }
        }
        },
        constructionPicked(newCount, oldCount) {
      if (newCount) {
        var miror = [];
      if(Object.values(this.inProgressConstructions).length != 0){
        this.inProgressConstructions.forEach(element => {
          if(element.date+element.incremented_nb === newCount){
              for(let i in this.constructionCards){
                  if(this.constructionCards[i]['construction_number'] === newCount){
                      this.constructionCards[i]['town'] = element.town 
                  }
              }
          this.town = element.town }});
          }}
          },
          constructionResumeResponse(newCount, oldCount) {
      if (newCount) {
        // reponse mais pas de succès
          if(newCount === true){
          this.requestWaiting = false;
          this.$store.state.constructionResumeResponse = false;
          return
          }
          // reponse avec succès, s'en suis un reload
          this.succesMessage = true;
          //this.requestWaiting = false;
          this.$store.state.constructionResumeResponse = false;
          location.reload();
      }
          },
    },
    methods : {
        validateAllResumes(){
            this.requestWaiting = true;
            if(Object.values(this.constructionCards).length === 0){
                alert('Aucune fiche détéctée');
                this.requestWaiting = false;
                return
            }
            for(let i in this.constructionCards){
                if(!this.constructionCards[i]['client'] || this.constructionCards[i]['client'] === ''){
                    alert('Veuillez renseigner un client pour la fiche numéro ' + i);
                    this.requestWaiting = false;
                    return
                }
                else if(!this.constructionCards[i]['activity'] || this.constructionCards[i]['activity'] === ''){
                    alert('Veuillez renseigner une activité pour la fiche numéro ' + i);
                    this.requestWaiting = false;
                    return
                }
                else if(!this.constructionCards[i]['construction_number'] || this.constructionCards[i]['construction_number'] === ''){
                    alert('Veuillez renseigner un numero de chantier pour la fiche numéro ' + i);
                    this.requestWaiting = false;
                    return
                }
                else if(!this.constructionCards[i]['materials']){}
                else if(!this.constructionCards[i]['cables']){}
                else if(!this.constructionCards[i]['status'] || this.constructionCards[i]['status'] === ''){
                    alert('Veuillez renseigner un statut chantier pour la fiche numéro ' + i);
                    this.requestWaiting = false;
                    return
                }
                if(Object.values(this.constructionCards[i]['materials']).length !=0){
                    const materials = this.constructionCards[i]['materials'];
                    for(let x in materials){
                        if(materials[x] <= 0){
                            alert('carte numéro '+i+', '+x+' , la quantité est invalide');
                            this.requestWaiting = false;
                            return
                        }
                    }
                }
                if(Object.values(this.constructionCards[i]['cables']).length !=0){
                    const cables = this.constructionCards[i]['cables'];
                    for(let x in cables){
                        if(parseInt(cables[x]['meter']) < 0 ||
                            this.calculQtty(cables[x]['before'],parseInt(cables[x]['meter'])) === 0){
                            alert('Fiche numéro '+i+', '+x+' , le metré début est invalide');
                            this.requestWaiting = false;
                            return
                        }
                    }
                }
            }

            var obj = {};
            // empecher id de créer une nouvelle carte chantier
            obj = JSON.parse(JSON.stringify(this.constructionCards));
            obj['id'] = this.connectedUser['id_user'];
            obj['username'] = this.connectedUser['username'];
            //this.constructionCards['id'] = this.connectedUser['id_user'];
            this.$store.dispatch('sendConstructionResume',obj);
        },
        cleanCard(card,index){
            card['client'] = ''; 
            for(let mat in card['materials']){
                this.deleteMaterial(index,mat);
            }
        },
        searching(str_nb){
            //console.log(this.$refs['input-nb']);
            //alert(this.$refs['input-nb'].reportValidity());
            if(!(this.$refs['input-nb'].reportValidity())){
                alert('Veuillez rentrer un numero chantier valide');
            //pattern="[0-9]{4}[-][0-9]{2}[-][0-9]{1,4}"
            return
            }
            this.$store.state.waitingRequest2 = true;
         const codeStr = 'construction-nb:'+str_nb+'_'+this.connectedUser.id_user ;
        this.$store.dispatch('getConstructionsHistoryResume',codeStr);
        },
        cleanConstructionNb(card){
            card['construction_number'] = '';
            card['town'] = '';
            this.constructionPicked = '';
        },
        materialsFilterFunction(index){
            var newItemClients = {};
        var thisSet = new Set();
        //filtre pour eviter les refs jumelles en choissisant un client
        for(let i in this.itemClients){
            //console.log(this.itemClients[i].supplier);
            // choisir également par défaut une ref sur les deux dépôt en evitant les doublons
            if(thisSet.has(this.itemClients[i].ref)){
                continue
            }
            if(this.itemClients[i].supplier === this.constructionCards[index]['client']){
                thisSet.add(this.itemClients[i].ref);
                newItemClients[i] = this.itemClients[i];
            }
            
        }
        return newItemClients
        },
        addConstructionCard(){
            var count = 0;
            //console.log((Object.keys(this.constructionCards)).length+1);
            let globalIndex;
            const number =(Object.keys(this.constructionCards)).length;
            //console.log(number);
            for(let i in this.constructionCards){
            count +=1;
            if(count === number){
                globalIndex = parseInt(i)+1;
            this.constructionCards[parseInt(i)+1] = {
                materials : {},
                cables : {},
                status : ''
            };
            }}
            if(number === 0 ){
                globalIndex = 1;
                this.constructionCards[1] = {
                materials : {},
                cables : {},
                status : ''
            };
            }
            this.clientPicked = '';
            this.activityPicked = '';
            this.constructionPicked = '';
            this.constructionStatus = '';
            //activer par defaut les filtres des select fiche chantier
            //settimeout est necessaire le temps que le DOM integre la nouvelle carte
            setTimeout(() => {
            this.checkBoxState(0,globalIndex);
            this.checkBoxState(1,globalIndex) 
            }, 1000);
        },
        addMaterial(index){
            //alert(index);
            
            //rejouter le numero de la carte chantier
            if(this.materialSet.has(this.materialPicked+index)){
                alert('Matériel déjà ajouté');
                return
            }
            for(let i in this.materialsFilterFunction(index)){
                const obj = this.materialsFilterFunction(index)[i]
                if(obj.item === this.materialPicked){
                    
                    this.constructionCards[index]['materials'][this.materialPicked ] = 0;
                    this.materialSet.add(this.materialPicked+index);
                }
            }

        },
        checkUniq(index,cable){
            if(this.cableControlUniq[cable]){
            if(this.cableArray.includes(cable) && this.cableControlUniq[cable] != cable+index){
                return true
            }
            else {
                return false
            }}
            else {
                return false
            }
            
        },
        addCable(index){
            let last_before;
            //normalement 1 seul élément si une seule ref par cable
            if(this.arraySearch.length > 1 ){
            alert('le filtre de recherche rencontre un problème')}
            if(this.cableFilterList.indexOf('checkBoxFilterBis'+index) == -1){
                if(this.arraySearch[0]){
                this.cablePicked = this.arraySearch[0];
                }
                else{
                    alert('Veuillez sélectionner un cable');
                    return
                }
            }
            

            if(this.cableSet.has(this.cablePicked+index)){
                alert('Cable déjà ajouté');
                return
            }
            //POSER LE CONTROLE POUR VOIR SI ON UTILISE PLUSIEURS FOIS LE MEME CABLE ENTRE DIFFERENTS CHANTIER
            if(this.cableArray.includes(this.cablePicked)){
                //console.log('Cable déjà ajouté ARRAY');
                const occurences = this.cableArray.filter(x => x===this.cablePicked).length;
                //console.log(occurences);
                //1 = 2 occurences
                var compare = [];
                for(let index in this.constructionCards){
                    if(this.constructionCards[index]['cables']){
                        if(this.constructionCards[index]['cables'][this.cablePicked]){
                            //trouvé
                            const start = this.constructionCards[index]['cables'][this.cablePicked]['meter'];
                            if(start === -1){
                                alert(`Vous avez des cables identiques entre les différentes fiches chantier, veuillez les ajouter dans l'ordre d'utilisation en renseignant le dernier metré début sur un chantier avant d'en ajouter un autre.`);
                                return
                            }
                            //on va récupérer le plus proche par raport au métré fin
                            const end = this.constructionCards[index]['cables'][this.cablePicked]['meterFinal']['end']
                            const diff = Math.abs(end-start);
                            //console.log(start);
                            //console.log(end);
                            //console.log(diff);
                            compare.push([start,diff]);
                        }
                    }
                }

                //ensuite choisir le metré début cohérent dans compare
                var finalChoice = -1;
                
                for(let i in compare){
                    if(finalChoice === -1){
                        finalChoice = compare[i][1];
                    };
                    if(finalChoice > compare[i][1]){
                        finalChoice = compare[i][1];
                    }
                }
                //finalChoice a sa valeur définitive
                for(let i in compare){
                    if(finalChoice === compare[i][1]){
                        last_before = compare[i][0]
                    }
                }
                //permet de garder en memoire le dernier ajouter et donc de griser les autres
                this.cableControlUniq[this.cablePicked] = this.cablePicked+index;
            }



            for(let i in this.itemCables){
                if(this.itemCables[i].item +' / ref :' +this.itemCables[i].ref === this.cablePicked){
                    let meter;
                    let meterFinal;
                    if(this.itemCables[i]['meter']){
                     meter = JSON.parse(this.itemCables[i]['meter']).start;
                     meterFinal = {
                         end : JSON.parse(this.itemCables[i]['meter']).end,
                         start : 0,
                     }
                    }
                    if(last_before){

                        this.constructionCards[index]['cables'][this.cablePicked ] = {
                    meter : -1,
                    before : last_before,
                    id : this.itemCables[i]['id'],
                    meterFinal : meterFinal};
                    this.cableSet.add(this.cablePicked+index);
                    this.cableArray.push(this.cablePicked);

                    }
                    else{

                        this.constructionCards[index]['cables'][this.cablePicked ] = {
                    meter : -1,
                    before : meter,
                    id : this.itemCables[i]['id'],
                    meterFinal : meterFinal};
                    this.cableSet.add(this.cablePicked+index);
                    this.cableArray.push(this.cablePicked);

                    }
                    
                }
            }
        },
        deleteCard(index){
            //permet de nettoyer les set et array getter de doublons
            for(let i in this.constructionCards[index]['cables']){
        //vérifier qu'on essaie pas de delete un cable faisant partie d'une série en plein milieu
        if(this.$refs['input-meter'+index+i].disabled){
                alert(`Suprression impossible, un cable est au milieu d'une logique d'utilisation du metré,
                veuillez supprimer du plus proche du metré fin au plus éloigné`);
                return
            }
                this.deleteCable(index,i);            }
            //supprimer la totalité de la fiche
            delete this.constructionCards[index];
            
        },
        deleteMaterial(index,material){
            delete this.constructionCards[index]['materials'][material];
            this.materialSet.delete(material+index);
        },
        materialSetQtty(event,index,material){
            if(event === '+'){
                this.constructionCards[index]['materials'][material] += 1;
                return
            }
            else if( event === '-'){
                this.constructionCards[index]['materials'][material] -= 1;
                return
            }
            this.constructionCards[index]['materials'][material] = event.target.value;
        },
        deleteCable(index,cable){
             //vérifier qu'on essaie pas de delete un cable faisant partie d'une série en plein milieu
            if(this.$refs['input-meter'+index+cable].disabled){
                alert(`Suprression impossible, un cable est au milieu d'une logique d'utilisation du metré, veuillez supprimer du plus proche du metré fin au plus éloigné.`);
                return
            }

            delete this.constructionCards[index]['cables'][cable];
            this.cableSet.delete(cable+index);
            var myIndex = this.cableArray.indexOf(cable);
            if (myIndex !== -1) {
            this.cableArray.splice(myIndex, 1);
            }

            let diff;
            //permet de modifier le dernier cable identique ajouté et donc de griser les autres
                if(this.cableControlUniq[cable] && this.cableControlUniq[cable] === cable+index){
                for(let idx in this.constructionCards){
                    for(let cab in this.constructionCards[idx]['cables']){
                        if(cab === cable){
                            //on fait en sorte que l'input qui va s'ouvrir est 
                            //celui du cable le plus proche du metré end, ordre logique
                            if(this.constructionCards[idx]['cables'][cab]){
                            const start = this.constructionCards[idx]['cables'][cab]['meter'];
                            //on va récupérer le plus proche par raport au métré fin
                            const end = this.constructionCards[idx]['cables'][cab]['meterFinal']['end']
                            const diff0 = Math.abs(end-start);
                            //1ere occurence
                            if(!diff){
                                diff = diff0;
                                this.cableControlUniq[cab] = cab+idx;
                            }
                            //comparer si les suivantes sont plus petites
                            if(diff > diff0){
                                diff = diff0;
                                this.cableControlUniq[cab] = cab+idx;
                            }
                            
                        }}
                    }
                }
                //si c'est tj le même, c'était le dernier, on le fait sauter
                if(this.cableControlUniq[cable] === cable+index){
                    delete this.cableControlUniq[cable];
                }
                }
        },
        cableSetQtty(event,index,cable){
            this.constructionCards[index]['cables'][cable]['meter'] = event.target.value;
            this.constructionCards[index]['cables'][cable]['meterFinal']['start'] = event.target.value;
        },
        activitySelected(event,index){
        this.constructionCards[index]['activity'] = event.target.value;
        this.activityPicked = event.target.value;
        if(this.firstStyle === true){
        this.firstStyle = false
        }
        this.size = 1;
        this.constructionPicked = '';
        this.constructionCards[index]['construction_number'] = '';
        this.town = '';
        event.target.blur();
        },
        clientSelected(event,index){
        this.constructionCards[index]['client'] = event.target.value;
        this.clientPicked = event.target.value;
        if(this.firstStyle2 === true){
        this.firstStyle2 = false
        }
        this.size2 = 1;
        this.constructionPicked = '';
        this.constructionCards[index]['construction_number'] = '';
        this.town = '';
        event.target.blur();
        },
        constructionSelected(event,index){
            const nb = event.target.value.split(' / ')[0];
        this.constructionCards[index]['construction_number'] = nb;
        for(let i in this.constructions){
            if(this.constructions[i]['date']+this.constructions[i]['incremented_nb'] == nb){
                this.constructionCards[index]['construction_id'] = this.constructions[i]['id'];
            }
        }
        this.constructionPicked = nb;
        if(this.firstStyle3 === true){
        this.firstStyle3 = false
        }
        this.size3 = 1;
        event.target.blur();
        },
        materialSelected(event){
        if(this.firstStyle4 === true){
        this.firstStyle4 = false
        }
        this.size4 = 1;
        event.target.blur();
        },
        cableSelected(event){
        if(this.firstStyle5 === true){
        this.firstStyle5 = false
        }
        this.size5 = 1;
        event.target.blur();
        },
        statusSelected(event,index){
            //console.log(event.target.value);
        this.constructionCards[index]['status'] = event.target.value;
        },
        datesGrouped(date) {
      return this.myRecentConstructions
        .filter((day) => this.dateFormat(day.date) === date)
        .map((day) => [day.construction_nb,day.item, day.qtty,day.town]);
        },
        datesGroupedGlobal(date){
            return this.globalRecentConstructions
        .filter((day) => this.dateFormat(day.date) === date)
        .map((day) => [day.construction_nb,day.item, day.qtty, day.username]);
        },
        nbGrouped(nb) {
      return this.myResumeConstructions
        .filter((data) => data.construction_nb === nb)
        .map((data) => [data.type_,data.item, data.qtty]);
        },
        nbGrouped2(nb) {
      return this.myResumeConstructionsGlobal
        .filter((data) => data.construction_nb === nb)
        .map((data) => [data.type_,data.item, data.qtty]);
        },
        onFocusSelect(bool){
            switch (bool) {
                case 0:
                    this.size = 4;
                    break;
                case 2:
                    this.size2 = 3;
                    break;
                case 3:
                    this.size3 = 4;
                    break;
                case 4:
                    this.size4 = 4;
                    break;
                case 5:
                    this.size5 = 4;
                    break;
                default:
                    break;
            }
        },
        onBlurSelect(bool){
        switch (bool) {
                case 0:
                    this.size = 1;
                    break;
                case 2:
                    this.size2 = 1;
                    break;
                case 3:
                    this.size3 = 1;
                    break;
                case 4:
                    this.size4 = 1;
                    break;
                case 5:
                    this.size5 = 1;
                    break;
                default:
                    break;
            }
    },
    splitRef(str,bool){
        if(bool){
           return str.split(' / ref :')[1];
        }
        else {
        return str.split(' / ref :')[0];
    }
    },
    calculQtty(before,after){
        if(after === -1){
            return 0
        }
        return Math.abs(before - after)

    },
    checkBoxState(bool,index){
        if(!bool){
            //console.log(index);
            //console.log(this.$refs['checkBoxFilter'+index]);
        //console.log(this.$refs['checkBoxFilter'+index].checked);
        const box = this.$refs['checkBoxFilter'+index].checked;
        if(box === true){
            //this.materialFilter = true;
            this.materialFilterList.push('checkBoxFilter'+index);
        }
        else {
            //this.materialFilter = false;
            var myIndex = this.materialFilterList.indexOf('checkBoxFilter'+index);
            if (myIndex !== -1) {
            this.materialFilterList.splice(myIndex, 1);
            }
        }
    }
    else{
        const box = this.$refs['checkBoxFilterBis'+index].checked;
        if(box === true){
            this.cableFilterList.push('checkBoxFilterBis'+index);
        }
        else {
            var myIndex = this.cableFilterList.indexOf('checkBoxFilterBis'+index);
            if (myIndex !== -1) {
            this.cableFilterList.splice(myIndex, 1);
            }
        }
    }},
    dateFormat(date){
        const date_ = date.split('T')[0];
        const easyDate = date_.split('-')[2]+' '+this.monthNames[parseInt(date_.split('-')[1])-1
        ]+' '+date_.split('-')[0];
        return easyDate
    },
    typeFormat(ty){
        if(ty === 'client'){
            return 'Matériel'
        } else{
            return 'Cable'
        }
    },
    setDateFilter(event){
        //alert(event.target.value);
        const number = event.target.value;
        switch (number) {
            case '1':
                this.interval = 0;
                break;
            case '2':
                this.interval = 86400000;
                break;
            case '3':
                this.interval = 604800000;
                break;
            case '4':
                this.interval = 2678400000;
                break;
        
            default:
                break;
        }
    },
    setDateFilterForGlobal(event){
        const number = event.target.value;
        switch (number) {
            case '1':
                this.intervalG = 0;
                break;
            case '2':
                this.intervalG = 86400000;
                break;
            case '3':
                this.intervalG = 604800000;
                break;
            case '4':
                this.intervalG = 2678400000;
                break;
        
            default:
                break;
        }
    },
    
    wordFilter2(){
        this.searchFilter = true;
    },
    
}}
</script>
<style scoped>

.activity-resume-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  max-width: 100%;
  width: 1100px;
  height: auto;
  font-size: 20px;
  background: #0d444b;
  border: 1px solid #f38414;
  border-radius: 16px;
  padding: 32px;
  margin-bottom: 30px;
   -webkit-box-shadow: 1px 2px 5px 1px #000000; 
  box-shadow: 2px 2px 7px 1px #000000;
}
.construction-card {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  /*align-items: center;*/
  color: white;
  max-width: 80%;
  width: 800px;
  height: auto;
  font-size: 20px;
  background-color: #f38414;
  border: 1px solid #f38414;
  border-radius: 16px;
  padding: 32px;
  margin-bottom: 30px;
   -webkit-box-shadow: 1px 2px 5px 1px #000000; 
  box-shadow: 2px 2px 7px 1px #000000;
}
.succes-message {
  width: 300px;
  height: 100px;
  border: 2px rgb(23, 189, 23) solid;
  font-size: 25px;
  font-weight: bold;
  border-radius: 5px;
  color: rgb(23, 189, 23);
  background-color: rgb(255, 255, 255);
  top: 30%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
  position: fixed;
  line-height: 100px;
  z-index : 10;
}
.filters-input{
max-width : 130px;
margin-top: 20px ;
}
.header-button {
    width : 100%;
}
.flex-container {
    display: flex;
    justify-content: space-around;
    border: 1px solid #f38414;
    background-color: #0d444b;
    padding: 20px;
    margin: 10px 0;
}
.adaptable-container {
    flex-wrap: wrap;
}
.material-card {
    margin : 20px;
    border: 1px solid yellow;
    background-color: #f38414;
    color: black;
    padding: 10px;
}
.crossSpan {
    cursor : pointer;
    border : 1px white solid;
    padding: 0 7px 2px 7px;
   
}
.cable-card {
    margin : 20px;
    border: 1px solid yellow;
    background-color: #f38414;
    color: black;
    padding: 15px;
    width: 500px;
}
.cable-card > div > label {
    margin :0px;
}
.cable-card > div {
    display: flex;
    justify-content: space-between;
}
.choosen {
    background-color: #f38414;
    padding : 5px;
    color : black;
}
.cable-card > .margin-top {
    margin-top : 30px;
}
.delete-button {
    width: 22px;
    font-size: 12px;
    margin : auto;
    margin-right : 0;
    font-weight: bold;
}
.cable-card,.material-card{
position : relative;
padding-top : 30px;
}
.cable-card > .delete-button ,.material-card > .delete-button{
position : absolute;
right : 0px;
top : 0px;
}
.show-btn {
  background-color:#0d444b;
  width: 300px;
  font-size: 18px;
  margin-bottom : 10px;
  color : white;
}
.no-margin {
    margin : 0;
}
.construction-select {
    font-size : 20px;
}
.selectAct {
font-size : 20px;
}
.construction-status {
    margin : 20px auto;
}
.construction-status > span {
    margin : 0 15px;
}
.mini-container > span,label {
    margin :20px;
}
.mini-container > label > i {
    margin-left :5px;
}
.mini-container2 > div {
    margin-top :15px;
}
.checkbox {
    margin : 10px auto;
    border: 1px solid #f38414;
    padding: 5px;
    width: 300px;
}
.checkbox input {
    filter: invert(75%) hue-rotate(18deg) brightness(1.7);
}
.number{
  width: 60px;
  text-align: center;
  margin-right : 10px;
  font-size : 20px;
}
.selectAct > option {
    padding : 5px;
    overflow:hidden; 
    white-space:nowrap; 
    text-overflow:ellipsis;
}
.addBtn{
    width: 30px;
    background-color: #f38414;
    font-size: 20px;
    padding-top: 0;
    margin-left: 10px;
    padding-bottom: 0px;
}
.validation-button {
    margin: auto;
    margin-top : 20px;
    width: 200px;
    font-size: 22px;
}
@-moz-document url-prefix() {
.number{
  width: 60px;
  
}}
.qtty {
  width: 40px;
  height: 40px;
  margin: 5px;
  font-size: 30px;
  color: #f38414;
  background-color: #0d444b;
}

/* PARTIE RECAP HISTORIQUE */

.history-grid {
    display: grid;
grid-template-columns: 1fr 1fr 1fr;
}
.history-grid span {
    background: white;
    align-content: center;
    padding: 2px;
    display: grid;
    align-items: center;
}
.history-grid .category {
    background: #f38414;
    color: white;
}
.history-day-card {
border: black 1px solid;
width: 800px;
margin: auto;
background-color: #0d444b;
margin-bottom : 20px;
padding: 0 5px ;
}
.flexi{
display: flex;
justify-content: center;
flex-wrap: wrap;
}
.nb_search:invalid{
border: 2px dashed red;
}
.nb_search:valid{
border: 2px solid black;
}
.history-day-card .span-hd {
border: 1px solid black;
margin: 10px;
}
.category {
border: 1px black solid;
margin-bottom: 5px;
background-color: white;
}
.infos-div {
    border: 1px solid #0d444b ;
    /*background-color: white;*/
}
.span-hd {
    display: flex;
    flex-direction: column;
    padding: 5px;
    background-color: #f38414;
    color: white;
}
 .h4-colored{
    background: #f38414;
    width: 400px;
    font-size: 25px;
    color: white;
    margin: auto;
 }
.no-bottom-margin {
margin-bottom: 0px;
margin-top: 50px;
}
.history-day-card .span-date {
max-width: 150px;
margin: 5px auto;
}
.select-date {
    margin: 10px auto;
}
.input-nb-container {
  margin-bottom: 10px;
}
.search-nb-container {
    border: 1px solid black;
    background: #f38414;
    margin: auto;
    margin-bottom: 30px;
    width: 400px;
    
}
.span-container {
margin: 20px;
}
.span-title {
border: 1px solid black;
padding: 5px;
}
.span-btn {
margin-left : 20px;
}
.span-info {
margin: 10px;
}
.empty {
    border: black 1px solid;
    max-width: 100px;
    margin: 20px auto;
}
.separator {
    width: 300px;
    border: 2px black solid;
    background: black;
    margin: 70px auto;
}
.cable-find {
background-color: white;
color: black;
width: 250px;
margin: 20px;
padding: 2px;
}
.search-div{
  margin : 10px auto;
  width: 300px;
}
.search-sub-div{
    display: flex;
    width: 100px;
    margin: auto;
}
.search-input {
  height : 30px;
  width: 60px;
  font-size: 20px;
  margin :0;
}
.fa-person-circle-plus {
    color: white;
}
@media screen and (max-width: 1100px) {
    input,select {
    background-color: white;
  }
.activity-resume-container {
  width: 340px;/**/ 
  padding: 0px;/**/ 
}
.construction-card {
  max-width:300px;/**/ 
  width: 300px;/**/ 
  padding: 0px;/**/ 
}
.flex-container {
    display: flex;
    flex-direction : column;/**/ 
    justify-content: space-around;
}
.material-card {
    margin : 20px;
    padding: 10px;
}
.cable-card {
    margin : 0px;/**/ 
    padding: 2px;/**/ 
    width: 250px;/**/ 
    display: flex;
    flex-direction : column;
}
.cable-card > div > label,span,div {
    margin :0px;/**/ 
    margin-bottom : 10px;/**/ 
}
.cable-card > div {
    display: flex;
    flex-direction : column;/**/
    justify-content: space-between;
}
.cable-card > .margin-top {
    margin-top : 0px;/**/
}
.delete-button {
    margin-top : 7px;/**/ 
    margin-right : 10px;/**/ 
}
.show-btn {
  width: auto;/**/
  font-size: 16px;/**/
  margin-bottom : 10px;/**/
}
.no-margin {
    margin-left : 10px;/**/
    }
.selectAct {
font-size : 17px;/**/ 
max-width: 240px;/**/ 
}
.construction-status > span {
    margin : 0 15px;
    font-size : 17px;/**/ 
}
/**/ 
.mini-container {
    margin-bottom :15px;
}
/**/
.mini-container > div {
    margin :10px;
}
.checkbox {
    padding: 1px;/**/ 
    width: 245px;/**/ 
}
.number{
  text-align: center;
  margin-right : 10px;
  font-size : 20px;
}
.validation-button {
    font-size: 20px;/**/ 
}
.cable-card,.material-card{
padding-top : 40px;
}
/* part history resume */
.history-day-card {
border: black 1px solid;
width: 320px;/**/ 
margin: auto;
background-color: #0d444b;
margin-bottom : 20px;
}
.flexi{
display: flex;
justify-content: center;
}
.nb_search:invalid{
border: 2px dashed red;
}
.nb_search:valid{
border: 2px solid black;
}
.history-day-card .span-hd {
border: 1px solid black;
margin: 10px;
}
.category {
border: 1px black solid;
margin-bottom: 5px;
background-color: white;
}
.infos-div {
    border: 1px solid #0d444b ;
    /*background-color: white;*/
}
.span-hd {
    display: flex;
    flex-direction: column;
    padding: 5px;
    background-color: #f38414;
}
 .h4-colored{
    background: #f38414;
    width: 250px;/**/ 
    margin: auto;
 }
.no-bottom-margin {
margin-bottom: 0px;
margin-top: 50px;
}
.bottom-margin {
margin-bottom: 25px;
}
.history-day-card .span-date {
max-width: 150px;
margin: 5px auto;
}
.select-date {
    margin: 10px auto;
}
.input-nb-container {
  margin-bottom: 10px;
}
.search-nb-container {
    border: 1px solid black;
    background: #f38414;
    margin: auto;
    margin-bottom: 30px;
    width: 280px;/**/ 
    
}
.span-container {
margin: 20px;
}
.span-title {
border: 1px solid black;
padding: 5px;
}
.span-btn {
margin-left : 20px;
}
.span-info {
margin: 10px;
}
.empty {
    border: black 1px solid;
    max-width: 100px;
    margin: 20px auto;
}
.history-cont span {
    overflow: scroll;
}
.search-div{
  margin : 10px auto;
  width: 280px;
}
.cable-find {
width: 210px;

}
}
</style>