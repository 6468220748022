<template>
    <div>
        <div  v-if="writingDocument === 'none'" class="activity-container">
            <h3>Code chantier</h3>
            <div id="constructionForScroll" class="activity-subcontainer">
                <div class="input-container">
                  <div class="constructionInputCont">
                   <div> <label for="client"> Client</label>
                  
                     <!--input v-model="clientConstruction" name="client" type="text"-->
                    <div v-if="!variousClient"><select class="selectAct large-selector" placeholder="" 
       :size="size" @focus="onFocusSelect(1)" @blur="onBlurSelect(1)"
       @change="clientSelected($event)"
       :style="[firstStyle ? {'color' : 'black', 'background-color' : 'white'}:{}]">
        <option>--Liste--</option>
        <option v-for="choice in clients" :key="choice"
        >{{choice}}</option>
      </select></div>
      <div v-else> <input type="text" v-model="clientConstruction"></div>
      </div>
      <div class="various-div"><span class="various-span" >Divers</span>
      <input type="checkbox" @change="variousClient = !variousClient"></div>
                  </div>

                    <div class="constructionInputCont">
                   <div> <label for="client"> Ville</label>
                    <input class="town-input" v-model="town" name="client" type="text"></div></div>

                    <div class="constructionInputCont">
                      <div>
                    <label for="activité">Activité</label>
                    <!--input v-model="activityConstruction" name="activité" type="text"-->
                    <div><select class="selectAct large-selector" placeholder="" 
       :size="size2" @focus="onFocusSelect(0)" @blur="onBlurSelect(0)"
       @change="activitySelected($event)"
       :style="[firstStyle2 ? {'color' : 'black', 'background-color' : 'white'}:{}]">
        <option>--Liste--</option>
        <option v-for="choice in allActivities" :key="choice"
        >{{choice.activity}}</option>
      </select></div>
                    </div></div>

                     <div class="constructionInputCont">
                   <div> <label for="coord"> Coordinateur</label>
                    <input class="town-input" v-model="coordinatorConstruction" name="coord" type="text"></div></div>

            
            <button class="construction-generator" ref="constructionGeneratorBtn" @click="newConstruction()"> Générer un code chantier </button>
            <div ref="hourglass" class="hourglass"> ⌛ </div>
            <div class="generatedCode" v-if="lastConstructionNumber != ''">{{lastConstructionNumber}} 
              <span v-if="lastConstructionNumber != '' && lastConstructionNumber != '⌛'"> a été généré ! 
                <button @click="lastConstructionNumber = ''">x</button>
              </span>
              </div>
            
            </div>

        <div class="filter-container">
            <h4>Mes chantiers</h4>
              <div class="margin"> <!-- activé / desactivé mode suppression-->
            <button v-if="!deleteMode" class="delete-btn" @click="deleteMode = true">Mode suppression</button>
            <button v-else class="delete-btn" @click="deleteMode = false">Retour</button></div>
            <div class="filter-sub-container">
            <span  class="filter-card" v-for="numb in activityStatusList" :key="numb">
              {{statusShow(numb)}} 
              <input v-if="numb != 6" class="checker" :ref="'status_'+numb.toString()" type="checkbox" checked>
              <input v-else class="number" :ref="'status_'+numb.toString()" type="number" value="1">
            </span>
            <button @click="getAllConstructionsByStatus()">Lancer la requête</button>
            </div>

            <div class="filter-client"> 
                     <!--input v-model="clientConstruction" name="client" type="text"-->
                    <select class="large-selector" placeholder="" 
       :size3="size3" @focus="onFocusSelect(2)" @blur="onBlurSelect(2)"
      @change="pickedClient = $event.target.value"
       :style="[firstStyle ? {'color' : 'black', 'background-color' : 'white'}:{}]">
        <option>--Filtre client--</option>
        <option v-for="choice in clients" :key="choice"
        >{{choice}}</option>
      </select>
      </div>
      <button class="legend-btn" @click="legend = true"
      @blur="legend = false"> Légende</button>
      <div v-if="legend" class="legend" >
            Légende des couleurs dans l'ordre du process de cloture : 
            <div><span class="circle-state no-border" style="background-color : black"></span> Document(s) initié(s) </div>
            <div><span class="circle-state" style="background-color : orange; border-color :yellow "></span> En cours Devis (& autres)</div>
            <div><span class="circle-state no-border" style="background-color : yellow"></span> Devis validé(s)</div>
            <div><span class="circle-state" style="background-color : orange; border-color :green"></span>  En cours Attachement(s) (& autres)</div>
            <div><span class="circle-state no-border" style="background-color : green"></span> Attachement(s) validé(s)</div>
            <div><span class="circle-state" style="background-color : orange; border-color :white"></span> En cours Facture(s)</div>
            <div><span class="circle-state no-border" style="background-color : white"></span> Facture(s) validé(s)</div>
            <div> Bordure jaune = Chantier en pause </div>
            <div> Bordure vertes = Chantier terminé/annulé </div>
          </div>
            </div>

            <div v-if="allConstructions && Object.values(allConstructions).length != 0" class="activity-card-container">
                <div class="activity-card" 
                :style="
                [construction.status_ === ('Terminé'||'Annulé') ? {'borderColor' : 'greenyellow', 'color' : 'greenyellow'}:{},
                construction.status_ === 'En pause' ? {'borderColor' : 'red', 'color' : 'red'}:{},
                constructionNumber === construction.date + construction.incremented_nb ? {'backgroundColor' : '#f38414'}:{}]"
                v-for="construction in filteredAllConstructions" :key="construction"
                @click="focusConstruction(construction)">
                    {{construction.date + construction.incremented_nb + ' / ' + construction.town}} 
                    <div v-if="findStyle(construction.id)"
                    :style="findStyle(construction.id)"
                    class="circle-state"></div>
                    <!-- supprimer une activité-->
        <i v-if="deleteMode" @click="deleteConstruction(construction.id)" class="fas fa-trash-alt">
            </i>
            </div>
            </div>
            <span v-else> Rien à afficher</span>

            <div v-if="moreInfos">
              <h4  class="span-label">Code chantier : <span style="color : white">{{this.constructionNumber}}</span></h4>
              <span><button v-if="!editMode" @click="editMode = true">Edition</button>
                <button v-else @click="editMode = false">Retour</button></span>
              <p v-if="!editMode"><span class="span-label">Statut</span> : <span>{{this.constructionStatus}}</span></p>
              <p v-else><span  class="span-label">Statut</span> : <span>
                 <select  placeholder="" @change="setNewStatus($event)">
        <option>{{this.constructionStatus}}</option>
        <option v-for="choice in statusList" :key="choice">{{choice}}</option>
      </select>
                </span> <button ref="constructionStatusBtn" @click="modifyStatus(construction)">Modifier</button>
                </p>
                <p><span  class="span-label">Activité</span> : <span>{{this.constructionActivity}}</span></p>
              <p><span  class="span-label">Client</span> : <span>{{this.constructionClient}}</span></p>
              <p><span  class="span-label">Ville</span> : <span>{{this.constructionTown}}</span></p>
              <p><span  class="span-label">Coordinateur</span> : <span>{{this.coordinator}}</span></p>
            </div>

            <div v-if="constructionNumber != ''" class="init-btn-div margin">
              <button @click="generateDocument('quotation')">Initier un devis</button>
              <button @click="generateDocument('summary')">Initier un attachement</button>
               <button @click="generateDocument('invoice')">Initier une facture</button>

                <!-- activé / desactivé mode suppression-->
            <button v-if="!deleteDoc " class="delete-btn" @click="deleteDoc = true">Supprimer un document</button>
            <button v-else class="delete-btn" @click="deleteDoc = false">Retour</button>
            </div>

            <div v-for="doc in this.documentsInfos" :key="doc">
              <div class="quotation-div" v-if="doc.id == this.id_construction">
                
                <span v-if="doc.type_ === 'quotation'" >
                  <!--DEVIS ICONE !-->
                 <button class="doc-icon quotation" @click="writeDocument(doc.type_,doc.document_name,doc.document_id,
                 doc.addresse,doc.document_status,doc.comment,'afterClick')"> &#128196;</button>
                  
                  <span class="doc-name" :class="{greenyellow : doc.document_status === 'validated' }">{{doc.document_name}}</span>
                  </span>

                  <span v-if="doc.type_ === 'summary'" >
                     <!--ATTACHEMENT ICONE !-->
                 <button class="doc-icon summary" @click="writeDocument(doc.type_,doc.document_name,doc.document_id,
                 doc.addresse,doc.document_status,doc.comment,'afterClick')"> &#128196;</button>
                  
                  <span class="doc-name" :class="{greenyellow : doc.document_status === 'validated' }">{{doc.document_name}}</span>
                  </span>

                  <span v-if="doc.type_ === 'invoice'" >
                     <!--INVOICE ICONE !-->
                 <button class="doc-icon invoice" @click="writeDocument(doc.type_,doc.document_name,doc.document_id,
                 doc.addresse,doc.document_status,doc.comment,'afterClick')"> &#128196;</button>
                  
                  <span class="doc-name" :class="{greenyellow : doc.document_status === 'validated' }">{{doc.document_name}}</span>
                  </span>

                <i v-if="deleteDoc" @click="deleteDocument(doc.document_id)" class="fas fa-trash-alt">
            </i>
            <span v-if="deleteDoc && deletingDoc"> ⌛
            </span>
              </div>
            </div>

            <div v-if="constructionsResumeAdmin[0] && constructionsResumeAdmin[0].length != 0">
            <h5>Résumé matériel :</h5>
            <div class="flex-recap">
            <div  v-for="construct in constructionsResumeAdmin[0]" :key="construct">
             <span class="orange"> {{construct['item']}} </span> : 
             <span >{{construct['qtty']}} <span v-if="construct['type_'] === 'cable'">mètres</span></span>
            </div></div>

            </div></div>
        </div>
        <!------------------------------ DOCUMENT LOGIC --------------------!-->
        <div v-else class="document-page"
        :class="{lockEvent : lockClick}">
        <div v-if="!openConfirmDiv" class="lowOpacity"></div>
          <button class="back" @click="backPage()">&#8678;</button>
          <h4>{{documentPicked||'DEVIS'}} 
          <button v-if="writingDocument != 'invoice'" class="doc-button3" >
            <button class="absolute-btn-0" @click="notUnder0()">-</button>
            V{{versionNumber}} 
            <button class="absolute-btn-1" @click="versionNumber = parseInt(versionNumber) + 1">+</button>
            </button></h4>
         
          <!--{{documentsData}}
          {{benefitName}}
          {{benefitRef}}
          <div style="color : white">{{document}}</div>!-->
         
      <div class="document-grid">
        <div class="doc-part doc-client" > 
          <div>{{constructionClient}} </div>
          <textarea v-if="modifyAddresse" type=""
          style="font-size : 20px;"
          rows="4" v-model="clientAddresse2"> </textarea>
          <span v-else-if="clientAddresse2">{{clientAddresse2}}</span>
          <span v-else-if="clientAddresse != 'Adresse non répertoriée'">{{clientAddresse}}</span>
          <textarea v-else-if="!clientAddresse2 && clientAddresse == 'Adresse non répertoriée'" type=""
          style="font-size : 20px;"
          rows="4" v-model="newAddresses"> </textarea>
          
          <button v-if="!modifyAddresse 
          && ((clientAddresse === 'Adresse non répertoriée' && clientAddresse2)
          || (clientAddresse != 'Adresse non répertoriée' && !clientAddresse2))" class="document-edit"
          @click="modifyAddresse = !modifyAddresse">✎</button>
          <button v-if="modifyAddresse " class="document-edit"
          @click="modifyAddresse = !modifyAddresse"
          >X</button>

        </div>
        <div class="doc-part doc-resume">
          <div>Activité :  {{constructionActivity}}</div>
          <div>Numéro : {{documentPicked}} </div>
          <div>date : {{new Date(Date.now())
                          .toLocaleString()
                          .split(" ")[0]}}</div>
          <div v-if="writingDocument != 'summary'" class="flex-recap">
          Réf client : <textarea v-model="client_ref_comment"></textarea></div>

        </div>
        <div class="doc-part doc-data" :class="{lockEvent : lockClick2}">

          <div v-if="writingDocument === 'summary'" class="flex-row">
            <div v-for="doc in this.documentsInfos" :key="doc">
              <div class="quotation-div" v-if="doc.id == this.id_construction">
                
                <span v-if="doc.type_ === 'quotation'" >
                  <!--DEVIS ICONE !-->
                 <button class="doc-icon quotation relative" 
                 @click="injectDocumentTotal(doc.document_id)"> 
                 &#128196; <span class="thumbtack">&#128204; </span></button>
                 <button class="doc-icon quotation" 
                 @click="injectRows(doc.document_id)"> &#128196;</button>
                 <button class="doc-icon quotation" 
                 @click="showRecap = doc.document_id;showDocTotal(doc.document_id)"
                 @blur="showRecap =-1"> &#128064;</button>
                  <div class="flex-recap2" v-if="showRecap === doc.document_id">
                    <div v-for="line,ix in oneDocument(doc.document_id)" :key="line" :index="ix">
                      {{line.qtty}} x {{line.name}} ({{line.price}}) = {{fixRounding(line.qtty*line.price,2)}} €
                    <div v-if="ix+1 == oneDocument(doc.document_id).length">
                      Total : {{pickedTotal}} €
                    </div>
                    </div>
                    <div v-if="oneDocument(doc.document_id).length === 0">
                      Vide
                    </div>
                  </div>
                  <span class="doc-name" :class="{greenyellow : doc.document_status === 'validated' }">{{doc.document_name}}</span>
                  </span>
              </div>
            </div>
          </div>

          <div v-else-if="writingDocument === 'invoice'" class="flex-row">
            <div v-for="doc in this.documentsInfos" :key="doc">
              <div class="quotation-div" v-if="doc.id == this.id_construction">
                
                <span v-if="doc.type_ === 'summary'" >
                  <!--DEVIS ICONE !-->
                 <button class="doc-icon summary relative" 
                 @click="injectDocumentTotal(doc.document_id)"> 
                 &#128196; <span class="thumbtack">&#128204; </span></button>
                 <button class="doc-icon summary" 
                 @click="injectRows(doc.document_id)"> &#128196;</button>
                 <button class="doc-icon summary" 
                 @click="showRecap = doc.document_id;showDocTotal(doc.document_id)"
                 @blur="showRecap =-1"> &#128064;</button>
                  <div class="flex-recap2" v-if="showRecap === doc.document_id">
                    <div v-for="line,ix in oneDocument(doc.document_id)" :key="line" :index="ix">
                      {{line.qtty}} x {{line.name}} ({{line.price}}) = {{fixRounding(line.qtty*line.price,2)}} €
                    <div v-if="ix+1 == oneDocument(doc.document_id).length">
                      Total : {{pickedTotal}} €
                    </div>
                    </div>
                    <div v-if="oneDocument(doc.document_id).length === 0">
                      Vide
                    </div>
                  </div>
                  <span class="doc-name" :class="{greenyellow : doc.document_status === 'validated' }">{{doc.document_name}}</span>
                  </span>
              </div>
            </div>
          </div>
         

          <div class="header-table row-document ">
            <span>⚙️</span>
            <span>REF</span><span>DESIGNATION</span><span>Q'TE</span>
            <span>TARIF</span><span>TOTAL HT</span>
          </div>
          
          <div class="row-document "
          v-for="row,index in this.document" :key="row" :index="index">
          
          <div>
          
          <!--bouton edition en cas de ligne deja existante -->
          <button v-if="!modifyLock.includes('line'+index) && row.id != -1 
          && row.id != -2 && !isDocument(row.ref)" class="document-edit"
          @click="modifyLock.push('line'+index)" style="margin-left :0">✎</button>
          <button v-else-if="row.id != -1 && row.id != -2 && !isDocument(row.ref)" class="document-edit"
          style="margin-left :0"
          @click="modifyLock = []"
          >X</button>
             </div>
          <div :class="{modified : row.id === -2 || isDocument(row.ref)}">

             <!-- Plus grand pour voir le nom du devis ou de l'attachment !-->
             <input disabled="true" 
            v-if="row.id === -2 || isDocument(row.ref)"
            class="inputRef large-input"
             type="text" v-model="row.ref">

            <input :disabled="!modifyLock.includes('line'+index)" 
            v-else-if="row.id != -1 || row.action === 'injecting'"
            class="inputRef"
             type="text" v-model="row.ref">
             
            <input v-else 
            class="inputRef"
            type="text" v-model="row.ref">
            
            </div>

            <div>
              <!-- vide si row.id === -2 !-->
            <input v-if="row.id === -2" 
            class="inputName"
            type="text"  v-model="row.name">
            <!-- existante !-->
            <input :disabled="!modifyLock.includes('line'+index)" 
            v-else-if="row.id != -1 || row.action === 'injecting'" 
            class="inputName" type="text" v-model="row.name">
            <!-- modifiable !-->
            <input v-else-if="row.action === 'empty'" 
            class="inputName"
            type="text"  v-model="row.name">
            <!-- selecteur, dynamique !-->
            <select v-else-if="row.action === 'dynamic'" class="selectName" name="" :id="'sel'+index"
            @change="putDynamicRow($event,index)">
            <option value=""> -Liste-</option>
              <option :value="name"
              v-for="name in this.benefitName" :key="name">
              {{name}}</option>
            </select>
            </div>

            <div>
            <input :disabled="!modifyLock.includes('line'+index)" v-if="row.id != -1 || row.action === 'injecting'" 
            type="number" @change="setHtPrice(row.qtty,row.price,index)" v-model="row.qtty">
            <input v-else type="number" @change="setHtPrice(row.qtty,row.price,index)" v-model="row.qtty"></div>
            
            <div>&nbsp;&nbsp;&nbsp;
              <input :disabled="!modifyLock.includes('line'+index)"  v-if="row.id != -1 || row.action === 'injecting'"
               type="number" @change="setHtPrice(row.qtty,row.price,index)" v-model="row.price">
             <input v-else type="number" @change="setHtPrice(row.qtty,row.price,index)" v-model="row.price"> &nbsp;€</div>
            
            <div>
            <span>{{row.htPrice}}€</span>

              <i v-if="!deletingRow" @click="deleteDataRow(row.id,index)" class="fas fa-trash-alt ">
            </i>
            <i v-else class="hourglass"> ⌛ 
          </i>
          </div>
          </div>
          <div class="flex-div">
          <button class="doc-button" @click="newLine('empty')">+ Ligne vide</button>
          <button :disabled="confirmVarious === true && this.benefitName.size === 0"
           class="doc-button" @click="newLine('dynamic')">+ Ligne dynamique</button>
           </div>

           <!-- mini recap chantier -->
            <div class="recap-container"
            v-if="showMatRecap && writingDocument != 'quotation' && constructionsResumeAdmin[0] && constructionsResumeAdmin[0].length != 0">
            <h5><button class="btn-hide-recap" @click="showMatRecap = false">X</button> Résumé matériel : </h5> 
            <div class="flex-recap">
            <div  v-for="construct in constructionsResumeAdmin[0]" :key="construct"
            class="recap-row">
             <span class="orange"> {{construct['item']}} </span> : 
             <span >{{construct['qtty']}} <span v-if="construct['type_'] === 'cable'">mètres</span></span>
            </div></div>

            </div>
            <button class="btn-see-recap"
            v-else-if="writingDocument != 'quotation' && constructionsResumeAdmin[0] && constructionsResumeAdmin[0].length != 0" @click="showMatRecap = true">&#128064; Materiel</button>
        </div>

        <div class="btn-cont">
           <button class="doc-button save" @click="saveDocument('record')">
            <span v-if="!lockClick">&#128190;</span>
            <span v-else>⌛</span>
            Enregistrer</button>
          <button class="doc-button doc-button2" @click="download('see')">PDF <i class="fa fa-solid fa-eye" aria-hidden="true"></i></button>
           <button class="doc-button doc-button2" @click="download('download')">PDF <i class="fa fa-download" aria-hidden="true"></i></button>
          <!-- <button v-if="writingDocument != 'invoice'" class="doc-button3" >
            <button class="absolute-btn-0" @click="notUnder0()">-</button>
            V{{versionNumber}} 
            <button class="absolute-btn-1" @click="versionNumber = parseInt(versionNumber) + 1">+</button>
            </button>!-->
            <button v-if="writingDocument === 'invoice'" class="doc-button doc-button2"
            @click="switchTimeLimit()">
              <span v-if="invoiceTimeLimit === '45 FDM'">45j FDM</span>
              <span v-else-if="invoiceTimeLimit === '30 FDM'">30j FDM </span>
              <span v-else-if="invoiceTimeLimit === 45">45j</span>
               <span v-else >30j</span>
            </button>
                      <div class="doc-state-cont" style="color : white">
           <span style="font-size : 22px">Etat : </span>  
           <button v-if="!docValidation && this.writingDocument === 'summary'" @click="askConfirming()" class="doc-state red" >Non validé</button>
          <button v-else-if="!docValidation" @click="docValidation = !docValidation" class="doc-state red" >Non validé</button>
           <button  v-else-if="docValidation 
           && writingDocument === 'summary'
           && client_ref_comment != '' && client_ref_comment != 'à préciser'" class="doc-state green" >Cloturé {{client_ref_comment}} &#9989;</button>
           <button v-else @click="docValidation = !docValidation" class="doc-state green" >Validé &#9989;</button></div>
        
        <!-- pour le cas de l'attachement -->
        <div class="closeSummary" v-if="!openConfirmDiv">
          <span class="absolute-icon">⚠️</span>
          <span>  Confirmer la cloture défintive de l'attachement ?</span>
          <div>
          <span>Date de clotûre : </span> 
          <input type="search" name="date" id="" v-model="closingDate"></div>
          <button @click="closeSummary()">Oui</button>
          <button @click="askConfirming()">Annuler</button>
        </div>
        
        </div>

        <div class="doc-part doc-total">
          <span> Total HT : {{totalHt.toFixed(2)}} €</span>
          <br>
          <span v-if="!noTVA">TVA : {{(this.fixRounding((totalHt* 0.2),2)).toFixed(2)}} € </span>
          <span v-else>TVA : Auto-Liquidation </span>
          <br>
          <span v-if="!noTVA">Total TTC : {{(this.fixRounding((totalHt + totalHt * 0.2),2)).toFixed(2)}} €</span>
          <span v-else>Total TTC : {{(this.fixRounding((totalHt),2)).toFixed(2)}} €</span>
          <br>
          <button @click="noTVA = !noTVA">TVA
            <span v-if="!noTVA">✅ </span>
            <span v-else>❌</span>
          </button>
        </div>


      </div>
    </div>
    </div>
    
</template>
<script>

import { image, signature, iban_digi,iban_digi2,
iban_digi3,iban_digi4, mentions_digi } from "../../../assets/logo.js";
import pdfMake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { mapState } from "vuex";

const myAppPath = require('../../../switchUrl');
const appPath = myAppPath.appPath;

export default {
    data() {
        return {
          connectedUser: {},  
          deleteMode : false,
          activity : '',
          activityConstruction : '',
          clientConstruction : '',
          town : '',
          coordinatorConstruction : '',
          firstStyle : true,
          firstStyle2 : true,
          size :1,
          size2 :1,
          size3 :1,
          deleteMode : false,
          deleteDoc : false,
          moreInfos : true,
          constructionNumber : '',
          constructionTown : '',
          constructionClient : '',
          constructionActivity : '',
          constructionStatus : '',
          coordinator : '',
          id_construction : '',
          editMode : false,
          cStatusResponse : false,
          newStatusConstruction : '',
          activityStatusList : [1,2,3,4,5,6],
          lastConstructionNumber : '',
          pickedClient : '--Filtre client--',
          variousClient : false,
          writingDocument : 'none',
          documentPicked : '',
          documentId : '',
          clientAddresse : 'Adresse non répertoriée',
          document : [],
          deletingRow : false,
          isVarious0 : false,
          modifyLock : [],
          docValidation : false,
          newAddresses : '',
          clientAddresse2 : false,
          client_ref_comment : 'à préciser',
          modifyAddresse : false,
          confirmVarious : false,
          lockClick : false,
          str_maj_type : '',
          showRecap : -1,
          openConfirmDiv : true,
          closingDate : (new Date()).toLocaleDateString("fr-FR"),
          deletingDoc : false,
          showMatRecap : true,
          versionNumber : 1,
          legend : false,
          noTVA : true, //par defaut true demande Alexis
          invoiceTimeLimit : '45 FDM',
          objStatus : {
            quotation :
            {
            validated : [],
            invalidated : [],
            initial : []
            },
            summary :
            {
            validated : [],
            invalidated : [],
            initial : []
            },
            invoice :
            {
            validated : [],
            invalidated : [],
            initial : []
            },
          }
          
        }
    },
    mounted() {
    this.$store.commit("isConnected");
    // récuperer l'utilisateur actuel
    this.connectedUser = JSON.parse(localStorage.getItem("connectedUser"));

    // envoyer un filtre sous forme de numero pour les statuts
    // (1afaire)(2encours)(3enpause)(4annulé)(5terminé)
    const strInfos ='1+2+3+4+5+m1'+'_'+this.connectedUser.id_user ;
    this.$store.dispatch('getAllConstructions',strInfos);
    this.$store.dispatch('getAllActivities',this.connectedUser.id_user);
    this.$store.dispatch('getClientFromServices',this.connectedUser.id_user);
    this.$store.dispatch('getDocumentsInfos',this.connectedUser.id_user);
    this.$store.dispatch('getDocumentsData',this.connectedUser.id_user);
    this.$store.dispatch("getAddresses", this.connectedUser.id_user);
    this.$store.dispatch("getServices",this.connectedUser.id_user);
    },
    computed: {
    ...mapState({
      constructionResponse : "constructionResponse",
      constructionStatusResponse : "constructionStatusResponse",
      allActivities : 'allActivities',
      allConstructions : 'allConstructions',
      clientFromServices : "clientFromServices",
      documentsInfos : "documentsInfos",
      constructionsResumeAdmin : 'constructionsResumeAdmin',
      allAddresses: "allAddresses",
      documentsData : "documentsData",
      servicesClients: "servicesClients"
    }),
    lockClick2(){
      if(this.docValidation 
           && this.writingDocument === 'summary'
           && this.client_ref_comment != '' && this.client_ref_comment != 'à préciser'){
            return true
           }
      return false
    },
    
    benefitRef(){
      var thisSet = new Set();
      for(let i in this.servicesClients){
        if(this.constructionClient === this.servicesClients[i].client){
          this.confirmVarious = false;
        thisSet.add(this.servicesClients[i].ref)}
      }
      //si pas trouvé le client 
      if(thisSet.size === 0){
        this.confirmVarious = true;
        for(let i in this.servicesClients){
        if( this.servicesClients[i].client === 'Divers'){
        thisSet.add(this.servicesClients[i].ref)}
      }}
      return thisSet;
    },
    benefitName(){
      var thisSet = new Set();
      for(let i in this.servicesClients){
        if(this.constructionClient === this.servicesClients[i].client){
          this.confirmVarious = false;
        thisSet.add(this.servicesClients[i].name)}
      }
      //si pas trouvé le client 
      if(thisSet.size === 0){
        this.confirmVarious = true;
        for(let i in this.servicesClients){
        if( this.servicesClients[i].client === 'Divers'){
        thisSet.add(this.servicesClients[i].name)}
      }}
      return thisSet
    },
    isVarious(){
      if(this.clients.has(this.constructionClient) && !this.isVarious0){
        return false
      }
      else{
      return true
      }
    },
    clients() {
      var thisSet = new Set();
      if(Object.values(this.clientFromServices).length != 0){
        this.clientFromServices.forEach(element => {
          //console.log(element.client);
          thisSet.add(element.client);
        });}
      return thisSet;
    },
    statusList (){
      var list = ['A faire','En cours','En pause','Annulé','Terminé'];
      if(list.includes(this.constructionStatus)){
        var myIndex = list.indexOf(this.constructionStatus);
      if (myIndex !== -1) {
        list.splice(myIndex, 1);
    }
      }
      return list;
    },
    filteredAllConstructions(){
      var obj = {};
      if(this.pickedClient === '--Filtre client--'){
          return this.allConstructions
        }

      for(let i in this.allConstructions){
        //filter client
        if(this.allConstructions[i].client === this.pickedClient){
          obj[i] = this.allConstructions[i];
          }
      }
      return obj
    },
    totalHt(){
      var total = 0;
      for(let i in this.document){
        //console.log(typeof parseFloat(this.document[i]['htPrice']));
        if(typeof parseFloat(this.document[i]['htPrice']) === 'number'){
        total = total + parseFloat(this.document[i]['htPrice']);
        }
      }
      return this.fixRounding(total,2)
    }
    },
    watch : {
        documentsInfos(newCount, oldCount) {
          if (newCount){
            //reinit obj Status
            this.objStatus = {
            quotation :
            {
            validated : [],
            invalidated : [],
            initial : []
            },
            summary :
            {
            validated : [],
            invalidated : [],
            initial : []
            },
            invoice :
            {
            validated : [],
            invalidated : [],
            initial : []
            },
          }
            for(let i in this.documentsInfos){
              const doc = this.documentsInfos[i];
              //gérer les couleurs des codes chantiers
              this.sortStatus(doc.type_,doc.document_status,doc.id);

              //actualiser le document en cours
              if(doc.document_id === this.documentId){
                //actualiser seulement si ouvert
                if(this.writingDocument != 'none'){
                 this.writeDocument(doc.type_,doc.document_name,doc.document_id,doc.addresse,
                 doc.document_status,doc.comment,'afterRefresh')}
              }
            }
            
          }
        },
        constructionResponse(newCount, oldCount) {
      if (newCount === true) {
        this.lastConstructionNumber = '⌛';
        //alert("Echec de la commande");
        this.$refs['constructionGeneratorBtn'].disabled = false;
        this.$refs['hourglass'].style.display = 'none';
        this.$store.state.constructionResponse = false;
        setTimeout(() => {
          var pop = this.allConstructions[(this.allConstructions.length-1)];
          this.id_construction = pop.id;
        this.lastConstructionNumber = pop.date + pop.incremented_nb;
        for(let i in this.allConstructions){
          //afficher le nouvellement créée
          if(this.allConstructions[i].id === pop.id){
            this.focusConstruction(this.allConstructions[i]);
          }
        }
        }, 500);
        
      }},
      constructionStatusResponse(newCount, oldCount) {
      if (newCount === true) {
        this.cStatusResponse = true;
        }},
      allConstructions(newCount, oldCount) {
      if (newCount && this.cStatusResponse===true) {
        for(let i in this.allConstructions){
          //afficher le nouvellement créée
          if(this.allConstructions[i].id === this.id_construction){
            this.focusConstruction(this.allConstructions[i]);
          }
        this.$refs['hourglass'].style.display = 'none';
        this.$refs['constructionStatusBtn'].disabled = false;
        this.editMode = false;
        this.cStatusResponse = false;
        this.$store.state.constructionStatusResponse = false;
        }
        
      }},
      documentsData(newCount, oldCount) {
      if (newCount) {
        // surtout utile pour actualiser apres suppresion d'une ligne
        //document data
        this.document=[];

      for(let i in this.documentsData){
        this.documentsData[i]['price'] = this.documentsData[i]['price'].toFixed(2);
        
        if(this.documentsData[i].document_id === this.documentId){
           this.document.push(
        {
            'id' : this.documentsData[i].id,
            'ref' : this.documentsData[i].ref,
            'name' : this.documentsData[i].name,
            'qtty' : this.documentsData[i].qtty,
            'price' : this.documentsData[i].price,
            'htPrice' : (this.fixRounding((this.documentsData[i].qtty*this.documentsData[i].price),2)).toFixed(2),
            'action' : 'existing'}
      );
        }
      };

      //push empty line
      //this.newLine('empty')
      
      // enlver le sablier de suprpession
      this.deletingRow = false;
       // enlever le loader de sauvegarde
      if(this.lockClick = true){
              this.lockClick = false
            }
        
      }}
      
    },
    methods: {
      askConfirming(){
      this.openConfirmDiv = !this.openConfirmDiv
      },
      sortStatus(type_,status_,id){
        /*console.log(type_,status_,id)
        console.log(this.objStatus[type_])
        console.log(this.objStatus[type_][status_])*/

        //evite les doublons sans passer par un set
        if(this.objStatus[type_] && this.objStatus[type_][status_] ){
        if(!this.objStatus[type_][status_].includes(id)){
          this.objStatus[type_][status_].push(id);
        }}
        else{
          this.objStatus[type_][status_] = [];
          this.objStatus[type_][status_].push(id);
        }
        
      },
      switchTimeLimit(){
      if(this.invoiceTimeLimit === '45 FDM'){
        this.invoiceTimeLimit = '30 FDM';
      }
      else if (this.invoiceTimeLimit === '30 FDM'){
        this.invoiceTimeLimit = 45;
      }
      else if(this.invoiceTimeLimit === 45){
        this.invoiceTimeLimit = 30;
      }
      else {
        this.invoiceTimeLimit = '45 FDM';
      }
    },
      closeSummary(){
       //verifier le format de la date jj/mm/aaaa
      var pattern =/^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/;
       
       if(!pattern.test(this.closingDate)){
           alert("Erreur de format de date, veuillez respecter ce format : jj/mm/aaaa");
           return
         }
         this.saveDocument('closing');
         this.openConfirmDiv = !this.openConfirmDiv;

      },
      showDocTotal(id){
        for(let i in this.documentsInfos){
          if(this.documentsInfos[i].document_id === id){
            this.pickedTotal = this.documentsInfos[i].total
          }
        }
      },
    oneDocument(id){
      var array = [];
      for(let i in this.documentsData){
        //console.log(this.documentsData[i].id);
        //console.log(id);
        //console.log('----');
        if(this.documentsData[i].document_id === id){
          array.push(this.documentsData[i]);
        }
        
      }
      return array
    },
     fixRounding(value, precision) {
    var power = Math.pow(10, precision || 0);
    return Math.round(value * power) / power;
    },
    newConstruction(){
            if(this.clientConstruction === '' || this.activityConstruction === '' || this.town === ''){
                alert('Veuillez remplir tous les champs');
                return;
            }
            this.$refs['constructionGeneratorBtn'].disabled = true;
            this.$refs['hourglass'].style.display = 'block';
            var activityNumber = '';
            for(let i in this.allActivities){
              if(this.allActivities[i].activity === this.activityConstruction){
                activityNumber = this.allActivities[i].id;
              }
            }
            if(activityNumber === ''){
              alert('Activité non reconnue');
              this.$refs['constructionGeneratorBtn'].disabled = false;
              this.$refs['hourglass'].style.display = 'none';
              return
            }
            const date = new Date();
            const monthDate = ("0"+(date.getMonth()+ 1)).slice(-2);
            const yearDate = date.getFullYear();
            const strDate = yearDate +'-'+ monthDate + '-';
            //alert(strDate);
            const obj = {
                client : this.clientConstruction,
                town : this.town,
                activity : activityNumber,
                date : strDate,
                coordinator : this.coordinatorConstruction,
                various : this.variousClient,
                user_id : this.connectedUser.id_user
                            }
            this.$store.dispatch("createConstruction",obj );
        },
    deleteConstruction(id) {
            this.$refs['hourglass'].style.display = 'block';

      //fetching
      this.fetchDeleteConstruction(id)
        .then((data) => {
          //result
          if (data["data"].affectedRows) {
              //console.log(data);
            }
          else {alert('Suppression non effective')}
          this.$store.dispatch('getAllConstructions','1+2+3+4+5+m1'+'_'+this.connectedUser.id_user);
          this.$refs['hourglass'].style.display = 'none';
        })
        .catch((e) => console.log(e));
    },
    async fetchDeleteConstruction(id) {
      const construction_id = id;
      let key;
      //key for only web app native request (!postman)
      if (
        (this.connectedUser.a5e789410fd.includes("2f+g2dfg25h2d+f15b1gs5bnj1ki1lo61q1dc16"))
      ) {
        key = "h1d5he15fdhs";
      }
      const requestOptions = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
        body: JSON.stringify({
          construction: construction_id,
          user_id: this.connectedUser.id_user,
          key: key,
        }),
      };

      let response = await fetch(
        appPath+"/activities/delete_construction",
        requestOptions
      );
      if (!response.ok) {
        alert("Une erreur empêche la requête");
        //stop loader
        this.putLoader = false;
        // get error message from body or default to response status
        const error = (data && data.message) || response.status;
        //console.log('not response ok, error : ' + error);
        //console.log(error);
        return Promise.reject(error);
      }
      return await response.json();
    },
    getAllConstructionsByStatus(){
      var list = [];
      if(this.$refs['status_1'].checked === true){
        list.push('1');
      }
      if(this.$refs['status_2'].checked === true){
        list.push('2');
      }
      if(this.$refs['status_3'].checked === true){
        list.push('3');
      }
      if(this.$refs['status_4'].checked === true){
        list.push('4');
      }
      if(this.$refs['status_5'].checked === true){
        list.push('5');
      }
      if(this.$refs['status_6'].value > 0){
        const nb = 'm'+this.$refs['status_6'].value.toString()
        list.push(nb);
      }
      else {
        alert('Choisissez une période supérieur à 0...');
        return
      }
      if(list.length === 0){
        alert('Aucun élément coché...');
        return
      }
      var str = '';
      for(let i in list){
        //console.log(typeof list.length);
        //console.log(typeof i)
        if(list.length === parseInt(i)+1){
          str += list[i];
        }
        else{
          str += list[i]+'+';
        }
      }
      //alert(str);
    //nettoyer le picked actuel sur demande Alexis
    this.constructionNumber = '';
    this.constructionTown = '';
    this.constructionClient = '';
    this.constructionActivity = '';
    this.constructionStatus = '';
    this.id_construction = '';

    const strInfos =str+'_'+this.connectedUser.id_user ;
    this.$store.dispatch('getAllConstructions',strInfos);
    },
    statusShow(number){
      switch (number) {
        case 1:
          return 'A faire'
          break;
        case 2:
          return 'En cours'
          break;
        case 3:
          return 'En pause'
          break;
        case 4:
          return 'Annulé'
          break;
        case 5:
          return 'Terminé'
          break;
        case 6:
          return 'Mois'
          break;
      
        default:
          break;
      }
    },
    //for construction code generator picker 
    clientSelected(event){
      this.clientConstruction = event.target.value;
      if(this.firstStyle === true){
        this.firstStyle = false
      }
      this.size = 1;
      event.target.blur();
    },
    activitySelected(event){
      this.activityConstruction = event.target.value;
      if(this.firstStyle2 === true){
        this.firstStyle2 = false
      }
      this.size2 = 1;
      event.target.blur();
    },
    
    onFocusSelect(bool){
      if(bool){
        if(bool === 2){
          this.size3 = 4;
          return
        }
      this.size = 4;
      return}
      this.size2 = 6;
    },
    onBlurSelect(bool){
      if(bool){
        if(bool === 2){
          this.size3 = 1;
        }
      this.size = 1;
      return}
      this.size2 = 1;
    },
    /*formatNb(number){
      return ("00"+number).slice(-3);
    },*/
    focusConstruction(construction){
      //alert('focus');
      this.constructionNumber = construction.date + construction.incremented_nb;
      this.constructionTown = construction.town;
      this.constructionClient = construction.client;
      this.constructionActivity = construction.activity;
      this.constructionStatus = construction.status_;
      this.id_construction = construction.id;
      this.coordinator = construction.coordinator;

      //requete pour avoir le résume du chantier
      const userStr ='construction-nb-Admin'+ this.constructionNumber+'_'+this.connectedUser.id_user ;
    this.$store.dispatch('getConstructionsHistoryResume',userStr);
    },
    setNewStatus(event){
      this.newStatusConstruction = event.target.value;
    },
    modifyStatus(){
      if(this.newStatusConstruction === ''){
        alert('Veuillez choisir un nouveau statut');
                return;
      };
      if(this.id_construction === ''){
        alert('Veuillez choisir un code chantier');
                return;
      };
       this.$refs['constructionStatusBtn'].disabled = true;
       this.$refs['hourglass'].style.display = 'block';
      const obj = {
                construction : this.id_construction,
                status_ : this.newStatusConstruction,
                username : this.connectedUser.username,
                user_id : this.connectedUser.id_user
                            }
      this.$store.dispatch("modifyConstructionStatus",obj );

    },
    generateDocument(type_) {
      // FETCH
      this.fetchGenerateDocument(type_)
        .then((data) => {
          if(data['data']){
          this.$store.dispatch('getDocumentsInfos',this.connectedUser.id_user)};
        })
        .catch((e) => console.log(e));
    },
    async fetchGenerateDocument(type_) {
        let key;
      //key for only web app native request (!postman)
      if (
        (this.connectedUser.a5e789410fd.includes("2f+g2dfg25h2d+f15b1gs5bnj1ki1lo61q1dc16"))
      ) {
        key = "h1d5he15fdhs";
      }
      let strType;
      if(type_ === 'quotation'){
      strType = 'DEV-';
      }
      else if (type_ === 'summary'){
      strType = 'ATT-';
      }
      else if(type_ === 'invoice'){
      strType = 'FAC-';
      }

        const requestOptions = {
          method: "POST",
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            type_: type_,
            id: this.id_construction,
            document_name: strType+this.constructionNumber,
            user_id: this.connectedUser.id_user,
            key:key
          }),
        };

        let response = await fetch(
          appPath+"/activities/new_document",
          requestOptions
        );
        if (!response.ok) {
          alert("La requête a échouée");
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          //console.log('not response ok, error : ' + error);
          return Promise.reject(error);
        }
        return await response.json();
      
    },
    injectRows(id){
      //document data
      for(let i in this.documentsData){
        if(this.documentsData[i].document_id === id){
           this.document.push(
        {
            'id' : -1,
            'ref' : this.documentsData[i].ref,
            'name' : this.documentsData[i].name,
            'qtty' : this.documentsData[i].qtty,
            'price' : this.documentsData[i].price,
            'htPrice' : (this.fixRounding((this.documentsData[i].qtty*this.documentsData[i].price),2)).toFixed(2),
            'action' : 'injecting'}
      );
        }
      };
    },
    injectDocumentTotal(id){
      //document infos inject total
      for(let i in this.documentsInfos){
        if(this.documentsInfos[i].document_id === id){
           this.document.push(
        {
            'id' : -2,
            'ref' : this.documentsInfos[i].document_name,
            'name' : '',
            'qtty' : 1,
            'price' : this.documentsInfos[i].total,
            'htPrice' : (this.fixRounding((1*this.documentsInfos[i].total),2)).toFixed(2),
            'action' : 'injecting'}
      );
        }
      };
    },
    isDocument(ref){
      //console.log('name');
      //console.log(ref);
      if(ref.includes('DEV')){
        return true
      }
      return false
    },
    writeDocument(type_,name,id,addresse,status_,comment,action){
      /*console.log(type_)
      console.log(name)
      console.log(id)
      console.log(addresse)
      console.log(status_)
      console.log(comment)*/
      this.$emit('document-edit', true);
      /*recap focus construction
      this.constructionNumber = construction.date + construction.incremented_nb;
      this.constructionTown = construction.town;
      this.constructionClient = construction.client;
      this.constructionActivity = construction.activity;
      this.constructionStatus = construction.status_;
      this.id_construction = construction.id;
      this.coordinator = construction.coordinator;
      */
     switch (type_) {
      case 'quotation':
        this.str_maj_type = 'DEVIS'
        break;
        case 'summary':
        this.str_maj_type = 'ATTACHEMENTS'
        break;
        case 'invoice':
        this.str_maj_type = 'FACTURE'
        break;
     
      default:
        break;
     }
     //les données doivent être réinitialisées entre deux documents différents
     this.clientAddresse = 'Adresse non répertoriée';
     this.versionNumber = 1;
     this.clientAddresse2 = false;
     this.modifyAddresse = false;
     this.client_ref_comment = 'à préciser';
     this.docValidation = false;
      //client adresse
      for(let i in this.allAddresses){
        if((this.allAddresses[i].entity).toUpperCase() === this.constructionClient.toUpperCase()){
          this.clientAddresse = this.allAddresses[i].address
        }
      }
      
      this.writingDocument = type_;
      this.documentPicked = name;
      this.documentId = id;
      if(addresse && addresse != ''){
        this.clientAddresse2 = addresse;
      }
      if(status_ && status_ === 'validated'){
        this.docValidation = true;
      }
      if(comment && comment != '' ){
        this.client_ref_comment = comment;
      }

      //document data
      // sinon passe deux fois car aussi watcher documentData
      if(action === 'afterClick'){
      for(let i in this.documentsData){
        //console.log(this.documentsData[i]);
        //console.log(this.documentId);
        if(this.documentsData[i].document_id === this.documentId){
           this.document.push(
        {
            'id' : this.documentsData[i].id,
            'ref' : this.documentsData[i].ref,
            'name' : this.documentsData[i].name,
            'qtty' : this.documentsData[i].qtty,
            'price' : this.documentsData[i].price,
            'htPrice' : (this.fixRounding((this.documentsData[i].qtty*this.documentsData[i].price),2)).toFixed(2),
            'action' : 'existing'}
      );
        }
      }};
      //push empty line
      //this.newLine()

      
    },
    backPage(){
      this.writingDocument = 'none';
      this.document = [];
      this.$emit('document-edit', false);
      /*setTimeout(() => {
      const element = document.getElementById("constructionForScroll");
      element.scrollIntoView({behavior: "smooth"});
      }, 500);*/
      
    },
    newLine(action){
      if(action === 'empty'){
      this.document.push(
        {
            'id' : -1,
            'ref' : '',
            'name' : '',
            'qtty' : '',
            'price' : '',
            'htPrice' : 0,
            'action' : 'empty'}
      );}
      else if(action === 'dynamic'){
        this.document.push(
        {
            'id' : -1,
            'ref' : '',
            'name' : '',
            'qtty' : '',
            'price' : '',
            'htPrice' : 0,
            'action' : 'dynamic'}
      );
      }
    },
    setHtPrice(qtty,price,index){
      this.document[index].htPrice = this.fixRounding((qtty*price),2);
    },
    saveDocument(action) {
      // FETCH
      for(let i in this.document){
        if(this.document[i].ref == false || this.document[i].name == false
        || this.document[i].qtty == false || this.document[i].price == false){
          alert('Toutes les cases doivent être remplies et supérieures à 0');
          return
        }
      };
      this.lockClick = true;
      if(action === 'closing'){
         this.docValidation = true;
         this.client_ref_comment = this.closingDate;
      };
      
      this.fetchSaveDocument()
        .then((data) => {
          if(data['data']){
            if(data['data'].includes('ERREUR')){ 
              alert(data['data']);
              if(action === 'closing'){this.docValidation = false;}  }
          this.$store.dispatch('getDocumentsInfos',this.connectedUser.id_user)};
          this.$store.dispatch('getDocumentsData',this.connectedUser.id_user);
        })
        .catch((e) => console.log(e));
    },
    async fetchSaveDocument() {
        let key;
      //key for only web app native request (!postman)
      if (
        (this.connectedUser.a5e789410fd.includes("2f+g2dfg25h2d+f15b1gs5bnj1ki1lo61q1dc16"))
      ) {
        key = "h1d5he15fdhs";
      }

      let addresse;
      if(this.newAddresses != '' && this.clientAddresse === "Adresse non répertoriée" //VERIFIER QUE LADRESSE NEXSITE PAS)
      ){
        addresse = this.newAddresses;
      }
      else if(this.clientAddresse2 && this.clientAddresse2 != ''){
        addresse = this.clientAddresse2;
      }
      else{
        addresse = 'addresse'
      }
      
      const obj_document_infos = {
        addresse : addresse,
        state : this.docValidation,
        comment : this.client_ref_comment,
        totalHt : this.totalHt,
      }

        const requestOptions = {
          method: "POST",
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            documentData : this.document,
            obj_document_infos : obj_document_infos,
            documentId : this.documentId,
            construction_id : this.id_construction,
            user_id: this.connectedUser.id_user,
            key:key
          }),
        };

        let response = await fetch(
          appPath+"/activities/data_document",
          requestOptions
        );
        if (!response.ok) {
          alert("La requête a échouée");
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          //console.log('not response ok, error : ' + error);
          return Promise.reject(error);
        }
        return await response.json();
      
    },
     deleteDataRow(id,index) {
            this.deletingRow = true;
            if(id === -1 || id === -2){
              this.document.splice(index,1);
              this.deletingRow = false;
              return
            }

      //fetching
      this.fetchDeleteDataRow(id)
        .then((data) => {
          //result
          if (data["data"].affectedRows) {
              //console.log(data);
              this.$store.dispatch('getDocumentsData',this.connectedUser.id_user);
              
            }
          else {alert('Suppression non effective');
          this.deletingRow = false;}
          //this.$store.dispatch('getAllConstructions','1+2+3+4+5+m1'+'_'+this.connectedUser.id_user);
          //this.$refs['hourglass'].style.display = 'none';
        })
        .catch((e) => console.log(e));
    },
    async fetchDeleteDataRow(id) {
      const row_id = id;
      let key;
      //key for only web app native request (!postman)
      if (
        (this.connectedUser.a5e789410fd.includes("2f+g2dfg25h2d+f15b1gs5bnj1ki1lo61q1dc16"))
      ) {
        key = "h1d5he15fdhs";
      }
      const requestOptions = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
        body: JSON.stringify({
          row_id: row_id,
          user_id: this.connectedUser.id_user,
          key: key,
        }),
      };

      let response = await fetch(
        appPath+"/activities/delete_document_row",
        requestOptions
      );
      if (!response.ok) {
        alert("Une erreur empêche la requête");
        //stop loader
        this.putLoader = false;
        // get error message from body or default to response status
        const error = (data && data.message) || response.status;
        //console.log('not response ok, error : ' + error);
        //console.log(error);
        return Promise.reject(error);
      }
      return await response.json();
    },
    putDynamicRow(event,index){
      //console.log(event.target);
        this.document[index]['name'] = event.target.value;
      for(let i in this.servicesClients){
        //client repertorié
        if(this.constructionClient === this.servicesClients[i].client
         && this.servicesClients[i].name === event.target.value){
          this.document[index]['ref'] = this.servicesClients[i].ref;
        this.document[index]['price'] = this.servicesClients[i].price;
        this.setHtPrice(this.document[index]['qtty'],this.document[index]['price'],index);
        }
        //non repertorié
        else if(this.confirmVarious === true && this.benefitName.size != 0 &&
        this.servicesClients[i].client === 'Divers' &&
         this.servicesClients[i].name === event.target.value){
          this.document[index]['ref'] = this.servicesClients[i].ref;
        this.document[index]['price'] = this.servicesClients[i].price;
        this.setHtPrice(this.document[index]['qtty'],this.document[index]['price'],index);
        }
      }
      
     
      
    },
     deleteDocument(id) {
      this.deletingDoc = true;
      //console.log(id);
      //fetching
      this.fetchDeleteDocument(id)
        .then((data) => {
          //result
          if (data["data"].affectedRows) {
              //console.log(data);
              this.$store.dispatch('getDocumentsInfos',this.connectedUser.id_user);
              this.$store.dispatch('getDocumentsData',this.connectedUser.id_user);
              this.deletingDoc = false;
            }
          else {alert('Suppression non effective');
          this.deletingDoc = false;}
          //this.$store.dispatch('getAllConstructions','1+2+3+4+5+m1'+'_'+this.connectedUser.id_user);
          //this.$refs['hourglass'].style.display = 'none';
        })
        .catch((e) => console.log(e));
    },
    async fetchDeleteDocument(id) {
      
      let key;
      //key for only web app native request (!postman)
      if (
        (this.connectedUser.a5e789410fd.includes("2f+g2dfg25h2d+f15b1gs5bnj1ki1lo61q1dc16"))
      ) {
        key = "h1d5he15fdhs";
      }
      const requestOptions = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
        body: JSON.stringify({
          id: id,
          user_id: this.connectedUser.id_user,
          key: key,
        }),
      };

      let response = await fetch(
        appPath+"/activities/delete_document",
        requestOptions
      );
      if (!response.ok) {
        alert("Une erreur empêche la requête");
        //stop loader
        this.putLoader = false;
        // get error message from body or default to response status
        const error = (data && data.message) || response.status;
        //console.log('not response ok, error : ' + error);
        //console.log(error);
        return Promise.reject(error);
      }
      return await response.json();
    },
    // PDF GENERATOR
    download(action) {
      var myAddress = "Adresse de l'entreprise [...]";
      var myInfos = "Adresse & Infos de l'entreprise [...]";
      var supplierAddress = this.clientAddresse||"Adresse & Infos de l'entreprise [...]";
      if(this.clientAddresse === "Adresse non répertoriée" && this.clientAddresse2 != false){
        supplierAddress = this.clientAddresse2;
      }
      var myFooter = "informations bas de page de l'entreprise";
      //console.log(this.allAddresses);
      for (let i in this.allAddresses) {
        if (this.allAddresses[i].entity === "Digitech-adresse") {
          myAddress = this.allAddresses[i].address;
        }
        if (this.allAddresses[i].entity === "Digitech") {
          myInfos = this.allAddresses[i].address;
        }
        if (this.allAddresses[i].entity === "Digitech-footer") {
          myFooter = this.allAddresses[i].address;
        }
      }
      if (supplierAddress === "Adresse & Infos de l'entreprise [...]") {
        alert("L'adresse de ce fournisseur n'est pas répertoriée");
        return "error";
      }
      //console.log(myAddress);
      //console.log(myInfos);
      var versioning = ' V'+this.versionNumber;
      if(this.writingDocument === 'invoice'){
        versioning = ''
      }

      // date limit invoice est utilisé après
      let dateLimitInvoice;
      const BC_NUMBER = this.documentPicked;

      //check if tva
      let totalPrice = 0;
      totalPrice = this.totalHt;
      var tvaPrice = (this.fixRounding((totalPrice * 0.2),2)).toFixed(2) + " €";
      var totalDownload = (this.fixRounding((totalPrice + totalPrice * 0.2),2)).toFixed(2) + " €";
      if(this.noTVA){
        tvaPrice = 'Auto-Liquidation';
        totalDownload = (this.fixRounding((totalPrice),2)).toFixed(2) + " €";
      }

      //différent selon devis ou attachement
      let docDefinition;
      let textOrSignature;
      let variableMargin;
      variableMargin = 147;
      if(this.writingDocument === 'quotation'){
      textOrSignature = {
            text: "Devis valable 30 jours.",
            width: 200,
            margin: [0, 20, 0, 0],
            italics : true,
            fontSize : 10,
          };
        docDefinition = {
          info: {
	title: BC_NUMBER + versioning,
	author: 'DigistockApp',
	subject: 'Activity',
  },
        pageBreakBefore: function(currentNode) {
          if (currentNode.headlineLevel === 19) {
            return true;
          } else if (currentNode.headlineLevel % 48 === 0) {
            return true;
          }
          return false;
        },
        pageMargins: [15, 15, 15, 60],

        footer: function(currentPage, pageCount) {
          return {
            columns: [
              {
                margin: [100, 0, 0, 0],

                layout: {
                  vLineWidth: function(i) {
                    return 0;
                  },
                },
                table: {
                  body: [
                    [
                      {
                        alignment: "center",
                        fontSize: 10,
                        text: myFooter,
                      },
                    ],
                  ],
                },
              },
              {
                width: "10%",
                text: currentPage.toString() + "/" + pageCount,
              },
            ],
          };
        },
        content: [
          //TITRES
          {
            //DOCUMENT
            columns: [
              {
                // you'll most often use dataURI images on the browser side
                // if no width/height/fit is provided, the original size will be used
                image: image.img,
                width: 320,
              },]},
          // adresse numero date
          {
            columns: [
              {
                //Adresse
                fontSize: 10,
                text: myAddress,
              },
              {
                width: "35%",

                table: {
                  headerRows: 1,
                  widths: [65, 100],

                  body: [
                    [
                      {
                        background: "#F7E6D9",
                        fillColor: "#F7E6D9",
                        fontSize: 14,
                        text: this.str_maj_type,
                        colSpan :2,
                        alignment : 'center',
                        bold : true,
                      },''
                    ],
                    [
                      {
                        background: "#F7E6D9",
                        fillColor: "#F7E6D9",
                        fontSize: 10,
                        text: "Activité",
                        alignment : 'center',
                        bold: true,
                      },
                      {
                        background: "white",
                        fillColor: "white",
                        fontSize: 10,
                        text: this.constructionActivity,
                        alignment : 'center',
                      },],
                      [
                      {
                        background: "#F7E6D9",
                        fillColor: "#F7E6D9",
                        fontSize: 10,
                        text: "Numéro",
                        alignment : 'center',
                        bold: true,
                      },
                      {
                        background: "white",
                        fillColor: "white",
                        fontSize: 10,
                        text: BC_NUMBER + versioning,
                        alignment : 'center',
                      },
                    ],
                    [
                      {
                        background: "#F7E6D9",
                        fillColor: "#F7E6D9",
                        fontSize: 10,
                        text: "Date",
                        alignment : 'center',
                        bold: true,
                      },
                      {
                        background: "white",
                        fillColor: "white",
                        fontSize: 10,
                        text: new Date(Date.now())
                          .toLocaleString()
                          .split(" ")[0],
                          alignment : 'center',
                      },],
                      
                     [
      {
                        background: "#F7E6D9",
                        fillColor: "#F7E6D9",
                        fontSize: 10,
                        text: "Réf client",
                        alignment : 'center',
                        bold: true,
                      },
                      {
                        background: "white",
                        fillColor: "white",
                        fontSize: 10,
                        text: this.client_ref_comment,
                        alignment : 'center',
                      },]
                    ,
                  ],

                  
                },
                layout: {
                  hLineWidth: function(i,node) {
                    return i === 0 || i === 1  ? 2:1;
                  },
                  /*
                  vLineWidth: function(i,node) {
                    return i === 1 ? 2:1;
                  },*/
                },
              },
            ],
          },
          //SPACE
          {
            text: "space",
            color: "white",
            lineHeight: 2,
          },
          // Fournisseur livraison
          {
            columns: [
              {
                width: "56.5%",

                table: {
                  headerRows: 1,
                  widths: [120],

                  body: [
                    [
                      {
                        background: "#F7E6D9",
                        fillColor: "#F7E6D9",
                        fontSize: 11,
                        text: "Client",
                        alignment : 'center',
                        bold: true,
                      },
                    ],
                  ],
                },
                layout: {
                  paddingLeft: function(i, node) {
                    return 50;
                  },
                  paddingRight: function(i, node) {
                    return 50;
                  },
                },
              },
            ],
          },
          // texte informatif
          {
            columns: [
              {
                width: "39%",
                fontSize: 11,
                margin: [0, 4, 0, 0],
                text:this.constructionClient,
                alignment : 'center',
                bold : true
              },
              
            ],
          },
          {
            columns: [
              {
                width: "39%",
                fontSize: 10,
                text: supplierAddress,
                alignment : 'center',
              },
              
            ],
          },
          //header
          {
            columns: [
              {
                margin: [0, 20, 0, 0],

                table: {
                  headerRows: 1,
                  widths: [80, 200, 40, 80, 100],

                  body: [
                    [
                      {
                        background: "#F7E6D9",
                        fillColor: "#F7E6D9",
                        fontSize: 11,
                        text: "REFERENCE",
                        alignment : 'center',
                        bold : true,
                      },
                      {
                        background: "#F7E6D9",
                        fillColor: "#F7E6D9",
                        fontSize: 11,
                        text: "DESIGNATION",
                        alignment : 'center',
                        bold : true,
                      },
                      {
                        background: "#F7E6D9",
                        fillColor: "#F7E6D9",
                        fontSize: 11,
                        text: "Q'TE",
                        alignment : 'center',
                        bold : true,
                      },
                      {
                        background: "#F7E6D9",
                        fillColor: "#F7E6D9",
                        fontSize: 11,
                        text: "TARIF",
                        alignment : 'center',
                        bold : true,
                      },
                      {
                        background: "#F7E6D9",
                        fillColor: "#F7E6D9",
                        fontSize: 11,
                        text: "TOTAL HT",
                        alignment : 'center',
                        bold : true,
                      },
                    ],
                  ],
                },
              },
            ],
          },
        ],
        defaultStyle: {
          alignment: "justify",
        },
      };
      }
      else if(this.writingDocument === 'summary'){
          textOrSignature = {
            image: signature.image,
            width: 200,
            margin: [20, 20, 0, 0],
          };
        docDefinition = {
          info: {
	title: BC_NUMBER + versioning,
	author: 'DigistockApp',
	subject: 'Activity',
  },
        pageBreakBefore: function(currentNode) {
          if (currentNode.headlineLevel === 19) {
            return true;
          } else if (currentNode.headlineLevel % 48 === 0) {
            return true;
          }
          return false;
        },
        pageMargins: [15, 15, 15, 60],

        footer: function(currentPage, pageCount) {
          return {
            columns: [
              {
                margin: [100, 0, 0, 0],

                layout: {
                  vLineWidth: function(i) {
                    return 0;
                  },
                },
                table: {
                  body: [
                    [
                      {
                        alignment: "center",
                        fontSize: 10,
                        text: myFooter,
                      },
                    ],
                  ],
                },
              },
              {
                width: "10%",
                text: currentPage.toString() + "/" + pageCount,
              },
            ],
          };
        },
        content: [
          //TITRES
          {
            //DOCUMENT
            columns: [
              {
                // you'll most often use dataURI images on the browser side
                // if no width/height/fit is provided, the original size will be used
                image: image.img,
                width: 320,
              },]},
          // adresse numero date
          {
            columns: [
              {
                //Adresse
                fontSize: 10,
                text: myAddress,
              },
              
              {
                width: "35%",

                table: {
                  headerRows: 1,
                  widths: [65, 100],

                  body: [
                    [
                      {
                        background: "#F7E6D9",
                        fillColor: "#F7E6D9",
                        fontSize: 14,
                        text: this.str_maj_type,
                        colSpan :2,
                        alignment : 'center',
                        bold : true,
                      },''
                    ],
                    [
                      {
                        background: "#F7E6D9",
                        fillColor: "#F7E6D9",
                        fontSize: 10,
                        text: "Activité",
                        alignment : 'center',
                        bold: true,
                      },
                      {
                        background: "white",
                        fillColor: "white",
                        fontSize: 10,
                        text: this.constructionActivity,
                        alignment : 'center',
                        
                      },],
                      [
                      {
                        background: "#F7E6D9",
                        fillColor: "#F7E6D9",
                        fontSize: 10,
                        text: "Numéro",
                        alignment : 'center',
                        bold: true,
                      },
                      {
                        background: "white",
                        fillColor: "white",
                        fontSize: 10,
                        text: BC_NUMBER + versioning,
                        alignment : 'center',
                        
                      },
                    ],
                    [
                      {
                        background: "#F7E6D9",
                        fillColor: "#F7E6D9",
                        fontSize: 10,
                        text: "Date",
                        alignment : 'center',
                        bold: true,
                      },
                      {
                        background: "white",
                        fillColor: "white",
                        fontSize: 10,
                        text: new Date(Date.now())
                          .toLocaleString()
                          .split(" ")[0],
                          alignment : 'center',
                        
                      },],
                      
                  ],
                },
              },
            ],
          },
          //SPACE
          {
            text: "space",
            color: "white",
            lineHeight: 2,
          },
          // Fournisseur livraison
          {
            columns: [
              {
                width: "56.5%",

                table: {
                  headerRows: 1,
                  widths: [120],

                  body: [
                    [
                      {
                        background: "#F7E6D9",
                        fillColor: "#F7E6D9",
                        fontSize: 11,
                        text: "Client",
                        alignment : 'center',
                        bold: true,
                      },
                    ],
                  ],
                },
                layout: {
                  paddingLeft: function(i, node) {
                    return 50;
                  },
                  paddingRight: function(i, node) {
                    return 50;
                  },
                },
              },
            ],
          },
          // texte informatif
          {
            columns: [
              {
                width: "39%",
                fontSize: 11,
                margin: [0, 4, 0, 0],
                text:this.constructionClient,
                alignment : 'center',
                bold : true
              },
              
            ],
          },
          {
            columns: [
              {
                width: "39%",
                fontSize: 10,
                text: supplierAddress,
                alignment : 'center',
              },
              
            ],
          },
          //header
          {
            columns: [
              {
                margin: [0, 20, 0, 0],

                table: {
                  headerRows: 1,
                  widths: [80, 200, 40, 80, 100],

                  body: [
                    [
                      {
                        background: "#F7E6D9",
                        fillColor: "#F7E6D9",
                        fontSize: 11,
                        text: "REFERENCE",
                        alignment : 'center',
                        bold : true,
                      },
                      {
                        background: "#F7E6D9",
                        fillColor: "#F7E6D9",
                        fontSize: 11,
                        text: "DESIGNATION",
                        alignment : 'center',
                        bold : true,
                      },
                      {
                        background: "#F7E6D9",
                        fillColor: "#F7E6D9",
                        fontSize: 11,
                        text: "Q'TE",
                        alignment : 'center',
                        bold : true,
                      },
                      {
                        background: "#F7E6D9",
                        fillColor: "#F7E6D9",
                        fontSize: 11,
                        text: "TARIF",
                        alignment : 'center',
                        bold : true,
                      },
                      {
                        background: "#F7E6D9",
                        fillColor: "#F7E6D9",
                        fontSize: 11,
                        text: "TOTAL HT",
                        alignment : 'center',
                        bold : true,
                      },
                    ],
                  ],
                },
              },
            ],
          },
        ],
        defaultStyle: {
          alignment: "justify",
        },
      };
      }
      else {
        //--------------------- invoice only
        
        var Ddate = new Date(Date.now());
        let ibanAndInvoiceDate;
        // gérer +30 j ou + 45j
        if (this.invoiceTimeLimit === '45 FDM'){
          dateLimitInvoice = this.addDays(Ddate,45,this.invoiceTimeLimit);
          dateLimitInvoice = dateLimitInvoice.toLocaleString()
                          .split(" ")[0];
          ibanAndInvoiceDate = iban_digi.image;
          
        }
        else if (this.invoiceTimeLimit === '30 FDM'){
          dateLimitInvoice = this.addDays(Ddate,30,this.invoiceTimeLimit);
          dateLimitInvoice = dateLimitInvoice.toLocaleString()
                          .split(" ")[0];
          ibanAndInvoiceDate = iban_digi2.image;

        }
        else if(this.invoiceTimeLimit === 45){
          dateLimitInvoice = this.addDays(Ddate,45,this.invoiceTimeLimit);
          dateLimitInvoice = dateLimitInvoice.toLocaleString()
                          .split(" ")[0];
          ibanAndInvoiceDate = iban_digi4.image;
        }
        else{
          dateLimitInvoice = this.addDays(Ddate,30,this.invoiceTimeLimit);
          dateLimitInvoice = dateLimitInvoice.toLocaleString()
                          .split(" ")[0];
          ibanAndInvoiceDate = iban_digi3.image;
        }
        //---------------------

        variableMargin = 0;
        textOrSignature = {
            image: ibanAndInvoiceDate,
            width: 290,
            margin: [0, 20, 0, 0],
          };
        docDefinition = {
          info: {
	title: BC_NUMBER + versioning,
	author: 'DigistockApp',
	subject: 'Activity',
  },
        pageBreakBefore: function(currentNode) {
          if (currentNode.headlineLevel === 19) {
            return true;
          } else if (currentNode.headlineLevel % 48 === 0) {
            return true;
          }
          return false;
        },
        pageMargins: [15, 15, 15, 60],

        footer: function(currentPage, pageCount) {
          return {
            columns: [
              {
                margin: [100, 0, 0, 0],

                layout: {
                  vLineWidth: function(i) {
                    return 0;
                  },
                },
                table: {
                  body: [
                    [
                      {
                        alignment: "center",
                        fontSize: 10,
                        text: myFooter,
                      },
                    ],
                  ],
                },
              },
              {
                width: "10%",
                text: currentPage.toString() + "/" + pageCount,
              },
            ],
          };
        },
        content: [
          //TITRES
          {
            //DOCUMENT
            columns: [
              {
                // you'll most often use dataURI images on the browser side
                // if no width/height/fit is provided, the original size will be used
                image: image.img,
                width: 320,
              },]},
          // adresse numero date
          {
            columns: [
              {
                //Adresse
                fontSize: 10,
                text: myAddress,
              },
              {
                width: "35%",

                table: {
                  headerRows: 1,
                  widths: [65, 100],

                  body: [
                    [
                      
                      {
                        background: "#F7E6D9",
                        fillColor: "#F7E6D9",
                        fontSize: 14,
                        text: this.str_maj_type,
                        colSpan :2,
                        alignment : 'center',
                        bold : true,
                      },''
                    ],[
                      {
                        background: "#F7E6D9",
                        fillColor: "#F7E6D9",
                        fontSize: 10,
                        text: "Activité",
                        alignment : 'center',
                        bold: true,
                      },
                      {
                        background: "white",
                        fillColor: "white",
                        fontSize: 10,
                        text: this.constructionActivity,
                        alignment : 'center',
                        
                      },],
                      [
                      {
                        background: "#F7E6D9",
                        fillColor: "#F7E6D9",
                        fontSize: 10,
                        text: "Numéro",
                        alignment : 'center',
                        bold: true,
                      },
                      {
                        background: "white",
                        fillColor: "white",
                        fontSize: 10,
                        text: BC_NUMBER + versioning,
                        alignment : 'center',
                        
                      },
                    ],
                    [
                      {
                        background: "#F7E6D9",
                        fillColor: "#F7E6D9",
                        fontSize: 10,
                        text: "Date",
                        alignment : 'center',
                        bold: true,
                      },
                      {
                        background: "white",
                        fillColor: "white",
                        fontSize: 10,
                        text: new Date(Date.now())
                          .toLocaleString()
                          .split(" ")[0],
                          alignment : 'center',
                        
                      },],
                      
                  ],
                },
              },
            ],
          },
          //SPACE
          {
            text: "space",
            color: "white",
            lineHeight: 2,
          },
          // Fournisseur livraison
          {
            columns: [
              {
                width: "56.5%",

                table: {
                  headerRows: 1,
                  widths: [120],

                  body: [
                    [
                      {
                        background: "#F7E6D9",
                        fillColor: "#F7E6D9",
                        fontSize: 11,
                        text: "Client",
                        alignment : 'center',
                        bold: true,
                      },
                    ],
                  ],
                },
                layout: {
                  paddingLeft: function(i, node) {
                    return 50;
                  },
                  paddingRight: function(i, node) {
                    return 50;
                  },
                },
              },
            ],
          },
          // texte informatif
          {
            columns: [
              {
                width: "39%",
                fontSize: 11,
                margin: [0, 4, 0, 0],
                text:this.constructionClient,
                alignment : 'center',
                bold : true
              },
              
            ],
          },
          {
            columns: [
              {
                width: "39%",
                fontSize: 10,
                text: supplierAddress,
                alignment : 'center',
              },
              
            ],
          },
          //header
          {
            columns: [
              {
                margin: [0, 20, 0, 0],

                table: {
                  headerRows: 1,
                  widths: [80, 200, 40, 80, 100],

                  body: [
                    [
                      {
                        background: "#F7E6D9",
                        fillColor: "#F7E6D9",
                        fontSize: 11,
                        text: "REFERENCE",
                        alignment : 'center',
                        bold : true,
                      },
                      {
                        background: "#F7E6D9",
                        fillColor: "#F7E6D9",
                        fontSize: 11,
                        text: "DESIGNATION",
                        alignment : 'center',
                        bold : true,
                      },
                      {
                        background: "#F7E6D9",
                        fillColor: "#F7E6D9",
                        fontSize: 11,
                        text: "Q'TE",
                        alignment : 'center',
                        bold : true,
                      },
                      {
                        background: "#F7E6D9",
                        fillColor: "#F7E6D9",
                        fontSize: 11,
                        text: "TARIF",
                        alignment : 'center',
                        bold : true,
                      },
                      {
                        background: "#F7E6D9",
                        fillColor: "#F7E6D9",
                        fontSize: 11,
                        text: "TOTAL HT",
                        alignment : 'center',
                        bold : true,
                      },
                    ],
                  ],
                },
              },
            ],
          },
        ],
        defaultStyle: {
          alignment: "justify",
        },
      };
      }
     
      let n;
      
      // INJECTION DYNAMIQUE
      for (let i in this.document) {
        n = i + 1;
        
        
        docDefinition.content.push({
          columns: [
            {
              table: {
                widths: [80, 200, 40, 80, 100],

                body: [
                  [
                    {
                      fontSize: 10,
                      headlineLevel: n,
                      text: this.document[i].ref,
                      alignment : 'center',
                    },
                    {
                      fontSize: 10,
                      headlineLevel: n,
                      text: this.document[i].name,
                      alignment : 'center',
                    },
                    {
                      fontSize: 10,
                      headlineLevel: n,
                      text: this.document[i].qtty,
                      alignment : 'right',
                    },
                    {
                      fontSize: 10,
                      headlineLevel: n,
                      text: this.document[i].price + ' €',
                      alignment : 'right',
                    },
                    {
                      fontSize: 10,
                      headlineLevel: n,
                      text:
                        this.document[i].htPrice +
                        " €",
                        alignment : 'right',
                    },
                  ],
                ],
              },
            },
          ],
        });
      }
      // signature et total
      // la facture a deux ligne en plus et pas les autres
      if(this.writingDocument != 'invoice'){
        

        docDefinition.content.push({
        columns: [
          textOrSignature,
          {
            width: "37.5%",
            margin: [variableMargin, 30, 0, 0],
           /* width: "37.5%",
            margin: [147, 30, 0, 0],*/

            table: {
              headerRows: 1,
              widths: [80, 100],

              body: [
                [
                  {
                    background: "#F3BB91",
                    fillColor: "#F3BB91",
                    fontSize: 11,
                    text: "TOTAL HT",
                    alignment : 'right',
                    bold: true,
                  },
                  {
                    background: "#F7E6D9",
                    fillColor: "#F7E6D9",
                    fontSize: 11,
                    text: (this.fixRounding(totalPrice,2)).toFixed(2) + " €",
                    alignment : 'right',
                  },
                ],
                [
                  {
                    background: "#F3BB91",
                    fillColor: "#F3BB91",
                    fontSize: 11,
                    text: "TVA",
                    alignment : 'right',
                    bold: true,
                  },
                  {
                    background: "#F7E6D9",
                    fillColor: "#F7E6D9",
                    fontSize: 11,
                    text: tvaPrice,
                    alignment : 'right',
                  },
                ],
                [
                  {
                    background: "#F3BB91",
                    fillColor: "#F3BB91",
                    fontSize: 11,
                    text: "TOTAL TTC",
                    alignment : 'right',
                    bold: true,
                  },
                  {
                    background: "#F7E6D9",
                    fillColor: "#F7E6D9",
                    fontSize: 11,
                    text: totalDownload,
                    alignment : 'right',
                  },
                ],
              ],
            },
          },
        ],
      });
      }
      else {

        docDefinition.content.push({
        columns: [
          textOrSignature,
          {
            text : dateLimitInvoice,
            width : 60,
            fontSize: 9,
            bold : true,
            margin: [0, 27, 10, 0],
          },
          {
            width: "37.5%",
            margin: [variableMargin, 30, 0, 0],
           /* width: "37.5%",
            margin: [147, 30, 0, 0],*/

            table: {
              headerRows: 1,
              widths: [80, 100],

              body: [
                [
                  {
                    background: "#F3BB91",
                    fillColor: "#F3BB91",
                    fontSize: 11,
                    text: "TOTAL HT",
                    alignment : 'right',
                    bold: true,
                  },
                  {
                    background: "#F7E6D9",
                    fillColor: "#F7E6D9",
                    fontSize: 11,
                    text: (this.fixRounding(totalPrice,2)).toFixed(2) + " €",
                    alignment : 'right',
                  },
                ],
                [
                  {
                    background: "#F3BB91",
                    fillColor: "#F3BB91",
                    fontSize: 11,
                    text: "TVA",
                    alignment : 'right',
                    bold: true,
                  },
                  {
                    background: "#F7E6D9",
                    fillColor: "#F7E6D9",
                    fontSize: 11,
                    text: tvaPrice,
                    alignment : 'right',
                  },
                ],
                [
                  {
                    background: "#F3BB91",
                    fillColor: "#F3BB91",
                    fontSize: 11,
                    text: "TOTAL TTC",
                    alignment : 'right',
                    bold: true,
                  },
                  {
                    background: "#F7E6D9",
                    fillColor: "#F7E6D9",
                    fontSize: 11,
                    text: totalDownload,
                    alignment : 'right',
                    
                  },
                ],
              ],
            },
          },
          
        ],
      });
      docDefinition.content.push({
        columns:
      [
          {
            image: mentions_digi.image,
            width: 550,
            margin: [0, 30, 0, 0],
          },
        ],
});
      }
      

      //CREATION
      //const nameFile = this.constructionClient+'-Digitech-'+BC_NUMBER;
      if(action === 'see'){
        pdfMake.createPdf(docDefinition).open();
      }
      else {
        pdfMake.createPdf(docDefinition).download(BC_NUMBER + versioning);
      }
      
      return true;
    },
    notUnder0(){
      if(parseInt(this.versionNumber) > 1){this.versionNumber = parseInt(this.versionNumber)- 1}
    },
    findStyle(id){
      //invalidé ?
      if(this.objStatus['quotation']['invalidated'].includes(id)){
        return  `background-color : #f38414; border-color : yellow`
      }
      if(this.objStatus['summary']['invalidated'].includes(id)){
        return  `background-color : #f38414; border-color : green`
      }
      if(this.objStatus['invoice']['invalidated'].includes(id)){
        return  `background-color : #f38414; border-color : white`
      }
      //---------
      //initial ?
      if(this.objStatus['quotation']['initial'].includes(id)){
        return  `background-color : black`
      }
      if(this.objStatus['summary']['initial'].includes(id)){
        return  `background-color : black`
      }
      if(this.objStatus['invoice']['initial'].includes(id)){
        return  `background-color : black`
      }

      //---------
      //Validation ok
      if(this.objStatus['invoice']['validated'].includes(id)){
        return  `background-color : white`
      }
      if(this.objStatus['summary']['validated'].includes(id)){
        return  `background-color : green`
      }
      if(this.objStatus['quotation']['validated'].includes(id)){
        return  `background-color : yellow`
      }
      // si pas de document
      return false
    },
    addDays(date,days,type_){
    var thisDate = date;
    thisDate.setDate(thisDate.getDate() + days);
    if(type_ === '45 FDM' || type_ === '30 FDM'){
      //console.log(thisDate.getMonth());
      thisDate = new Date(thisDate.getFullYear(), thisDate.getMonth()+1, 0);
      
    }
    
    return thisDate;
    }
    },
    
}
</script>
<style scoped>
.activity-container{

}
textarea {
padding: 2px 10px;
}
h3{
    font-size: 22px;
}
.activity-subcontainer {
  display: flex;
  flex-direction: column;
  /*align-items: center;*/
  color: white;
  max-width: 100%;
  width: 1100px;
  height: auto;
  font-size: 20px;
  background: #0d444b;
  border-radius: 16px;
  padding: 32px;
   -webkit-box-shadow: 1px 2px 5px 1px #000000; 
  box-shadow: 2px 2px 7px 1px #000000;
}
.activity-card-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    height: auto;
    max-height: 600px;
    overflow-y: scroll;
}
.activity-card {
    border: 2px solid #f38414;
    padding: 10px;
    margin: 5px;
    height: 25px;
    width: auto;
    cursor: pointer;
}
.activity-card:hover {
    background-color: black;
}
.activity-card i{
    cursor : pointer;
}
.legend {
background : #f38414; 
position: absolute;
left: 25%;
top: 85%;
color: black;
padding: 20px;
}
.legend-btn {
margin-top: 10px;
}
.circle-state {
  display: inline-block;
  background-color:#0d444b;
  border-radius: 25px;
  border: 3px solid transparent;
  width: 12px;
  height: 12px;
  margin-left: 5px;
}
.no-border {
  border : none;
}
.filter-container {
border: 1px solid white;
margin-top: 40px;
padding-bottom : 20px;
}
.filter-sub-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom : 20px;
}
.filter-sub-container span {
  font-size: 21px;
  color: #f38414;
  font-weight: bold;
}
.filter-sub-container .number {
  width: 60px;
  font-size: 18px;
}
@-moz-document url-prefix() {
.filter-sub-container .number {
  width: 65px;
}}
.input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}
.input-container label {
    background-color : #f38414;
     color : #0d444b;
     border-radius: 16px;
     padding : 5px 0px;
     margin : 5px 30px 25px 0;
     width : 140px;
}
.mini-container {
    display: flex;
}
.input-container input {
    width: 275px;
    height: 25px;
    margin: 5px 5px 5px 25px;
    font-size: 20px;
    font-weight: 600;
    padding-top: 0;
    padding-bottom: 0;
}
.construction-generator,.doc-button{
    background-color: #f38414;
    font-size: 22px;
    padding: 5px 10px;
    margin-top : 20px;
    height: auto;
}
.construction-generator:hover,.doc-button:hover{
    color: white;
}
.absolute-btn-0,.absolute-btn-1{
  position: absolute;
  bottom: -10px;
}
.absolute-btn-0{
  left: -10px;
}
.absolute-btn-1{
  right: -10px;
  padding-left: 4px;
}
 .doc-button2 {
  margin-left: 20px;
 }
 .doc-button3 {
  margin-left: 20px;
  height: 50px;
  position: relative;
  background-color: #f38414;
    font-size: 22px;
  padding: 5px;
 }
.doc-state{
    font-size: 22px;
    padding: 5px 10px;
    margin-top : 20px;
}
.doc-state:hover{
    color: black;
}
.red{
  color : red;
  height: 42px;
  padding-top: 8px;
}
.green {
  color : green;
}
.hourglass {
    font-size: 30px;
    margin-left: 20px;
    display: none;
}
.fa-trash-alt {
    margin-left: 20px;
    border: 1px solid white;
    padding: 5px;
    color : #f38414;
    cursor : pointer;
}
.selectAct {
  color: #f38414;;
  background-color: transparent;
  position : absolute;
  top: 10px;
  left: 20px;
  cursor: pointer;
}
.large-selector{
  font-size: 22px;
  min-width: 280px;
  margin: -5px 5px 0px 5px;
}
.selectAct:focus {
  color: black;
  background-color: white;
}
.constructionInputCont {
  width: 1000px;
  display: flex;
  justify-content:center;
}
.constructionInputCont > div {
  display: flex;
  width: 490px;
  justify-content : start;
}
.constructionInputCont > div > div {
 position : relative;
}
.span-label {
  color :#f38414;
}
.checker {
  filter: invert(75%) hue-rotate(18deg) brightness(1.7);
}
.flex-recap, .flex-row{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.flex-recap > div {
  margin: 10px;
  padding: 4px;
  border: 1px white solid;
}
.flex-recap2 {
display: flex;
flex-direction: column;
position: absolute;
background-color: white;
color: black;
padding: 10px;
font-size: 20px;
z-index: 5;
}
.orange {
  color :#f38414;
}
.generatedCode {
  margin-top: 20px;
}
.filter-client{
  height: 50px;
}
.filter-client select{
  background-color: white;
  font-size: 18px;
  margin-top: 20px;
}
select {text-align-last:center;}
.margin {
   margin-bottom : 30px;
}
.init-btn-div > button {
height: 40px;
margin: 0px 10px;
}
.constructionInputCont >.various-div{
position: absolute;
right: 150px;
width: 120px;
}
.various-span {
font-size: 23px;
line-height: 30px;
}
.quotation-div {
margin: 10px;
}
.doc-icon {
  padding: 5px;
margin: 5px;
font-size: 20px;
}
.quotation {
background-color: yellow;
}
.summary {
background-color: rgb(2, 158, 2);
}
.invoice {
background-color: white;
}
.greenyellow {
  color : greenyellow
}
.relative {
  position: relative;
}
.thumbtack {
  position: absolute;
  left: 10px;
  top: -4px;
}
.document-page > h4 {
  color: white;
  height: 50px;
  line-height: 60px;
  font-size: 25px;
}
.document-page {
  width : 1100px;
  /*min-height: 970px;*/
  padding-bottom: 20px;
  background: #0d444b;
  border-radius: 16px;
   -webkit-box-shadow: 1px 2px 5px 1px #000000; 
  box-shadow: 2px 2px 7px 1px #000000;
  position: relative;
}
.back {
  font-size: 30px;
  position: absolute;
  left : 20px;
  top: 10px;
  font-weight: bold;
  background-color: #f38414 ;
}
.document-grid {
  display: grid;
  margin: auto;
  max-width: 1000px;
  grid-template-columns:1.2fr 0.8fr 1fr ;
  grid-column-gap: 50px;
  /*grid-template-rows:1fr 4fr 1fr ;*/
}
.doc-part{
margin: 5px;
background-color: #0d444b;
color: white;
border : 4px solid #f38414;
border-radius: 16px;
padding: 7px;
margin-bottom: 10px;
  
}
.doc-client {
  min-width: 200px;
  font-size: 22px;
}
.doc-client > div {
  margin: 20px auto;
}
.doc-resume {
min-width: 300px;
grid-row: 1;
grid-column:  2/4;
justify-self: end;
font-size: 22px;
}
.doc-resume > div {
margin: 10px;
}
.doc-resume > div > textarea{
font-size: 20px;
margin-left: 5px;
}
.doc-data {
  display: grid;
  grid-row: 2;
  grid-column: 1 /4;
  /*width: 965px;*/
  /*min-height: 480px;*/
  justify-self: start;
}
.doc-total{
  width: 300px;
  font-size: 22px;
  grid-row: 3;
  grid-column: 3;
  justify-self: end;
}

.row-document {
 display: grid;
  margin: auto;
  margin-bottom: 20px;
  max-width: 1000px;
  width: 965px;
  grid-template-columns:0.6fr 1.1fr 2fr 1.1fr 1.2fr 1.2fr ;
  grid-template-rows:1fr ;
  font-size: 22px;
  justify-items: start;
  align-items: center;
}
.header-table {
background: #f38414;
margin: 20px auto;
}
.row-document > div {
  display: flex;
  
}
.row-document > div > input {
  font-size: 20px;
}

.row-document > div > .inputName {
  width : 200px;
}
.row-document > div > input[type=number],.inputRef {
  width : 80px;
}
.row-document > div > .large-input {
  width : 200px;
}
.row-document > .modified {
  grid-column: 1/3;
}
.row-document > i {
  grid-column: 6;
}
.row-document > .selectRef,.inputRef {
  grid-column: 2;
}
.row-document > .selectName,.inputName {
  grid-column: 3;
}
.selectName {
  font-size: 22px;
  width: 205px;
  cursor: pointer;
}
.row-document > div > .document-checkbox {
  grid-column: 1;
  width : 25px;
  height: 40px;
}
.document-edit {
  grid-column: 1;
  font-size: 22px;
  margin-left: 15px;
  background: #f38414;
  color: #0d444b;
}
.flex-div{
  display: flex;
  justify-content:center ;
}
.flex-div > button {
margin: 20px;
}
.lockEvent {
  pointer-events: none;
}
.btn-cont {
  position: relative;
}
.closeSummary {
  left:340px;
  top: -5px;
  min-width: 300px;
  background-color: #f38414;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  position: absolute;
  border: white 4px solid;
  padding: 3px;
  z-index: 5;
}
.closeSummary > * {
  margin: 6px;
  font-size: 21px;
}
.closeSummary > div > input {
  font-size: 20px;
  width: 140px;
}
.absolute-icon {
  position: absolute;
  font-size: 30px;
  top: -48px;
  left: 125px;
  background-color: white;
  border : black 1px solid;
  border-radius: 25px;
  padding: 0 4px 10px 4px;
  }
  .recap-container {
    border: #f38414 3px solid;
  }
  .recap-container > h5 {
    margin: 10px auto;
  }
  h5 {
    font-size: 21px;
    text-decoration: underline;
    text-underline-offset: 4px;
  }
  .flex-recap > .recap-row {
    font-size: 20px;
    padding: 10px;
  }
  .btn-see-recap {
    max-width: 180px ;
    max-height: 40px;
    justify-self: center;
    font-size: 18px;
  }
  .btn-hide-recap {
    margin-right : 10px;
  }
  .lowOpacity {
    position: absolute;
    width: 100%;
    background-color: white;
    height: 100%;
    opacity: 0.7;
    z-index: 4;
  }
  .delete-btn {
    background-color: white;
    color: red;
  }
/*----------media screen-limit----------*/
@media screen and (max-width: 1100px) {
  input,select {
    background-color: white;
  }
.activity-container{
    max-width: 340px;
}
.activity-subcontainer {
  max-width: 100%;
  width: 320px;
  /*max-height: 380px;*/
  font-size: 20px;
  border-radius: 16px;
  padding: 2px;
}
.input-container input {
    width: 200px;
}
.large-selector{
  min-width: 250px;
}
.activity-card {
    padding: 5px;
    margin: 10px 3px;
}
.fa-trash-alt {
    margin-left: 5px;
    padding: 2px;
}
.constructionInputCont {
  width: 300px;

}
.constructionInputCont > div {
  width: 280px;
  flex-wrap: wrap;
  margin-bottom : 30px;
}
.constructionInputCont > div > input {
  width: 250px;
}
.constructionInputCont > div > label {
  width: 110px;
  font-size : 17px;
  margin-bottom: 5px;
  margin-right : 5px;
}
.selectAct {
  color: #f38414;;
  background-color: transparent;
  position : relative;
  top: 10px;
  left: 20px;
  cursor: pointer;
}
.activity-card-container {
    justify-content: center;
    max-height: 300px;
}
.filter-container {
border: 1px solid white;
margin-top: 40px;
padding-bottom : 20px;
}
.filter-sub-container {
  flex-wrap: wrap;
  margin-bottom : 20px;
}
.filter-sub-container span {
  font-size: 18px;
  padding: 10px;
}
.filter-sub-container .number {
  width: 40px;
  font-size: 18px;
}
.constructionInputCont >.various-div{
right: 40px;
width: 102px;
flex-wrap: nowrap;
}
.constructionInputCont >.various-div > input {
  font-size: 25px;
}
.various-span {
font-size: 19px;
}
@-moz-document url-prefix() {
.filter-sub-container .number {
  width: 45px;
}}


/*---------------- document part-----------------------*/ 

.document-page > h4 {
  margin-left: 40px;/*modifié*/
  height: 100px; /*modifié*/
}
.document-page {
  width : 300px; /*modifié*/ 
  /*min-height: 970px;*/
}
.document-grid {
  max-width: 300px; /*modifié*/ 
  grid-template-columns:1fr ; /*modifié*/
  grid-column-gap: 0px;  /*modifié*/ 
  
}
.doc-client {
  min-width: 200px; /*modifié*/
  max-width: 250px; /*modifié*/
  justify-self: center; /*modifié*/
}
.doc-resume {
min-width: 100px; /*modifié*/
grid-row: 2;/*modifié*/
grid-column:  1;/*modifié*/
justify-self: end;/*modifié*/
}

.doc-data {
  display: block; /*modifié*/
  grid-row: 3; /*modifié*/
  grid-column: 1; /*modifié*/
  width: 270px; /*modifié*/
  /*min-height: 480px;*/
  justify-self: start;
  overflow: scroll;/*modifié*/
}
.doc-data > div {
  overflow: scroll;/*modifié*/
}
.doc-total{
  width: 200px; /*modifié*/
  font-size: 22px;
  grid-row: 4;/*modifié*/
  grid-column: 1;/*modifié*/
  justify-self: center;/*modifié*/
  max-height: 120px;/*modifié*/
}

.row-document {
 display: flex; /*modifié*/
 flex-direction: column;/*modifié*/
  margin: auto;/*modifié*/
  margin-bottom: 20px;/*modifié*/
  max-width: 300px; /*modifié*/
  width: 250px;/*modifié*/
  grid-template-columns:0.6fr 1.1fr 2fr 1.1fr 1.2fr 1.2fr ;/*modifié*/
  grid-template-rows:1fr ;/*modifié*/
}

.closeSummary {
  min-width: 100px; /*modifié*/
}
 .doc-button2 {
  margin-left: 0px;  /*modifié*/
 }
.save { /*modifié*/
margin: 30px; /*modifié*/
}
.doc-state-cont { /*modifié*/
  margin-top : 30px; /*modifié*/
}
}

</style>