<template>
  <div v-if="seeUsers">
    <div v-if="qrImg != ''" class="img-fluid">
    <img :src="qrImg"  />
    <button @click="qrImg = ''" class="absolute"> x </button>
    </div>
    
    <button class="large-btn" @click="seeUsers = !seeUsers">
      Masquer les utilisateurs
    </button>
    <h2>Utilisateurs enregistrés</h2>
    <button @click="seeUsername = !seeUsername">Identifiant</button>
    <button @click="deleteMode = !deleteMode">Modification</button>

    <div class="users-container">
      <p v-for="user,index in allUsersReversed" :key="user" :index="index" class="p-container">
        <span class="succes-message" v-if="user['id'] === deletedUser">
          suppression...</span
        >
        <span class="succes-message" v-if="user['id'] === updatedUser">
          modification...</span
        >
        <span class="username" v-if="seeUsername">{{ user["username"] }}</span>
        <span>{{ user["name"] }} {{ user["familly_name"] }}</span>
        <span id="admin" v-if="user['admin'] === 1"   
        >ADMIN
        <i v-if="user['super'] === 1">⭐</i></span>
        <span v-if="!deleteMode
        && user.id != connectedUser.id && user.username != connectedUser.username
        && user['super'] != 1 && (user['admin'] != 1 || (user['admin'] === 1 && checkUserR(user)) )
        " @click="useMode(user)" class="use-mode">&#128119;
           <span class="infoBtn">USE MODE (prendre le controle)</span></span>
       
        
        <!-- modifier le statut ADMIN!-->
        <span v-if="checkUserR(user) && chgPass === 'NaN'">
        <button
          v-if="deleteMode && user['admin'] != 1" class="btn"
          @click="setAdmin(user,'up')"
        >&#10133;</button>
        <button
          v-else-if="deleteMode && user['admin'] === 1" class="btn"
          @click="setAdmin(user,'down')"
        >&#10134;</button>
        </span>
         <!-- modifier le MOT DE PASSE-->
         <span  v-if="checkUserStatus(user) && deleteMode">
        <button v-if="deleteMode && chgPass === 'NaN'" class="btn" @click="chgPass = index">&#128273;	</button>
        <button v-else-if="deleteMode && chgPass === index" class="btn" @click="chgPass = 'NaN'">Retour</button>
        
        <span v-if="deleteMode && chgPass === index" >
       
      <!-- Password -->
      <input
        class="form-row__input"
        id="password-input"
        :type="visibility"
        placeholder="Mot de passe"
        :ref ="'input'+index"
      />

      <span class="show__hide__password__container">
        <!-- show the password -->
        <a
          @click="showPassword()"
          class="show__hide__password"
          v-if="visibility == 'password'"
        >
          <img
            class="show__hide__logo"
            src="../../../assets/closed-eyes.svg"
            alt="Cacher le mot de passe"
          />
        </a>
        <!-- hide the password -->
        <a
          @click="hidePassword()"
          class="show__hide__password"
          v-if="visibility == 'text'"
        >
          <img
            class="show__hide__logo"
            src="../../../assets/vision.svg"
            alt="afficher le mot de passe"
        /></a>
      </span>
    <button v-if="deleteMode && chgPass === index" class="btn" @click="chgPassFn(index,user,'chg')">Modifier</button>
    <button v-if="user['admin'] === 1 && deleteMode && chgPass === index" class="btn" @click="chgPassFn(index,user,'QR')">QR</button>
        </span>
        </span>

        <!-- supprimer un utilisateur-->
        <i
          v-if="deleteMode && chgPass === 'NaN' && checkDeleteLogo(user)"
          class="fas fa-trash-alt"
          @click="deleteUser(user)"
        ></i>
      </p>
    </div>
  </div>
  <div v-else>
    <button class="large-btn" @click="seeUsers = !seeUsers">
      Afficher les utilisateurs
    </button>
  </div>
</template>

<script>
import { mapState } from "vuex";

const myAppPath = require('./../../../switchUrl');
const appPath = myAppPath.appPath;

export default {
  name: "Users",
  data: function() {
    return {
      deleteMode: false,
      seeUsername: false,
      connectedUser: {},
      deletedUser: "NaN",
      updatedUser : "NaN",
      seeUsers: false,
      visibility: "password",
      chgPass : 'NaN',
      qrImg : '',
    };
  },
  mounted() {
    this.$store.commit("isConnected");
    // récuperer l'utilisateur actuel
    this.connectedUser = JSON.parse(localStorage.getItem("connectedUser"));
  },
  computed: {
    //STORE
    ...mapState({
      allUsers: "allUsers",
    }),
    //renvoyer les utilisateurs dans l'ordre inverse
    allUsersReversed() {
      //console.log(this.allUsers);
      let reversedArray = this.allUsers.reverse();
      return reversedArray;
    },
  },
  methods: {
    //console log
    logFn() {
      //console.log(this.userExist);
    },
    //especially created for test learning
    loggy(x){
    if(x === 0){
        return 1
    }
    else {
        return 0
    }
  },
    useMode(user){
      //alert(user.username + ' ' + user.id);
      const usingMInfos = {
        username : user.username,
        id : user.id,
        name: user.name
      }
      this.$store.commit("useMode",usingMInfos);
      
    },
    // rafraichir apres delete requete
    refreshAllUsers() {
      let connectedUser = JSON.parse(localStorage.getItem("connectedUser"));
      this.$store.dispatch("getAllUsers", connectedUser.id_user);
    },
    deleteUser(user) {
      //console.log(user);
      //abort if want delete admin
      if (user["admin"] === 1) {
        alert("Vous ne pouvez pas supprimer l'administrateur");
        return;
      }
      // loader
      this.deletedUser = user["id"];
      // user we want to delete
      let username = user["username"];
      //fetching
      this.fetchDeleteUser(username)
        .then((data) => {
          this.deletedUser = "NaN";
          //result
          if (data["data"]) {
            if(data["data"] == false){
              alert('Echec de la suppression utilisateur')
            }
            setTimeout(
              function() {
                this.refreshAllUsers();
              }.bind(this),
              500
            );
           
          }
        })
        .catch((e) => console.log(e));
    },
    async fetchDeleteUser(username) {
      let key;
      //key for only web app native request (!postman)
      if (
        (this.connectedUser.a5e789410fd.includes("2f+g2dfg25h2d+f15b1gs5bnj1ki1lo61q1dc16"))
      ) {
        key = "h1d5he15fdhs";
      }
      const requestOptions = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
        body: JSON.stringify({
          username: username,
          user_id: this.connectedUser.id_user,
          key: key,
          data_feeder : this.connectedUser.username,
        }),
      };

      let response = await fetch(
        appPath+"/auth/deleteAccount",
        requestOptions
      );
      if (!response.ok) {
        alert("Une erreur empêche la requête");
        //stop loader
        this.deletedUser = "NaN";
        // get error message from body or default to response status
        const error = (data && data.message) || response.status;
        //console.log('not response ok, error : ' + error);
        //console.log(error);
        return Promise.reject(error);
      }
      return await response.json();
    },
    setAdmin(user,type){
      //console.log(user);
      // loader
      this.updatedUser = user["id"];
      // user we want to delete
      let username = user["username"];
      //fetching
      this.fetchSetAdmin(username,type)
        .then((data) => {
          this.updatedUser = "NaN";
          //result
          //console.log(data);
          //console.log(typeof data['data']);
          if (data["data"]) {
            if(typeof data["data"] === 'string' && data["data"].includes("ERREUR")){
              alert(data["data"]);
              return
            }
            setTimeout(
              function() {
                this.refreshAllUsers();
              }.bind(this),
              600
            );
            //actualiser la liste allUsers du store après inscription
          }
        })
        .catch((e) => console.log(e));
    },
    async fetchSetAdmin(username,type) {
      let key;
      //key for only web app native request (!postman)
      if (
        (this.connectedUser.a5e789410fd.includes("2f+g2dfg25h2d+f15b1gs5bnj1ki1lo61q1dc16"))
      ) {
        key = "h1d5he15fdhs";
      }
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
        body: JSON.stringify({
          username: username,
          user_id: this.connectedUser.id_user,
          type:type,
          key: key,
          data_feeder : this.connectedUser.username,
        }),
      };

      let response = await fetch(
        appPath+"/auth/setAdmin",
        requestOptions
      );
      if (!response.ok) {
        alert("Une erreur empêche la requête");
        //stop loader
        this.udaptedUser = "NaN";
        // get error message from body or default to response status
        const error = (data && data.message) || response.status;
        //console.log('not response ok, error : ' + error);
        //console.log(error);
        return Promise.reject(error);
      }
      return await response.json();
    },
    setInfos(user,infos){
      //console.log(user);
      // loader
      this.updatedUser = user["id"];
      // user in question
      let username = user["username"];
      //fetching
      this.fetchSetInfos(username,infos)
        .then((data) => {
          this.updatedUser = "NaN";
          //result
          //console.log(data);
          //console.log(typeof data['data']);
          if (data["data"]) {
            if(typeof data["data"] === 'string' && data["data"].includes("ERREUR")){
              alert(data["data"]);
              return
            }
            else {
              alert("le changement de mot de passe a été pris en compte");
            }
            setTimeout(
              function() {
                this.refreshAllUsers();
              }.bind(this),
              600
            );
            //actualiser la liste allUsers du store après inscription
          }
        })
        .catch((e) => console.log(e));
    },
    async fetchSetInfos(username,infos) {
      let key;
      //key for only web app native request (!postman)
      if (
        (this.connectedUser.a5e789410fd.includes("2f+g2dfg25h2d+f15b1gs5bnj1ki1lo61q1dc16"))
      ) {
        key = "h1d5he15fdhs";
      }
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
        body: JSON.stringify({
          username: username,
          user_id: this.connectedUser.id_user,
          password:infos,
          key: key,
          data_feeder : this.connectedUser.username,
        }),
      };

      let response = await fetch(
        appPath+"/auth/setInfos",
        requestOptions
      );
      if (!response.ok) {
        alert("Une erreur empêche la requête");
        //stop loader
        this.udaptedUser = "NaN";
        // get error message from body or default to response status
        const error = (data && data.message) || response.status;
        //console.log('not response ok, error : ' + error);
        //console.log(error);
        return Promise.reject(error);
      }
      return await response.json();
    },

    createQr(user,infos) {
      // user in question
      let username = user["username"];
      // FETCH
      this.fetchCreateQr(username,infos)
        .then((data) => {
          //console.log(data);
          if(data["data"][1]){
            //console.log('data[qrCode]');
            //console.log(data["data"][2]);
            this.qrImg = data["data"][1];
          }
          else {
            alert('Erreur, QR code non généré...')
          }
          
        })
        .catch((e) => console.log(e));
    },
    // create QR Request-------------------------------------------------
    async fetchCreateQr(username,infos) {
      
      if (!(username === "")) {
        let key;
      //key for only web app native request (!postman)
      if (
        (this.connectedUser.a5e789410fd.includes("2f+g2dfg25h2d+f15b1gs5bnj1ki1lo61q1dc16"))
      ) {
        key = "hdfg5487reghjjkfd7478";
      }
        const requestOptions = {
          method: "POST",
          headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
          body: JSON.stringify({
            _type : 'getQR',
            key : key,
            username: username,
            password: infos,
            user_id: this.connectedUser.id_user,
          }),
        };
        let response = await fetch(
          appPath+"/auth/getQr",
          requestOptions
        );
        if (!response.ok) { 
          alert("Une Erreur innattendue s'est produite");
          const backMess= await response.json();
          if(backMess['error']){
            alert(JSON.stringify(backMess['error']));
            
            // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
          }
          
        }
        return await response.json();
      } //si un champ est resté vide on ne passe pas dans fetch
      else {
        alert("veuillez remplir tous les champs");
      }
    },

    checkUserR(user){
      if(this.connectedUser && this.connectedUser["token"]){
      if(user['super'] === 1){
        return false
      }
      if(this.connectedUser["kiy656dgr28e"] === 'gdf2g5dhy7'+this.connectedUser["token"].substring(21,58) ){
        return true
      }
      return false
      }
      return false
    },
     showPassword() {
      this.visibility = "text";
    },
    hidePassword() {
      this.visibility = "password";
    },
    chgPassFn(index,data,action){
      //console.log(data);
      
      //console.log(/(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(str));
      //pattern="(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$"
      switch (action){
        case 'chg':
          const user = data;
      /*console.log(this.$refs['input'+index].value);*/
      const str = this.$refs['input'+index].value;
      const arr = ['$','<','>','/','*','&','|','§','!','?','\\'];
      for (let i in arr){
        if(str.includes(arr[i])){
          alert('carractères spécial non admis '+arr[i]);
          return
        }
      }
      const tst = /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(str);
      if(!tst){
        alert(`Le mot de passe doit comprendre au moins 8 carractères (requis : 1
        majuscule, 1 chiffre. Certains carractères spéciaux sont bannis)`);
        return
      }
        this.setInfos(user,str);  
          break;
        case 'QR':
        const user2 = data;
        const str2 = this.$refs['input'+index].value;
        this.createQr(user2,str2);  
          break;
      
        default:
          break;
      }
      
      
    },
    checkUserStatus(user){
      if(this.connectedUser && this.connectedUser["token"]){
      if(user['super'] === 1){
        return false
      }
      if(this.connectedUser["kiy656dgr28e"] === 'gdf2g5dhy7'+this.connectedUser["token"].substring(21,58)){
        return true
      };
      if(this.connectedUser['a5e789410fd'] === '2f+g2dfg25h2d+f15b1gs5bnj1ki1lo61q1dc16'+JSON.parse(localStorage.getItem('token'))+'1202'+JSON.parse(localStorage.getItem('token')).substr(12, 42)
      && user['admin'] !=1){
        return true
      }
      if(this.connectedUser['a5e789410fd'] === '2f+g2dfg25h2d+f15b1gs5bnj1ki1lo61q1dc16'+JSON.parse(localStorage.getItem('token'))+'1202'+JSON.parse(localStorage.getItem('token')).substr(12, 42)
      && user['name'] === this.connectedUser['name'] && user['id'] === this.connectedUser['id_user'] && user['admin'] ===1){
        return true
      }
      return false}
      return false
    },
    checkDeleteLogo(user){
      if(user['super']===1 || user['admin']===1){
        return false
      }
      return true
    }
  },
};
</script>

<style scoped>
p {
  position: relative;
  font-size: 20px;
  padding: 5px;
  padding-bottom: 10px;
  margin: 5px;
  border: 1px black solid;
}
.p-container span {
  margin: 10px;
}
.p-container span .btn {
  margin: 15px;
  width: auto;
}

.username {
  border: none;
  margin-right: 10px;
}
#admin {
  margin-left: 10px;
  font-weight: bold;
}
button {
  width: 200px;
  height: 40px;
  margin-bottom: 20px;
  font-size: 20px;
  background-color: #0d444b;
  color: #ee821a;
}
.large-btn {
  height: 80px;
  margin-top: 20px;
}
.users-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.fa-trash-alt {
  cursor: pointer;
  margin-left: 10px;
  border: black 1px solid;
  background-color: yellow;
  padding: 9px;
  opacity: 0.6;
}
.fa-trash-alt:hover{
  opacity: 1;
}
.use-mode {
  border: 1px black solid;
  border-radius: 20px;
  padding : 5px 5px 6px 5px;
  background-color: #ee821a;
  /*opacity: 0.4;*/
  cursor: pointer;
}
.infoBtn {
 border: 1px black solid;
 padding: 2px;
 background-color: #ee821a;
 color: white;
 position: absolute;
 bottom: 25px;
 display: none;
}
.use-mode:hover > .infoBtn {
  display: block;}

.succes-message {
  position: absolute;
  background-color: greenyellow;
  border: none;
  left: 0;
  top: 0;
  width: 100%;
  height: 30px;
  font-size: 25px;
  z-index : 10;
}

.form-row__input {
  padding: 8px;
  border: none;
  border-radius: 8px;
  background: #f2f2f2;
  font-weight: 500;
  font-size: 25px;
  flex: 1;
  min-width: 100px;
  max-width: 200px;
  color: black;
}

.form-row__input::placeholder {
  color: #aaaaaa;
}
.show__hide__password__container {
  pointer-events: none;
  background-color: transparent;
  height: 50px;
}
.show__hide__password {
  pointer-events: visible;
  cursor: pointer;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  width: 50px;
  padding: 10px;
  padding-bottom: 8px;
  border: 1px black solid;
  background-color: #ee821a;
  opacity: 1;}
  
.show__hide__password:hover {
  opacity: 0.7;
}
.show__hide__logo {
  height: 20px;
  cursor: pointer;
}
.btn {
max-width: 100px;
background-color: #ee821a;
color: black;
}
.img-fluid {
  position: fixed;
  border: 10px solid #0d444b;
  outline: 50px solid #ee821a;
  top: 50%;
  left: 50%;
  z-index: 10;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
  cursor : pointer;
}
.absolute {
  position: absolute;
  top: -60px;
  width: 50px;
  font-size: 20px;
}

@media screen and (max-width: 1100px) {
}
</style>
