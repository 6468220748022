<template>
  <div class="history-container">
     <!--{{connectedUser}} !-->
        <!-- {{globalHistory}} !-->
        <button class="showOptions" v-if="!controlPanel" @click="controlPanel = true">Afficher les options &#x25BC;</button>

        <div class="control-panel-div" v-show="controlPanel">
           <button class="hideOptions"  @click="controlPanel = false">Masquer les options &#x25B2; </button>
         <div @click="exportToExcel()" class="excel-div">
        <button>Export Excel</button>
        <img id="icon1" src="../../../assets/excel.jpg" alt=""/>
        <img id="icon2" src="../../../assets/libreoff.jpg" alt=""/>
        
        </div>

        <div class="search-container interval-container">
          <p>Choisir un période jj/mm/aaaa (par défaut 31 derniers jours)</p>
          <div>
          <label for="date">Entre : </label>
        <input type="search" name="date" ref="date_filter" v-model="choosedStartDate">
        </div>
          <div>
        <label for="date">et : </label>
        <input type="search" name="date" ref="date_filter" v-model="choosedEndDate">
        </div>
        <button @click="newDateInterval()">Lancer la requête</button>
        <button @click="defaultInterval()">Effacer</button>
        </div>

        <div class="search-container">
          <div>
        <label for="date">Filtrer une date : </label>
        <input type="search" name="date" id="" v-model="choosedDate">
        </div>
        <div>
        <label for="date"> Filtrer une heure : </label>
        <input type="search" name="date" id="" v-model="choosedHour">
        </div>

        
        </div>
        
        <div class="filters-container">
        <div class="user-filter-container">
          <select name="" id="autoSize" :size="size" @focus="onFocusSelect" @blur="onBlurSelect"
          @change="userFilter($event)">
            <option selected value="inactive">
              -Filtre utilisateur desactivé-
            </option>
            <option :value="username" v-for="username in userSetHistory" :key="username">
              {{username}}
            </option>
          </select>
        
        </div>
        

        <div class="location-filter-container">
          <select name="" id="" @change="locationFilter($event)">
            <option selected value="inactive">
              -Filtre ville desactivé-
            </option>
            <option :value="loc" v-for="loc in townSetHistory" :key="loc">
              {{loc}}
            </option>
          </select>
        </div>


        <div class="type-filter-container">
          <select name="" id="" @change="clientFilter($event)">
            <option selected value="inactive">
              -Filtre client desactivé-
            </option>
            <option :value="client" v-for="client in clientSetHistory" :key="client">
              {{client}}
            </option>
          </select>
        </div>
        
        </div>
        </div>
        
              <br>
<div class="table-container">
    <table v-if="Object.values(multiFilters).length != 0">
        <thead class="global-history-table" >
            <tr>
          <td>Ligne</td>
          <!--td>color</td!-->
          <td>Chantier</td>
          <td>Ville</td>
          <td>Client</td>
          <td>Statut modifié</td>
          <td>Utilisateur</td>
         
          <td><button v-if="!showHour" @click="showHour = true">Date</button>
          <button v-else @click="showHour = false">Date + </button></td>
          </tr>
      </thead>

      <tbody ref="user_table" id="usertable" class="global-history-table" >
        <!-- DATE/HOUR FILTER !-->
        <tr v-for="row in multiFilters" :key="row"
            :class="row.color"
            >
          <td>{{row.id}}</td>
          <!--td>{{row.color}}</td!-->
          
          <td>{{row.id_construction}}</td>
          <td>{{row.town}}</td>
          <td>{{row.client}}</td>
          <td>{{row.status_}}</td>
          <td>{{row.username}} </td>
          
          <td v-if="!showHour" class="date" @click="copyFn(dateFormat(row.date),'date')">{{dateFormat(row.date)}} </td>
          <td v-else >{{dateFormatH(row.date)}} </td>

          </tr>
      </tbody>
     
</table>

<div v-else class="loader" >Recherche de données... &#8987;</div>
  </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
const XLSX = require("xlsx");
const { saveAs } = require('file-saver');

export default {
    name : "HistoryConstructions",
    props : ["connectedUser"],
    data() {
        return {
            showHour : false,
            choosedDate : '',
            choosedHour : '',
            activeUserFilter : false,
            choosedUser : '',
            activeLocationFilter : false,
            choosedLocation : '',
            activeClientFilter : '',
            choosedClient : '',
            choosedStartDate : '',
            choosedEndDate : '',
            controlPanel : false,
            size : 1
            
        }
    },
      mounted() {
    this.$store.state.intervalDateActivities = [];
    this.$store.commit("isConnected");
    this.getConstructionStatusHistory();
  },
      computed: {
    //STORE
    ...mapState({
      constructionsStatusHistory: "constructionsStatusHistory",
      intervalDateActivities : "intervalDateActivities",
    }),
      userSetHistory(){
      var theSet = new Set;
      let miror;
      if(Object.values(this.intervalDateActivities).length != 0){
        miror = this.intervalDateActivities;
      }
      else{
        miror = this.constructionsStatusHistory;
      };
      miror.forEach(element => {
        theSet.add(element.username);
      });
      
      return theSet;
      },
      townSetHistory(){
      var theSet = new Set;
      let miror;
      if(Object.values(this.intervalDateActivities).length != 0){
        miror = this.intervalDateActivities;
      }
      else{
        miror = this.constructionsStatusHistory;
      };
      miror.forEach(element => {
        theSet.add(element.town);
      });
      
      return theSet;
      },
      clientSetHistory(){
      var theSet = new Set;

      let miror;
      if(Object.values(this.intervalDateActivities).length != 0){
        miror = this.intervalDateActivities;
      }
      else{
        miror = this.constructionsStatusHistory;
      };
      miror.forEach(element => {
        theSet.add(element.client);
      });
     
      return theSet;
      },

      multiFilters(){

      var obj = [];
      let miror;
      if(Object.values(this.intervalDateActivities).length != 0){
        miror = this.intervalDateActivities;
      }
      else{
        miror = this.constructionsStatusHistory;
      };
      
      if(this.choosedDate != ''){
        miror.forEach(element => {
          if(this.dateFormat(element.date) === this.choosedDate){
            obj.push(element);
          }
        });
        miror = obj;
        obj = [];
      }
      if(this.choosedHour != ''){
        miror.forEach(element => {
          if(this.dateFormatH(element.date).split(',')[1].includes(' '+this.choosedHour)){
            obj.push(element);
          }
        });
        miror = obj;
        obj = [];
      }
      if(this.activeUserFilter){
        miror.forEach(element => {
          if(element.username === this.choosedUser){
            obj.push(element);
          }
        });
        miror = obj;
        obj = [];
      }

      if(this.activeLocationFilter){
        miror.forEach(element => {
          if(element.town === this.choosedLocation){
            obj.push(element);
          }
        });
        miror = obj;
        obj = [];
      }

      if(this.activeClientFilter){
        miror.forEach(element => {
          if(element.client === this.choosedClient){
            obj.push(element);
          }
        });
        miror = obj;
        obj = [];
      }
      
      return miror;
    },
     translateForExcel(){
      var jsonObj = [];
      var miror = JSON.parse(JSON.stringify(this.multiFilters));
        //var start = new Date();
        miror.forEach(element => {
          delete Object.assign(element, {['Ligne']: element['id'] })['id'];
          delete Object.assign(element, {['Chantier']: element['id_construction'] })['id_construction'];
          delete Object.assign(element, {['Ville']: element['town'] })['town'];
          delete Object.assign(element, {['Client']: element['client'] })['client'];
          delete Object.assign(element, {['Statut modifié']: element['status_'] })['status_'];
          delete Object.assign(element, {['Utilisateur']: element['username'] })['username'];
          
          element.date = this.dateFormat(element.date);
          delete Object.assign(element, {['Date']: element['date'] })['date'];
          delete element.color;
          jsonObj.push(element);
        });
        // 7 milliseconds
        //var time = new Date() - start;
        //console.log(time);
        return jsonObj;
    }
  },

  watch: {
    constructionsStatusHistory(newCount, oldCount) {
      if (newCount){
        this.constructionsH = newCount;
      }
    },
  },

  methods : {
    onFocusSelect(){
      this.size = 4;
    },
    onBlurSelect(){
      this.size = 1;
    },
      getConstructionStatusHistory(){
        if (Object.keys(this.connectedUser).length != 0) {
        this.$store.dispatch("getConstructionStatusHistory", this.connectedUser.id_user);
      } else {
        alert("Accès bloqué, l'utilisateur n'est pas reconnu !");
      }
      },
      dateFormat(data){
       // console.log( data);
       // console.log(typeof data);
        const dateObject = new Date(data);
        //console.log( dateObject);
        //console.log(typeof dateObject);
        let humanDateFormat;
        if(dateObject.toLocaleString().includes(',')){
        humanDateFormat = dateObject.toLocaleString().split(',')[0];}
        else{
        humanDateFormat = dateObject.toLocaleString().split(' ')[0];  
        }
        //console.log( humanDateFormat.split(',')[0]);
        //console.log(typeof humanDateFormat);
        return humanDateFormat;
      },
      dateFormatH(data){
        const dateObject = new Date(data);
        const humanDateFormat = dateObject.toLocaleString();
        return humanDateFormat;
      },
      userFilter(event){
        //console.log(event.target.value);
        //console.log(typeof event.target.value);

        // si filtre desactivé, revenir à l'affichage classique
        if(event.target.value === 'inactive')
        {
          this.activeUserFilter = false;
          return
        }

        this.activeUserFilter = true;
        this.choosedUser = event.target.value;
        this.size = 1;
      event.target.blur();
      },
      locationFilter(event){
        //console.log(event.target.value);
        //console.log(typeof event.target.value);

        // si filtre desactivé, revenir à l'affichage classique
        if(event.target.value === 'inactive')
        {
          this.activeLocationFilter = false;
          return
        }

        this.activeLocationFilter = true;
        this.choosedLocation = event.target.value;
      },
      clientFilter(event){
        // si filtre desactivé, revenir à l'affichage classique
        if(event.target.value === 'inactive')
        {
          this.activeClientFilter = false;
          return
        }

        this.activeClientFilter = true;
        this.choosedClient = event.target.value;
      },
      copyFn(number,type) {
        if(type === 'order'){
  var copyText = number.toString();
  const searchInput = this.$refs['order_filter'];
  searchInput.value = copyText;
  searchInput.focus();
  this.choosedOrder = copyText;}
  else {
  var copyText = number.toString();
  const searchInput = this.$refs['date_filter'];
  searchInput.value = copyText;
  searchInput.focus();
  this.choosedDate = copyText;
  }
      },
      newDateInterval(){
        if(this.choosedStartDate != '' && this.choosedEndDate != ''){

         var j = this.choosedStartDate.split('/')[0];
         var m = this.choosedStartDate.split('/')[1];
         var a = this.choosedStartDate.split('/')[2];
         const choosedStartDate = a+'-'+m+'-'+j;
         j = this.choosedEndDate.split('/')[0];
         m = this.choosedEndDate.split('/')[1];
         a = this.choosedEndDate.split('/')[2];
         const choosedEndDate = a+'-'+m+'-'+j;

         //console.log(j);console.log(m);console.log(a);
         
         const startDate = new Date(choosedStartDate+'Z');
         var endDate0 = new Date(choosedEndDate+'Z');
         endDate0.setUTCHours(22);
         endDate0.setUTCMinutes(59);
         endDate0.setUTCSeconds(59);
         //endDate0 = endDate0.setUTCMinutes(59);
         //console.log(endDate0);
         const endDate = endDate0;
         if(isNaN(Date.parse(startDate))  || isNaN(Date.parse(endDate))){
           alert("erreur de format de date, veuillez respecter ce format : jj/mm/aaaa");
         }
         
         if (Object.keys(this.connectedUser).length != 0) {
           
           const historyData = {
           date : [startDate,endDate],
           compOrigin :'constructions_status_history',
           username : this.connectedUser.username,
           user_id : this.connectedUser.id_user
         }

        this.$store.dispatch("putIntervalActivities", historyData);
         } else {
        alert("Accès bloqué, l'utilisateur n'est pas reconnu !");}

        }else{
          alert("Veuillez remplir tous les champs d'interval de date !");
        }
      },
      defaultInterval(){
        this.choosedStartDate = '';
        this.choosedEndDate = '';
        this.$store.state.intervalDateActivities = [];
      },
      cutString(username){
        if(username != null && username.includes(' creatOnRet')){
        return username.split(' creatOnRet')[0];}
        return username;
      },
      exportToExcel(){
        var miror = JSON.parse(JSON.stringify(this.translateForExcel));
        //console.log(miror);
        this.downloadAsExcel(miror);
      },
      downloadAsExcel(data){
        let worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = {
          Sheets : {
            'data':worksheet
          },
          SheetNames: ['data']
        };
        const excelBuffer = XLSX.write(workbook, {booktype : 'xlsx', type : 'array'});
        //console.log(excelBuffer);
        this.saveAsExcel(excelBuffer, 'myFile');
      },
      saveAsExcel(buffer,filename){
        const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([buffer],{type:EXCEL_TYPE});
        saveAs(data,filename+'_export_'+new Date().getTime()+EXCEL_EXTENSION);
      },
      extendOrderNumber(username,number){
        if(number === null || number === 1000001){
          return number;
        }
        //si la modification a dejà eu lieu ne pas transformer le numero
        //console.log(number.toString().length);
        if(number.toString().length >10){
          return number;
        }
        var id = 0;
        //console.log(this.usersIds);
        //console.log(this.usersIds.length);
        this.usersIds.forEach(element => {
          //console.log(element.username);
          if(username == element.username || username.includes(element.username)){
            id = element.id;
          }

        });
        const o_number = id.toString() + number.toString();
          return parseInt(o_number);
      }
  }

}
</script>

<style scoped>
table{
  margin : auto;
  overflow: scroll;
}
.table-container{/**/
  margin-top: 80px;
  margin-bottom: 60px;
  max-width: 1400px;
  overflow-x: scroll;
}
thead {
  font-size: 20px;
  font-weight: bold;
}
thead td{
  border: 1px solid black;
  text-overflow: ellipsis;
}
.gainsboro{
  background-color: gainsboro;
}
.white{
  background-color: white;
}
.gainsboro:hover,.white:hover{
  background-color: rgb(116, 223, 223);
}
.global-history-table{
margin: 10px;
}
.global-history-table td{
margin: 10px;
}
.search-container {
  display: flex;
  margin: 30px;
  justify-content: space-evenly;
}
.search-container div input{
  width: 100px;
  margin-left : 10px;
}
.search-container div label{
  font-size: 22px;
}
.interval-container{
  display: flex;
  font-size: 19px;
  align-content: center;
  padding-left: 10px;
  border: 1px black solid;
}
.interval-container p{
margin-right: 20px;
line-height: 30px;
}
.interval-container div{
margin: 20px;
}
.interval-container button{
margin: 20px;
font-size: 19px;
}
.order-number {
  cursor: pointer;
}
.date{
  cursor: pointer;
}
.date:hover{
  background-color: greenyellow;
}
.order-number:hover{
  background-color: yellow;
}
.excel-div img {
width : 50px;
height: 50px;
margin-left: 20px ;
}
.excel-div {
    display: flex;
    justify-content: center;
}
.excel-div button{
    font-size : 20px;
    color: white;
    background-color: green;
    opacity: 1;
}
.excel-div button:active {
	transform: scale(1.1);
	transition: .1s;
}

.excel-div button:hover {
  opacity : 0.7;
}
.loader {
  margin-top: 80px;
  font-size: 50px;
}
.filters-container {
  display: flex;
  height: 100px;
  margin-bottom : 30px;
  justify-content: space-around;
  align-items: center;
  border: 1px black solid;
}
div select{
  text-align: center;
  width: 290px;
  height: 45px;
  font-size: 18px;
  background-color: rgb(239, 239, 239);
}
.hideOptions {
  margin-bottom: 20px;
  font-size: 18px;
}
.showOptions{
  font-size: 18px;
}
div #autoSize {
height : auto;
}
tr:hover {
  background-color: rgb(116, 223, 223);;
}
@media screen and (max-width: 1100px) {
table{
  overflow-x: scroll;
}
.table-container{
  margin-top: 50px;
  max-width: 280px;
  overflow-x: scroll;
}
.history-container{
  max-width: 280px;
  overflow-x: hidden;
}
.control-panel-div{
  max-width: 280px;
  overflow-x: scroll;
}
.search-container {
  flex-direction: column;
  margin: 20px;
}
.search-container div label{
  font-size: 18px;
}
.interval-container{
  flex-direction: column;
}
.loader {
  margin-top: 50px;
  font-size: 20px;
}
.filters-container {
  flex-direction: column;
  height: 140px;
  margin-bottom : 10px;
}
div select{
  width: 240px;
  height: 35px;
}

}
</style>