<template >
    <div class="main-div" v-if="!documentEditing">
        <h3>Suivi des prestations</h3>

        <!-- date container -->
        <div class="search-container interval-container">
          <p>Choisir un période jj/mm/aaaa (par défaut dernière période entre 16 & 15)</p>
          <div>
          <label for="date">Entre : </label>
        <input type="search" name="date" ref="date_filter" v-model="choosedStartDate">
        </div>
          <div>
        <label for="date">Et : </label>
        <input type="search" name="date" ref="date_filter" v-model="choosedEndDate">
        </div>
        <button @click="getTracking()">Lancer la requête</button>
        </div>
            <!-- export excel -->
         <div @click="exportToExcel()" class="excel-div">
        <button>Export Excel</button>
        <img id="icon1" src="../../../assets/excel.jpg" alt=""/>
        <img id="icon2" src="../../../assets/libreoff.jpg" alt=""/>
        
        </div>

            <div class="table-container">
                <table>
          <thead>
            <tr >
                <th>Date</th>
              <th>Chantier</th>
              <th>Client</th>
              <th>Document</th>
              <th>Statut</th>
              <th>Doc n°</th>
              <th>Ref</th>
              <th>Désignation</th>
              <th>Quanttié</th>
              <th>Prix</th>
              
              
            </tr>
          </thead>
          <tbody
            v-for="(row, index) in trackingData"
            :key="row"
            :index="index"
          >
            <tr>
                <td>
                {{ dateFormat(row["docDate"]) }} 
             </td>
              <td>
                {{ row["date"] + row["incremented_nb"]}}
              </td>
              <td>
                {{ row["client"] }}
              </td>
              <td>
                {{ row["document_name"] }}
              </td>
              <td>
                {{ translateStatus(row["document_status"]) }}
              </td>
              <td>
                {{ row["document_id"] }}
              </td>
              <td>
                {{ row["ref"] }}
              </td>
              <td>
                {{ row["name"] }}
              </td>
              <td>
                {{ row["qtty"] }}
              </td>
              <td>
                {{ row["price"] }} €
             </td>
              
              
        
            </tr>
          </tbody>
        </table>
        </div>
    </div>
</template>


<script>

import { mapState } from "vuex";
const XLSX = require("xlsx");
const { saveAs } = require('file-saver');

const myAppPath = require('../../../switchUrl');
const appPath = myAppPath.appPath;
export default {
    props : ['documentEditing'],
    data() {
        return {
            connectedUser: {}, 
            trackingData : [],
            choosedStartDate : '',
            choosedEndDate : ''
        }
    },
    mounted() {
        this.$store.commit("isConnected");
    // récuperer l'utilisateur actuel
    this.connectedUser = JSON.parse(localStorage.getItem("connectedUser"));
    //this.getDocData(); à supprimer + fn + computed ?
    this.getTracking();
    
    
  
    },
    watch: {
        
    },
    computed: {
        ...mapState({
      documentsData : "documentsData",
    }),
    translateForExcel(){
      var jsonObj = [];
      var miror = JSON.parse(JSON.stringify(this.trackingData));
        //var start = new Date();
        miror.forEach(element => {
            element['Chantier'] = element.date + element.incremented_nb;
            delete element.date;
            delete element.incremented_nb;
          delete Object.assign(element, {['Client']: element['client'] })['client'];
          delete Object.assign(element, {['Réf']: element['ref'] })['ref'];
          delete Object.assign(element, {['Document']: element['document_name'] })['document_name'];
          delete Object.assign(element, {['Statut']: element['document_status'] })['document_status'];
          switch (element['Statut']) {
            case 'validated':
            element['Statut'] = 'Validé'
            break;
          case 'invalidated':
            element['Statut'] = 'En cours'
            break;
        
          
            default:
              break;
          }
          delete Object.assign(element, {['Doc n°']: element['document_id'] })['document_id'];
          delete Object.assign(element, {['Désignation']: element['name'] })['name'];
          delete Object.assign(element, {['Quantité']: element['qtty'] })['qtty'];
          delete Object.assign(element, {['Prix']: element['price'] })['price'];
          delete Object.assign(element, {['Date']: element['docDate'] })['docDate'];
          jsonObj.push(element);
        });
        // 7 milliseconds
        //var time = new Date() - start;
        //console.log(time);
        return jsonObj;
    }
    },
    methods: {
        getDocData(){
            this.$store.dispatch('getDocumentsData',this.connectedUser.id_user);
        },
        getTracking(){
            this.generateDate();
            const id = this.connectedUser.id_user;
            const startDate = this.choosedStartDate.replace(/['/']/g,'+');
            const endDate = this.choosedEndDate.replace(/['/']/g,'+');
            const idAndDate = startDate +'-'+ endDate +'_' + id ;
            //console.log(idAndDate);
    this.fetchGetTracking(idAndDate)
        .then((data) => {
          if (data["data"]) {
              this.trackingData =data["data"];
          }
          // infinite loop if no return because call by generateDate
          return 'end'
        })
        .catch((e) => console.log(e));
        },
        async fetchGetTracking(idAndDate){
            let response = await fetch(appPath+"/activities/getTracking/" + idAndDate, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    },
  });
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (data && data.message) || response.status;
    //console.log('not response ok, error : ' + error);
    alert("une erreur innattendue s'est produite");
    return Promise.reject(error);
  }
  return await response.json();
        },
        generateDate(){
             //console.log('infinite ?');
            if(this.choosedEndDate === '' && this.choosedStartDate === ''){
            var date = new Date();
            var day = date.getDate();
            let month;
            let previousMonth;
            var year = date.getFullYear();
            var previousYear = year;

            if(day < 15){
                if(day < 10){
                    day = '0'+day.toString();
                }
               month = (date.getMonth()+1) -1;
               previousMonth = (date.getMonth()+1) -2;
            }
            else {
               month = (date.getMonth()+1);
               previousMonth = (date.getMonth()+1) -1;
            }
            if(month === 0){
                month = 12;
                year = year -1;
                previousYear = year;
            }
            if(month.toString().length <2){
                month = '0'+month.toString();
            }
            else {
                 month = month.toString();
            }
            if(previousMonth === 0){
                previousMonth = 12;
                previousYear = date.getFullYear() -1;
            }
            else if (previousMonth === -1){
                 previousMonth = 11;
                 previousYear = date.getFullYear() -1;
            }
            if(previousMonth.toString().length <2){
                previousMonth = '0'+previousMonth.toString();
            }
            else {
                 previousMonth = previousMonth.toString();
            }
            this.choosedEndDate = '15/'+month+'/'+year;
            this.choosedStartDate = '16/'+previousMonth+'/'+previousYear;
            
            }
            else{
                //verifier le format de la date jj/mm/aaaa
                var pattern =/^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/;
       
       if(!pattern.test(this.choosedEndDate) || !pattern.test(this.choosedStartDate )){
           alert("Erreur de format de date, veuillez respecter ce format : jj/mm/aaaa");
           return
         }
         else{
            return
         }
            }
            return
        },
        exportToExcel(){
        var miror = JSON.parse(JSON.stringify(this.translateForExcel));
        this.downloadAsExcel(miror);
      },
      downloadAsExcel(data){
        let worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = {
          Sheets : {
            'data':worksheet
          },
          SheetNames: ['data']
        };
        const excelBuffer = XLSX.write(workbook, {booktype : 'xlsx', type : 'array'});
        console.log(excelBuffer);
        this.saveAsExcel(excelBuffer, 'myFile');
      },
      saveAsExcel(buffer,filename){
        const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([buffer],{type:EXCEL_TYPE});
        saveAs(data,filename+'_export_'+new Date().getTime()+EXCEL_EXTENSION);
      },
      translateStatus(data){
        switch (data) {
            case 'validated':
                return 'Validé'
                break;
            case 'invalidated':
                return 'En cours'
                break;
            default:
                return data
                break;
        }
      },
      dateFormat(data){
       // console.log( data);
       // console.log(typeof data);
        const dateObject = new Date(data);
        //console.log( dateObject);
        //console.log(typeof dateObject);
        let humanDateFormat;
        if(dateObject.toLocaleString().includes(',')){
        humanDateFormat = dateObject.toLocaleString().split(',')[0];}
        else{
        humanDateFormat = dateObject.toLocaleString().split(' ')[0];  
        }
        //console.log( humanDateFormat.split(',')[0]);
        //console.log(typeof humanDateFormat);
        return humanDateFormat;
      },
    },
}

</script>
<style >
.main-div {
     margin: 50px auto 80px auto;
}
table{
    margin: auto;
}
.table-container {
    max-height: 850px;
    overflow: scroll;
}
td {
    border: black 1px solid;
    padding: 10px 10px;
    max-width: 150px;
    overflow: auto;
}
tr:hover {
    background-color: rgba(255, 255, 0,0.7);
}
.search-container {
  display: flex;
  margin: 30px;
  justify-content: space-evenly;
}
.search-container div input{
  width: 100px;
  margin-left : 10px;
}
.search-container div label{
  font-size: 22px;
}
.interval-container{
  display: flex;
  font-size: 19px;
  align-content: center;
  padding-left: 10px;
  border: 1px black solid;
}
.interval-container p{
margin-right: 20px;
line-height: 30px;
}
.interval-container div{
margin: 20px;
}
.interval-container button{
margin: 20px;
font-size: 19px;
}
.excel-div img {
width : 50px;
height: 50px;
margin-left: 20px ;
}
.excel-div {
    display: flex;
    justify-content: center;
    margin: 40px;
}
.excel-div button{
    font-size : 20px;
    color: white;
    background-color: green;
    opacity: 1;
}
.excel-div button:active {
	transform: scale(1.1);
	transition: .1s;
}

.excel-div button:hover {
  opacity : 0.7;
}
</style>