<template>
  <div class="page_container" v-if="(userExist['username']) && 
  (connectedUser['a5e789410fd'] === this.rand)">

      <div class="header">
        <img alt="logo digitech" src="../assets/Digitech.jpg" />
        <h1><i class="fas fa-user-circle"></i> {{userName}}</h1>
        <button class="button" @click="this.$router.push('/')">Déconnexion </button>
      </div>
      <h2>Historiques
         <select name="" id="" @change="switchHistory($event)">
        <option value="stock">Stock</option>
        <option value="activity">Activité</option>
      </select>
      </h2>
     
      <div class="history-btn-container">
        <div v-if="storageStock"> 
      <button class="history-btn" :style="showHistory.Global ? {'backgroundColor' : 'yellow'}:{}" @click="showHistoryFn('showGlobal')">Global
        <p>Entrées & sorties</p>
      </button>
      <button class="history-btn" :style="showHistory.User ? {'backgroundColor' : 'yellow'}:{}" @click="showHistoryFn('showUser')">Utilisateurs</button>
      <button class="history-btn" :style="showHistory.Reception ? {'backgroundColor' : 'yellow'}:{}" @click="showHistoryFn('showReception')">Réceptions</button>
      <button class="history-btn" :style="showHistory.AdminChanges ? {'backgroundColor' : 'yellow'}:{}" @click="showHistoryFn('showAdminChanges')">Administrateur</button>
      <button class="history-btn" :style="showHistory.Booking ? {'backgroundColor' : 'yellow'}:{}" @click="showHistoryFn('showBooking')">Réservations</button>
      <button class="history-btn" :style="showHistory.Reels ? {'backgroundColor' : 'yellow'}:{}" @click="showHistoryFn('showReels')">Tourets</button>
      <button class="history-btn" :style="showHistory.Tools ? {'backgroundColor' : 'yellow'}:{}" @click="showHistoryFn('showTools')">Alerte Outils</button>
      </div>
    <div v-else>
      <button class="history-btn" :style="showHistory.Constructions ? {'backgroundColor' : 'yellow'}:{}" @click="showHistoryFn('showConstructions')">Chantiers</button>
      <button class="history-btn" :style="showHistory.ConstructionsStatus ? {'backgroundColor' : 'yellow'}:{}" @click="showHistoryFn('showConstructionsStatus')">Statuts chantiers</button>
      <button class="history-btn" :style="showHistory.Assignement ? {'backgroundColor' : 'yellow'}:{}" @click="showHistoryFn('showAssignement')">Affectation</button>
      </div></div>

    
    <HistoryGlobal v-if="showHistory.Global" :connectedUser="connectedUser"></HistoryGlobal>
    <HistoryReception v-if="showHistory.Reception" :connectedUser="connectedUser"></HistoryReception>
    <HistoryUser v-if="showHistory.User" :connectedUser="connectedUser"></HistoryUser>
    <HistoryAdminChanges v-if="showHistory.AdminChanges" :connectedUser="connectedUser"></HistoryAdminChanges>
    <HistoryBooking v-if="showHistory.Booking" :connectedUser="connectedUser"></HistoryBooking>
    <HistoryReels v-if="showHistory.Reels" :connectedUser="connectedUser"></HistoryReels>
    <HistoryTools v-if="showHistory.Tools" :connectedUser="connectedUser"></HistoryTools>
    
    <HistoryConstructions v-if="showHistory.Constructions" :connectedUser="connectedUser"></HistoryConstructions>
    <HistoryConstructionsStatus v-if="showHistory.ConstructionsStatus" :connectedUser="connectedUser"></HistoryConstructionsStatus>
    <HistoryAssignement v-if="showHistory.Assignement" :connectedUser="connectedUser"></HistoryAssignement>
  
  </div>
  <div v-else class="security-info">Vérification de vos accès ...
    Si les données n'apparaissent pas reconnectez vous.
     <p><router-link to="/" >Retrouner à la connexion</router-link></p>
  </div>
</template>

<script>

import HistoryGlobal from "@/components/Admin/History/HistoryGlobal.vue";
import HistoryReception from "@/components/Admin/History/HistoryReception.vue";
import HistoryUser from "@/components/Admin/History/HistoryUser.vue";
import HistoryAdminChanges from "@/components/Admin/History/HistoryAdminChanges.vue";
import HistoryBooking from "@/components/Admin/History/HistoryBooking.vue";
import HistoryReels from "@/components/Admin/History/HistoryReels.vue";
import HistoryTools from "@/components/Admin/History/HistoryTools.vue";
//activity
import HistoryConstructions from "@/components/Admin/History/HistoryConstructions.vue";
import HistoryConstructionsStatus from "@/components/Admin/History/HistoryStatus.vue";
import HistoryAssignement from "@/components/Admin/History/HistoryAssignement.vue";
import { mapState } from "vuex";
import timerAcces from "./timer_access";

export default {
  name: 'History',
  components: {
    HistoryGlobal,HistoryReception,
    HistoryUser,HistoryAdminChanges,
    HistoryBooking,HistoryReels,HistoryTools,
    HistoryConstructions, HistoryConstructionsStatus,
    HistoryAssignement
  },
  data() {
    return {
      userName : 'utilisateur',
      connectedUser : {}, 
      rand : '',
      showHistory : {
      Global : false,
      Reception : false,
      User : false,
      AdminChanges : false,
      Booking : false,
      Reels : false,
      Tools : false,
      Constructions : false,
      ConstructionsStatus : false,
      Assignement : false

      },
      storageStock : true,
      interval0 : null,
      tOut : null
    }
  },
  methods: {
    switchHistory(event){
      if(event.target.value === 'stock'){
        this.storageStock = true;
      }
      else {
        this.storageStock = false;
      }
    },
    userExistChecking(){
       if(!this.userExist['username']){
      alert('Utilisateur inexistant');
    }
  },
  showHistoryFn(type){

    switch (type) {
      case 'showGlobal':
      Object.keys(this.showHistory).forEach(v => this.showHistory[v] = false)
      this.showHistory.Global = true;
        break;
      case 'showReception':
        Object.keys(this.showHistory).forEach(v => this.showHistory[v] = false)
        this.showHistory.Reception = true;
        break;
      case 'showUser':
        Object.keys(this.showHistory).forEach(v => this.showHistory[v] = false)
        this.showHistory.User = true;
        break;
      case 'showAdminChanges':
        Object.keys(this.showHistory).forEach(v => this.showHistory[v] = false)
        this.showHistory.AdminChanges = true;
        break;
      case 'showBooking':
        Object.keys(this.showHistory).forEach(v => this.showHistory[v] = false)
        this.showHistory.Booking = true;
        break;
      case 'showReels':
        Object.keys(this.showHistory).forEach(v => this.showHistory[v] = false)
        this.showHistory.Reels = true;
        break;
      case 'showTools':
        Object.keys(this.showHistory).forEach(v => this.showHistory[v] = false)
        this.showHistory.Tools = true;
        break;
      case 'showConstructions':
        Object.keys(this.showHistory).forEach(v => this.showHistory[v] = false)
        this.showHistory.Constructions = true;
        break;
      case 'showConstructionsStatus':
        Object.keys(this.showHistory).forEach(v => this.showHistory[v] = false)
        this.showHistory.ConstructionsStatus = true;
        break;
      case 'showAssignement':
        Object.keys(this.showHistory).forEach(v => this.showHistory[v] = false)
        this.showHistory.Assignement = true;
        break;
    
      default:
        break;
    }
  }
  },
  created : function (){
    setTimeout(function () { this.userExistChecking(); }.bind(this), 6000);
    this.rand = '2f+g2dfg25h2d+f15b1gs5bnj1ki1lo61q1dc16'+JSON.parse(localStorage.getItem('token'))+'1202'+JSON.parse(localStorage.getItem('token')).substr(12, 42);
  },
  computed: {
    ...mapState({
      userExist : "userExist",
      t_Key : "t_Key",
    })
    },
    beforeUnmount() {
    //console.log('beforeUnmount');
    clearInterval(this.interval0);
    clearTimeout(this.tOut);
  },
  mounted() {
    this.$store.commit("isConnected");
    // récuperer l'utilisateur actuel
    const user = JSON.parse(localStorage.getItem('connectedUser'));
    this.userName = user.name;
    this.connectedUser = user;

    //----------NO useMode ---> EXIT-------------
    if(user.username.includes('USER&MODE')){
      alert('Vous n\'avez pas quitté correctement le mode -USE MODE-; Reconnexion souhaitée');
      localStorage.clear();
      sessionStorage.clear();
      this.$router.push('/')
    }

    // requete permettant de récuperer les users pour Users.vue
    this.$store.dispatch("getAllUsers", this.connectedUser.id_user);

    // rafraichir la page régulièrement (toute les heures)
    setTimeout(function() { location.reload(); }, 3600000);

   // vérifier que l'utilisateur n'a pas été supprimé de la base de donnée
    const ids = user.id_user; + "_" + user.id_user;
    this.$store.dispatch("getOneUser", ids);
    this.$store.dispatch("getAllUsersIds",user.id_user);

    //----------timer access---------
        const special_id = 'tkrqst_'+ user.id_user ;
        //console.log('getTimerAccess');
        this.$store.dispatch('getTimerAccess', special_id );
        this.tOut = setTimeout(() => {
        if(timerAcces.verifyAccess(this.t_Key) === false){
          //console.log("false acces")
          //Quitter la connexion
          this.$router.push('/')
        };
        // revérifer toutes les 5 minutes
        this.interval0 = setInterval(() => {
        if(timerAcces.verifyAccess(this.t_Key) === false){
          //console.log("false acces")
          //Quitter la connexion
          this.$router.push('/')
        };
        }, 300000);
        }, 10000);
    //-------end timer access -----------
  },
}
</script>
<style scoped>
.page_container {
  display: flex;
  margin-top: 0px;
  flex-direction: column;
  align-items: center;
}

.header{
display: grid;
width:80%;
margin: 20px 0 20px 0;
font-weight: 600;
grid-template-columns: 1fr 1fr 1fr;
grid-template-rows: 1fr;
}
img {
  max-width: 80px;
}
.history-btn {
  background-color: #0d444b;
  color:rgb(238, 130, 26);
  width: auto;
  overflow: hidden;
  margin: 0;
  font-size: 20px;
  margin: 15px;

  padding: 10px;
}
h2 {
  margin-top: 0;
}
.button {
width: 200px;
height: 50px;
font-size: 20px;
justify-self: right;
align-self: center;
background-color: #0d444b;
  color : rgb(238, 130, 26);
}
button > p {
  font-size : 16px;
}

#logo__title {
  font-size: 27px;
}
.security-info{
  font-size : 22px;
  margin : 30px;
}
select {
  font-size: 22px;
  padding: 0px 5px;
  margin-left: 15px;
}
@media screen and (max-width: 1100px) {

  #logo__title {
    font-size: 19px;
  }
  img {
  max-width: 40px;
}
.header {
  width: 95%;
}
h1 {
  font-size: 19px;
}
.button {
width: 95px;
height: 30px;
font-size: 14px;
}

}
</style>