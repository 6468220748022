<template>
  <div class="history-container">
     <!--{{connectedUser}} !-->
        <!-- {{globalHistory}} !-->
        <button class="showOptions" v-if="!controlPanel" @click="controlPanel = true">Afficher les options &#x25BC;</button>

        <div class="control-panel-div" v-show="controlPanel">
           <button class="hideOptions"  @click="controlPanel = false">Masquer les options &#x25B2; </button>
         <div @click="exportToExcel()" class="excel-div">
        <button>Export Excel</button>
        <img id="icon1" src="../../../assets/excel.jpg" alt=""/>
        <img id="icon2" src="../../../assets/libreoff.jpg" alt=""/>
        
        </div>

        <div class="search-container interval-container">
          <p>Choisir un période jj/mm/aaaa (par défaut 31 derniers jours)</p>
          <div>
          <label for="date">Entre : </label>
        <input type="search" name="date" ref="date_filter" v-model="choosedStartDate">
        </div>
          <div>
        <label for="date">et : </label>
        <input type="search" name="date" ref="date_filter" v-model="choosedEndDate">
        </div>
        <button @click="newDateInterval()">Lancer la requête</button>
        <button @click="defaultInterval()">Effacer</button>
        </div>

        <div class="search-container">
          <div>
        <label for="date">Filtrer une date : </label>
        <input type="search" name="date" id="" v-model="choosedDate">
        </div>
        <div>
        <label for="date"> Filtrer une heure : </label>
        <input type="search" name="date" id="" v-model="choosedHour">
        </div>

        <div>
        <label for="date">Filtrer un numéro de commande : </label>
        <input type="search" name="order" ref="order_filter" v-model="choosedOrder">
        </div>
        </div>
        
        <div class="filters-container">
        <div class="user-filter-container">
          <select name="" id="autoSize" :size="size" @focus="onFocusSelect" @blur="onBlurSelect"
          @change="userFilter($event)">
            <option selected value="inactive">
              -Filtre utilisateur desactivé-
            </option>
            <option :value="user.username" v-for="user in allUsers" :key="user">
              {{user.username}}
            </option>
          </select>
        </div>
        

        <div class="location-filter-container">
          <select name="" id="" @change="locationFilter($event)">
            <option selected value="inactive">
              -Filtre location desactivé-
            </option>
            <option :value="loc" v-for="loc in locationList" :key="loc">
              {{loc}}
            </option>
          </select>
        </div>

        <div class="type-filter-container">
          <select name="" id="" @change="typeFilter($event)">
            <option selected value="inactive">
              -Filtre type desactivé-
            </option>
            <option :value="type" v-for="type in typeSetHistory" :key="type">
              {{translateFormat(type)}}
            </option>
          </select>
        </div>
        
        <div class="return-filter-container" >
          <select name="" id="" @change="returnFilter($event)">
            <option selected value="0">
              -Filtre retour/retrait desactivé-
            </option>
            <option value="1">
              Voir les retours
            </option>
            <option value="2">
              Voir les retraits
            </option>
          </select>
        </div>
        </div>
        </div>
        
              <br>
          
        <h3>TOTAL : {{totalPrice}} €</h3>
<div class="table-container">
    <table v-if="Object.values(multiFilters).length != 0">
        <thead class="global-history-table" >
            <tr>
          <td>Ligne</td>
          <!--td>color</td!-->
          <td>Utilisateur</td>
          <td>Ref</td>
          <td>Id objet</td>
          <td>Type</td>
          <td>Quantité</td>
          <td>Prix</td>
          <td>Dépôt</td>
          <td><button v-if="!showHour" @click="showHour = true">Date</button>
          <button v-else @click="showHour = false">Date + </button></td>
          <td>N°commande</td>
          </tr>
      </thead>

      <tbody ref="user_table" id="usertable" class="global-history-table" >
        <!-- DATE/HOUR FILTER !-->
        <tr v-for="row in multiFilters" :key="row"
            :class="row.color"
            >
          <td>{{row.id_user_order}}</td>
          <!--td>{{row.color}}</td!-->
          <!--<td v-if="row.username != null && row.username.includes(' creatOnRet')">{{cutString(row.username)}} &#9194; &#128035;</td>
          on ne veut pas d'icone-->
           <td >{{cutString(row.username)}}</td>
          <td>{{row.ref}}</td>
          <td>{{row.item_id}}</td>
          <td>{{translateFormat(row.type_)}}</td>
          <td>
            <span v-if="row.qtty > 0">+</span>{{row.qtty}}</td>
          <td class='price' >{{row.price}}</td>
          <td>{{row.storage_location}}</td>
          <td v-if="!showHour" class="date" @click="copyFn(dateFormat(row.date),'date')">{{dateFormat(row.date)}} </td>
          <td v-else >{{dateFormatH(row.date)}} </td>

          <td class="order-number" @click="copyFn(row.order_number,'order')">{{row.order_number}}</td>

          </tr>

     TOTAL : {{totalPrice}}
      </tbody>
     
</table>

<div v-else class="loader" >Recherche de données... &#8987;</div>
  </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
const XLSX = require("xlsx");
const { saveAs } = require('file-saver');

export default {
    name : "HistoryUser",
    props : ["connectedUser"],
    data() {
        return {
            showHour : false,
            choosedDate : '',
            choosedHour : '',
            activeUserFilter : false,
            choosedUser : '',
            locationList: [],
            activeLocationFilter : false,
            choosedLocation : '',
            totalPrice :0,
            activeReturnFilter : 0,
            choosedType : '',
            activeTypeFilter : false,
            choosedOrder :'',
            choosedStartDate : '',
            choosedEndDate : '',
            controlPanel : false,
            size : 1
            
        }
    },
      mounted() {
    this.$store.state.intervalDate = [];
    this.$store.commit("isConnected");
    //récupérer la liste des dépôts
    this.$store.dispatch("getAllLocations", this.connectedUser.id_user);

    this.getUserHistory();
    this.$store.dispatch("getAllUsers", this.connectedUser.id_user);
  },
      computed: {
    //STORE
    ...mapState({
      userHistory: "userHistory",
      allUsers: "allUsers",
      intervalDate : "intervalDate",
      usersIds : "usersIds",
      allLocations : "allLocations"
    }),
    typeSetHistory(){
      var theSet = new Set;

      let miror;
      if(Object.values(this.intervalDate).length != 0){
        miror = this.intervalDate;
      }
      else{
        miror = this.userHistory;
      };
      miror.forEach(element => {
        theSet.add(element.type_);
      });
      return theSet;
      },

      multiFilters(){

      var obj = [];
      let miror;
      if(Object.values(this.intervalDate).length != 0){
        miror = this.intervalDate;
      }
      else{
        miror = this.userHistory;
      };
      // transforme systèmatiquement le numero de commande pour être sur qu'il soit unique
      //sinon c'est simplement un timestamp seconde
      if(this.usersIds.length != 0){
      miror.forEach(element => {
        element.order_number = this.extendOrderNumber(element.username, element.order_number);
      });}


      var totalPrice = 0;
      if(this.choosedDate != ''){
        miror.forEach(element => {
          if(this.dateFormat(element.date) === this.choosedDate){
            obj.push(element);
          }
        });
        miror = obj;
        obj = [];
      }
      if(this.choosedHour != ''){
        miror.forEach(element => {
          if(this.dateFormatH(element.date).split(',')[1].includes(' '+this.choosedHour)){
            obj.push(element);
          }
        });
        miror = obj;
        obj = [];
      }
      if(this.choosedOrder !=''){
        let nb;
        miror.forEach(element => {
          if(element.order_number != null){
            nb = element.order_number.toString();
          if(nb.includes(this.choosedOrder)){
            obj.push(element);
          }}
        });
        miror = obj;
        obj = [];
      }
      if(this.activeUserFilter){
        miror.forEach(element => {
          if(element.username === this.choosedUser){
            obj.push(element);
          }
        });
        miror = obj;
        obj = [];
      }
      if(this.activeLocationFilter){
        miror.forEach(element => {
          if(element.storage_location === this.choosedLocation){
            obj.push(element);
          }
        });
        miror = obj;
        obj = [];
      }
      if(this.activeTypeFilter){
        miror.forEach(element => {
          if(element.type_ === this.choosedType){
            obj.push(element);
          }
        });
        miror = obj;
        obj = [];
      }
      if(this.activeReturnFilter != 0){
        //voir retour uniquement
      if(this.activeReturnFilter == 1){
          miror.forEach(element => {
          if(element.qtty > 0){
            obj.push(element);
          }
        });
        miror = obj;
        obj = [];
        }
        //voir retrait uniquement
      else if(this.activeReturnFilter == 2){
          miror.forEach(element => {
          if(element.qtty <0){
            obj.push(element);
          }
        });
        miror = obj;
        obj = [];
        }
        
      }

      //totaux prix
      miror.forEach(element => {
      totalPrice = totalPrice + (element.price*element.qtty);
        });
      this.totalPrice = totalPrice.toFixed(2);
      miror = this.colorHistoryFilter(miror);
      return miror;
    },
     translateForExcel(){
      var jsonObj = [];
      var miror = JSON.parse(JSON.stringify(this.multiFilters));
        //var start = new Date();
        miror.forEach(element => {
          delete Object.assign(element, {['Ligne']: element['id_user_order'] })['id_user_order'];
          delete Object.assign(element, {['Utilisateur']: element['username'] })['username'];
          delete Object.assign(element, {['Désignation']: element['item'] })['item'];
          delete Object.assign(element, {['Réf']: element['ref'] })['ref'];
          delete Object.assign(element, {['Id_objet']: element['item_id'] })['item_id'];
          delete Object.assign(element, {['Type']: element['type_'] })['type_'];
          delete Object.assign(element, {['Qtté']: element['qtty'] })['qtty'];
          delete Object.assign(element, {['Prix']: element['price'] })['price'];
          delete Object.assign(element, {['Dépôt']: element['storage_location'] })['storage_location'];
          element.date = this.dateFormat(element.date);
          delete Object.assign(element, {['Date']: element['date'] })['date'];
          delete Object.assign(element, {['N°commande']: element['order_number'] })['order_number'];
          delete element.color;
          jsonObj.push(element);
        });
        // 7 milliseconds
        //var time = new Date() - start;
        //console.log(time);
        return jsonObj;
    }
  },

  watch: {
    userHistory(newCount, oldCount) {
      if (newCount){
        this.userH = newCount;
      }
    },
    allLocations(newCount, oldCount) {
      if(newCount) {
        //alert(newCount);
        this.locationList = newCount;
      }
    },
  },



  methods : {
    onFocusSelect(){
      this.size = 4;
    },
    onBlurSelect(){
      this.size = 1;
    },
      getUserHistory(){
        if (Object.keys(this.connectedUser).length != 0) {
        this.$store.dispatch("getUserHistory", this.connectedUser.id_user);
      } else {
        alert("Accès bloqué, l'utilisateur n'est pas reconnu !");
      }
      },
      dateFormat(data){
       // console.log( data);
       // console.log(typeof data);
        const dateObject = new Date(data);
        //console.log( dateObject);
        //console.log(typeof dateObject);
        let humanDateFormat;
        if(dateObject.toLocaleString().includes(',')){
        humanDateFormat = dateObject.toLocaleString().split(',')[0];}
        else{
        humanDateFormat = dateObject.toLocaleString().split(' ')[0];  
        }
        //console.log( humanDateFormat.split(',')[0]);
        //console.log(typeof humanDateFormat);
        return humanDateFormat;
      },
      dateFormatH(data){
        const dateObject = new Date(data);
        const humanDateFormat = dateObject.toLocaleString();
        return humanDateFormat;
      },
      translateFormat(data){
        switch (data) {
          case 'consumable':
            return 'conso'
            break;
          case 'tool':
            return 'outil'
            break;
          default:
            return data
            break;
        }
      },
      // fonction qui permet d'ajouter une valeur color à chaque ligne,
      //afin d'appliquer le même style aux lignes de la meme commande
      colorHistoryFilter(data){
          var box = [];
          var color = 'white';
          for(let i in data){
              //console.log(box.slice(-1).toString());
              //console.log(data[i].order_number);
              if( (i != 0) && (data[i].order_number == box.slice(-1).toString())){
                  data[i].color = data[i-1].color;
                  //console.log('1ere cond + '+i);
              }
              else{
                  //console.log('2eme cond + '+i);
                  data[i].color = color;
                  if(color == 'white'){
                      //console.log("= white")
                      color = 'gainsboro';
                  }
                  else {
                      color = 'white'
                  }
              }
              box.push(data[i].order_number);
          }
           //console.log(data);
          return data
         
      },
      userFilter(event){
        //console.log(event.target.value);
        //console.log(typeof event.target.value);

        // si filtre desactivé, revenir à l'affichage classique
        if(event.target.value === 'inactive')
        {
          this.activeUserFilter = false;
          return
        }

        this.activeUserFilter = true;
        this.choosedUser = event.target.value;
        this.size = 1;
      event.target.blur();
      },
      locationFilter(event){
        //console.log(event.target.value);
        //console.log(typeof event.target.value);

        // si filtre desactivé, revenir à l'affichage classique
        if(event.target.value === 'inactive')
        {
          this.activeLocationFilter = false;
          return
        }

        this.activeLocationFilter = true;
        this.choosedLocation = event.target.value;
      },
      returnFilter(event){
        this.activeReturnFilter = event.target.value;
      },
      typeFilter(event){
        // si filtre desactivé, revenir à l'affichage classique
        if(event.target.value === 'inactive')
        {
          this.activeTypeFilter = false;
          return
        }

        this.activeTypeFilter = true;
        this.choosedType = event.target.value;
      },
      copyFn(number,type) {
        if(type === 'order'){
  var copyText = number.toString();
  const searchInput = this.$refs['order_filter'];
  searchInput.value = copyText;
  searchInput.focus();
  this.choosedOrder = copyText;}
  else {
  var copyText = number.toString();
  const searchInput = this.$refs['date_filter'];
  searchInput.value = copyText;
  searchInput.focus();
  this.choosedDate = copyText;
  }
      },
      newDateInterval(){
        if(this.choosedStartDate != '' && this.choosedEndDate != ''){

         var j = this.choosedStartDate.split('/')[0];
         var m = this.choosedStartDate.split('/')[1];
         var a = this.choosedStartDate.split('/')[2];
         const choosedStartDate = a+'-'+m+'-'+j;
         j = this.choosedEndDate.split('/')[0];
         m = this.choosedEndDate.split('/')[1];
         a = this.choosedEndDate.split('/')[2];
         const choosedEndDate = a+'-'+m+'-'+j;

         //console.log(j);console.log(m);console.log(a);
         
         const startDate = new Date(choosedStartDate+'Z');
         var endDate0 = new Date(choosedEndDate+'Z');
         endDate0.setUTCHours(22);
         endDate0.setUTCMinutes(59);
         endDate0.setUTCSeconds(59);
         //endDate0 = endDate0.setUTCMinutes(59);
         //console.log(endDate0);
         const endDate = endDate0;
         if(isNaN(Date.parse(startDate))  || isNaN(Date.parse(endDate))){
           alert("erreur de format de date, veuillez respecter ce format : jj/mm/aaaa");
         }
         
         if (Object.keys(this.connectedUser).length != 0) {
           
           const historyData = {
           date : [startDate,endDate],
           compOrigin :'history_user',
           username : this.connectedUser.username,
           user_id : this.connectedUser.id_user
         }

        this.$store.dispatch("putIntervalDate", historyData);
         } else {
        alert("Accès bloqué, l'utilisateur n'est pas reconnu !");}

        }else{
          alert("Veuillez remplir tous les champs d'interval de date !");
        }
      },
      defaultInterval(){
        this.choosedStartDate = '';
        this.choosedEndDate = '';
        this.$store.state.intervalDate = [];
      },
      cutString(username){
        if(username != null && username.includes(' creatOnRet')){
        return username.split(' creatOnRet')[0];}
        return username;
      },
      exportToExcel(){
        var miror = JSON.parse(JSON.stringify(this.translateForExcel));
        //console.log(miror);
        this.downloadAsExcel(miror);
      },
      downloadAsExcel(data){
        let worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = {
          Sheets : {
            'data':worksheet
          },
          SheetNames: ['data']
        };
        const excelBuffer = XLSX.write(workbook, {booktype : 'xlsx', type : 'array'});
        //console.log(excelBuffer);
        this.saveAsExcel(excelBuffer, 'myFile');
      },
      saveAsExcel(buffer,filename){
        const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([buffer],{type:EXCEL_TYPE});
        saveAs(data,filename+'_export_'+new Date().getTime()+EXCEL_EXTENSION);
      },
      extendOrderNumber(username,number){
        if(number === null || number === 1000001){
          return number;
        }
        //si la modification a dejà eu lieu ne pas transformer le numero
        //console.log(number.toString().length);
        if(number.toString().length >10){
          return number;
        }
        var id = 0;
        //console.log(this.usersIds);
        //console.log(this.usersIds.length);
        this.usersIds.forEach(element => {
          //console.log(element.username);
          if(username == element.username || username.includes(element.username)){
            id = element.id;
          }

        });
        const o_number = id.toString() + number.toString();
          return parseInt(o_number);
      }
  }

}
</script>

<style scoped>
table{
  margin : auto;
  overflow: scroll;
}
.table-container{/**/
  margin-top: 80px;
  margin-bottom: 60px;
  max-width: 1400px;
  overflow-x: scroll;
}
thead {
  font-size: 20px;
  font-weight: bold;
}
thead td{
  border: 1px solid black;
  text-overflow: ellipsis;
}
.gainsboro{
  background-color: gainsboro;
}
.white{
  background-color: white;
}
.gainsboro:hover,.white:hover{
  background-color: rgb(116, 223, 223);
}
.global-history-table{
margin: 10px;
}
.global-history-table td{
margin: 10px;
}
.search-container {
  display: flex;
  margin: 30px;
  justify-content: space-evenly;
}
.search-container div input{
  width: 100px;
  margin-left : 10px;
}
.search-container div label{
  font-size: 22px;
}
.interval-container{
  display: flex;
  font-size: 19px;
  align-content: center;
  padding-left: 10px;
  border: 1px black solid;
}
.interval-container p{
margin-right: 20px;
line-height: 30px;
}
.interval-container div{
margin: 20px;
}
.interval-container button{
margin: 20px;
font-size: 19px;
}
.order-number {
  cursor: pointer;
}
.date{
  cursor: pointer;
}
.date:hover{
  background-color: greenyellow;
}
.order-number:hover{
  background-color: yellow;
}
.excel-div img {
width : 50px;
height: 50px;
margin-left: 20px ;
}
.excel-div {
    display: flex;
    justify-content: center;
}
.excel-div button{
    font-size : 20px;
    color: white;
    background-color: green;
    opacity: 1;
}
.excel-div button:active {
	transform: scale(1.1);
	transition: .1s;
}

.excel-div button:hover {
  opacity : 0.7;
}
.loader {
  margin-top: 80px;
  font-size: 50px;
}
.filters-container {
  display: flex;
  height: 100px;
  margin-bottom : 30px;
  justify-content: space-around;
  align-items: center;
  border: 1px black solid;
}
div select{
  text-align: center;
  width: 290px;
  height: 45px;
  font-size: 18px;
  background-color: rgb(239, 239, 239);
}
.hideOptions {
  margin-bottom: 20px;
  font-size: 18px;
}
.showOptions{
  font-size: 18px;
}
div #autoSize {
height : auto;
}
@media screen and (max-width: 1100px) {
table{
  overflow-x: scroll;
}
.table-container{
  margin-top: 50px;
  max-width: 280px;
  overflow-x: scroll;
}
.history-container{
  max-width: 280px;
  overflow-x: hidden;
}
.control-panel-div{
  max-width: 280px;
  overflow-x: scroll;
}
.search-container {
  flex-direction: column;
  margin: 20px;
}
.search-container div label{
  font-size: 18px;
}
.interval-container{
  flex-direction: column;
}
.loader {
  margin-top: 50px;
  font-size: 20px;
}
.filters-container {
  flex-direction: column;
  height: 140px;
  margin-bottom : 10px;
}
div select{
  width: 240px;
  height: 35px;
}

}
</style>