<template>
  <div class="seestock-container">

    <h2>Gestion des stocks</h2>
    
    <div class="btn-container">
    <button id="show" class="large-button" @click="getStocks()">
      Rafraichir les stocks
      <i class="fas fa-sync-alt"></i>
    </button>

    <button
      id="edition"
      class="large-button"
      v-if="editMode === false"
      :disabled="isGetStocks === false"
      @click="editMode = !editMode"
    >
      Editer
    </button>

    <button
      id="edition"
      class="large-button"
      v-else
      @click="editMode = !editMode"
      :disabled="
        editConsumables != '0' ||
          editTools != '0' ||
          editCables != '0' ||
          editClients != '0'
      "
    >
      Sortir de l'édition
    </button>
<div class="lowStock-container">
    <button :disabled="!dataFetched" @click="exportToExcel()" class="excel-btn">
      Export Excel</button>


      <button @click="this.showLowStock = !this.showLowStock" class="large-button sh-button"
      :style="this.showLowStock?{'background-color':'rgb(255, 204, 204)'}:{}">
      Stock faible</button> <input type="number" class="number2" v-model="this.lowStockMultiplier">
</div>
</div>
<div v-if="editMode" class="transfer-container">
<button @click="transferLocation = !transferLocation" > Transfert de dépot</button>
    <div class="flex-div" v-if="transferLocation && editMode">
      <div>
      <span>Déplacer tout de </span>
      <select v-model="item_storageTransfer" name="type">
        <option default value="">--Choisir une option--</option>
        <option v-for="loc in locationList" :key="loc" :value="loc">{{loc}}</option>
      </select>
       <span> - vers - </span>
    
      <select  v-if="switchSelect" v-model="item_storageTransfer2" name="type" >
        <option default value="">--Choisir une option--</option>
        <option v-for="loc in locationList" :key="loc" :value="loc">{{loc}}</option>
      </select>
      <input v-else type="text" v-model="item_storageTransfer2" >
    </div>
     <span class="flex-btn-container">
      <button class="btn-transfer" @click="switchSelect = !switchSelect"> 
        <span  v-if="switchSelect"> 	&#9997;</span>
        <span  v-else>&#128196;</span>
      </button>

      <button class="btn-transfer"  @click="confirmT = !confirmT">Confirmer</button>
     
      </span></div>
       <div class="confirm-div" v-if="confirmT">
      <span > 
        Pour éviter une erreur innattendue dans le stock, 
        veuillez effectuer cette action à une heure où 
        aucune requête de retrait, sur les dépôts concernés, n'est succeptible d'être engagée.
 </span>
 <br>
<span style="color : red; font-weight : bold">
        Si il y a des réservations utilisateurs ou des réceptions fournisseur en cours sur le dépot à transférer, 
        toutes les commandes associés seront annulés. Êtes vous sur ? </span>
      <button v-if="!loadTransfer" @click="transferLoc(item_storageTransfer,item_storageTransfer2,'transfer')">Déclencher</button>
      <button v-else >Processus en cours...</button>
      <button @click="confirmT = false">Retour</button>
      </div>
</div>

    <p v-if="editMode">
      Avertissement : La quantité et la quantité disponible sont liées. Veuillez
      toujours a modifier <strong>l'un avec l'autre</strong> avec le même
      rapport sous peine de fausser les réservations, ou de bloquer les
      commandes.
    </p>

    
   <!-- CONSOMABLES !-->
    <div class="seestock-sub-container">
      <div class="items-header">
        <h3>Consommables</h3>
        <button
        class="showHideBtn"
          v-if="Object.values(itemConsumables).length != 0"
          @click="showConsumables = !showConsumables"
        >
          Afficher / Masquer
        </button>
        <span v-else> [...]</span>
      </div>
      <div
        class="table-container"
        v-if="
          Object.values(itemConsumables).length != 0 && showConsumables === true
        "
      >
        <table>
          <thead>
            <tr :class="{ tr2: cDetails }">
              <th v-if="!editMode">Add</th>
              <th>Réf interne
                <span>
              <button v-if="!orderByRef" @click="orderByRefFn()">&#11167;</button>
              
              </span>
              </th>
              <th>Désignation
                <span><button v-if="orderByRef" @click="orderByRefFn()">&#11167;</button></span>
              </th>
              <th>Image</th>
              <th>Tarif</th>
              
              <th>Id</th>
              <th>Nom fournisseur</th>
              <th>Dépôt</th>
              <th>Quantité</th>
              <th>
                Quantité (dispo)
                <button
                  v-if="cDetails === false"
                  class="menu extend-reduce"
                  @click="cDetails = !cDetails"
                >
                  +
                </button>
                <button
                  v-else
                  class="menu extend-reduce"
                  :style="[
                    cDetails && !editMode ? { top: '-28px' } : {},
                    cDetails && editMode ? { top: '40px' } : {},
                  ]"
                  @click="cDetails = !cDetails"
                >
                  -
                </button>
              </th>
              <th v-if="cDetails">Sous-catégorie</th>
              <th v-if="cDetails">Stock min</th>
              <th v-if="cDetails">Sortie max</th>
              <th v-if="cDetails">Réf fournisseur</th>
              <th v-if="cDetails">Fourn. défaut</th>
              <th v-if="cDetails">Condition. Entrée</th>
              <th v-if="cDetails">Condition. Sortie</th>
              <th v-if="cDetails">Modifié par</th>
            </tr>
          </thead>
          <tbody
            v-for="(items, index) in itemConsumables"
            :key="items"
            :index="index"
          >
            <div :style="[cDetails ? { 'max-width': '80px' } : {}]">
              <button
                class="button edit"
                v-if="editMode && editConsumables != items"
                :disabled="
                  (editConsumables != '0' && editConsumables != items) ||
                    editTools != '0' ||
                    editCables != '0' ||
                    items['on_order']
                "
                @click="editItemFn(items, 'consumable')"
              >
                Editer
              </button>
              <button
                v-if="editConsumables === items"
                @click="deleteItem(index, 'consumable')"
                style="position : relative"
                :class="{'delete-btn-warning' : (items['qtty_in']!=items['qtty_available'])}"
              >
                <i class="fas fa-trash-alt"></i>
                <span class="warning-icon" v-if="items['qtty_in']!=items['qtty_available']" >&#9888;&#65039;</span>
                <span class="warning-message" >Suppression déconseillé, 
                  des reservations sont en cours sur ce produit</span>
              </button>

              <button
                class="button"
                v-if="editConsumables === items"
                @click="editConsumables = '0'"
              >
                Annuler
              </button>
              <button
                :disabled="newUrl"
                class="button submit"
                v-if="editConsumables === items"
                @click="modifyItem(index, 'consumable')"
              >
                Valider
              </button>
              <i
                v-if="putLoader === true && editConsumables === items"
                class="fas fa-hourglass-half"
              ></i>
            </div>
            <tr
              :class="{ tr2: cDetails }"
              :style="[
                items['on_order'] ? { 'background-color': '#E3E1E0' } : {},
                !items['on_order'] && showLowStock && items['qtty_in'] < (items['qtty_min']*this.lowStockMultiplier) ? {'background-color': 'rgb(255, 204, 204)' }:{}
              ]"
            >
              <td v-if="!editMode">
                <button @click="addItemToOrder(items, 'consumable')">+ <i class="fas fa-shopping-basket"></i></button>
              </td>
              <td>
                {{ items["ref"] }}
              </td>
              <td >
                {{ items["item"] }}
                <span v-if="items['qtty_in']!=items['qtty_available']" class="booked-item">&#128230;
                  <span>reservation(s) en cours</span>
                </span>
              </td>
              <td id="tdImg">
                <div id="changeImg-container" v-if="editConsumables === items">
                  <input
                    v-if="!items['image']"
                    type="file"
                    accept="image/*"
                    @change="addImg"
                  />
                  <input
                    v-else
                    value="retirer"
                    id="removeImg"
                    type="button"
                    @click="removeImg(index, 'consumable','editorAction')"
                  />
                  <i
                v-if="loaderImg === true"
                class="fas fa-hourglass-half"
              ></i>
                </div>

                <span v-if="!(editConsumables === items) && items['image']" 
                  >OUI 
                  <button class="icon-img1" @click="copyImg(items['image'])"> &#128248;</button>
                  <div class="see-copied-img">
                    <span>
                      Actuellement en memoire
                    </span>
                    <img :src="items['image']||copiedImg" alt="">
                  </div>
                  <button class="icon-img" v-show="copiedImg !=''" @click="stuckImg(index,items,'yes')">&#128229; </button> 
                  <span class="stuck-message">
                      Coller l'image
                    </span>
                  </span>
                <span v-else-if="!(editConsumables === items)">NON
                  <button class="icon-img" v-show="copiedImg !=''" @click="stuckImg(index,items,'non')">&#128229; </button> 
                <span class="stuck-message">
                      Coller l'image
                    </span>
                </span>
              </td>
              <td>{{ items["price"] }} €</td>
              <td>
                {{ items["id"] }}
              </td>
              <td>
                {{ items["supplier"] }}
              </td>
              <td>
                {{ items["item_storage"] }}
              </td>
              <td>
                {{ items["qtty_in"] }}
              </td>
              <td>
                {{ items["qtty_available"] }}
              </td>
              <td v-if="cDetails">
                {{ items["sub_type"] }}
              </td>
              <td v-if="cDetails">
                
                {{ items["qtty_min"] }}
              </td>
              <td v-if="cDetails">
                {{ items["qtty_max"] }}
              </td>
              <td v-if="cDetails">
                {{ items["ref_supplier"] }}
              </td>
              <td v-if="cDetails">
                {{ items["def_supplier"] }}
              </td>
              <td v-if="cDetails">
                {{ items["packaging"] }}
              </td>
              <td v-if="cDetails">
                {{ items["out_packaging"] }}
              </td>

              <td v-if="cDetails">
                {{ cutString(items["data_feeder"]) }}
              </td>
            </tr>
            <tr
              v-if="editConsumables === items"
              id="isEditing"
              :class="{ tr2: cDetails }"
            >
            <td>
                <input v-model="ref" placeholder="" />
              </td>
              <td>
                <input v-model="item" placeholder="" />
              </td>
              <td id="tdImg">
                <img
                  v-if="items['image'] || newUrl"
                  :src="items['image'] || newUrl"
                  alt=""
                />
                <button
                  id="save-img"
                  v-if="newUrl"
                  @click="confirmImage(index, 'consumable')"
                >
                  Enregistrer l'image
                </button>
              </td>
              <td>
                
                <input
                :disabled ="lockPriceChanges"
                  v-model="price"
                  class="number"
                  type="number"
                  min="0"
                  placeholder="0"
                  step="0.01"
                  @keydown="checkChars"
                />
                €
                <button v-if="lockPriceChanges" @mouseover="warningPrice = true" @mouseleave="warningPrice = false" @click="lockPriceChanges = false">	
                &#128274;</button>
                <button v-else @mouseover="warningPrice = true" @mouseleave="warningPrice = false" @click="lockPriceChanges = true">	
                &#128273;</button>
              </td>
              
              <td>
                <input disabled v-model="items['id']" placeholder="" />
              </td>
              <td>
                <input v-model="supplier" placeholder="" />
              </td>
              <td>
              
                 <select v-model="item_storage" name="type" required>
                  <option v-for="loc in locationList" :key="loc" :value="loc">{{loc}}</option>
                </select>
              </td>
              <td>
                <input
                  v-model="qtty_in"
                  class="qtty-number number"
                  type="number"
                  min="0"
                  placeholder="0"
                  @keydown="checkChars"
                  @change="checkChange($event,index,'consumable')"
                />
              </td>
              <td>
                <input
                :disabled ="lockQttyAvailable"
                  v-model="qtty_available"
                  class="qtty-number number"
                  type="number"
                  min="0"
                  placeholder="0"
                  @keydown="checkChars"
                />
                <button v-if="lockQttyAvailable" @mouseover="warningAvailable = true" @mouseleave="warningAvailable = false" @click="lockQttyAvailable = false">	
                &#128274;</button>
                <button v-else @mouseover="warningAvailable = true" @mouseleave="warningAvailable = false" @click="lockQttyAvailable = true">	
                &#128273;</button>
                <span class="block warning-message" v-show="warningAvailable"> &#9888;&#65039; Modifier les quantités disponibles n'annulera pas les reservations 
                  concernés et risque de créer des erreurs dans l'application. </span>
              </td>
              <td v-if="cDetails">
                 <input v-model="sub_type" placeholder="" />
              </td>
              <td v-if="cDetails">
                <input
                  v-model="qtty_min"
                  class="little-number number"
                  type="number"
                  min="0"
                  placeholder="0"
                  @keydown="checkChars"
                />
              </td>
              <td v-if="cDetails">
                <input
                  v-model="qtty_max"
                  class="little-number number"
                  type="number"
                  min="0"
                  placeholder="0"
                  @keydown="checkChars"
                />
              </td>
              <td v-if="cDetails">
                <input v-model="ref_supplier" placeholder="" />
              </td>
              <td v-if="cDetails">
                <input v-model="def_supplier" placeholder="" />
              </td>
              <td v-if="cDetails">
                <input
                  v-model="packaging"
                  class="little-number number"
                  type="number"
                  min="0"
                  placeholder="0"
                  @keydown="checkChars"
                />
              </td>
              <td v-if="cDetails">
                <input
                  v-model="out_packaging"
                  class="little-number number"
                  type="number"
                  min="0"
                  placeholder="0"
                  @keydown="checkChars"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- OUTILS !-->
    <div class="seestock-sub-container">
      <div class="items-header">
        <h3>Outils</h3>
        <button
        class="showHideBtn"
          v-if="Object.values(itemTools).length != 0"
          @click="showTools = !showTools"
        >
          Afficher / Masquer
        </button>
        <span v-else> [...]</span>
      </div>
      <div
        class="table-container"
        v-if="Object.values(itemTools).length != 0 && showTools === true"
      >
        <table>
          <thead>
            <tr :class="{ tr2: tDetails }">
              <th v-if="!editMode">Add</th>
              <th> Réf interne
                <span>
              <button v-if="!orderByRef" @click="orderByRefFn()">&#11167;</button>
     
              </span>
              </th>
              <th>Désignation
                <span><button v-if="orderByRef" @click="orderByRefFn()">&#11167;</button></span>
              </th>
              <th>Image</th>
              <th>Tarif</th>
              
              <th>Id</th>
              <th>Nom fournisseur</th>
              <th>Dépôt</th>
              <th>Quantité</th>
              <th>
                Quantité (dispo)
                <button
                  v-if="tDetails === false"
                  class="menu extend-reduce"
                  @click="tDetails = !tDetails"
                >
                  +
                </button>
                <button
                  v-else
                  class="menu extend-reduce"
                  :style="[
                    tDetails && !editMode ? { top: '-28px' } : {},
                    tDetails && editMode ? { top: '40px' } : {},
                  ]"
                  @click="tDetails = !tDetails"
                >
                  -
                </button>
              </th>
              <th>Périodicté</th>
              <th v-if="tDetails">Sous-catégorie</th>
              <th v-if="tDetails">Stock min</th>
              <th v-if="tDetails">Sortie max</th>
              <th v-if="tDetails">Réf fournisseur</th>
              <th v-if="tDetails">Fourn. défaut</th>
              <th v-if="tDetails">Condition. Entrée</th>
              <th v-if="tDetails">Condition. Sortie</th>
              <th v-if="tDetails">Modifié par</th>
            </tr>
          </thead>
          <tbody
            v-for="(items, index) in itemTools"
            :key="items"
            :index="index"
          >
            <div :style="[tDetails ? { 'max-width': '80px' } : {}]">
              <button
                class="button edit"
                v-if="editMode && editTools != items"
                :disabled="
                  (editTools != '0' && editTools != items) ||
                    editConsumables != '0' ||
                    editCables != '0' ||
                    items['on_order']
                "
                @click="editItemFn(items, 'tool')"
              >
                Editer
              </button>
              <button
                v-if="editTools === items"
                @click="deleteItem(index, 'tool')"
              style="position : relative"
                :class="{'delete-btn-warning' : (items['qtty_in']!=items['qtty_available'])}"
              >
                <i class="fas fa-trash-alt"></i>
                <span class="warning-icon" v-if="items['qtty_in']!=items['qtty_available']" >&#9888;&#65039;</span>
                <span class="warning-message" >Suppression déconseillé, 
                  des reservations sont en cours sur ce produit</span>
              </button>

              <button
                class="button"
                v-if="editTools === items"
                @click="editTools = '0'"
              >
                Annuler
              </button>
              <button
                :disabled="newUrl"
                class="button submit"
                v-if="editTools === items"
                @click="modifyItem(index, 'tool')"
              >
                Valider
              </button>
              <i
                v-if="putLoader === true && editTools === items"
                class="fas fa-hourglass-half"
              ></i>
            </div>
            <tr :class="{ tr2: tDetails }"
            :style="[
                items['on_order'] ? { 'background-color': '#E3E1E0' } : {},
                !items['on-order'] && showLowStock && items['qtty_in'] < (items['qtty_min']*this.lowStockMultiplier) ? {'background-color': 'rgb(255, 204, 204)' }:{}
              ]">
              <td v-if="!editMode">
                <button @click="addItemToOrder(items, 'tool')">+ <i class="fas fa-shopping-basket"></i></button>
              </td>
              <td>
                {{ items["ref"] }}
              </td>
              <td>
                {{ items["item"] }}
                <span v-if="items['qtty_in']!=items['qtty_available']" class="booked-item">&#128230;
                  <span>reservation(s) en cours</span>
                </span>
              </td>
              <td id="tdImg">
                <div id="changeImg-container" v-if="editTools === items">
                  <input
                    v-if="!items['image']"
                    type="file"
                    accept="image/*"
                    @change="addImg"
                  />
                  <input
                    v-else
                    value="retirer"
                    id="removeImg"
                    type="button"
                    @click="removeImg(index, 'tool','editorAction')"
                  />
                  <i
                v-if="loaderImg === true"
                class="fas fa-hourglass-half"
              ></i>
                </div>

                <span v-if="!(editTools === items) && items['image']" >OUI
                  <button class="icon-img1" @click="copyImg(items['image'])"> &#128248;</button>
                  <div class="see-copied-img">
                    <span>
                      Actuellement en memoire
                    </span>
                    <img :src="items['image']||copiedImg" alt="">
                  </div>
                  <button class="icon-img" v-show="copiedImg !=''" @click="stuckImg(index,items,'yes')">&#128229; </button> 
                <span class="stuck-message">
                      Coller l'image
                    </span>
                </span>
                <span v-else-if="!(editTools === items)">NON
                  <button class="icon-img" v-show="copiedImg !=''" @click="stuckImg(index,items,'non')">&#128229; </button> 
                <span class="stuck-message">
                      Coller l'image
                    </span>
                </span>
              </td>
              <td>{{ items["price"] }} €</td>
             
              <td>
                {{ items["id"] }}
              </td>
              <td>
                {{ items["supplier"] }}
              </td>
              <td>
                
                {{ items["item_storage"] }}
              </td>
              <td>
                {{ items["qtty_in"] }}
              </td>
              <td>
                {{ items["qtty_available"] }}
              </td>
              <td>{{ items["period"] }} J</td>
              <td v-if="tDetails">
                {{ items["sub_type"] }}
              </td>
              <td v-if="tDetails">
                {{ items["qtty_min"] }}
              </td>
              <td v-if="tDetails">
                {{ items["qtty_max"] }}
              </td>
              <td v-if="tDetails">
                {{ items["ref_supplier"] }}
              </td>
              <td v-if="tDetails">
                {{ items["def_supplier"] }}
              </td>
              <td v-if="tDetails">
                {{ items["packaging"] }}
              </td>
              <td v-if="tDetails">
                {{ items["out_packaging"] }}
              </td>
              <td v-if="tDetails">
                {{ cutString(items["data_feeder"]) }}
              </td>
            </tr>
            <tr
              v-if="editTools === items"
              id="isEditing"
              :class="{ tr2: tDetails }"
            >
            <td>
                <input v-model="ref" placeholder="" />
              </td>
              <td>
                <input v-model="item" placeholder="" />
              </td>
              <td id="tdImg">
                <img
                  v-if="items['image'] || newUrl"
                  :src="items['image'] || newUrl"
                  alt=""
                />
                <button
                  v-if="newUrl"
                  id="save-img"
                  @click="confirmImage(index, 'tool')"
                >
                  Enregistrer l'image
                </button>
              </td>
              <td>
                <input
                  v-model="price"
                  class="number"
                  type="number"
                  min="0"
                  placeholder="0"
                  step="0.01"
                  @keydown="checkChars"
                />
                €
              </td>
              
              <td>
                <input disabled v-model="items['id']" placeholder="" />
              </td>
              <td>
                <input v-model="supplier" placeholder="" />
              </td>
              <td>
               <select v-model="item_storage" name="type" required>
                  <option v-for="loc in locationList" :key="loc" :value="loc">{{loc}}</option>
                </select>
              </td>
              <td>
                <input
                  v-model="qtty_in"
                  class="qtty-number number"
                  type="number"
                  min="0"
                  placeholder="0"
                  @keydown="checkChars"
                  @change="checkChange($event,index,'tool')"
                />
              </td>
              <td>
                <input
                :disabled="lockQttyAvailable"
                  v-model="qtty_available"
                  class="qtty-number number"
                  type="number"
                  min="0"
                  placeholder="0"
                  @keydown="checkChars"
                />
                <button v-if="lockQttyAvailable" @mouseover="warningAvailable = true" @mouseleave="warningAvailable = false" @click="lockQttyAvailable = false">	
                &#128274;</button>
                <button v-else @mouseover="warningAvailable = true" @mouseleave="warningAvailable = false" @click="lockQttyAvailable = true">	
                &#128273;</button>
                <span class="block warning-message" v-show="warningAvailable"> &#9888;&#65039; Modifier les quantités disponibles n'annulera pas les reservations 
                  concernés et risque de créer des erreurs dans l'application. </span>
              </td>
              <td>
                <input
                  v-model="period"
                  class="little-number number"
                  type="number"
                  min="0"
                  placeholder="0"
                  @keydown="checkChars"
                />
                J
              </td>
              <td v-if="tDetails">
                <input v-model="sub_type" placeholder="" />
                
              </td>
              <td v-if="tDetails">
                <input
                  v-model="qtty_min"
                  class="little-number number"
                  type="number"
                  min="0"
                  placeholder="0"
                  @keydown="checkChars"
                />
              </td>
              <td v-if="tDetails">
                <input
                  v-model="qtty_max"
                  class="little-number number"
                  type="number"
                  min="0"
                  placeholder="0"
                  @keydown="checkChars"
                />
              </td>
              <td v-if="tDetails">
                <input v-model="ref_supplier" placeholder="" />
              </td>
              <td v-if="tDetails">
                <input v-model="def_supplier" placeholder="" />
              </td>
              <td v-if="tDetails">
                <input
                  v-model="packaging"
                  class="little-number number"
                  type="number"
                  min="0"
                  placeholder="0"
                  @keydown="checkChars"
                />
              </td>
              <td v-if="tDetails">
                <input
                  v-model="out_packaging"
                  class="little-number number"
                  type="number"
                  min="0"
                  placeholder="0"
                  @keydown="checkChars"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- CABLES !-->
    <div class="seestock-sub-container">
      <div class="items-header">
        <h3>Cables</h3>
        <button
        class="showHideBtn"
          v-if="Object.values(itemCables).length != 0"
          @click="showCables = !showCables"
        >
          Afficher / Masquer
        </button>
        <span v-else> [...]</span>
      </div>
      <div
        class="table-container"
        v-if="Object.values(itemCables).length != 0 && showCables === true"
      >
        <table>
          <colgroup>
            <col span="15" />
            <col class="grey" span="6" />
          </colgroup>
          <thead>
            <tr :class="{ tr2: cabDetails }">
              <th>Réf interne
                <span>
              <button v-if="!orderByRef" @click="orderByRefFn()">&#11167;</button>
              
              </span>
              </th>
              <th>Désignation
                <span><button v-if="orderByRef" @click="orderByRefFn()">&#11167;</button></span>
              </th>
              <th>Image</th>
              
              <th>Id</th>
              <th>Nom Client</th>
              <th>Dépôt</th>
              <th>Longueur restante</th>
              <th>Lgr rest. (dispo)</th>
              <th>
                Etat
                <!--(périodicité)!-->
                <button
                  v-if="cabDetails === false"
                  class="menu extend-reduce"
                  @click="cabDetails = !cabDetails"
                >
                  +
                </button>
                <button
                  v-else
                  class="menu extend-reduce"
                  :style="[
                    cabDetails && !editMode ? { top: '-28px' } : {},
                    cabDetails && editMode ? { top: '60px' } : {},
                  ]"
                  @click="cabDetails = !cabDetails"
                >
                  -
                </button>
              </th>
              <th v-if="cabDetails">Sous-catégorie</th>
              <th v-if="cabDetails">Réf Client</th>
              
              <th v-if="cabDetails">Métré début</th>
              <th v-if="cabDetails">Métré fin</th>
              <th v-if="cabDetails">Emprunté par</th>
              <th v-if="cabDetails">Modifié par</th>
              <th v-if="cabDetails">Tarif (non)</th>
              <th v-if="cabDetails">Stock min (non)</th>
              <th v-if="cabDetails">Sortie max (non)</th>
              <th v-if="cabDetails">Fourn. défaut (non)</th>
              <th v-if="cabDetails">Condition. Entrée (non)</th>
              <th v-if="cabDetails">Condition. Sortie (non)</th>
            </tr>
          </thead>
          <tbody
            v-for="(items, index) in itemCables"
            :key="items"
            :index="index"
          >
            <div :style="[cabDetails ? { 'max-width': '80px' } : {}]">
              <button
                class="button edit"
                v-if="editMode && editCables != items"
                :disabled="
                  (editCables != '0' && editCables != items) ||
                    editTools != '0' ||
                    editConsumables != '0'
                "
                @click="editItemFn(items, 'cable')"
              >
                Editer
              </button>

              <button
                v-if="editCables === items"
                @click="deleteItem(index, 'cable')"
              style="position : relative"
                :class="{'delete-btn-warning' : (items['qtty_in']!=items['qtty_available'])}"
              >
                <i class="fas fa-trash-alt"></i>
                <span class="warning-icon" v-if="items['qtty_in']!=items['qtty_available']" >&#9888;&#65039;</span>
                <span class="warning-message" >Suppression déconseillé, 
                  des reservations sont en cours sur ce produit</span>
              </button>

              <button
                class="button"
                v-if="editCables === items"
                @click="editCables = '0'"
              >
                Annuler
              </button>
              <button
                :disabled="newUrl"
                class="button submit"
                v-if="editCables === items"
                @click="modifyItem(index, 'cable')"
              >
                Valider
              </button>
              <i
                v-if="putLoader === true && editCables === items"
                class="fas fa-hourglass-half"
              ></i>
            </div>
            <tr :class="{ tr2: cabDetails }">
              <td >
                {{ items["ref"] }}
              </td>
              <td>
                {{ items["item"] }}
                <span v-if="items['qtty_in']!=items['qtty_available']" class="booked-item">&#128230;
                  <span>reservation(s) en cours</span>
                </span>
              </td>
              <td id="tdImg">
                <div id="changeImg-container" v-if="editCables === items">
                  <input
                    v-if="!items['image']"
                    type="file"
                    accept="image/*"
                    @change="addImg"
                  />
                  <input
                    v-else
                    value="retirer"
                    id="removeImg"
                    type="button"
                    @click="removeImg(index, 'cable','editorAction')"
                  />
                  <i
                v-if="loaderImg === true"
                class="fas fa-hourglass-half"
              ></i>
                </div>

                <span v-if="!(editCables === items) && items['image']" 
                  >OUI
                  <button class="icon-img1" @click="copyImg(items['image'])"> &#128248;</button>
                  <div class="see-copied-img">
                    <span>
                      Actuellement en memoire
                    </span>
                    <img :src="items['image']||copiedImg" alt="">
                  </div>
                  <button class="icon-img" v-show="copiedImg !=''" @click="stuckImg(index,items,'yes')">
                    &#128229; </button> 
                    <span class="stuck-message">
                      Coller l'image
                    </span>
                    </span
                >
                <span v-else-if="!(editCables === items)">NON
                  <button class="icon-img" v-show="copiedImg !=''" @click="stuckImg(index,items,'non')">&#128229; </button> 
                <span class="stuck-message">
                      Coller l'image
                    </span>
                </span>
              </td>
              <td>
                {{ items["id"] }}
              </td>
              <td>
                {{ items["supplier"] }}
              </td>
              <td>
                {{ items["item_storage"] }}
              </td>
              <td>
                {{ items["qtty_in"] }}
              </td>
              <td>
                {{ items["qtty_available"] }}
              </td>
              <td>
                <!--{{items['period']}}!-->
                <span v-if="items['period'] == 0">Dépôt</span>
                <span v-else-if="items['period'] == 2">Réservé</span>
                <span v-else-if="items['period'] == 1">Chantier</span>
              </td>
              <td v-if="cabDetails">
                
                {{ items["sub_type"] }}
              </td>

              <td v-if="cabDetails">
                {{ items["ref_supplier"] }}
              </td>

              

              <td v-if="cabDetails">
                {{ JSON.parse(items["meter"]).start }}
              </td>
              <td v-if="cabDetails">
                {{ JSON.parse(items["meter"]).end }}
              </td>
              <td v-if="cabDetails">
                {{ cutString(items["username"]) }}
              </td>

              <td v-if="cabDetails">
                {{ cutString(items["data_feeder"]) }}
              </td>
              <td v-if="cabDetails">{{ items["price"] }} €</td>
              <td v-if="cabDetails">
                {{ items["qtty_min"] }}
              </td>
              <td v-if="cabDetails">
                {{ items["qtty_max"] }}
              </td>
              <td v-if="cabDetails">
                {{ items["def_supplier"] }}
              </td>
              <td v-if="cabDetails">
                {{ items["packaging"] }}
              </td>

              <td v-if="cabDetails">
                {{ items["out_packaging"] }}
              </td>


            </tr>

            <tr
              v-if="editCables === items"
              id="isEditing"
              :class="{ tr2: cabDetails }"
            >
             <td>
                <input disabled v-model="ref" placeholder="" />
              </td>
              <td>
                <input v-model="item" placeholder="" />
              </td>
              <td id="tdImg">
                <img
                  v-if="items['image'] || newUrl"
                  :src="items['image'] || newUrl"
                  alt=""
                />
                <button
                  v-if="newUrl"
                  id="save-img"
                  @click="confirmImage(index, 'cable')"
                >
                  Enregistrer l'image
                </button>
              </td>

             
              <td>
                <input disabled v-model="items['id']" placeholder="" />
              </td>
              <td>
                <input v-model="supplier" placeholder="" />
              </td>
              <td>
                
                <select v-model="item_storage" name="type" required>
                  <option v-for="loc in locationList" :key="loc" :value="loc">{{loc}}</option>
                </select>
              </td>
              <td>
                <input
                  
                  v-model="qtty_in"
                  class="qtty-number number"
                  type="number"
                  min="0"
                  placeholder="0"
                  @keydown="checkChars"
                  @change="checkChange($event,index,'cable')"
                />
              </td>
              <td>
                <input
                  :disabled="lockQttyAvailable"
                  v-model="qtty_available"
                  class="qtty-number number"
                  type="number"
                  min="0"
                  placeholder="0"
                  @keydown="checkChars"
                />
                <button v-if="lockQttyAvailable" @mouseover="warningAvailable = true" @mouseleave="warningAvailable = false" @click="lockQttyAvailable = false">	
                &#128274;</button>
                <button v-else @mouseover="warningAvailable = true" @mouseleave="warningAvailable = false" @click="lockQttyAvailable = true">	
                &#128273;</button>
                <span class="block warning-message" v-show="warningAvailable"> &#9888;&#65039; Modifier les quantités disponibles n'annulera pas les reservations 
                  concernés et risque de créer des erreurs dans l'application. </span>
              </td>
              <td>
                <select disabled
                  v-model="period"
                  @keydown="checkChars"
                  name="type"
                  required
                >
                  <option value="0">Dépôt</option>
                  <option value="1">Chantier</option>
                  <option value="2">Réservé</option>
                </select>
              </td>
              <td v-if="cabDetails">
                <input v-model="sub_type" placeholder="" />
              </td>

              <td v-if="cabDetails">
                <input v-model="ref_supplier" placeholder="" />
              </td>

              

              <td v-if="cabDetails">
                <input
                disabled
                  v-model="m_start"
                  class="number"
                  type="number"
                  min="0"
                  placeholder="0"
                  @keydown="checkChars"
                />
              </td>
              <td v-if="cabDetails">
                <input
                disabled
                  v-model="m_end"
                  class="number"
                  type="number"
                  min="0"
                  placeholder="0"
                  @keydown="checkChars"
                />
              </td>

              <td>
                Auto
              </td>
              <td>
                Auto
              </td>
              <td v-if="cabDetails">
                <input
                  v-model="price"
                  class="number"
                  type="number"
                  min="0"
                  placeholder="0"
                  step="0.01"
                  @keydown="checkChars"
                />
                €
              </td>
              <td v-if="cabDetails">
                <input
                  v-model="qtty_min"
                  class="little-number number"
                  type="number"
                  min="0"
                  placeholder="0"
                  @keydown="checkChars"
                />
              </td>
              <td v-if="cabDetails">
                <input
                  v-model="qtty_max"
                  class="little-number number"
                  type="number"
                  min="0"
                  placeholder="0"
                  @keydown="checkChars"
                />
              </td>
              <td v-if="cabDetails">
                <input v-model="def_supplier" placeholder="" />
              </td>
              <td v-if="cabDetails">
                <input
                  v-model="packaging"
                  class="little-number number"
                  type="number"
                  min="0"
                  placeholder="0"
                  @keydown="checkChars"
                />
              </td>
              <td v-if="cabDetails">
                <input
                  v-model="out_packaging"
                  class="little-number number"
                  type="number"
                  min="0"
                  placeholder="0"
                  @keydown="checkChars"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
   <!-- CLIENTS !-->
    <div class="seestock-sub-container">
      <div class="items-header">
        <h3>Matériel clients</h3>
        <button
        class="showHideBtn"
          v-if="Object.values(itemClients).length != 0"
          @click="showClients = !showClients"
        >
          Afficher / Masquer
        </button>
        <span v-else> [...]</span>
      </div>
      <div
        class="table-container"
        v-if="Object.values(itemClients).length != 0 && showClients === true"
      >
        <table>
          <colgroup>
            <col span="12" />
            <col class="grey" span="4" />
          </colgroup>
          <thead>
            <tr :class="{ tr2: clDetails }">
              <th>Réf interne 
              <span>
              <button v-if="!orderByRef" @click="orderByRefFn()">&#11167;</button>
              
              </span></th>
              <th>Désignation
                <span><button v-if="orderByRef" @click="orderByRefFn()">&#11167;</button></span>
              </th>
              <th>Image</th>
              
              <th>Id</th>
              <th>Nom fournisseur</th>
              <th>Dépôt</th>
              <th>Quantité</th>
              <th>
                Quantité (dispo)
                <button
                  v-if="clDetails === false"
                  class="menu extend-reduce"
                  @click="clDetails = !clDetails"
                >
                  +
                </button>
                <button
                  v-else
                  class="menu extend-reduce"
                  :style="[
                    clDetails && !editMode ? { top: '-28px' } : {},
                    clDetails && editMode ? { top: '40px' } : {},
                  ]"
                  @click="clDetails = !clDetails"
                >
                  -
                </button>
              </th>
              <th v-if="clDetails">Sous-catégorie</th>
              <th v-if="clDetails">Stock min</th>
              <th v-if="clDetails">Réf fournisseur</th>
              <th v-if="clDetails">Condition. Sortie</th>
              <th v-if="clDetails">Modifié par</th>
              <th v-if="clDetails">Tarif (non)</th>
              <th v-if="clDetails">Sortie max (non)</th>
              <th v-if="clDetails">Fourn. défaut (non)</th>
            </tr>
          </thead>
          <tbody
            v-for="(items, index) in itemClients"
            :key="items"
            :index="index"
          >
            <div :style="[clDetails ? { 'max-width': '80px' } : {}]">
              <button
                class="button edit"
                v-if="editMode && editClients != items"
                :disabled="
                  (editClients != '0' && editClients != items) ||
                    editTools != '0' ||
                    editConsumables != '0' ||
                    editCables != '0'
                "
                @click="editItemFn(items, 'client')"
              >
                Editer
              </button>

              <button
                v-if="editClients === items"
                @click="deleteItem(index, 'client')"
              style="position : relative"
                :class="{'delete-btn-warning' : (items['qtty_in']!=items['qtty_available'])}"
              >
                <i class="fas fa-trash-alt"></i>
                <span class="warning-icon" v-if="items['qtty_in']!=items['qtty_available']" >&#9888;&#65039;</span>
                <span class="warning-message" >Suppression déconseillé, 
                  des reservations sont en cours sur ce produit</span>
              </button>

              <button
                class="button"
                v-if="editClients === items"
                @click="editClients = '0'"
              >
                Annuler
              </button>
              <button
                :disabled="newUrl"
                class="button submit"
                v-if="editClients === items"
                @click="modifyItem(index, 'client')"
              >
                Valider
              </button>
              <i
                v-if="putLoader === true && editClients === items"
                class="fas fa-hourglass-half"
              ></i>
            </div>
            <tr :class="{ tr2: clDetails }"
            :style="[
                !items['on_order'] && showLowStock && items['qtty_in'] < (items['qtty_min']*this.lowStockMultiplier) ? {'background-color': 'rgb(255, 204, 204)' }:{}
              ]">
              <td>
                {{ items["ref"] }}
              </td>
              <td>
                {{ items["item"] }}
                <span v-if="items['qtty_in']!=items['qtty_available']" class="booked-item">&#128230;
                  <span>reservation(s) en cours</span>
                </span>
              </td>
              <td id="tdImg">
                <div id="changeImg-container" v-if="editClients === items">
                  <input
                    v-if="!items['image']"
                    type="file"
                    accept="image/*"
                    @change="addImg"
                  />
                  <input
                    v-else
                    value="retirer"
                    id="removeImg"
                    type="button"
                    @click="removeImg(index, 'client','editorAction')"
                  />
                  <i
                v-if="loaderImg === true"
                class="fas fa-hourglass-half"
              ></i>
                </div>

                <span v-if="!(editClients === items) && items['image']"
                  >OUI
                  <button class="icon-img1" @click="copyImg(items['image'])"> &#128248;</button>
                  <div class="see-copied-img">
                    <span>
                      Actuellement en memoire
                    </span>
                    <img :src="items['image']||copiedImg" alt="">
                  </div>
                  <button class="icon-img" v-show="copiedImg !=''" @click="stuckImg(index,items,'yes')">&#128229; </button>
                  <span class="stuck-message">
                      Coller l'image
                    </span>
                     </span
                >
                <span v-else-if="!(editClients === items)">NON
                  <button class="icon-img" v-show="copiedImg !=''" @click="stuckImg(index,items,'non')">&#128229; </button> 
                <span class="stuck-message">
                      Coller l'image
                    </span>
                </span>
              </td>
              
              <td>
                {{ items["id"] }}
              </td>
              <td>
                {{ items["supplier"] }}
              </td>
              <td>
                
                {{ items["item_storage"] }}
              </td>
              <td>
                {{ items["qtty_in"] }}
              </td>
              <td>
                {{ items["qtty_available"] }}
              </td>
              <td v-if="clDetails">
                {{ items["sub_type"] }}
              </td>
              <td v-if="clDetails">
                {{ items["qtty_min"] }}
              </td>
              <td v-if="clDetails">
                {{ items["ref_supplier"] }}
              </td>
              <td v-if="clDetails">
                {{ items["out_packaging"] }}
              </td>

              <td v-if="clDetails">
                {{ cutString(items["data_feeder"]) }}
              </td>
              <td v-if="clDetails">{{ items["price"] }} €</td>
              <td v-if="clDetails">
                {{ items["qtty_max"] }}
              </td>
              <td v-if="clDetails">
                {{ items["def_supplier"] }}
              </td>
            </tr>
            <tr
              v-if="editClients === items"
              id="isEditing"
              :class="{ tr2: clDetails }"
            >
            <td>
                <input v-model="ref" placeholder="" />
              </td>
              <td>
                <input v-model="item" placeholder="" />
              </td>
              <td id="tdImg">
                <img
                  v-if="items['image'] || newUrl"
                  :src="items['image'] || newUrl"
                  alt=""
                />
                <button
                  v-if="newUrl"
                  id="save-img"
                  @click="confirmImage(index, 'client')"
                >
                  Enregistrer l'image
                </button>
              </td>
              
              
              <td>
                <input disabled v-model="items['id']" placeholder="" />
              </td>
              <td>
                <input v-model="supplier" placeholder="" />
              </td>
              <td>
                <select v-model="item_storage" name="type" required>
                  <option v-for="loc in locationList" :key="loc" :value="loc">{{loc}}</option>
                </select>
              </td>
              <td>
                <input
                  v-model="qtty_in"
                  class="qtty-number number"
                  type="number"
                  min="0"
                  placeholder="0"
                  @keydown="checkChars"
                  @change="checkChange($event,index,'client')"
                />
              </td>
              <td>
                <input
                :disabled="lockQttyAvailable"
                  v-model="qtty_available"
                  class="qtty-number number"
                  type="number"
                  min="0"
                  placeholder="0"
                  @keydown="checkChars"
                />
                <button v-if="lockQttyAvailable" @mouseover="warningAvailable = true" @mouseleave="warningAvailable = false" @click="lockQttyAvailable = false">	
                &#128274;</button>
                <button v-else @mouseover="warningAvailable = true" @mouseleave="warningAvailable = false" @click="lockQttyAvailable = true">	
                &#128273;</button>
                <span class="block warning-message" v-show="warningAvailable"> &#9888;&#65039; Modifier les quantités disponibles n'annulera pas les reservations 
                  concernés et risque de créer des erreurs dans l'application. </span>
              </td>
              <td v-if="clDetails">
                <input v-model="sub_type" placeholder="" />
                
              </td>
              <td v-if="clDetails">
                <input
                  v-model="qtty_min"
                  class="little-number number"
                  type="number"
                  min="0"
                  placeholder="0"
                  @keydown="checkChars"
                />
              </td>
              <td v-if="clDetails">
                <input v-model="ref_supplier" placeholder="" />
              </td>
              <td v-if="clDetails">
                <input
                  v-model="out_packaging"
                  class="little-number number"
                  type="number"
                  min="0"
                  placeholder="0"
                  @keydown="checkChars"
                />
              </td>
              <td>
                Auto
              </td>
              <td v-if="clDetails">
                <input
                  v-model="price"
                  class="number"
                  type="number"
                  min="0"
                  placeholder="0"
                  step="0.01"
                  @keydown="checkChars"
                />
                €
              </td>
              <td v-if="clDetails">
                <input
                  v-model="qtty_max"
                  class="little-number number"
                  type="number"
                  min="0"
                  placeholder="0"
                  @keydown="checkChars"
                />
              </td>
              <td v-if="clDetails">
                <input v-model="def_supplier" placeholder="" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <button id="goTop" @click="toTheTop()">
      <i class="fas fa-arrow-up"></i>
    </button>

  </div>
</template>
<script>
import { mapState } from "vuex";
const XLSX = require("xlsx");
const { saveAs } = require('file-saver');

const myAppPath = require('./../../../switchUrl');
const appPath = myAppPath.appPath;

class Item {
  constructor(
    ref,
    ref_supplier,
    item,
    price,
    supplier,
    def_supplier,
    qtty_min,
    qtty_max,
    packaging,
    out_packaging,
    type_,
    sub_type,
    item_storage,
    period,
    meter,
    qtty_in,
    qtty_available,
    data_feeder
  ) {
    this.ref = ref;
    this.ref_supplier = ref_supplier;
    this.item = item;
    this.price = price;
    this.supplier = supplier;
    this.def_supplier = def_supplier;
    this.qtty_min = qtty_min;
    this.qtty_max = qtty_max;
    this.packaging = packaging;
    this.out_packaging = out_packaging;
    this.item_storage = item_storage;
    this.type_ = type_;
    this.sub_type = sub_type;
    this.period = period;
    this.meter = meter;
    this.qtty_in = qtty_in;
    this.qtty_available = qtty_available;
    this.data_feeder = data_feeder;
  }
}

export default {
  name: "SeeStock",
  props: ['refreshCall2'],
  data() {
    return {
      connectedUser: {},
      showConsumables: false,
      showTools: false,
      showCables: false,
      showClients: false,
      cDetails: false,
      tDetails: false,
      cabDetails: false,
      clDetails: false,
      editMode: false,
      editConsumables: "0",
      editTools: "0",
      editCables: "0",
      editClients: "0",
      ref: "",
      ref_supplier: "",
      item: "",
      newUrl: "",
      image: "",
      price: "",
      supplier: "",
      def_supplier: "",
      qtty_min: "",
      qtty_max: "",
      packaging: "",
      out_packaging: "",
      type_: "",
      sub_type: "",
      item_storage: "",
      period: "",
      meter: {},
      qtty_in: "",
      qtty_available: "",
      data_feeder: "",
      item_id: "",
      isGetStocks: false,
      putLoader: false,
      invalidChars: ["-", "+", "e"],
      lockQttyAvailable : true,
      loaderImg : false,
      copiedImg : '',
      warningAvailable : false,
      lockPriceChanges : true,
      lowStockMultiplier : 2,
      showLowStock : false,
      orderByRef : false,
      locationList : [],
      transferLocation : false,
      switchSelect : true,
      item_storageTransfer : "",
      item_storageTransfer2 : "",
      confirmT : false,
      loadTransfer : false
    };
  },
  mounted() {
    this.$store.commit("isConnected");
    // récuperer l'utilisateur actuel
    this.connectedUser = JSON.parse(localStorage.getItem("connectedUser"));
    const instr = '';

    if(this.allLocations.length !=0){    
    this.locationList = this.allLocations;}

    this.getStocks(instr);
  },
  // l'excell envoie une demande de refresh apres injection
  watch: {
    refreshCall2(newCount, oldCount) {
      if (newCount === true) {
        const instr = '';
        this.getStocks(instr);
      }
    },
    allLocations(newCount, oldCount) {
      if(newCount) {
        //alert(newCount);
        this.locationList = newCount;
      }
    },
    },
  computed: {
    //STORE
    ...mapState({
      itemConsumables: "itemConsumablesAndDetails",
      itemTools: "itemToolsAndDetails",
      itemCables: "itemCablesAndDetails",
      itemClients: "itemClientsAndDetails",
      dataFetched : "dataFetched",
      allLocations : "allLocations"
    }),

    translateForExcel(){
      var jsonObj = [];
      //var miror = JSON.parse(JSON.stringify(this.multiFilters));
      let miror;
      for(let i =0; i<4; i++){
        //console.log(i);
        if(i ===0){
          miror = JSON.parse(JSON.stringify(this.itemConsumables));
        }
        else if(i ===1){
          miror = JSON.parse(JSON.stringify(this.itemTools));
        }
        else if(i ===2){
          miror = JSON.parse(JSON.stringify(this.itemCables));
        }
        else if(i ===3){
          miror = JSON.parse(JSON.stringify(this.itemClients));
        }
      

        //var start = new Date();
        miror.forEach(element => {
          //element.date = this.dateFormat(element.date);
          
          delete Object.assign(element, {['Désignation']: element['item'] })['item'];
          if(element.type_ === "cable" || element.type_ === "client"){
            delete element.price;
          }
          delete Object.assign(element, {['Tarif']: element['price'] })['price'];
          delete Object.assign(element, {['Réf interne']: element['ref'] })['ref'];
          delete Object.assign(element, {['Id']: element['id'] })['id'];
          delete Object.assign(element, {['Fournisseur']: element['supplier'] })['supplier'];
          delete Object.assign(element, {['Catégorie']: element['type_'] })['type_'];
          delete Object.assign(element, {['Sous-catégorie']: element['sub_type'] })['sub_type'];
          delete Object.assign(element, {['Quantité']: element['qtty_in'] })['qtty_in'];
          delete Object.assign(element, {['Quantité (dispo)']: element['qtty_available'] })['qtty_available'];
          if(element.Catégorie === "cable" || element.Catégorie === "client"){
            delete element.qtty_max;
            if(element.Catégorie === "cable"){
            delete element.qtty_min; }
          }
          delete Object.assign(element, {['Dépôt']: element['item_storage'] })['item_storage'];
          delete Object.assign(element, {['Sortie max']: element['qtty_max'] })['qtty_max'];
          delete Object.assign(element, {['Stock min']: element['qtty_min'] })['qtty_min'];
          
          if(element.Catégorie === "cable" || element.Catégorie === "client"){
            delete element.def_supplier;
          }
          delete Object.assign(element, {['Ref fournisseur']: element['ref_supplier'] })['ref_supplier'];
          delete Object.assign(element, {['Fournisseur par défaut']: element['def_supplier'] })['def_supplier'];
          
          if(element.Catégorie === "cable" || element.Catégorie === "client"){
            delete element.packaging;
            delete element.out_packaging;
          }
          delete Object.assign(element, {['Conditionnement entrée']: element['packaging'] })['packaging'];
          delete Object.assign(element, {['Conditionnement sortie']: element['out_packaging'] })['out_packaging'];
          if(element.Catégorie != "cable"){
            delete element.meter;
          }
          else{
            element['Metré début'] = JSON.parse(element["meter"]).start
            element['Metré fin'] = JSON.parse(element["meter"]).end
            delete element.meter;
          }
          //delete Object.assign(element, {['Metré']: element['meter'] })['meter'];
          if(element.Catégorie != "tool"){
            delete element.period;
          }
          delete Object.assign(element, {['Périodicité']: element['period'] })['period'];
          //delete Object.assign(element, {['Modifié par']: element['data_feeder'] })['data_feeder'];
          delete element.image;
          delete element.id_details;
          delete element.internal_ref;
          delete element.def_supplier;
          delete element.item_storage_bis
          delete element.qtty_in_bis;
          delete element.qtty_available_bis;
          delete element.on_order;
          delete element.username;
          delete element.data_feeder;
          delete element.date;
          delete element.fifo_date;
          jsonObj.push(element);
        });
      }
        // 7 milliseconds
        //var time = new Date() - start;
        //console.log(time);
        return jsonObj;
    }

  },
  
  methods: {
    transferLoc(from,to,action){
      if(from == '' || to == ''){
        alert('Veuillez renseigner un dépôt');
        return
      };
      //loader
      this.loadTransfer = true;

      this.fetchTransferLoc(from,to,action)
        .then((data) => {
          //console.log(data);

          if (data["data"]) {
            //console.log(data["data"]);

            if(typeof data["data"] === 'string' && data["data"].includes('ERREUR')){
              alert(data['data']);
              return
            };

           
          } else {
            alert(
              "Echec du transfert"
            );
          }
          this.loadTransfer = false;
          //récupérer la liste des dépôts
    this.$store.dispatch("getAllLocations", this.connectedUser.id_user);
          
        })
        .catch((e) => console.log(e));
    },
    async fetchTransferLoc(from,to,action) {
      //console.log(this.connectedUser.id_user);
      let key;
        //key for only web app native request (!postman)
        if (
          (this.connectedUser.a5e789410fd.includes("2f+g2dfg25h2d+f15b1gs5bnj1ki1lo61q1dc16"))
        ) {
          key = "h1d5he15fdhs";
        }
        const requestOptions = {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
          body: 
          JSON.stringify({
          from : from,
          to : to,
          action : action,
          data_feeder : this.connectedUser.username+' from_console',
          user_id: this.connectedUser.id_user,
          key: key,
        }),
        };

        let response = await fetch(
          appPath+"/items/location_transfer",
          requestOptions
        );
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          //console.log('not response ok, error : ' + error);
          alert("une erreur innattendue s'est produite");
          return Promise.reject(error);
        }
        return await response.json();
      
    },
    orderByRefFn(){
      if(this.orderByRef === true){
        this.orderByRef = false;
        const instr = '';
    this.getStocks(instr);
      }
      else{
        this.orderByRef = true;
        const instr = 'ref';
    this.getStocks(instr);
      }
    },
    checkChars(e) {
      if (this.invalidChars.includes(e.key)) {
        e.preventDefault();
      }
      //console.log(e);
    },
    checkChange(event,index,type_) {
      if(this.lockQttyAvailable === true){
      //console.log(event.target.value);
      let obj;
      switch (type_) {
        case 'consumable':
          obj = this.itemConsumables;
          break;
        case 'tool':
          obj = this.itemTools;
          break;
        case 'cable':
          obj = this.itemCables;
          break;
        case 'client':
          obj = this.itemClients;
          break;
      
        default:
          break;
      }
      //const step = obj[index].qtty_in - obj[index].qtty_available;
      const step = obj[index].qtty_in - event.target.value;
      if(obj[index].qtty_available - step < 0){
        alert("vous ne pouvez pas réduire la quantité disponible, des réservations sont en cours");
        this.qtty_in = obj[index].qtty_in;
        this.qtty_available = obj[index].qtty_available;
        return
      };
      this.qtty_available = obj[index].qtty_available - step;
    }},
    //---GET STOCKS DATA WITH STORE
    getStocks(instr) {
      // acces boolean
      this.isGetStocks = true;
      //annuler l'edition au cas ou actif
      this.editConsumables = "0";
      this.editTools = "0";
      this.editCables = "0";
      this.editClients = "0";
      let id_infos;
      id_infos = this.connectedUser.id_user
      if(instr === 'ref'){
        id_infos = 'ref_'+this.connectedUser.id_user;
      }
      // store request
      if (Object.keys(this.connectedUser).length != 0) {
        this.$store.dispatch(
          "getAllItemsAndDetails",
          id_infos
        );
      } else {
        alert("Accès bloqué, l'utilisateur n'est pas reconnu !");
      }
    },
    // Scroll to top
    toTheTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    // gérer la casse des string
    capitalizeFirstLetter(string) {
      //console.log(typeof string);
      if (typeof string === "string") {
        const str = string.toLowerCase();
        return str.charAt(0).toUpperCase() + str.slice(1);
      } else {
        return string;
      }
    },
    // Ajouter les valeurs dans les input de la ligne en cours de mofification
    editItemFn(items, type_) {
      this.newUrl = null;
      //console.log(type);
      //console.log(type === 'consumable');
      this.ref = items["ref"];
      this.ref_supplier = items["ref_supplier"];
      this.item = items["item"];
      this.image = items["image"];
      this.price = items["price"];
      this.supplier = items["supplier"];
      this.def_supplier = items["def_supplier"];
      this.qtty_min = items["qtty_min"];
      this.qtty_max = items["qtty_max"];
      this.packaging = items["packaging"];
      this.out_packaging = items["out_packaging"];
      this.type_ = items["type_"];
      this.sub_type = items["sub_type"];
      this.item_storage = items["item_storage"];
      this.period = items["period"];
      this.qtty_in = items["qtty_in"];
      this.qtty_available = items["qtty_available"];
      this.item_id = items["id"];
      //console.log('item id');
      //console.log(items['id']);
      //this.data_feeder= items['data_feeder'];
      switch (type_) {
        case "consumable":
          this.editConsumables = items;
          break;
        case "tool":
          this.editTools = items;
          break;
        case "cable":
          this.m_start = JSON.parse(items["meter"]).start;
          this.m_end = JSON.parse(items["meter"]).end;
          this.editCables = items;
          break;
        case "client":
          this.editClients = items;
          break;

        default:
          break;
      }
    },
    // --------------DB REQUEST
    modifyItem(index, type_) {
      this.putLoader = true;
      this.lockQttyAvailable = true;
      if(this.qtty_in < this.qtty_available){
        alert('Impossible : quantité disponible supérieure à la quantité réelle');
        return
      }
      this.fetchModifyItem(index,type_)
        .then((data) => {
          //console.log(data);

          this.newUrl = null;
          // rafraichir les données
          if (data["data"]) {
            //console.log(data["data"]);
            if(data["data"].includes('ERREUR')){
              alert(data['data']);
              return
            };
            //console.log("getOneItem");
            this.getOneItem(index, type_);
          } else {
            alert(
              "La modification a échoué... (Causes possibles : saisie non reconnue/acceptée - triade ref/tarif/Nom fournisseur déjà existante - pblm serveur)"
            );
            this.putLoader = false;
          }
        })
        .catch((e) => console.log(e));
    },
    async fetchModifyItem(index,type_) {
      //console.log(this.connectedUser.id_user);
      //calcul si cable
      if (type_ === "cable" && this.m_start && this.m_end) {
        this.meter = {
          start: this.m_start,
          end: this.m_end,
        };
        const diff = this.itemCables[index].qtty_in - this.qtty_in;
        var m_start = parseInt(this.m_start);
        var m_end = parseInt(this.m_end);
        //const max = Math.max(m_start, m_end);
        //const min = Math.min(m_start, m_end);
        if(m_start > m_end){
          //degressif
          m_start = m_start-diff;
        }
        else if(m_start < m_end){
          m_start = m_start + diff;
        }
        this.meter['end']= m_end;
        this.meter['start']= m_start;
        //console.log(this.meter);
        this.meter = JSON.stringify(this.meter);
      } else {
        this.meter = null;
      }

      if (
        !(this.ref === "") &&
        !(this.ref_supplier === "") &&
        !(this.item === "") &&
        !(this.price === "") &&
        !(this.packaging === "") &&
        !(this.type_ === "") &&
        !(this.qtty_in === "") &&
        !(this.item_storage === "") &&
        Object.keys(this.connectedUser).length != 0
      ) {
        //console.log("données :", this.image, this.user.id_user);
        this.data_feeder = this.connectedUser.username;
        if (!this.period) {
          this.period = 0;
        }

        const MODIFY_ITEM = new Item(
          this.ref,
          this.ref_supplier,
          this.item,
          this.price,
          this.supplier,
          this.def_supplier,
          this.qtty_min,
          this.qtty_max,
          this.packaging,
          this.out_packaging,
          this.type_,
          this.sub_type,
          this.item_storage,
          this.period,
          this.meter,
          this.qtty_in,
          this.qtty_available,
          this.data_feeder
        );

        for (var i in MODIFY_ITEM) {
          //console.log(i);
          //console.log(typeof i);
          if (type_ === 'cable' && typeof MODIFY_ITEM[i] === "string" && i === "item") {
            MODIFY_ITEM[i] = (MODIFY_ITEM[i].trim()).toUpperCase();
          }
          else if (typeof MODIFY_ITEM[i] === "string" && i != "type_") {
            MODIFY_ITEM[i] = this.capitalizeFirstLetter(MODIFY_ITEM[i].trim());
          }
          else if (typeof  MODIFY_ITEM[i] === "string" &&
            i === "type_"){
              MODIFY_ITEM[i] = MODIFY_ITEM[i].trim();
            }
        }

        let key;
        //key for only web app native request (!postman)
        if (
          (this.connectedUser.a5e789410fd.includes("2f+g2dfg25h2d+f15b1gs5bnj1ki1lo61q1dc16"))
        ) {
          key = "h1d5he15fdhs";
        }
        const requestOptions = {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
          body: JSON.stringify({
            ref: MODIFY_ITEM.ref,
            ref_supplier: MODIFY_ITEM.ref_supplier,
            item: MODIFY_ITEM.item,
            price: MODIFY_ITEM.price,
            supplier: MODIFY_ITEM.supplier,
            def_supplier: MODIFY_ITEM.def_supplier,
            qtty_min: MODIFY_ITEM.qtty_min,
            qtty_max: MODIFY_ITEM.qtty_max,
            packaging: MODIFY_ITEM.packaging,
            out_packaging: MODIFY_ITEM.out_packaging,
            type_: MODIFY_ITEM.type_,
            sub_type: MODIFY_ITEM.sub_type,
            item_storage: MODIFY_ITEM.item_storage,
            period: MODIFY_ITEM.period,
            meter: MODIFY_ITEM.meter,
            qtty_in: MODIFY_ITEM.qtty_in,
            qtty_available: MODIFY_ITEM.qtty_available,
            username : MODIFY_ITEM.data_feeder+' from_console',
            data_feeder: MODIFY_ITEM.data_feeder+' from_console',
            item_id: this.item_id,
            user_id: this.connectedUser.id_user,
            key: key,
          }),
        };

        let response = await fetch(
          appPath+"/items/modify_item",
          requestOptions
        );
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          //console.log('not response ok, error : ' + error);
          alert("une erreur innattendue s'est produite");
          return Promise.reject(error);
        }
        return await response.json();
      }
      //si un champ est resté vide on ne passe pas dans fetch
      else {
        //console.log("veuillez remplir tous les champs");
      }
    },
    getOneItem(index, type_) {
      this.fetchGetOneItem()
        .then((data) => {
          if (!data["data"]) {
            alert("le produit est introuvable");
          }
          //console.log(data['data']['0']);
          switch (type_) {
            case "consumable":
              this.itemConsumables[index] = data["data"]["0"];
              this.editConsumables = "0";
              this.putLoader = false;
              break;
            case "tool":
              this.itemTools[index] = data["data"]["0"];
              this.editTools = "0";
              this.putLoader = false;
              break;
            case "cable":
              this.itemCables[index] = data["data"]["0"];
              this.editCables = "0";
              this.putLoader = false;
              break;
            case "client":
              this.itemClients[index] = data["data"]["0"];
              this.editClients = "0";
              this.putLoader = false;
              break;

            default:
              break;
          }

          // rafraichir les données
        })
        .catch((e) => console.log(e));
    },
    async fetchGetOneItem() {
      const ids = this.item_id + "_" + this.connectedUser.id_user;
      let response = await fetch(appPath+"/items/getOne/" + ids, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      });
      if (!response.ok) {
        // get error message from body or default to response status
        const error = (data && data.message) || response.status;
        //console.log('not response ok, error : ' + error);
        alert("une erreur innattendue s'est produite");
        return Promise.reject(error);
      }
      return await response.json();
    },
    deleteItem(index, type_) {
      this.putLoader = true;
      this.newUrl = null;
      // loader
      //this.deletedUser = user['id'];
      // user we want to delete
      //fetching
      this.fetchDeleteItem()
        .then((data) => {
          //result
          if (data["data"] != false) {
            //alert(data);
            //console.log(data);
            switch (type_) {
              case "consumable":
                // supprimer l'element de la liste
                if (index > -1) {
                  this.itemConsumables.splice(index, 1);
                }
                this.editConsumables = "0";
                this.putLoader = false;
                break;
              case "tool":
                // supprimer l'element de la liste
                if (index > -1) {
                  this.itemTools.splice(index, 1);
                }
                this.editTools = "0";
                this.putLoader = false;
                break;
              case "cable":
                if (index > -1) {
                  this.itemCables.splice(index, 1);
                }
                this.editCables = "0";
                this.putLoader = false;
                break;
              case "client":
                // supprimer l'element de la liste
                if (index > -1) {
                  this.itemClients.splice(index, 1);
                }
                this.editClients = "0";
                this.putLoader = false;
                break;

              default:
                break;
              //setTimeout(function () { this.refreshAllUsers(); }.bind(this), 1200);
              //actualiser la liste allUsers du store après inscription
            }
          }
          else {alert('Delete error')}
        })
        .catch((e) => console.log(e));
    },
    async fetchDeleteItem() {
      let key;
      //key for only web app native request (!postman)
      if (
        (this.connectedUser.a5e789410fd.includes("2f+g2dfg25h2d+f15b1gs5bnj1ki1lo61q1dc16"))
      ) {
        key = "h1d5he15fdhs";
      }
      const requestOptions = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
        body: JSON.stringify({
          id: this.item_id,
          username: this.connectedUser.username+' from_console',
          user_id: this.connectedUser.id_user,
          key: key,
        }),
      };

      let response = await fetch(
        appPath+"/items/delete_item",
        requestOptions
      );
      if (!response.ok) {
        alert("Une erreur empêche la requête");
        //stop loader
        this.putLoader = false;
        // get error message from body or default to response status
        const error = (data && data.message) || response.status;
        //console.log('not response ok, error : ' + error);
        //console.log(error);
        return Promise.reject(error);
      }
      return await response.json();
    },
    addImg(e) {
      const file = e.target.files[0];
      this.image = file;
      this.newUrl = URL.createObjectURL(file);
    },
    confirmImage(index, type_) {
      this.loaderImg = true;
      this.fetchAddImage()
        .then((data) => {
          //result
          if (data["data"]) {
            //console.log('getOneItem');
            this.getOneItem(index, type_);
          } else {
            alert("La modification a échoué");
          };
          this.loaderImg = false;
        })
        .catch((e) => console.log(e));
    },
    async fetchAddImage() {
      if (!(this.image === "") && Object.keys(this.connectedUser).length != 0) {
         let key;
      //key for only web app native request (!postman)
      if (
        (this.connectedUser.a5e789410fd.includes("2f+g2dfg25h2d+f15b1gs5bnj1ki1lo61q1dc16"))
      ) {
        key = "h1d5he15fdhs";
      }
        let pathToAddImage;
        const formData = new FormData();
        let requestOptions;

        if(this.image != null && this.image != undefined 
        && this.image != '' && typeof this.image === 'string'){
          pathToAddImage = "/items/add_image2";
        requestOptions = {
          method: "PUT",
          headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
        body: JSON.stringify({
          user_id: this.connectedUser.id_user,
          username: this.connectedUser.username,
          item_id : this.item_id,
          image : this.image,
          key : key
        }),
        };
        }
        else{
          pathToAddImage = "/items/add_image";
          //console.log("données :", this.image, this.user.id_user);
        formData.append("user_id", this.connectedUser.id_user);
        formData.append("username", this.connectedUser.username);
        formData.append("item_id", this.item_id);
        formData.append("image", this.image);
        formData.append("key",key);

        requestOptions = {
          method: "PUT",
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
          body: formData,
        };
        }
        
        let response = await fetch(
          appPath+pathToAddImage,
          requestOptions
        );
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          //console.log('not response ok, error : ' + error);
          alert("une erreur innattendue s'est produite");
          return Promise.reject(error);
        }
        return await response.json();
      }
      //si un champ est resté vide on ne passe pas dans fetch
      else {
        //console.log("l'image ou l'utilisateur n'est pas reconnu ");
      }
    },
    removeImg(index, type_,action) {
      this.loaderImg = true;
      this.newUrl = null;
      this.image = null;
      this.fetchDeleteImage()
        .then((data) => {
          //result
          if (data["data"]) {
            //console.log(data);
            switch (type_) {
              case "consumable":
                // supprimer l'element de la liste
                if (index > -1) {
                  this.itemConsumables[index].image = null;
                }

                break;
              case "tool":
                // supprimer l'element de la liste
                if (index > -1) {
                  this.itemTools[index].image = null;
                }

                break;
              case "cable":
                if (index > -1) {
                  this.itemCables[index].image = null;
                }

                break;

              case "client":
                // supprimer l'element de la liste
                if (index > -1) {
                  this.itemClients[index].image = null;
                }

                break;

              default:
                break;
              //setTimeout(function () { this.refreshAllUsers(); }.bind(this), 1200);
              //actualiser la liste allUsers du store après inscription
            }
            if(action === 'quickAction'){
            this.image = this.copiedImg;
            this.confirmImage(index,type_);
            }
          }
          else{
            alert('la suppression a rencontré un problème')
          };
          this.loaderImg = false;
        })
        .catch((e) => console.log(e));
    },
    async fetchDeleteImage() {
      let key;
      //key for only web app native request (!postman)
      if (
        (this.connectedUser.a5e789410fd.includes("2f+g2dfg25h2d+f15b1gs5bnj1ki1lo61q1dc16"))
      ) {
        key = "h1d5he15fdhs";
      }
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
        body: JSON.stringify({
          id: this.item_id,
          user_id: this.connectedUser.id_user,
          key: key,
        }),
      };

      let response = await fetch(
        appPath+"/items/delete_image",
        requestOptions
      );
      if (!response.ok) {
        alert("Une erreur empêche la requête");
        //stop loader
        this.putLoader = false;
        // get error message from body or default to response status
        const error = (data && data.message) || response.status;
        //console.log('not response ok, error : ' + error);
        //console.log(error);
        return Promise.reject(error);
      }
      return await response.json();
    },
    // Ajouter un objet au panier dans le bon format
    addItemToOrder(item, type_) {
      var sum_in = 0;
      var sum_available = 0;
      if (JSON.parse(localStorage.getItem("adminOrder"))) {
        var adminOrder = JSON.parse(localStorage.getItem("adminOrder"));
        
        //emepcher les doublons dans le panier
        for (let i in adminOrder) {
          if (adminOrder[i]["ref"] === item["ref"]) {
            alert("déjà dans le panier");
            return;
          }
        }

        var arr = this.sumTotal(sum_in, sum_available, item, type_);
        //console.log(sum_in);
        item["sum_in"] = arr[0];
        item["sum_available"] = arr[1];
        adminOrder.push(item);
        localStorage.setItem("adminOrder", JSON.stringify(adminOrder));
      } else {
        var arr = this.sumTotal(sum_in, sum_available, item, type_);
        item["sum_in"] = arr[0];
        item["sum_available"] = arr[1];
        var adminOrder = [item];
        localStorage.setItem("adminOrder", JSON.stringify(adminOrder));
      }
    },
   //Faire les totaux de qttés entre ref jumelles de l'objet itemConsumables ou Tools
    sumTotal(sum_in, sum_available, item, type_) {
      if (type_ === "consumable") {
        for (let i in this.itemConsumables) {
          if (this.itemConsumables[i]["ref"] === item["ref"]) {
            sum_in = sum_in + this.itemConsumables[i]["qtty_in"];
            sum_available =
              sum_available + this.itemConsumables[i]["qtty_available"];
          }
        }
        return [sum_in, sum_available];
      } else if (type_ === "tool") {
        for (let i in this.itemTools) {
          if (this.itemTools[i]["ref"] === item["ref"]) {
            sum_in = sum_in + this.itemTools[i]["qtty_in"];
            sum_available = sum_available + this.itemTools[i]["qtty_available"];
          }
        }
        return [sum_in, sum_available];
      }
    },
    copyImg(img){
      this.copiedImg = img;
      },
    stuckImg(index,item,action){
      this.item_id = item['id'];
      if(action === 'yes'){
        this.removeImg(index,item['type_'],'quickAction');
        return
      }
      else{
        this.image = this.copiedImg;
        this.confirmImage(index,item['type_']);
      }
    },
    cutString(username){
        if(username != null && username.includes('from_console')){
        return username.split('from_console')[0];}
        else if(username != null && username.includes(' creatOnRet')){
        return username.split(' creatOnRet')[0];}
        else if(username != null && username.includes('expiredBooking')){
        return username.split('expiredBooking')[0]+'Expirée';}
        else if(username != null && username.includes('autoCreatedOnReception')){
        return username.split('autoCreatedOnReception')[0]+'Reception';}
        else if(username != null && username.includes('from_user')){
        return username.split('from_user')[0];}
        return username;
        		
      },
      exportToExcel(){
        var miror = JSON.parse(JSON.stringify(this.translateForExcel));
        this.downloadAsExcel(miror);
      },
      downloadAsExcel(data){
        //console.log(data);
        let worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = {
          Sheets : {
            'data':worksheet
          },
          SheetNames: ['data']
        };
        const excelBuffer = XLSX.write(workbook, {booktype : 'xlsx', type : 'array'});
        //console.log(excelBuffer);
        this.saveAsExcel(excelBuffer, 'myFile');
      },
      saveAsExcel(buffer,filename){
        const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([buffer],{type:EXCEL_TYPE});
        saveAs(data,filename+'_export_'+new Date().getTime()+EXCEL_EXTENSION);
      },
  },
};
</script>

<style lang="scss" scoped>
// ------TABLE -----------
.table-container {
  padding-top: 20px;
  max-width: 1250px;
  overflow-x: scroll;
  margin: auto;
}
table {
  border: black 1px solid;
}
td {
  border: black 1px solid;
  padding: 10px 10px;
  max-width: 150px;
  overflow: auto;
}
.tr2 > td {
  border: black 1px solid;
  padding: 5px 5px;
  max-width: 110px;
}
th {
  font-size: 15px;
  padding: 20px;
  position: relative;
}
.tr2 > th {
  font-size: 12px;
  padding: 5px;
  position: relative;
}
tr:hover {
  background-color: #f38414;
  cursor: pointer;
}
h3{
  font-size : 20px;
}
.seestock-sub-container{
  border : 10px solid #f38414;
  margin : 50px 0;
  -webkit-box-shadow: 5px 5px 15px 5px #0d444b; 
  box-shadow: 5px 5px 15px 5px #0d444b;
}
// ------DIVERS STYLE & edition-----------
.items-header {
  display: flex;
  justify-content: center;
  margin: 21px;
}
.items-header > * {
  margin: 5px;
}
.large-button {
  width: 300px;
  height: 50px;
  font-size: 20px;
  font-weight: bold;
  background-color: #0d444b;
  color : white;
}
.sh-button {
width: 150px;
padding: 0;
}
.number2 {
  width: 25px;
  font-size: 18px;
}
@-moz-document url-prefix() {
.number2{
  width: 35px;
}}
.lowStock-container{
  display : inline-block;
    width: 450px;
  }
.btn-container{
/**/ 
  }
#edition {
  margin-left: 20px;
  width: 150px;
}
#show {
  background-color: #0d444b;
  color : white;
  &:hover {
    background-color: #f38414;
  }
}
#show:active {
	transform: scale(1.1);
	transition: .1s;
}
.menu {
  position: absolute;
  padding: 0;
  line-height: 10px;
  height: 28px;
  right: 0;
  top: -16px;
}
.extend-reduce {
  font-size: 25px;
  width: 28px;
  background-color: #f38414;
  color : white;
}
.edition {
  font-size: 20px;
  width: 70px;
}
td input {
  padding: 0;
  max-width: 150px;
}
.tr2 > td input {
  padding: 0px;
  max-width: 80px;
}
.tr2 > td .little-number {
  padding: 0px;
  max-width: 40px;
}
.tr2 > td .qtty-number {
  padding: 0px;
  max-width: 60px;
}
.tr2 > td .number {
  padding: 0;
  width: 80px;
  font-size: 13px;
}
.number {
  padding: 0;
  width: 70px;
  font-size: 20px;
}
#isEditing {
  background-color: #f38414;
}
.seestock-container {
  position: relative;
}
#goTop {
  position: fixed;
  background-color: black;
  right: 100px;
  bottom: 50px;
  width: 50px;
  height: 50px;
}
// -----ICONS -------
.fa-arrow-up {
  font-size: 30px;
  color: white;
}
.fa-hourglass-half {
  margin-left: 20px;
}
.fa-sync-alt {
  margin-left: 10px;
}
// -----BUTTONS & IMG -------
.button {
  margin: 5px;
  background-color: #0d444b;
  color : white;
}
#save-img {
  height: 30px;
  font-size: 20px;
}
.submit {
  background-color: #f38414;
  color : white;
  opacity: 0.7;
  &:hover {
    opacity: 1;
  }
}
.edit {
  margin-bottom: 0;
  margin-top: 20px;
}
img {
  border: black 2px dashed;
  max-width: 150px;
}
.tr2 > #tdImg img {
  border: black 2px dashed;
  max-width: 110px;
}
#tdImg {
  border: black 1px solid;
  padding: 10px 10px;
  width: 150px;
  max-width: 300px;
}
.tr2 > #tdImg {
  border: black 1px solid;
  padding: 0px 0px;
  width: 110px;
  max-width: 110px;
}

input[type=file], /* FF, IE7+, chrome (except button) */
input[type=file]::-webkit-file-upload-button {
  /* chromes and blink button */
  cursor: pointer;
  background-color: #0d444b;
  color : white;
  height: 40px;
  max-width: 300px;
}
#removeImg {
  font-size: 20px;
  font-weight: bold;
}
#changeImg-container {
  border: black 1px solid;
  padding: 10px 10px;
}
.grey {
  background-color: rgba(128, 128, 128, 0.25);
}/*.edition
.relative-span {
  position : relative;
}*/
.tdImg{
  position: relative;
}
.icon-img1:hover + .see-copied-img {
display: block;
}
.icon-img:hover + .stuck-message {
display: block;
}
.see-copied-img{
  position: absolute;
  height: 180px;
  width: 200px;
  background-color: #0d444b;
  color : white;
  border: yellow 1px solid;
  left: 100px;
  display: none;
  overflow: visible;
}
.stuck-message{
  position: absolute;
  height: 20px;
  width: 100px;
  background-color: #0d444b;
  color : white;
  border: black 1px solid;
  display: none;
  overflow: visible;
}
.see-copied-img img {
  margin-top : 20px;
  max-height: 120px;
}
.delete-btn-warning:hover > .warning-message{
display: block;
}
.warning-message{
position : absolute;
display: none;
background-color: white;
border: red 1px solid;
font-size : 18px;
padding : 5px;
font-weight : bold;
color : red;
z-index : 2;
}
.block{
  display : inline-block;
}

.warning-icon {
font-size: 18px;
}
.fa-trash-alt{
  font-size : 17px;
}

.booked-item:hover > span{
  display : inline;
}
.booked-item span{
  position : absolute;
  display : none;
  background-color : white;
  border : black 1px solid;
  padding : 3px;
}
.excel-btn{
    font-size : 20px;
    color: white;
    background-color: green;
    opacity: 1;
    margin : 0px 20px;
    height : 49px;
    font-size: 20px;
}
.excel-btn:active {
	transform: scale(1.1);
	transition: .1s;
}
.excel-btn:disabled {
	background-color: grey;
}

.excel-btn:hover {
  opacity : 0.7;
}
.showHideBtn {
  background-color: #f38414;
  color : white;
  font-size : 18px;
}
.transfer-container {
  margin: 20px;
  
}
.transfer-container > button {
  margin : 20px;
}
.transfer-container * {
  font-size: 18px;
}
.btn-transfer {
 margin : 10px;
 height: 40px;
}
.flex-btn-container {
  display: flex;
  align-content: center;
  justify-content: center;
}
.flex-div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.confirm-div > span { 
display: inline-block;
margin: 20px 0px;
}
.confirm-div > button { 
margin: 10px;
}
@media screen and (max-width: 1100px) {
  .table-container {
    max-width: 300px;
    border: 1px black solid;
  }

  .large-button {
    width: 200px;
    height: 60px;
    font-size: 18px;
    line-height: normal;
    vertical-align: top;
  }
  .sh-button {
width: 110px;
margin : 10px 2px;
padding: 0;
}
.lowStock-container{
    width: 300px;
  }
  .excel-btn{
    height : 60px;
    font-size: 18px; 
    margin : 10px 2px;}

  #edition {
    margin-left: 0px;
    width: 120px;
    height: 60px;
    font-size: 15px;
  }
  img {
    max-width: 100px;
  }
  #tdImg {
    max-width: 150px;
  }
  #goTop {
    left: 0px;
    bottom: 10px;
    width: 20px;
    height: 60px;
    padding: 0;
  }
  .fa-arrow-up {
    font-size: 15px;
  }
}
</style>
