<template>
  <div>
<h2>Gestion des prestations</h2>

<!-- HEADER BTN CONTAINER  !-->
<div class="btn-container">
    <button id="show" class="large-button" @click="getServices()">
      Rafraichir les données
      <i class="fas fa-sync-alt"></i>
    </button>

    <button
      id="edition"
      class="large-button"
      v-if="editMode === false"
      
      @click="editMode = !editMode"
    >
      Editer
    </button>

    <button
      id="edition"
      class="large-button"
      v-else
      @click="editMode = !editMode"
    >
      Sortir de l'édition
    </button>

    <button :disabled="!dataFetched2" @click="exportToExcel()" class="excel-btn">
      Export Excel</button>
    </div>

     <!-- TABLEAU CONTAINER  !-->
     <div class="seestock-sub-container">
      <div class="row-header">
        <button class="mini-info-icon" @blur="showInfos = false" 
        @click="showInfos = true"> &#8505;</button>
        <div class="infos-mess" v-if="showInfos">
          Créer des lignes avec : client = 'Divers', permet de les récupérer 
          si besoin lors de la création d'un devis pour un client non repertorié.
        </div>
        <h3>Prestations Clients</h3>
        <button
        class="showHideBtn"
          v-if="Object.values(servicesClients).length != 0"
          @click="showBenefits = !showBenefits"
        >
          Afficher / Masquer
        </button>
        <span v-else> [...]</span>
      </div>

      <!-- TABLEAU !-->
      <div
        class="table-container"
        v-if="
          Object.values(servicesClients).length != 0 && showBenefits === true"
      >
        <table>
          <thead>
            <tr >
              <th>Client</th>
              <th>Catégorie</th>
              <th>Désignation</th>
              <th>Réf client</th>
              <th>Tarif</th>
            </tr>
          </thead>
          <tbody
            v-for="(row, index) in servicesClients"
            :key="row"
            :index="index"
          >
          <!-- EDIT AND BUTTON ROW CONTAINER !-->
            <div
            v-if="editMode" >
              <button
                class="button edit"
                v-if="editMode && focusedRow != index"
                @click="editRowFn(index,row)"
              >
                Editer
              </button>
              <button
               v-if="editMode && focusedRow === index"
                @click="deleteService(index)"
                
              >
                <i class="fas fa-trash-alt"></i>
              </button>

              <button v-if="editMode && focusedRow === index"
                class="button"
                @click="focusedRow = -1"
              >
              
                Annuler
              </button>
              <button
                 v-if="editMode && focusedRow === index"
                class="button submit"
                @click="modifyService(index)"
              >
                Valider
              </button>
              <i
                v-if="putLoader === true "
                class="fas fa-hourglass-half"
              ></i>
            </div>

            <!-- ROW DATA !-->

            <tr
            >
              <td>
                {{ row["client"] }}
              </td>
              <td >
                {{ row["type_"] }}
                <span v-if="row['qtty_in']!=row['qtty_available']" class="booked-item">&#128230;
                  <span>reservation(s) en cours</span>
                </span>
              </td>
              <td>{{ row["name"] }}</td>
              <td>
                {{ row["ref"] }}
              </td>
              <td>
                {{ row["price"] }} €
              </td>
              
            </tr>
            <tr
             v-if="focusedRow === index"
              id="isEditing"
            >
            <td>
                <input v-model="client" placeholder="" />
              </td>
              <td>
                <input v-model="type_" placeholder="" />
              </td>
              <td>
                <input v-model="name" placeholder="" />
              </td>
              <td>
                <input v-model="ref" placeholder="" />
              </td>
              <td>
                <input v-model="price" placeholder="" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

        <button id="goTop" @click="toTheTop()">
      <i class="fas fa-arrow-up"></i>
    </button>
  </div>
</template>

<script>
import { mapState } from "vuex";
const XLSX = require("xlsx");
const { saveAs } = require('file-saver');

const myAppPath = require('./../../../switchUrl');
const appPath = myAppPath.appPath;
export default {
name: "ServicesDashboard",
props: ['refreshCall'],
  data() {
    return {
    connectedUser: {},
    editMode: false,
    client : '',
    type_ : '',
    name : '',
    ref : '',
    price : '',
    service_id : '',
    focusedRow : -1,
    putLoader : false,
    showBenefits : false,
    showInfos : false,


    };
  },
  mounted() {
    this.$store.commit("isConnected");
    // récuperer l'utilisateur actuel
    this.connectedUser = JSON.parse(localStorage.getItem("connectedUser"));
    this.getServices();
  },
  computed: {
    //STORE
    ...mapState({
      servicesClients: "servicesClients",
      dataFetched2 : "dataFetched2"
    }),

    translateForExcel(){
      var jsonObj = [];
      //var miror = JSON.parse(JSON.stringify(this.multiFilters));
      let miror;
      miror = JSON.parse(JSON.stringify(this.servicesClients));
        
        //var start = new Date();
        miror.forEach(element => {
          //element.date = this.dateFormat(element.date);
          //client type_ name ref price
          delete Object.assign(element, {['Client']: element['client'] })['client'];
          delete Object.assign(element, {['Catégorie']: element['type_'] })['type_'];
          delete Object.assign(element, {['Désignation']: element['name'] })['name'];
          delete Object.assign(element, {['Réf client']: element['ref'] })['ref'];
          delete Object.assign(element, {['Tarif']: element['price'] })['price'];
          delete element.id
          jsonObj.push(element);
        });
      
        // 7 milliseconds
        //var time = new Date() - start;
        //console.log(time);
        return jsonObj;
    }

  },
  // l'excell envoie une demande de refresh apres injection
  watch: {
    refreshCall(newCount, oldCount) {
      if (newCount === true) {
        this.getServices();
      }
    }
    },
  methods: {
    //---GET STOCKS DATA WITH STORE
    getServices() {
    
      let id_infos;
      id_infos = this.connectedUser.id_user
      // store request
      if (Object.keys(this.connectedUser).length != 0) {
        this.$store.dispatch(
          "getServices",
          id_infos
        );
      } else {
        alert("Accès bloqué, l'utilisateur n'est pas reconnu !");
      }
    },
        modifyService(index) {
      this.putLoader = true;
      
      this.fetchModifyService()
        .then((data) => {
          //console.log(data);
          // rafraichir les données
          if (data["data"]) {
            //console.log(data["data"]);
            if(typeof data["data"] === 'string' &&  data["data"].includes('ERREUR')){
              alert(data['data']);
              return
            };
            //console.log("getOneService");
            this.getOneService(index);
          } 
          else {
            alert(
              "La modification a échoué..."
            );
            this.putLoader = false;
          }
        })
        .catch((e) => console.log(e));
    },
    async fetchModifyService() {

      if (
        !(this.client === "") &&
        !(this.type_ === "") &&
        !(this.name === "") &&
        !(this.ref === "") &&
        !(this.price === "") &&
        Object.keys(this.connectedUser).length != 0
      ) {
        
        this.client = this.capitalizeFirstLetter(this.client.trim());
        this.type_ = this.capitalizeFirstLetter(this.type_.trim());
        this.name = this.capitalizeFirstLetter(this.name.trim());
        this.ref = this.capitalizeFirstLetter(this.ref.trim());

        let key;
        //key for only web app native request (!postman)
        if (
          (this.connectedUser.a5e789410fd.includes("2f+g2dfg25h2d+f15b1gs5bnj1ki1lo61q1dc16"))
        ) {
          key = "h1d5he15fdhs";
        }
        const requestOptions = {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
          body: JSON.stringify({
            client:this.client,
            type_: this.type_,
            name: this.name,
            ref: this.ref,
            price: this.price,
            service_id : this.service_id,
            user_id: this.connectedUser.id_user,
            key: key,
          }),
        };

        let response = await fetch(
          appPath+"/activities/modify_service",
          requestOptions
        );
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          //console.log('not response ok, error : ' + error);
          alert("une erreur innattendue s'est produite");
          return Promise.reject(error);
        }
        return await response.json();
      }
      //si un champ est resté vide on ne passe pas dans fetch
      else {
        //console.log("veuillez remplir tous les champs");
      }
    },
    getOneService(index) {
      this.fetchGetOneService()
        .then((data) => {
          if (!data["data"]) {
            alert("le service actualisé est introuvable");
            return
          }
          this.servicesClients[index] = data["data"]["0"];
          this.focusedRow = -1;
          this.putLoader = false;
        })
        .catch((e) => console.log(e));
    },
    async fetchGetOneService() {
      const ids = this.service_id + "_" + this.connectedUser.id_user;
      let response = await fetch(appPath+"/activities/getOne/" + ids, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      });
      if (!response.ok) {
        // get error message from body or default to response status
        const error = (data && data.message) || response.status;
        //console.log('not response ok, error : ' + error);
        alert("une erreur innattendue s'est produite");
        return Promise.reject(error);
      }
      return await response.json();
    },
    deleteService(index) {
      this.putLoader = true;
      this.newUrl = null;
      // loader
      //this.deletedUser = user['id'];
      // user we want to delete
      //fetching
      this.fetchDeleteService()
        .then((data) => {
          //result
          if (data["data"] != false) {
            //alert(data);
            //console.log(data);
                if (index > -1) {
                  this.servicesClients.splice(index, 1);
                }
                this.focusedRow = -1;
                this.putLoader = false;
                
          }
          else {alert('Delete error')}
        })
        .catch((e) => console.log(e));
    },
    async fetchDeleteService() {
      let key;
      //key for only web app native request (!postman)
      if (
        (this.connectedUser.a5e789410fd.includes("2f+g2dfg25h2d+f15b1gs5bnj1ki1lo61q1dc16"))
      ) {
        key = "h1d5he15fdhs";
      }
      const requestOptions = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
        body: JSON.stringify({
          id: this.service_id,
          user_id: this.connectedUser.id_user,
          key: key,
        }),
      };

      let response = await fetch(
        appPath+"/activities/delete_service",
        requestOptions
      );
      if (!response.ok) {
        alert("Une erreur empêche la requête");
        //stop loader
        this.putLoader = false;
        // get error message from body or default to response status
        const error = (data && data.message) || response.status;
        //console.log('not response ok, error : ' + error);
        //console.log(error);
        return Promise.reject(error);
      }
      return await response.json();
    },
    // gérer la casse des string
    capitalizeFirstLetter(string) {
      //console.log(typeof string);
      if (typeof string === "string") {
        const str = string.toLowerCase();
        return str.charAt(0).toUpperCase() + str.slice(1);
      } else {
        return string;
      }
    },
    // Ajouter les valeurs dans les input de la ligne en cours de mofification
    editRowFn(index,row) {
     this.focusedRow = index;
      this.client = row["client"];
      this.type_ = row["type_"];
      this.name = row["name"];
      this.ref = row["ref"];
      this.price = row["price"];  
      this.service_id = row["id"];  
    },
    // Scroll to top
    toTheTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    exportToExcel(){
        var miror = JSON.parse(JSON.stringify(this.translateForExcel));
        this.downloadAsExcel(miror);
      },
      downloadAsExcel(data){
        //console.log(data);
        let worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = {
          Sheets : {
            'data':worksheet
          },
          SheetNames: ['data']
        };
        const excelBuffer = XLSX.write(workbook, {booktype : 'xlsx', type : 'array'});
        //console.log(excelBuffer);
        this.saveAsExcel(excelBuffer, 'myFile');
      },
      saveAsExcel(buffer,filename){
        const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([buffer],{type:EXCEL_TYPE});
        saveAs(data,filename+'_export_'+new Date().getTime()+EXCEL_EXTENSION);
      },
  },
}
</script>

<style lang="scss" scoped>
// ------TABLE -----------
.table-container {
  padding-top: 20px;
  max-width: 1500px;
  overflow-x: scroll;
  margin: auto;
}
table {
  border: black 1px solid;
  width: 1000px;
}
td {
  border: black 1px solid;
  padding: 10px 10px;
  max-width: 150px;
  overflow: auto;
}
.tr2 > td {
  border: black 1px solid;
  padding: 5px 5px;
  max-width: 110px;
}
th {
  font-size: 15px;
  padding: 20px;
  position: relative;
}
.tr2 > th {
  font-size: 12px;
  padding: 5px;
  position: relative;
}
tr:hover {
  background-color: #f38414;
  cursor: pointer;
}
h3{
  font-size : 20px;
  line-height: 35px;
}
.seestock-sub-container{
  border : 10px solid #f38414;
  margin : 50px 0;
  -webkit-box-shadow: 5px 5px 15px 5px #0d444b; 
  box-shadow: 5px 5px 15px 5px #0d444b;
}
.row-header {
  display: flex;
  position: relative;
  justify-content: center;
  margin: 21px;
}
.row-header > * {
  margin: 5px;
}

.large-button {
  width: 300px;
  height: 50px;
  font-size: 20px;
  font-weight: bold;
  background-color: #0d444b;
  color : white;
}

#edition {
  margin-left: 20px;
  width: 150px;
}
#show {
  background-color: #0d444b;
  color : white;
  &:hover {
    background-color: #f38414;
  }
}
#show:active {
	transform: scale(1.1);
	transition: .1s;
}
td input {
  padding: 0;
  max-width: 150px;
}
.tr2 > td input {
  padding: 0px;
  max-width: 80px;
}
#isEditing {
  background-color: #f38414;
}
#goTop {
  position: fixed;
  background-color: black;
  right: 100px;
  bottom: 50px;
  width: 50px;
  height: 50px;
}
// -----ICONS -------
.fa-arrow-up {
  font-size: 30px;
  color: white;
}
.fa-hourglass-half {
  margin-left: 20px;
}
.fa-sync-alt {
  margin-left: 10px;
}
// -----BUTTONS & IMG -------
.button {
  margin: 5px;
  background-color: #0d444b;
  color : white;
}
.submit {
  background-color: #f38414;
  color : white;
  opacity: 0.7;
  &:hover {
    opacity: 1;
  }
}
.edit {
  margin-bottom: 0;
  margin-top: 20px;
}
.fa-trash-alt{
  font-size : 17px;
}

.booked-item:hover > span{
  display : inline;
}
.booked-item span{
  position : absolute;
  display : none;
  background-color : white;
  border : black 1px solid;
  padding : 3px;
}

.showHideBtn {
  background-color: #f38414;
  color : white;
  font-size : 18px;
}
.mini-info-icon {
border-radius : 25px;
width: 30px;
height: 30px;
line-height: 6px;
font-size : 18px;
margin-right : 30px;
padding: 0;
background-color: #f38414;
color: #0d444b;
}
.infos-mess {
    z-index: 5;
    color : white;
    font-size : 25px;
    padding: 10px;
    background-color: #f38414;;
    position : absolute;
    left : 5%;
    top : 60px;
}
.excel-btn{
    font-size : 20px;
    color: white;
    background-color: green;
    opacity: 1;
    margin : 0px 20px;
    margin-left: 100px;
    height : 50px;
    font-size: 20px;
}
.excel-btn:active {
	transform: scale(1.1);
	transition: .1s;
}
.excel-btn:disabled {
	background-color: grey;
}

.excel-btn:hover {
  opacity : 0.7;
}
@media screen and (max-width: 1100px) {
  .table-container {
    max-width: 300px;
    border: 1px black solid;
  }

  .large-button {
    width: 200px;
    height: 60px;
    font-size: 18px;
    line-height: normal;
    vertical-align: top;
  }

  #edition {
    margin-left: 0px;
    width: 120px;
    height: 60px;
    font-size: 15px;
  }
  #goTop {
    left: 0px;
    bottom: 10px;
    width: 20px;
    height: 60px;
    padding: 0;
  }
  .fa-arrow-up {
    font-size: 15px;
  }
}
</style>
