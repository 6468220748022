<template>
  <div>
    <form
      ref="theForm"
      class="address__container"
      action="javascript:void(0)"
      @submit="submitMethod()"
    >
      <h2 class="address__title">
        Gérer les adresses
        <span class="addressImg">&#9993;</span>
      </h2>
      <span class="addresses-info">Pour effectuer une modification, 
        changer l'adresse mais pas le fournisseur.</span>

      <div class="form-row">
        <input
          type="text"
          name="supplier"
          required
          v-model="supplier"
          class="form-row__input"
          placeholder="Fournisseur/Client"
        />
      </div>

      <div class="form-row relative">
        <textarea
          v-model="address"
          class="form-row__input"
          type="text"
          placeholder="Adresse"
          autocomplete=""
          min="8"
          rows="5"
          cols="50"
          required
        />
      </div>

      <div class="form-row">
        <input
          class="button grey_btn fa fa-input"
          type="submit"
          value="Enregistrer"
        />
        <transition name="growSucces">
          <span class="succes-message" v-if="succesMessage"
            >Ajout effectué !</span
          >
        </transition>
      </div>
    </form>

    <!-- VOIR LES ADRESSES !-->
    <div v-if="seeAddress" class="address-flex">
      <button
        class="btn"
        :style="seeAddress ? { 'margin-bottom': '20px' } : {}"
        @click="seeAddress = !seeAddress"
      >
        Masquer les adresses
      </button>
      <div class="address-card" v-for="address in allAddresses" :key="address">
        <h3>{{ address.entity }}</h3>
        <p>{{ address.address }}</p>
        <span class="succes-message" v-if="succesMessage2 && deletedAddress === address.entity"
            >suppression ...</span
          >
        <button class="fa-trash-container" @click="deleteAddress(address.entity)">	
          <span class="fas fa-trash-alt"></span></button>
      </div>
    </div>

    <div v-else>
      <button class="btn" @click="showAndScroll()">
        Afficher les adresses
      </button>
    </div>
    
  </div>
</template>

<script>
import { mapState } from "vuex";

const myAppPath = require('./../../../switchUrl');
const appPath = myAppPath.appPath;


export default {
  name: "Addresses",
  data: function() {
    return {
      supplier: "",
      address: "",
      succesMessage: false,
      succesMessage2: false,
      seeAddress: false,
      connectedUser: "",
      deletedAddress : '',
    };
  },
  mounted() {
    this.connectedUser = JSON.parse(localStorage.getItem("connectedUser"));
    this.$store.dispatch("getAddresses", this.connectedUser.id_user);
  },
  computed: {
    //STORE
    ...mapState({
      allAddresses: "allAddresses",
    }),
  },
  methods: {
    // SUBMIT FORM ------------------------------------------------
    submitMethod() {
      this.saveAddress();
    },
    saveAddress() {
      // FETCH
      this.fetchPostAddress()
        .then((data) => {
          this.succesMessage = true;
          setTimeout(
            function() {
              this.cleanSuccesMessage();
            }.bind(this),
            1000
          );
          this.$refs.theForm.reset();
          if (data["data"]) {
            //actualiser la liste allUsers du store après inscription
            let connectedUser = JSON.parse(
              localStorage.getItem("connectedUser")
            );
            this.$store.dispatch("getAddresses", connectedUser.id_user);
          }
        })
        .catch((e) => console.log(e));
    },
    // INSCRIPTION ------------------------------------------------
    async fetchPostAddress() {
      if (!(this.supplier === "") && !(this.address === "")) {
        if (this.address.includes("'") || this.address.includes('"')) {
          this.address = this.address.replace("'", "\\'");
          this.address = this.address.replace('"', '\\"');
          //console.log(this.address);
        }
        this.supplier = this.capitalizeFirstLetter(this.supplier);
        
        let key;
      //key for only web app native request (!postman)
      if (
        (this.connectedUser.a5e789410fd.includes("2f+g2dfg25h2d+f15b1gs5bnj1ki1lo61q1dc16"))
      ) {
        key = "h1d5he15fdhs";
      }

        const requestOptions = {
          method: "POST",
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            supplier: this.supplier,
            address: this.address,
            user_id: this.connectedUser.id_user,
            data_feeder : this.connectedUser.username,
            key:key
          }),
        };

        let response = await fetch(
          appPath+"/admin_order/addresses",
          requestOptions
        );
        if (!response.ok) {
          alert("La requête a échouée");
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          //console.log('not response ok, error : ' + error);
          return Promise.reject(error);
        }
        return await response.json();
      } //si un champ est resté vide on ne passe pas dans fetch
      else {
        alert("veuillez remplir tous les champs");
      }
    },
    deleteAddress(entity){
      this.deletedAddress = entity;
      this.succesMessage2 = true;
      this.fetchDeleteAddress(entity)
        .then((data) => {
          setTimeout(
            function() {
              this.succesMessage2 = false;
            }.bind(this),
            800
          );
          if (data["data"]) {
            //actualiser la liste 
            let connectedUser = JSON.parse(
              localStorage.getItem("connectedUser")
            );
            this.$store.dispatch("getAddresses", connectedUser.id_user);
          }
        })
        .catch((e) => console.log(e));
    },
     async fetchDeleteAddress(entity) {
        let key;
      //key for only web app native request (!postman)
      if (
        (this.connectedUser.a5e789410fd.includes("2f+g2dfg25h2d+f15b1gs5bnj1ki1lo61q1dc16"))
      ) {
        key = "h1d5he15fdhs";
      }

        const requestOptions = {
          method: "DELETE",
          headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
                    },
          body: JSON.stringify({
            entity: entity,
            user_id: this.connectedUser.id_user,
            key:key
          }),
        };

        let response = await fetch(
          appPath+"/admin_order/delete_address",
          requestOptions
        );
        if (!response.ok) {
          alert("La requête a échouée");
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          //console.log('not response ok, error : ' + error);
          return Promise.reject(error);
        }
        return await response.json();
       //si un champ est resté vide on ne passe pas dans fetch
    },
    capitalizeFirstLetter(string) {
      //console.log(typeof string);
      if (typeof string === "string") {
        const str = string.toLowerCase();
        return str.charAt(0).toUpperCase() + str.slice(1);
      } else {
        return string;
      }
    },
    cleanSuccesMessage() {
      this.succesMessage = false;
    },
    showAndScroll(){
    this.seeAddress = !this.seeAddress;
    if(this.seeAddress ===true){
    const tRef = document.getElementById('main');
    //console.log(tRef.clientHeight);
    setTimeout(() => {
      window.scrollTo({
        top: tRef.clientHeight,
        behavior: "smooth",
      });
    }, 500);
    }},
  },
};
</script>

<style scoped>
.address__container {
  max-width: 100%;
  width: 540px;
  height: 430px;
  font-size: 20px;
  margin-top: 50px;
  background: #0d444b;
  border-radius: 16px;
  padding: 32px;
   -webkit-box-shadow: 1px 2px 5px 1px #000000; 
  box-shadow: 2px 2px 7px 1px #000000;
}
.address-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.address__title {
  text-align: center;
  margin-top: 0;
  font-weight: 800;
  color: #ee821a;
}
.address-card {
  border: black 1px solid;
  margin: 10px 0 10px 0;
  width: 400px;
}
.btn {
  width: 200px;
  height: 80px;
  margin-top: 20px;
  margin-bottom: 250px;
  font-size: 20px;
  background-color: #0d444b;
  color: #ee821a;
}

.button {
  /*color: black; */
  border: 4px transparent solid;
  border-radius: 8px;
  font-weight: 700;
  font-size: 22px;
  width: 100%;
  padding: 10px;
  color: #ee821a;
  cursor: pointer;
  transition: all .2s ease-in-out;
}
.button:active {
  transform: scale(1.1);
	transition: .1s;
}
.button:hover {
  background-color: white;
}

.form-row {
  display: flex;
  margin: 16px 0px;
  gap: 16px;
  flex-wrap: wrap;
}

.form-row__input {
  padding: 8px;
  border: none;
  border-radius: 8px;
  background: #f2f2f2;
  font-weight: 500;
  font-size: 25px;
  flex: 1;
  min-width: 100px;
  color: black;
}

.error-message {
  display: none;
  color: black;
}

.relative {
  position: relative;
}

.addressImg {
  font-size: 40px;
  margin-left: 5px;
}
.succes-message {
  background-color: greenyellow;
  width: 100%;
  height: 50px;
  font-size: 25px;
  line-height: 50px;
  z-index : 10;
}
.growSucces-enter-active {
  animation: grow 1s;
}
.growSucces-leave-active {
  animation: grow 1.5s reverse;
}
@keyframes grow {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  80% {
    opacity: 1;
    transform: scale(1);
  }
}
.addresses-info {
  color : white;
  font-size : 15px;
}
.fa-trash-container{
  margin-bottom : 5px;
}

@media screen and (max-width: 1100px) {
  .address__container {
    max-width: 350px;
    width: 300px;
    height: 350px;
    font-size: 15px;
    padding: 16px;
  }
  .address-card {
  border: black 1px solid;
  margin: 10px 0 10px 0;
  width: 260px;
}
  .button {
    font-size: 15px;
  }
  .form-row__input {
    font-size: 17px;
  }
}
</style>
