<template>
  <div class="seestock-container">
    <span v-if="succes03" class="succes-message">Confirmation...</span>
    <span v-if="succes04" class=" booking-message succes-message">Réservation...</span>
    <span v-if="succes05" class="return-message succes-message">Retour au dépôt...</span>
    <span v-if="error01" class="error-message succes-message">Echec de la commande...</span>
    <!--button class="type-button testbutton" @click="fetchTest()">FETCH TEST</button!-->
   <!--  <h2>itemInbasket :</h2>
    {{ itemInBasket }}
    <br/>
    {{errorRef}}
    <br />
    <h2>orderBasket :</h2>
    {{ orderBasket }}
    <br />
    <h2>orderInProgress :</h2>
    {{ orderInProgress }}
    <br />
    <h2>returnInProgress :</h2>
    {{ returnInProgress }}
    <h2>return mode</h2>
    {{ returnMode }} !-->
    <!-- ORDER MODE !-->
    <div v-if="!returnMode" class="seestock-sub-container">
      <div class="large-button-container">
      <div class="button-container">
      <button v-for="location in locationList" :key="location"
        class="location-button"
        :style="
          itemLocation === location
            ? { 'background-color': '#f38414' }
            : { opacity: '0.3' }
        "
        @click="itemLocationFn(location)"
      >
        {{location}}
      </button>
      
    </div>
       <!-- afficher, vider, modifier!-->
      <div class="button-container2">
        <button
          v-if="!showDetails"
          :disabled="
            chooseOrder ||
              Object.values(itemInBasket).length == 0 ||
              nothingToShow
          "
          class="button orange"
          @click="showDetails = true"
        >
          Afficher les détails
        </button>

        <button
          v-else
          :disabled="chooseOrder"
          class="button orange"
          @click="showDetails = false"
        >
          Masquer les détails
        </button>

        <button
          :disabled="chooseOrder"
          class="button"
          @click="cleanLocalStorage()"
        >
          Vider mon panier
        </button>

        <button
          v-if="!isReady"
          :disabled="chooseOrder"
          class="button"
          @click="isReady = true"
        >
          Confirmer
        </button>

        <button
          v-else
          class="button"
          :disabled="
            chooseOrder ||
              Object.values(itemInBasket).length == 0 ||
              nothingToShow
          "
          @click="isReady = false"
        >
          Modifier
        </button>
      </div>
      </div>

      <!-- validation ou annuler !-->
      <button
        v-if="!chooseOrder"
        class="button"
        :disabled="Object.values(itemInBasket).length == 0 || nothingToShow"
        @click="switchBtn('valid')"
      >
        VALIDATION
      </button>
      <button v-else class="button" @click="switchBtn('no-valid')">
        ANNULER
      </button>

    <!-- reserver ou prendre !-->
      <div v-if="chooseOrder">
        <p>
          Je prends ce matériel
          <button
            class="button"
            @click="confirmOrder('Withdraw', itemLocation)"
          >
            Retirer
          </button>
        </p>
        <p>
          Je vais passer le prendre plus tard
          <button class="button" @click="confirmOrder('Book', itemLocation)">
            Reserver
          </button>
        </p>
      </div>

    <!-- details du panier !-->
      <div v-if="(Object.values(itemInBasket).length != 0) && (!nothingToShow)">
        <div
          class="item"
          :style="
            showDetails
              ? { 'grid-template-rows': '1fr 1fr' }
              : { 'grid-template-rows': '1fr ' }
          "
          v-for="(items, index) in itemInBasket[itemLocation]"
          :key="items"
          :index="index"
          :ref="'item' + index.toString()"
        >
          
          <i
            v-if="showDetails && !isReady"
            class="fas fa-trash-alt"
            @click="trashItem(items, itemLocation, index)"
          ></i>
          <img v-else :src="items['image'] || defaultImg" />

          <h3>
            {{ items["item"] }}
          </h3>

          <div class="mini-grid">
            <button
              class="qtty"
              id="qtty-"
              :ref="'-' + index"
              :disabled="isReady"
              @click="modifyQtty('-', index, items)"
            >
              -
            </button>
            <button
              class="qtty"
              id="qtty2"
              :ref="'+' + index"
              :disabled="isReady"
              @click="modifyQtty('+', index, items)"
            >
              +
            </button>
          </div>

          <div class="mini-grid">
            <!-- dynamic value succès !-->
            <input
              v-if="orderInProgress[items['ref'] + '+' + items['item_storage']]"
              :value="calculateValue(items, index)"
              min="0"
              :max="maxArrow(items)"
              :ref="index.toString()"
              class="number"
              type="number"
              placeholder="0"
              :disabled="isReady"
              :style="{ backgroundColor: items['color'] }"
              @focusout="qttyLimit(index, items)"
            />

            <input
              v-else
              value="0"
              min="0"
              :max="items['qtty_max']"
              :ref="index.toString()"
              class="number"
              type="number"
              placeholder="0"
              :disabled="isReady"
              @focusout="qttyLimit(index, items)"
            />
          </div>

          <span v-show="showDetails">
            <span> Palier : {{ items["out_packaging"] }} </span>
            <label class="switch">
              <input :ref="'Step' + index" type="checkbox" />
              <span class="slider round"></span>
            </label>
          </span>

          <span v-if="showDetails">
            Stock :
            {{ items["qtty_in"] }}
          </span>
          <span v-if="showDetails">
            Dispo :
            {{ items["qtty_available"] }}
          </span>
          <span v-if="showDetails">
            Max :
            {{ items["qtty_max"] }}
          </span>
        </div>
      </div>
      <span v-else>Rien dans le panier ...</span>
      <br/>
      <button class="help-btn" @click="helpMessage = !helpMessage">
    Aide ?
    </button>
    <p v-if="helpMessage">Si vous rencontrez un problème pour passer commande,
     il se peut qu'un objet dans votre panier ne soit pas reconnu, il a pu être modifié, supprimé,
     ou commandé depuis votre dernière actualisation du stock.
     Si le problème persiste, videz votre panier et actualisez le stock.
      </p>
    </div>
    <!--  RETURN MODE !-->
    <div v-else class="seestock-sub-container">

    <div class="infos">
            <label class="returnSpan" >Rendre à</label>
            <select v-model="itemLocation" name="type" required>
              <option value="">-- Choisir --</option>
              <option v-for="loc in locationList" :key="loc" :value="loc">{{loc}}</option>
            </select>
          </div>
      
      <!-- confirmer, modifier, vider mon panier !-->
      <div class="button-container2">
        <button
          v-if="!isReady"
          :disabled="chooseOrder"
          class="button"
          @click="isReady = true"
        >
          Confirmer
        </button>

        <button
          v-else
          class="button"
          :disabled="
            chooseOrder ||
              Object.values(itemInBasket).length == 0 ||
              nothingToShow
          "
          @click="isReady = false"
        >
          Modifier
        </button>

        <button
          :disabled="chooseOrder"
          class="button"
          @click="cleanLocalStorage()"
        >
          Vider mon panier
        </button>
      </div>
      <!-- validation ou annuler !-->
      <button
        v-if="!chooseOrder"
        class="button"
        :disabled="Object.values(itemInBasket).length == 0 || nothingToShow"
        @click="switchBtn('valid')"
      >
        VALIDATION
      </button>
      <button v-else class="button" @click="switchBtn('no-valid')">
        ANNULER
      </button>

      <!-- rendre un produit bouton !-->
      <div v-if="chooseOrder">
        <p>
          Je remets ces produits en stock
          <button class="button" @click="confirmOrder('Return', itemLocation)">
            Rendre du matériel
          </button>
        </p>
      </div>
      <!-- details du panier retour !-->
      <div v-if="Object.values(itemInBasket).length != 0 && !nothingToShow">
        <div v-for="loc in locationList" :key="loc">
          <div
            class="return item"
            :style="
              showDetails
                ? { 'grid-template-rows': '1fr 1fr' }
                : { 'grid-template-rows': '1fr ' }
            "
            v-for="(items, index) in itemInBasket[loc]"
            :key="items"
            :index="index"
          >
            
            <i
              v-if="showDetails && !isReady"
              class="fas fa-trash-alt"
              @click="trashItem(items, loc, index)"
            ></i>
            <img 
            v-else :src="items['image'] || defaultImg" />

            <h3>
              {{ items["item"] }}
            </h3>

            <div class="mini-grid">
              <button
                class="qtty"
                id="qtty-"
                :ref="'-' + index"
                :disabled="isReady"
                @click="modifyQttyForReturn('-', index, items, loc)"
              >
                -
              </button>
              <button
                class="qtty"
                id="qtty2"
                :ref="'+' + index"
                :disabled="isReady"
                @click="modifyQttyForReturn('+', index, items, loc)"
              >
                +
              </button>
            </div>

            <div class="mini-grid">
              <!-- dynamic value succès !-->
              <input
                v-if="
                  returnInProgress[items['ref'] + '+' + items['item_storage']]
                "
                :value="
                  returnInProgress[items['ref'] + '+' + items['item_storage']]
                    .quantity
                "
                min="0"
                :ref="index.toString()"
                class="number"
                type="number"
                placeholder="0"
                :disabled="isReady"
                @focusout="qttyLimitForReturn(index, loc, items)"
              />

              <input
                v-else
                value="0"
                min="0"
                :max="items['qtty_max']"
                :ref="index.toString()"
                class="number"
                type="number"
                placeholder="0"
                :disabled="isReady"
                @focusout="qttyLimitForReturn(index, loc, items)"
              />
            </div>
          </div>
        </div>
      </div>
      <span v-else>Rien dans le panier ...</span>
    </div>

    <button id="goTop" @click="toTheTop()">
      <i class="fas fa-arrow-up"></i>
    </button>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "BasketMain",
  data() {
    return {
      orderInProgress: new Object(),
      returnInProgress: new Object(),
      orderBasket: new Object(),
      connectedUser: {},
      defaultImg: require("../../assets/photo.png"),
      qtty: 0,
      itemLocation: "",
      locationList: [],
      showDetails: false,
      isReady: true,
      chooseOrder: false,
      returnMode: false,
      nothingToShow: false,
      memoriseLimitTools: {},
      verifiedLimitTools: {},
      helpMessage : false,
    };
  },
  mounted() {
    this.$store.commit("isConnected");
    // récuperer l'utilisateur actuel
    this.connectedUser = JSON.parse(localStorage.getItem("connectedUser"));

    //récupérer la liste des dépôts
    this.$store.dispatch("getAllLocations", this.connectedUser.id_user);

     //----------gestion du useMode-------------
    const originalUsername = this.connectedUser.username;
    var connectedUser = this.connectedUser;

    const userMode = JSON.parse(localStorage.getItem('usingMode'));
    const userModeId  = JSON.parse(localStorage.getItem('usingModeI'));
    const userModeUsername = localStorage.getItem('usingModeU');
    if(userMode && userMode === true && userModeId && userModeUsername){
      if(!this.connectedUser.username.includes('USER&MODE')){
      connectedUser.username  = originalUsername + 'USER&MODE' + userModeUsername + '^&^'+userModeId;
      localStorage.setItem('connectedUser',JSON.stringify(connectedUser));
      this.connectedUser = JSON.parse(localStorage.getItem("connectedUser"));}
    }
    //----------END gestion du useMode-------------

    // mode retour ou commande
    if (JSON.parse(localStorage.getItem("returnMode"))) {
      this.returnMode = JSON.parse(localStorage.getItem("returnMode"));
      if (this.returnMode === false) {
        if (JSON.parse(localStorage.getItem("orderInProgress"))) {
          this.orderInProgress = JSON.parse(
            localStorage.getItem("orderInProgress")
          );
        }
      } else {
        if (JSON.parse(localStorage.getItem("returnInProgress"))) {
          this.returnInProgress = JSON.parse(
            localStorage.getItem("returnInProgress")
          );
        }
      }
    } else {
      this.orderInProgress = JSON.parse(
        localStorage.getItem("orderInProgress")
      );
    }

    if(this.returnMode){
      this.itemLocation = "";
    }
  },

  beforeUnmount() {
    localStorage.removeItem("memoriseLoc");
  },
  computed: {
    //STORE
    ...mapState({
      itemInBasket: "itemInBasket",
      succes03: "succes03",
      succes04: "succes04",
      succes05: "succes05",
      error01 : "error01",
      errorRef : "errorRef",
      basketResponse: "basketResponse",
      allLocations : "allLocations"
    }),
  },

  watch: {
   /* creer des bugs
   itemLocation(newCount, oldCount) {
      if(newCount) {
      if (!this.returnMode) {
        // utiliser noting to show pour attendre que le contenu du panier soit récupéré
        if (this.itemInBasket[newCount]) {
          this.nothingToShow = false;
        } else {
          this.nothingToShow = true;
        }
      }}
    },*/
    allLocations(newCount, oldCount) {
      if(newCount) {
        this.locationList = newCount;
        this.itemLocation = newCount[0];
        // GET POUR LES ITEMS DANS LE PANIER UNIQUEMENT
    this.basketItems();
      }
      },
    //est actualisé depuis le store après une requete sur le contenu du panier
    basketResponse(newCount, oldCount) {
      if (newCount === true) {
        if(!this.returnMode){
        if (JSON.parse(localStorage.getItem("memoriseLoc"))) {
          this.itemLocation = JSON.parse(localStorage.getItem("memoriseLoc"));
          //console.log(this.itemLocation);
        } else {
          this.itemLocation = this.locationList[0];
        }
      }}
    },
    succes03(newCount, oldCount) {
      if (newCount === true) {
        localStorage.removeItem("orderInProgress");
        localStorage.removeItem("returnInProgress");
        this.orderInProgress = {};
        this.returnInProgress = {};
        this.$store.state.itemInBasket = {};
        this.chooseOrder = false;
      }
    },
    succes04(newCount, oldCount) {
      if (newCount === true) {
        localStorage.removeItem("orderInProgress");
        localStorage.removeItem("returnInProgress");
        this.orderInProgress = {};
        this.returnInProgress = {};
        this.$store.state.itemInBasket = {};
        this.chooseOrder = false;
      }
    },
    succes05(newCount, oldCount) {
      if (newCount === true) {
        localStorage.removeItem("orderInProgress");
        localStorage.removeItem("returnInProgress");
        this.orderInProgress = {};
        this.returnInProgress = {};
        this.$store.state.itemInBasket = {};
        this.chooseOrder = false;
      }
    },
    errorRef(newCount, oldCount) {
      if (newCount != 'error') {
        //console.log('errorRef');
        //console.log(newCount);
        if(this.returnMode){
           const keys = Object.keys(this.returnInProgress);
          for (let i in keys){
            if(keys[i].includes(newCount)){
              delete this.returnInProgress[keys[i]];
            }
          }
        localStorage.setItem(
            "returnInProgress",
            JSON.stringify(this.returnInProgress)
          );
        }
        else{
 const keys = Object.keys(this.orderInProgress);
          for (let i in keys){
            if(keys[i].includes(newCount)){
              delete this.orderInProgress[keys[i]];
            }
          }
        localStorage.setItem(
            "orderInProgress",
            JSON.stringify(this.orderInProgress)
          );
        }
       
        location.reload();
      }
    },
    error01(newCount, oldCount) {
      if (newCount === true) {
        //console.log('error');
        this.$store.state.itemInBasket = {};
        //console.log(this.itemInBasket);
        this.basketItems();
        
      }
      },
      //ici le watcher ne semble pas fonctionner correctement
      itemInBasket(newCount, oldCount){
        if(newCount){
        //console.log(Object.values(newCount).length);
        setTimeout(() => {
          if(Object.values(newCount).length != 0){
            this.compareLengthObject();
          };
        }, 2000);
        }
      }
  },

  methods: {
    /*Calcule si les valeurs du localStorage concorde avec la BDD 
    très important dans le cas d'une utilisation détemporalisé */
    calculateValue(items, index) {
      //valeur venant du localStorage
      var qtty = this.orderInProgress[
        items["ref"] + "+" + items["item_storage"]
      ].quantity;
      //console.log(ref);
      //console.log('checkBox');
      //console.log(checkBox);
      //console.log(checkBox.checked);
      const qtty_in = this.itemInBasket[this.itemLocation][index].qtty_in;
      const qtty_available = this.itemInBasket[this.itemLocation][index]
        .qtty_available;
      const qtty_max = this.itemInBasket[this.itemLocation][index].qtty_max;

      if (
        qtty > qtty_in ||
        qtty > qtty_available ||
        (qtty > qtty_max && qtty_max != 0)
      ) {
        //ref.value = 0;
        var sortArray = [qtty_in, qtty_available, qtty_max];
        sortArray.sort(function(a, b) {
          return a - b;
        });
        //console.log(sortArray);
        // Impossible de changer le style depuis une propriété calculé comme cela
        alert(
          "les quantités souhaitées ne sont plus disponibles, ref : " +
            this.itemInBasket[this.itemLocation][index].ref + ' /' + this.itemInBasket[this.itemLocation][index].item);
        
        if (this.itemInBasket[this.itemLocation][index].qtty_in) {
          this.itemInBasket[this.itemLocation][index].color = "green";
          setTimeout(() => {
            delete this.itemInBasket[this.itemLocation][index].color;
          }, 8000);
        }

        this.addMultiplesItems(items, sortArray[0], index);
      } else {
        this.addMultiplesItems(items, qtty, index);
      }
      if (
        this.orderInProgress[items["ref"] + "+" + items["item_storage"]] &&
        this.orderInProgress[items["ref"] + "+" + items["item_storage"]]
          .quantity
      ) {
        return this.orderInProgress[items["ref"] + "+" + items["item_storage"]]
          .quantity;
      } else {
        return 0;
      }
    },
    //STORE REQUEST action = order or return
    confirmOrder(action, location) {
      //console.log(action);
      //console.log(location);
      var index = this.locationList.indexOf(location);
      //mettre à jour orderBasket, 1 précise d'ajouter les quantités
      if (action != "Return") {
        this.orderInProgressToOrderBasket(index, 1);
      } else {
        if(this.itemLocation === ""){
        alert('Veuilez choisir un lieu de retour ! ');
        return
      }
        this.returnInProgressToOrderBasket(index, 1);
      }
      //console.log(this.orderBasket);
      //console.log("SEND ORDER");
      this.$store.dispatch("userOrder", {
        username: this.connectedUser.username,
        action: action,
        user_id: this.connectedUser.id_user,
        orderBasket: this.orderBasket,
        limitTool: this.verifiedLimitTools,
      });

      //console.log("REINIT ORDERBASKET");
      this.orderBasket = {};
    },
    /* Créer, structurer OrderInBasket à partir de OrderInProgress puis
    STORE REQUEST GET POUR LES ITEMS DANS LE PANIER UNIQUEMENT */
    basketItems() {
      //enlever le message d'erreur si il est affiché et qu'on rafraichit les données
      setTimeout(() => {
        this.$store.state.error01 = false;
      }, 2000);
      
      /* NOTE -EXPLICATION basketItems :
          on aurait pu garder la même logique que la page magasin,
          mais cela implique de requeter tout le stock (et de boucler dessus v-for), 
          on prefera requeter uniquement les objets
          présent dans le panier, et cela permet également une actualisation 
          des quantités entre les deux pages (magasin et panier).
          C'est pourquoi nous créeons orderBasket qui sera envoyé autant de fois
          qu'il y a d'entrepôts, quant à lui OrderInProgress ne sépare par les ref des locations*/
      if (Object.keys(this.connectedUser).length != 0) {
        //BOUCLER SUR CHAQUE ENTREPOT, pour envoyer une requete par entrepot
        //pour récupérer ce que contient notre panier (localStorage)
        for (let indx in this.locationList) {
          //console.log(i);
          // order mode
          if (!this.returnMode) {
            //console.log("Order logic");
            //mettre à jour orderBasket, 0 est un code qui précise d'utiliser seulement les refs
            this.orderInProgressToOrderBasket(indx, 0);
            if (Object.keys(this.orderBasket).length != 0) {
              this.orderBasket["id"] = this.connectedUser.id_user;
              // envoi l'objet contenant une seule location
              this.$store.dispatch("getItemsInBasket", this.orderBasket);
            } else {
              // a causé des pblms, utilité non determinée ?
              //this.$store.state.itemInBasket = {};
            }
            // vide l'objet pour boucler sur une nouvelle location
            //console.log('COMPARE')
            //console.log(this.orderBasket)
            //console.log(this.orderInProgress)
            this.orderBasket = {};
          } 
          //return mode
          else {
            //console.log("Return logic");
            //mettre à jour orderBasket, 0 est un code qui précise d'utiliser seulement les refs
            this.returnInProgressToOrderBasket(indx, 0);
            if (Object.keys(this.orderBasket).length != 0) {
              this.orderBasket["id"] = this.connectedUser.id_user;
              // envoi l'objet contenant une seule location
              this.$store.dispatch("getItemsInBasket", this.orderBasket);
            } else {
              
              this.$store.state.itemInBasket = {};
            }
            // vide l'objet pour boucler sur une nouvelle location
            //console.log('COMPARE')
            //console.log(this.orderBasket)
            //console.log(this.orderInProgress)
            this.orderBasket = {};
          }
        }
      } else {
        alert("Accès bloqué, l'utilisateur n'est pas reconnu !");
      }
    },
    // double logique de structuration d'objet --> soit recup panier 0, soit commande 1
    orderInProgressToOrderBasket(indx, number) {
      // orderInProgress correspond au localStorage
      //1 ---> commande, avec quantités 0---> récupérer les objets du panier juste ref
      if (number === 1) {
        //cas avec outils hors délai
        if (JSON.parse(localStorage.getItem("memoriseLimitTools"))) {
          this.memoriseLimitTools = JSON.parse(
            localStorage.getItem("memoriseLimitTools")
          );
          const limitToolsArray = Object.keys(this.memoriseLimitTools);
          for (let i in this.orderInProgress) {
            // vérifer si la location est comprise dans l'objet [ref + '+' + 'location']
            if (i.includes(this.locationList[indx])) {
              var refName = i.split("+")[0];
              if (limitToolsArray.includes(refName)) {
                this.verifiedLimitTools[refName] = this.memoriseLimitTools[
                  refName
                ];
              }
              var qtty = this.orderInProgress[i].quantity;
              var thisArray = [refName, qtty];
              //constuire l'objet orderBasket de façon différente d'orderInProgress
              if (this.orderBasket[this.locationList[indx]]) {
                this.orderBasket[this.locationList[indx]].ref.push(thisArray);
              } else {
                this.orderBasket[this.locationList[indx]] = {
                  ref: [thisArray],
                };
              }
            }
          }
        }

        //cas normal
        else {
          for (let i in this.orderInProgress) {
            // vérifer si la location est comprise dans l'objet [ref + '+' + 'location']
            if (i.includes(this.locationList[indx])) {
              var refName = i.split("+")[0];

              var qtty = this.orderInProgress[i].quantity;
              var thisArray = [refName, qtty];
              //constuire l'objet orderBasket de façon différente d'orderInProgress
              if (this.orderBasket[this.locationList[indx]]) {
                this.orderBasket[this.locationList[indx]].ref.push(thisArray);
              } else {
                this.orderBasket[this.locationList[indx]] = {
                  ref: [thisArray],
                };
              }
            }
          }
        }
      } else {
        for (let i in this.orderInProgress) {
          // vérifer si la location est comprise dans l'objet [ref + '+' + 'location']
          if (i.includes(this.locationList[indx])) {
            var refName = i.split("+")[0];
            //console.log(this.orderInProgress[i]);
            //constuire l'objet orderBasket de façon différente d'orderInProgress
            if (this.orderBasket[this.locationList[indx]]) {
              this.orderBasket[this.locationList[indx]].ref.push(refName);
            } else {
              this.orderBasket[this.locationList[indx]] = {
                ref: [refName],
              };
            }
          }
        }
      }
    },
    // double logique de structuration d'objet --> soit recup retour 0, soit retour 1
    returnInProgressToOrderBasket(indx, number) {
      //requete UPDATE (ORDER)
      if (number === 1) {
        
        for (let i in this.returnInProgress) {
          // vérifer si la location est comprise dans l'objet [ref + '+' + 'location']
          var refName = i.split("+")[0];
          var qtty = this.returnInProgress[i].quantity;
          var thisArray = [refName, qtty];
          //constuire l'objet orderBasket de façon différente d'orderInProgress
          if (this.orderBasket[this.itemLocation]) {
            this.orderBasket[this.itemLocation].ref.push(thisArray);
          } else {
            this.orderBasket[this.itemLocation] = {
              ref: [thisArray],
            };
          }
        }
      }
      //requete SELECT (DOM)
      else {
        for (let i in this.returnInProgress) {
          if (i.includes(this.locationList[indx])) {
            var refName = i.split("+")[0];
            //console.log(this.orderInProgress[i]);
            //constuire l'objet orderBasket de façon différente d'orderInProgress
            if (this.orderBasket[this.locationList[indx]]) {
              this.orderBasket[this.locationList[indx]].ref.push(refName);
            } else {
              this.orderBasket[this.locationList[indx]] = {
                ref: [refName],
              };
            }
          }
        }
      }
    },
    //TRASH ITEM localStorage (valable pour retour ou commande)
    trashItem(item, location, index) {
      //console.log("trash");
      //console.log(item);
      //console.log(location);
      //console.log(index);
      if (!this.returnMode) {
        if (JSON.parse(localStorage.getItem("orderInProgress"))) {
          delete this.orderInProgress[item["ref"] + "+" + item["item_storage"]];
          localStorage.setItem(
            "orderInProgress",
            JSON.stringify(this.orderInProgress)
          );
          //this.itemInBasket[location].splice(index, 1); fonctionne aussi...
          this.$store.state.itemInBasket[location].splice(index, 1);
          var array = this.itemInBasket[location];
          //console.log(array);
          if (array.length === 0) {
            delete this.$store.state.itemInBasket[location];
          }
          //pas nécéssaire de faire un dispatch
          //this.$store.dispatch('trashItemInBasket', mirorItemInBasket);
        }
      } else {
        if (JSON.parse(localStorage.getItem("returnInProgress"))) {
          delete this.returnInProgress[
            item["ref"] + "+" + item["item_storage"]
          ];
          localStorage.setItem(
            "returnInProgress",
            JSON.stringify(this.returnInProgress)
          );
          //this.itemInBasket[location].splice(index, 1); fonctionne aussi...
          this.$store.state.itemInBasket[location].splice(index, 1);
          var array = this.itemInBasket[location];
          //console.log(array);
          if (array.length === 0) {
            delete this.$store.state.itemInBasket[location];
          }
          //pas nécéssaire de faire un dispatch
          //this.$store.dispatch('trashItemInBasket', mirorItemInBasket);
        }
      }
    },
    itemLocationFn(location) {
      if (!this.returnMode) {
        // utiliser noting to show pour attendre que le contenu du panier soit récupéré
        if (this.itemInBasket[location]) {
          this.nothingToShow = false;
        } else {
          this.nothingToShow = true;
        }
      }
      this.itemLocation = location;
    },
    //fonction de filtre et controle pour l'input quantité, reliée au local storage
    qttyLimit(index, items) {
      var ref = this.$refs[index];
      var checkBox = this.$refs["Step" + index];
      //qtty --> ancienne valeur, pas celle de l'input par l'utilisateur
      var qtty = this.orderInProgress[
        items["ref"] + "+" + items["item_storage"]
      ].quantity;
      //console.log('checkBox');
      //console.log(checkBox);
      //console.log(checkBox.checked);
      let thisBoolean;
      const qtty_in = this.itemInBasket[this.itemLocation][index].qtty_in;
      const qtty_available = this.itemInBasket[this.itemLocation][index]
        .qtty_available;
      const qtty_max = this.itemInBasket[this.itemLocation][index].qtty_max;
      //check if max =0;
      if (qtty_max != 0) {
        if (ref.value > qtty_max) {
          thisBoolean = true;
        } else {
          thisBoolean = false;
        }
      } else {
        thisBoolean = false;
      }

      if (
        ref.value > qtty_in ||
        ref.value > qtty_available ||
        thisBoolean ||
        (!checkBox.checked &&
          ref.value %
            this.itemInBasket[this.itemLocation][index].out_packaging !=
            0)
      ) {
        var sortArray = [qtty, qtty_in, qtty_available, qtty_max];
        sortArray.sort(function(a, b) {
          return a - b;
        });
        //console.log(sortArray);
        ref.value = sortArray[0];
        ref.style.backgroundColor = "red";
        setTimeout(() => {
          this.addMultiplesItems(items, sortArray[0], index);
          ref.style.backgroundColor = "white";
        }, 700);
      } else {
        this.addMultiplesItems(items, ref.value, index);
      }
    },
    qttyLimitForReturn(index, location, items) {
      var ref = this.$refs[index];
      //console.log('checkBox');
      //console.log(checkBox);
      //console.log(checkBox.checked);
      const UNIQUE_KEY = items["ref"] + "+" + items["item_storage"];
      if (this.returnInProgress[UNIQUE_KEY]) {
        this.returnInProgress[UNIQUE_KEY].quantity = parseInt(ref.value);
      } else {
        this.returnInProgress[UNIQUE_KEY] = {
          quantity: parseInt(ref.value),
        };
      }
      if (this.returnInProgress[UNIQUE_KEY].quantity === 0) {
        this.trashItem(items, location, index);
      }
      localStorage.setItem(
        "returnInProgress",
        JSON.stringify(this.returnInProgress)
      );
    },
    // HANDLER FOR + & -
    modifyQtty(str, i, item) {
      let number;
      if (this.orderInProgress[item["ref"] + "+" + item["item_storage"]]) {
        number = this.orderInProgress[item["ref"] + "+" + item["item_storage"]]
          .quantity;
      } else {
        number = 0;
      }
      var buttonSous = this.$refs["-" + i];
      var buttonAdd = this.$refs["+" + i];
      var checkBox = this.$refs["Step" + i];
      number = parseInt(number);

      //-----AJOUT
      // !checkbox.checked === green
      if (str === "+" && !checkBox.checked) {
        if (
          number + item["out_packaging"] <= item["qtty_available"] &&
          number + item["out_packaging"] <= item["qtty_in"] &&
          (number + item["out_packaging"] <= item["qtty_max"] ||
            item["qtty_max"] == 0)
        ) {
          //console.log(number);
          this.addItemToLocalStorage(item,1);
          if (buttonSous.disabled === true) {
            buttonSous.disabled = false;
          }
        } else if (
          number === item["qtty_available"] ||
          number === item["qtty_max"] ||
          number + item["out_packaging"] > item["qtty_max"] ||
          number + item["out_packaging"] > item["qtty_available"]
        ) {
          buttonAdd.disabled = true;
          buttonSous.disabled = false;
        } else {
          //console.log("out condition");
        }
      }
      else if (str === "+" && checkBox.checked) {
        if (
          number + 1 <= item["qtty_available"] &&
          number + 1 <= item["qtty_in"] &&
          (number + 1 <= item["qtty_max"] ||
            item["qtty_max"] == 0)
        ) {
          //console.log(number);
          this.addItemToLocalStorage(item,0);
          if (buttonSous.disabled === true) {
            buttonSous.disabled = false;
          }
        } else if (
          number === item["qtty_available"] ||
          number === item["qtty_max"] ||
          number + 1 > item["qtty_max"] ||
          number + 1 > item["qtty_available"]
        ) {
          buttonAdd.disabled = true;
          buttonSous.disabled = false;
        } else {
          //console.log("out condition");
        }
      }

      //------SOUSTRACTION
      else if (str === "-" && !checkBox.checked) {
        //console.log(number);
        if (number - 1 >= 0) {
          //console.log(number);
          this.takeOffItemFromLocalStorage(item, i,1);
          if (buttonAdd.disabled === true) {
            buttonAdd.disabled = false;
          }
        } else if (number - 1 < 0) {
          buttonSous.disabled = true;
          buttonAdd.disabled = false;
        }
      }
      else if (str === "-" && checkBox.checked) {
        //console.log(number);
        if (number - 1 >= 0) {
          //console.log(number);
          this.takeOffItemFromLocalStorage(item, i,0);
          if (buttonAdd.disabled === true) {
            buttonAdd.disabled = false;
          }
        } else if (number - 1 < 0) {
          buttonSous.disabled = true;
          buttonAdd.disabled = false;
        }
      }
    },
    modifyQttyForReturn(str, i, item, location) {
      //console.log(str);
      //console.log(this.$refs['consumable'+i]);
      let number;
      const UNIQUE_KEY = item["ref"] + "+" + item["item_storage"];
      if (this.returnInProgress[UNIQUE_KEY]) {
        number = this.returnInProgress[UNIQUE_KEY].quantity;
      } else {
        number = 0;
      }
      //console.log(typeof number);
      var buttonSous = this.$refs["-" + i];
      var buttonAdd = this.$refs["+" + i];
      number = parseInt(number);

      //-----AJOUT
      if (str === "+") {
        const UNIQUE_KEY = item["ref"] + "+" + item["item_storage"];
        if (this.returnInProgress[UNIQUE_KEY]) {
          this.returnInProgress[UNIQUE_KEY].quantity += item["out_packaging"];
        } else {
          this.returnInProgress[UNIQUE_KEY] = {
            quantity: item["out_packaging"],
          };
        }
        localStorage.setItem(
          "returnInProgress",
          JSON.stringify(this.returnInProgress)
        );

        if (buttonSous.disabled === true) {
          buttonSous.disabled = false;
        }
      }
      //------SOUSTRACTION
      else if (str === "-") {
        //console.log(number);
        if (number - 1 >= 0) {
          //console.log(number);
          const UNIQUE_KEY = item["ref"] + "+" + item["item_storage"];
          if (this.returnInProgress[UNIQUE_KEY]) {
            if (
              this.returnInProgress[UNIQUE_KEY].quantity >=
                item["out_packaging"] &&
              this.returnInProgress[UNIQUE_KEY].quantity != 0
            ) {
              this.returnInProgress[UNIQUE_KEY].quantity -=
                item["out_packaging"];
              if (this.returnInProgress[UNIQUE_KEY].quantity === 0) {
                this.trashItem(item, location, i);
              }
            } else {
              return;
            }
          } else {
            return;
          }
          localStorage.setItem(
            "returnInProgress",
            JSON.stringify(this.returnInProgress)
          );
          //this.$refs[type+i].value = number;
          if (buttonAdd.disabled === true) {
            buttonAdd.disabled = false;
          }
        } else if (number - 1 < 0) {
          buttonSous.disabled = true;
          buttonAdd.disabled = false;
        }
      }
    },
    // ajout via function modifyQtty 
    addItemToLocalStorage(item,bool) {
      // bool 1 palier ok, bool 0 palier désactivé
      if(bool){
      if (this.orderInProgress[item["ref"] + "+" + item["item_storage"]]) {
        this.orderInProgress[
          item["ref"] + "+" + item["item_storage"]
        ].quantity += item["out_packaging"];
      } else {
        this.orderInProgress[item["ref"] + "+" + item["item_storage"]] = {
          quantity: item["out_packaging"],
        };
      }
      localStorage.setItem(
        "orderInProgress",
        JSON.stringify(this.orderInProgress)
      );}
      else{
        if (this.orderInProgress[item["ref"] + "+" + item["item_storage"]]) {
        this.orderInProgress[
          item["ref"] + "+" + item["item_storage"]
        ].quantity += 1;
      } else {
        this.orderInProgress[item["ref"] + "+" + item["item_storage"]] = {
          quantity: 1,
        };
      }
      localStorage.setItem(
        "orderInProgress",
        JSON.stringify(this.orderInProgress)
      );}
    },
    // retrait via function modifyQtty
    takeOffItemFromLocalStorage(item, index,bool) {
      if(bool){
      if (this.orderInProgress[item["ref"] + "+" + item["item_storage"]]) {
        if (
          this.orderInProgress[item["ref"] + "+" + item["item_storage"]]
            .quantity >= item["out_packaging"] &&
          this.orderInProgress[item["ref"] + "+" + item["item_storage"]]
            .quantity != 0
        ) {
          this.orderInProgress[
            item["ref"] + "+" + item["item_storage"]
          ].quantity -= item["out_packaging"];
          if (
            this.orderInProgress[item["ref"] + "+" + item["item_storage"]]
              .quantity === 0
          ) {
            this.trashItem(item, this.itemLocation, index);
          }
        } else {
          return;
        }
      } else {
        return;
      }
      localStorage.setItem(
        "orderInProgress",
        JSON.stringify(this.orderInProgress)
      );
      }
      else{
        if (this.orderInProgress[item["ref"] + "+" + item["item_storage"]]) {
        if (
          this.orderInProgress[item["ref"] + "+" + item["item_storage"]]
            .quantity >= 1 &&
          this.orderInProgress[item["ref"] + "+" + item["item_storage"]]
            .quantity != 0
        ) {
          this.orderInProgress[
            item["ref"] + "+" + item["item_storage"]
          ].quantity -= 1;
          if (
            this.orderInProgress[item["ref"] + "+" + item["item_storage"]]
              .quantity === 0
          ) {
            this.trashItem(item, this.itemLocation, index);
          }
        } else {
          return;
        }
      } else {
        return;
      }
      localStorage.setItem(
        "orderInProgress",
        JSON.stringify(this.orderInProgress)
      );

      }
    },
    // ajout/modif directement via input
    addMultiplesItems(item, value, index) {
      //console.log(item);
      //console.log(value);
      //console.log(index);
      if (this.orderInProgress[item["ref"] + "+" + item["item_storage"]]) {
        this.orderInProgress[
          item["ref"] + "+" + item["item_storage"]
        ].quantity = parseInt(value);
      } else {
        this.orderInProgress[item["ref"] + "+" + item["item_storage"]] = {
          quantity: parseInt(value),
        };
      }
      if (
        this.orderInProgress[item["ref"] + "+" + item["item_storage"]]
          .quantity === 0
      ) {
        this.trashItem(item, this.itemLocation, index);
      }
      localStorage.setItem(
        "orderInProgress",
        JSON.stringify(this.orderInProgress)
      );
    },
    //définir dynamiquement le max sur un input
    maxArrow(item) {
      var lowNumber = Math.min(
        item["qtty_in"],
        item["qtty_max"],
        item["qtty_available"]
      );
      return lowNumber;
    },
    cleanLocalStorage() {
      localStorage.removeItem("orderInProgress");
      localStorage.removeItem("returnInProgress");
      location.reload();
    },
        // Scroll to top
    toTheTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    //check if fresh data from db as same length of inital order, 
    //if not, delete the item where qtty 0 in localStorage
    compareLengthObject(){
      var theSet = new Set();
      var mess = false;
      var refs = [];
      //console.log(Object.keys(this.itemInBasket));
      //console.log(Object.keys(this.itemInBasket)[0]);
      const location = Object.keys(this.itemInBasket)[0];
      const items = this.itemInBasket[location];
      //console.log(items);
      //console.log(Object.values(items).length);
      //console.log(Object.values(this.orderInProgress).length);
      if(Object.values(items).length != Object.values(this.orderInProgress).length){
        for(let i in items){
          theSet.add(items[i].ref);
        }
        const array = Object.keys(this.orderInProgress);
        //console.log(array);
        for(let i in array){
          if(!theSet.has(array[i].split('+')[0])){
            mess = true;
            refs.push(array[i].split('+')[0]);
            delete this.orderInProgress[array[i]];
            localStorage.setItem(
            "orderInProgress",
            JSON.stringify(this.orderInProgress)
          );
          }
        }
        if(mess){
         alert('certains produits ne sont plus disponibles, références : ' +refs);
        }}
      },
      switchBtn(action){
        if(action === 'valid'){
          this.isReady = true ;
          this.chooseOrder = true;
        }
        else {
          this.isReady = false ;
          this.chooseOrder = false;
        }
      }

  },
};
</script>

<style lang="scss" scoped>
.seestock-container {
  width: 800px;
  border : 1px solid black;
  display: flex;
  flex-direction: column;
}
.large-button-container{
  display: flex;
flex-wrap : wrap;
justify-content : center;
}
.item {
  background-color: #0d444b;
  color: white;
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  margin-bottom: 10px;
}
img {
  border: black 2px dashed;
  max-width: 40px;
  max-height: 40px;
  justify-self: center;
}
h3 {
  font-size: 15px;
}
.return {
  background-color: red;
}

.location-button {
  max-width: 300px;
  height: 70px;
  margin: 10px 20px;
  font-size: 20px;
}
.button-container {
  display: flex;
  width: 100%;
  flex-wrap : wrap;
  justify-content:center;
}
.button-container2 {
  display: flex;
  
  width: 350px;
  justify-content: space-evenly;
}

.qtty {
  width: 32px;
  height: 32px;
  font-size: 23px;
  background-color: #f38414;
}
.number {
  height: 35px;
  width: 70px;
  font-size: 22px;
}
.number:disabled {
  background-color: initial;
  color: white;
}

#goTop {
  position: fixed;
  background-color: black;
  right: 100px;
  bottom: 50px;
  width: 50px;
  height: 50px;
}
.fa-arrow-up {
  font-size: 30px;
  color: white;
}
.button {
  margin: 15px;
  background-color : #0d444b;
  color : white;
  padding : 5px;
}
.orange{
background-color : #f38414;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
}
//enelver les fleches sur input
input[type=number] {
    -moz-appearance: textfield;
}
//--------BOUTON ON OFF
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  margin-left: 20px;
  width: 40px;
  height: 20px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(183, 250, 170);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 3px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #ccc;
}

input:focus + .slider {
  box-shadow: 0 0 1px #ccc;
}

input:checked + .slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.succes-message {
  width: 300px;
  height: 100px;
  border: 2px rgb(23, 189, 23) solid;
  font-size: 25px;
  font-weight: bold;
  border-radius: 5px;
  color: rgb(23, 189, 23);
  background-color: rgb(255, 255, 255);
  position: fixed;
  top: 30%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
  z-index : 10;
  line-height: 100px;
}
.error-message {
  border: 2px red solid;
  color: red;
  padding : 5px;
  font-size : 22px;
  background-color: white;
}
.booking-message{
  //type of violet
  color : rgb(193, 143, 240);
  border-color : rgb(193, 143, 240);
}
.return-message{
  //type of violet
  color : black;
  border-color : red;
}
.returnSpan{
  margin: 10px;
  padding : 5px;
  color : black;
  background-color : #f38414;
  }
  .help-btn {
    margin : 10px;
  }
    .fa, .fas{
    cursor: pointer;
  }
  
@media screen and (max-width: 1100px) {
  input,select {
    background-color: white;
  }
  .seestock-container {
    width: 300px;
    border : none;
  }
  .button-container {
  width: auto;
}
.large-button-container{
  display: block;
}
  .qtty {
    width: 32px;
    height: 32px;
    font-size: 23px;
  }
  .number {
    height: 35px;
    width: 55px;
    font-size: 22px;
  }
  span {
    font-size: 16px;
    margin: 3px;
  }
  .mini-grid {
    display: flex;
    justify-content: space-around;
  }
  img {
    border: black 2px dashed;
    max-width: 40px;
    max-height: 40px;
    justify-self: center;
  }
  .fa-trash-alt {
    border: white 1px solid;
    width: 18px;
    padding: 10px;
    justify-self: center;
  }
  
  h3 {
    font-size: 15px;
  }
  .location-button {
    max-width: 120px;
    height: 30px;
    font-size: 20px;
  }

  #goTop {
    left: 0px;
    bottom: 10px;
    width: 28px;
    height: 50px;
    padding : 0;
  }
  .fa-arrow-up {
    font-size: 18px;
  }
  //--------BOUTON ON OFF
  /* The switch - the box around the slider */
  .switch {
    margin-left: 0px;
    height: 17px;
  }
  .slider:before {
    height: 9px;
    width: 9px;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(21px);
    -ms-transform: translateX(21px);
    transform: translateX(21px);
  }
}
</style>
