<template>
  <div class="btn__container">
    <h2 class="box__title">
        Récupérer les sauvegardes
      </h2>

    <div  class="flex">
      <button @click="getDbExport('1')" class="button">
        Exporter Stocks </button>
        <button @click="getDbExport('2')" class="button">
        Exporter Activtés </button>
      <div >
      </div>
    </div>
    
  </div>
</template>

<script>
import { mapState } from "vuex";

const myAppPath = require('../../../switchUrl');
const appPath = myAppPath.appPath;


export default {
  name: "ExportDb",
  data: function() {
    return {
      succesMessage: false,
      succesMessage2: false,
      connectedUser: "",
    };
  },
  mounted() {
    this.connectedUser = JSON.parse(localStorage.getItem("connectedUser"));
    //this.getDbExport();
  },
  computed: {
    //STORE
    ...mapState({
      
    }),
  },
  methods: {
    getDbExport(numb) {
      this.fetchGetDbExport(numb)
          .then( res => res.blob() )
  .then( blob => {
    //methode sans le nom personnalisé
    /*var file = window.URL.createObjectURL(blob);
    //window.location.assign(file);*/
 
    var file = new Blob([blob], { type: 'application/octet-stream' });
    if(file.size == 0){
      alert('Requete impossible, erreur du serveur ou fichier vide');
      return
    }
    var fileURL = URL.createObjectURL(file);

    // create <a> tag dinamically
    var fileLink = document.createElement('a');
    fileLink.href = fileURL;

    // it forces the name of the downloaded file
    let nameDb;
    if(numb === '1'){
      nameDb = 'db_stock'
    }
    else if(numb === '2'){
      nameDb = 'db_activité'
    }
    fileLink.download = nameDb;

    // triggers the click event
    fileLink.click();
  })
        .catch((e) => {console.log(e);
        alert('requête échouée/non authorisée');});
    },
    async fetchGetDbExport(numb) {
      let response = await fetch(appPath+"/items/get_dump/" + numb +'&&d5f8e7s6d4s_'+ this.connectedUser.id_user, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      });
      if (!response.ok) {
        // get error message from body or default to response status
        const error = (data && data.message) || response.status;
        //console.log('not response ok, error : ' + error);
        alert("une erreur innattendue s'est produite");
        return Promise.reject(error);
      }
      //return await response.json();
      return await response;
    },
    cleanSuccesMessage() {
      this.succesMessage = false;
    },
  
  },
};
</script>

<style scoped>
.btn__container {
  max-width: 100%;
  width: 540px;
  font-size: 20px;
  margin-top: 40px;
  background: #0d444b;
  border-radius: 16px;
  padding: 32px;
   -webkit-box-shadow: 1px 2px 5px 1px #000000; 
  box-shadow: 2px 2px 7px 1px #000000;
  margin-bottom: 50px;
}
.flex {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.button {
  /*color: black; */
  border: 4px transparent solid;
  border-radius: 8px;
  font-weight: 700;
  font-size: 22px;
  width: 100%;
  padding: 10px;
  margin: 10px;
  color: #ee821a;
  cursor: pointer;
  transition: all .2s ease-in-out;
}
.button:active {
  transform: scale(1.1);
	transition: .1s;
}
.button:hover {
  background-color: white;
}
.box__title {
   text-align: center;
  margin-top: 0;
  font-weight: 800;
  color: #ee821a;
}
@media screen and (max-width: 1100px) {
  .btn__container {
    max-width: 350px;
    width: 300px;
    font-size: 15px;
    padding: 16px;
  }
  .button {
    font-size: 15px;
  }
}
</style>

