<template lang="">
    <div>
        <h1>NOT FOUND </h1>
        <p class="security-info"> Cette page n'existe pas
        </p>
        <p><router-link to="/" >Retrouner à la connexion</router-link></p>
    </div>
</template>

<script>
export default {
    
}
</script>

<style scoped>
    .security-info{
  font-size : 22px;
  margin : 30px;
}
</style>