<template>
<div>
<h2>Création</h2>
  <form
    action="javascript:void(0)"
    @submit="createItem($event)"
    class="create-item-container"
    ref="create-item-container"
  >
    <h3>Créer un produit</h3>

    <div class="flex">
      <label>Catégorie de produit :</label>
      <select v-model="type_" @change="cleanForm()" name="type" required>
        <option value="">--Choisir une option--</option>
        <option value="consumable">Consommable</option>
        <option value="tool">Outil</option>
        <option value="cable">Cable</option>
        <option value="client">Client</option>
      </select>
    </div>

    <div class="flex">
      <label>Désignation :</label>
      <input v-model="item" placeholder="" required />
    </div>

    <div class="flex">
      <label>Référence interne :</label>
      <span v-if="type_ === 'cable' && newCableRef != 'none'">Dérnière Ref créée : {{newCableRef}}</span>
      <span v-else-if="type_ === 'cable'"  >automatique</span>
      <input v-else v-model="ref" placeholder="" required />
    </div>

    <div class="flex">
      <label>Dépôt :</label>
      <button @click="switchSelect = !switchSelect"> 
        <span v-if="switchSelect"> 	&#9997;</span>
        <span v-else>&#128196;</span>
      </button>
      <select  v-if="switchSelect" v-model="item_storage" name="type" required>
        <option value="">--Choisir une option--</option>
        <option v-for="loc in locationList" :key="loc" :value="loc">{{loc}}</option>
      </select>
      <input v-else type="text" v-model="item_storage" required>
      
    </div>

    <div class="flex">
      <label>Sous catégorie de produit :</label>
      <select class="selectSub" 
      :size="size" @focus="onFocusSelect(1)" @blur="onBlurSelect(1)"
      placeholder="" @change="subtypeSelected($event)"
      :style="[firstStyle ? {'color' : 'black', 'background-color' : 'white'}:{}]">
        <option>--Sous-catégories existantes--</option>
        <option v-for="choice in subtypeSelector" :key="choice"
        >{{choice}}</option>
      </select>
      <input v-model="sub_type" placeholder="" required />
    </div>

    <div class="flex">
      <label v-if="type_ === 'consumable' || type_ === 'tool'"
        >Nom Fournisseur :</label
      >
      <label v-else>Nom Client :</label>
       <select class="selectSub" placeholder="" 
       :size="size2" @focus="onFocusSelect(0)" @blur="onBlurSelect(0)"
       @change="supplierSelected($event)"
      :style="[firstStyle2 ? {'color' : 'black', 'background-color' : 'white'}:{}]">
        <option>--Liste--</option>
        <option v-for="choice in supplierSet" :key="choice"
        >{{choice}}</option>
      </select>
      <input v-model="supplier" placeholder="" required />
    </div>

    <div class="flex" v-if="type_ === 'consumable' || type_ === 'tool'">
      <label>Fournisseur par défaut :</label>
      <input v-model="def_supplier" placeholder="" required />
    </div>
    <div class="flex">
      <label>Référence fournisseur :</label>
      <input v-model="ref_supplier" placeholder="" required />
    </div>

    <div class="flex" v-if="type_ === 'consumable' || type_ === 'tool'">
      <label>Tarif (unité ttc) :</label>
      <input
        v-model="price"
        class="number"
        pattern="[0-9]"
        type="number"
        min="0"
        placeholder="0"
        step="0.01"
        @keydown="checkChars"
        required
      />
    </div>

    <div class="flex img-picker">
      <label>Image :</label>
      <button v-if="!showExistingImg" class="btn" type="button" @click="showExistingImg = true">Choisir une image pré-existante </button>
      <button v-else-if="type_ === '' && showExistingImg" type="button" >Vous devez choisir une catégorie </button> 
      <button v-else-if="showExistingImg" type="button" @click="showExistingImg = false">Masquer </button>  
      <button class="refresh-img" type="button" @click="getAllImages">&#128472;</button>
      <!--<span>(conseillé, évite les doublons sur le serveur)</span>!-->
      
      <input v-if="!newUrl" type="file" accept="image/*" @change="addImg" :disabled="showExistingImg"/>
      <input
        v-else
        type="button"
        id="removeImg"
        value="Retirer"
        @click="removeImg"
      />
      <img v-if="newUrl" :src="newUrl" />
    </div>

    <div class="img-container" v-if="!newUrl && showExistingImg" >
        <div v-for="img,index in allImagesByTypes" :key="img" :index="index" >
           <img :src="img" alt="" @click="setUrl(index)" :ref="'img'+index"></div>
        </div>

    <div class="flex">
      <label>Stock Min :</label>
      <input
        v-model="qtty_min"
        class="number"
        type="number"
        min="1"
        placeholder="1"
        @keydown="checkChars"
        required
      />
    </div>

    <div class="flex" v-if="type_ === 'consumable' || type_ === 'tool'">
      <label>Conditionnement d'entrée (fournisseur) : </label>
      <input
        v-model="packaging"
        class="number"
        type="number"
        min="1"
        placeholder="1"
        @keydown="checkChars"
        required
      />
    </div>

    <div v-if="type_ != 'cable'" class="flex">
      <label>Conditionnement de sortie : </label>
      <input
        v-model="out_packaging"
        class="number"
        type="number"
        min="1"
        placeholder="1"
        @keydown="checkChars"
        required
      />
    </div>

    <div v-if="type_ === 'consumable' || type_ === 'tool'" class="flex">
      <label>Sortie Max :</label>
      <input
        v-model="qtty_max"
        class="number"
        type="number"
        min="1"
        placeholder="1"
        @keydown="checkChars"
        required
      />
    </div>

    <div v-if="type_ === 'tool'" class="flex">
      <label>Périodicité de l'outillage :</label>
      <input
        v-model="period"
        class="number"
        type="number"
        min="0"
        placeholder="0"
        @keydown="checkChars"
        required
      />
    </div>

    <div v-if="type_ === 'cable'" class="flex">
      <label>Métré fin :</label>
      <input
        v-model="m_end"
        class="number"
        type="number"
        min="0"
        placeholder="0"
        @keydown="checkChars"
        required
      />
      <label>Métré début :</label>
      <input
        v-model="m_start"
        class="number"
        type="number"
        min="0"
        placeholder="0"
        @keydown="checkChars"
        required
      />
      
    </div>

    <div v-else class="flex">
      <label>Quantité :</label>
      <input
        v-model="qtty_in"
        class="number"
        type="number"
        min="0"
        placeholder="0"
        @keydown="checkChars"
        required
      />
    </div>

    <div>
      <input
        type="submit"
        class="submit"
        value="Valider"
      />

      <p class="succes-message" v-if="isCreated">Produit créé !</p>
    </div>
  </form>
  </div>
</template>

<script>

import { mapState } from "vuex";
const myAppPath = require('./../../../switchUrl');
const appPath = myAppPath.appPath;
// item with image
class Item {
  constructor(
    ref,
    ref_supplier,
    item,
    image,
    price,
    supplier,
    def_supplier,
    qtty_min,
    qtty_max,
    packaging,
    out_packaging,
    type_,
    sub_type,
    item_storage,
    period,
    meter,
    qtty_in,
    qtty_available,
    username,
    data_feeder
  ) {
    this.ref = ref;
    this.ref_supplier = ref_supplier;
    this.item = item;
    this.image = image;
    this.price = price;
    this.supplier = supplier;
    this.def_supplier = def_supplier;
    this.qtty_min = qtty_min;
    this.qtty_max = qtty_max;
    this.packaging = packaging;
    this.out_packaging = out_packaging;
    this.item_storage = item_storage;
    this.type_ = type_;
    this.sub_type = sub_type;
    this.period = period;
    this.meter = meter;
    this.qtty_in = qtty_in;
    this.qtty_available = qtty_available;
    this.username = username;
    this.data_feeder = data_feeder;
  }
}

export default {
  name: "CreateItem",
  data() {
    return {
      ref: "",
      ref_supplier: "",
      item: "",
      image: "",
      newUrl: "",
      price: "",
      supplier: "",
      def_supplier: "",
      qtty_min: 1,
      qtty_max: 1,
      packaging: 1,
      out_packaging: 1,
      type_: "",
      item_storage: "",
      sub_type: "",
      period: "0",
      meter: {},
      m_start: "",
      m_end: "",
      qtty_in: "",
      qtty_available: "",
      data_feeder: "",
      connectedUser: {},
      isCreated: false,
      invalidChars: ["-", "+", "e"],
      showExistingImg : false,
      waitingForAutoRef : false,
      newCableRef : 'none',
      subtypeSelector : [],
      firstStyle : true,
      firstStyle2 : true,
      size :1,
      size2 :1,
      locationList : [],
      switchSelect : true
    };
  },
  mounted() {
    // récuperer l'utilisateur actuel
    this.connectedUser = JSON.parse(localStorage.getItem("connectedUser"));

    if(this.allLocations.length !=0){    
    this.locationList = this.allLocations;}

    this.getAllImages();
  },
    computed: {
    //STORE
    ...mapState({
      allImages : "allImages",
      itemConsumables: "itemConsumablesAndDetails",
      itemTools: "itemToolsAndDetails",
      itemCables: "itemCablesAndDetails",
      itemClients: "itemClientsAndDetails",
      allLocations : "allLocations"
    }),

    supplierSet(){
      try{
        var thisSet = new Set();
        if(Object.values(this.itemConsumables).length != 0){
        this.itemConsumables.forEach(element => {
          thisSet.add(element.supplier);
        });}
        if(Object.values(this.itemTools).length != 0){
        this.itemTools.forEach(element => {
          thisSet.add(element.supplier);
        });}
        if(Object.values(this.itemCables).length != 0){
        this.itemCables.forEach(element => {
          thisSet.add(element.supplier);
        });}
        if(Object.values(this.itemClients).length != 0){
        this.itemClients.forEach(element => {
          thisSet.add(element.supplier);
        });}
        return thisSet;
        } catch(error) {
    console.log(error);
        }
      },
    

    allImagesByTypes(){
      //var obj = [];
      var theSet = new Set();
      this.allImages.forEach(element => {
        //console.log(element);
        if(element.type_ === this.type_){
          if(element.image != null){
            theSet.add(element.image);
          //obj.push(element.image)
          }
        }
      });
      //fonctionne, ne retourne pas deux source identiques
    return theSet
}
  },
  watch: {
    type_(newCount, oldCount) {
      if (newCount) {
        var thisSet = new Set();
        if(newCount === "consumable"){
          //console.log(this.itemConsumables);
          this.itemConsumables.forEach(element => {
            thisSet.add(element.sub_type)
          });
          this.subtypeSelector = thisSet;
          //console.log(thisSet);
        }
        else if (newCount === "tool"){
          this.itemTools.forEach(element => {
            thisSet.add(element.sub_type)
          });
          this.subtypeSelector = thisSet;
        }
        else if (newCount === "cable"){
          this.itemCables.forEach(element => {
            thisSet.add(element.sub_type)
          });
          this.subtypeSelector = thisSet;
        }
        else if (newCount === "client"){
          this.itemClients.forEach(element => {
            thisSet.add(element.sub_type)
          });
          this.subtypeSelector = thisSet;
        }
        
      }
    },
    allLocations(newCount, oldCount) {
      if(newCount) {
        //alert(newCount);
        this.locationList = newCount;
      }
    },
    },
  methods: {
    onFocusSelect(bool){
      if(bool){
      this.size = 4;
      return}
      this.size2 = 4;
    },
    onBlurSelect(bool){
      if(bool){
      this.size = 1;
      return}
      this.size2 = 1;
    },
    subtypeSelected(event) {
      this.sub_type = event.target.value;
      if(this.firstStyle === true){
        this.firstStyle = false
      }
      this.size = 1;
      event.target.blur();
      },
      supplierSelected(event) {
      this.supplier = event.target.value;
      if(this.firstStyle2 === true){
        this.firstStyle2 = false
      }
      this.size2 = 1;
      event.target.blur();
      },
      
    //controler les carractères selon une liste exclusive
    checkChars(e) {
      if (this.invalidChars.includes(e.key)) {
        e.preventDefault();
      }
    },
    // gérer la casse des string
    capitalizeFirstLetter(string) {
      //console.log(typeof string);
      if (typeof string === "string") {
        const str = string.toLowerCase();
        return str.charAt(0).toUpperCase() + str.slice(1);
      } else {
        return string;
      }
    },
    addImg(e) {
      const file = e.target.files[0];
      this.image = file;
      this.newUrl = URL.createObjectURL(file);
    },
    removeImg() {
      this.newUrl = null;
      this.image = null;
    },
    createItem() {
      this.fetchCreateItem()
        .then((data) => {
          //console.log(data);
          //récupérer la liste des dépôts
    this.$store.dispatch("getAllLocations", this.connectedUser.id_user);
          // rafraichir les données
          if (data["data"]) {
            if(data["data"].includes('ERREUR')){
              alert(data['data']);
              return
            };
            //récupérer la ref si c'est un cable
            if(this.waitingForAutoRef){
              //console.log(data['data'][1]);
              this.newCableRef = data['data'][1];
            };
            this.waitingForAutoRef = false;
            this.isCreated = true;
            //this.cleanForm();
            setTimeout(() => {
              this.isCreated = false;
            }, 2000);
          } else {
            alert("Création impossible");
          }
        })
        .catch((e) => console.log(e));
    },
    async fetchCreateItem() {
      //Gérer les données si c'est un Câble
      if (this.type_ === "cable" && this.m_start && this.m_end) {
        this.waitingForAutoRef = true;
        this.ref = '';
        this.meter = {
          start: this.m_start,
          end: this.m_end,
        };
        this.meter = JSON.stringify(this.meter);
        const max = Math.max(this.m_start, this.m_end);
        const min = Math.min(this.m_start, this.m_end);
        
        this.qtty_in = max - min;
        this.qtty_max = this.qtty_in;
        this.price = 0;
        this.out_packaging = 1;
        this.packaging = 0;
        this.qtty_max = 0;
        this.def_supplier = "";
      } 
      else {
        // chgt effectué ne sait plus si 0 important this.meter = 0;
        this.meter = null;
      }
      //Gérer les données si c'est un Client
      if (this.type_ === "client") {
        this.price = 0;
        this.packaging = 0;
        this.qtty_max = 0;
        this.def_supplier = "";
      }
      //Gérer la periodicité si ça n'est pas un outil
      if (this.type_ != "tool") {
        this.period = 0;
      }
      // vérifier les données indispensables sont présentes
      if (
        !(this.ref === "" && this.type_ !='cable') &&
        !(this.ref_supplier === "") &&
        !(this.item === "") &&
        !(this.price === "") &&
        !(this.packaging === "") &&
        !(this.type_ === "") &&
        !(this.qtty_in === "") &&
        !(this.item_storage === "") &&
        Object.keys(this.connectedUser).length != 0
      ) {
        //console.log("données :", this.image, this.user.id_user);
        this.qtty_available = this.qtty_in;
        this.data_feeder = this.connectedUser.username;
        //console.log(this.image);

        const CREATE_ITEM = new Item(
          this.ref,
          this.ref_supplier,
          this.item,
          this.image,
          this.price,
          this.supplier,
          this.def_supplier,
          this.qtty_min,
          this.qtty_max,
          this.packaging,
          this.out_packaging,
          this.type_,
          this.sub_type,
          this.item_storage,
          this.period,
          this.meter,
          this.qtty_in,
          this.qtty_available,
          this.data_feeder+' from_console',
          this.data_feeder+' from_console'
        );

        //gérer la casse
        for (var key in CREATE_ITEM) {

          // gérer la casse des Str
          if (this.type_ === 'cable' && typeof CREATE_ITEM[key] === "string" && key === "item") {
            CREATE_ITEM[key] = (CREATE_ITEM[key].trim()).toUpperCase();
          }
          else if (
            typeof CREATE_ITEM[key] === "string" &&
            key != "type_" && key != "image"
          ) {
            CREATE_ITEM[key] = this.capitalizeFirstLetter(CREATE_ITEM[key].trim());
          }
          else if (typeof CREATE_ITEM[key] === "string" &&
            key === "type_"){
              CREATE_ITEM[key] = CREATE_ITEM[key].trim();
            }
        }

        let pathForCreate;
        const formData = new FormData();
        let requestOptions;

        //vérifier la provenance de l'image
        //console.log(typeof this.image);
        //console.log(this.image);
        
        // no form data
        if(this.image != null && this.image != undefined && this.image != '' && typeof this.image === 'string'){
          pathForCreate = "/items/create_item2";
        let key0;
      //key for only web app native request (!postman)
      if (
        (this.connectedUser.a5e789410fd.includes("2f+g2dfg25h2d+f15b1gs5bnj1ki1lo61q1dc16"))
      ) {
        key0 = "h1d5he15fdhs";
          }
        
        requestOptions = {
          method: "POST",
          headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
        body: JSON.stringify({
          user_id: this.connectedUser.id_user,
          key0: key0,
          ref : CREATE_ITEM['ref'],
          ref_supplier : CREATE_ITEM['ref_supplier'],
          item : CREATE_ITEM['item'],
          image : CREATE_ITEM['image'],
          price : CREATE_ITEM['price'],
          supplier : CREATE_ITEM['supplier'],
          def_supplier : CREATE_ITEM['def_supplier'],
          qtty_min: CREATE_ITEM['qtty_min'],
          qtty_max : CREATE_ITEM['qtty_max'],
          packaging: CREATE_ITEM['packaging'],
          out_packaging: CREATE_ITEM['out_packaging'],
          type_: CREATE_ITEM['type_'],
          sub_type: CREATE_ITEM['sub_type'],
          item_storage: CREATE_ITEM['item_storage'],
          period: CREATE_ITEM['period'],
          meter: CREATE_ITEM['meter'],
          qtty_in: CREATE_ITEM['qtty_in'],
          qtty_available: CREATE_ITEM['qtty_available'],
          username: CREATE_ITEM['username'],
          data_feeder: CREATE_ITEM['data_feeder']

        }),
        };
        }
        //formdata
        else {
          pathForCreate = "/items/create_item";

        //console.log(CREATE_ITEM);
        //ajouter les donnnées au formData
        for (var key in CREATE_ITEM) {
          formData.append(key, CREATE_ITEM[key]);
        };

        formData.append("user_id", this.connectedUser.id_user);
        let key0;
      //key for only web app native request (!postman)
      if (
        (this.connectedUser.a5e789410fd.includes("2f+g2dfg25h2d+f15b1gs5bnj1ki1lo61q1dc16"))
      ) {
        key0 = "h1d5he15fdhs";
          }
        formData.append("key0", key0);
        
        requestOptions = {
          method: "POST",
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
          body: formData,
        };
        }

        let response = await fetch(
          appPath+pathForCreate,
          requestOptions
        );
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          //console.log('not response ok, error : ' + error);
          alert("une erreur innattendue s'est produite");
          return Promise.reject(error);
        }
        return await response.json();
      }
      //si un champ est resté vide on ne passe pas dans fetch
      else {
        alert("Veuillez remplir tous les champs");
      }
    },
    //reinitialiser le formulaire
    cleanForm() {
      this.ref = "";
      this.ref_supplier = "";
      this.item = "";
      this.image = "";
      this.newUrl = "";
      this.price = "";
      this.supplier = "";
      this.def_supplier = "";
      this.qtty_min = 1;
      this.qtty_max = 1;
      this.packaging = 1;
      this.out_packaging = 1;
      this.item_storage = "";
      this.sub_type = "";
      this.period = "0";
      this.meter = {};
      this.m_start = "";
      this.m_end = "";
      this.qtty_in = "";
      this.qtty_available = "";
      this.data_feeder = "";
    },
    getAllImages(){
      if (Object.keys(this.connectedUser).length != 0) {
        this.$store.dispatch(
          "getAllImages",
          this.connectedUser.id_user
        );
      } else {
        alert("Accès bloqué, l'utilisateur n'est pas reconnu !");
      }
    },
    setUrl(index){
      //console.log(index);
      //console.log(this.$refs['img'+index]);
      const pat = this.$refs['img'+index];
      //console.log(pat.src);
      this.newUrl = pat.src;
      this.image = pat.src;
    },
  },
};
</script>

<style scoped>
.create-item-container {
  display: flex;
  border: 2px solid black;
  background-color: #0d444b;
  color: white;
  flex-direction: column;
  align-items: center;
  width: 800px;
  /*margin-top: 50px;*/
  margin: auto;
  margin-bottom: 120px;
  -webkit-box-shadow: 5px 5px 15px 5px black; 
  box-shadow: 5px 5px 15px 5px black;
}
.flex {
  display: flex;
  width: 600px;
  justify-content: space-between;
  margin-bottom: 20px;
}
.selectSub {
  color: transparent;
  background-color: transparent;
}
.selectSub:focus {
  color: black;
  background-color: white;
}
.img-container{
  display: flex;
  flex-direction: row;
  max-width: 500px;
  max-height: 120px;
  overflow-x : scroll;
}
.img-container img {
width: 100px;
height: 100px;
cursor: pointer;
}
.img-container img:hover {
  border: 1px black solid;
}
.img-picker button {
max-height: 50px;
}
.refresh-img{
  font-size : 30px;
  overflow: hidden;
  background-color : #f38414;;
  color : #0d444b;
}
.btn{
  background-color : #f38414;;
  color : #0d444b;
}
label {
  text-align: left;
}
.number {
  width: 70px;
  font-size: 25px;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
}
input[type=file], /* FF, IE7+, chrome (except button) */
input[type=file]::-webkit-file-upload-button {
  /* chromes and blink button */
  cursor: pointer;
  background-color: #f38414;
  color : white;
  height: 40px;
}
#removeImg {
  width: 100px;
  height: 50px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
}
img {
  max-width: 300px;
}
.submit {
  background-color: #f38414;
  width: 200px;
  height: 50px;
  border: 5px white solid;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: bold;
  opacity: 0.8;
	transition: all .2s ease-in-out;
  cursor : pointer;
}
.submit:active {
	transform: scale(1.1);
	transition: .1s;
}
.submit:hover {
  opacity: 1;
}
.succes-message {
  width: 300px;
  height: 100px;
  border: 2px rgb(23, 189, 23) solid;
  font-size: 25px;
  font-weight: bold;
  border-radius: 5px;
  color: rgb(23, 189, 23);
  background-color: rgb(255, 255, 255);
  position: fixed;
  top: 80%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
  z-index : 2;
  line-height: 100px;
}
@media screen and (max-width: 1100px) {
  input {
    background-color: white;
  }
  .create-item-container {
    width: 320px;
    margin-top: 50px;
    margin-bottom: 120px;
  }
  .flex {
    width: 300px;
    flex-direction: column;
  }
  #removeImg {
    width: 70px;
    height: 40px;
    font-size: 15px;
  }
  img {
    max-width: 150px;
  }
.img-picker {
flex-wrap: wrap;
}
  .img-container{
  max-width: 200px;
  max-height: 70px;
}
.img-container img {
width: 70px;
height: 70px;
}
.img-picker button {
width: 150px;
margin-bottom : 20px;
}
.img-picker .refresh-img{
  width: 60px;
}
}
</style>
