<template>
    <div v-if="!documentEditing">
        <div class="activity-container">
            <h3>Gestionnaire d'activité</h3>
            <div class="activity-subcontainer">
                <div class="input-container">
            <label for="new-activity"> Créer une activitée</label>
            <div class="mini-container"><input name="new-activity" type="text"
            v-model="activity">
            <button class="valid-input" ref="newActivityBtn" @click="newActivity()"> + </button>
            <div ref="hourglass" class="hourglass"> ⌛ </div>
            </div>
            <!-- activé / desactivé mode suppression-->
            <button v-if="!deleteMode" @click="deleteMode = true">Mode suppression</button>
            <button v-else @click="deleteMode = false">Retour</button>
            </div>
            <div v-if="allActivities && Object.values(allActivities).length != 0" class="activity-card-container">
                <p class="activity-card" v-for="activity in allActivities" :key="activity">
                    {{activity.activity}} 
        <!-- supprimer une activité-->
        <i v-if="deleteMode" @click="deleteActivity(activity.activity)" class="fas fa-trash-alt">
            </i>
            </p></div>
            <span v-else> Rien à afficher</span>

            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from "vuex";

const myAppPath = require('./../../../switchUrl');
const appPath = myAppPath.appPath;

export default {
  props : ['documentEditing'],
    data() {
        return {
          connectedUser: {},  
          deleteMode : false,
          activity : '',
        }
    },
    mounted() {
    this.$store.commit("isConnected");
    // récuperer l'utilisateur actuel
    this.connectedUser = JSON.parse(localStorage.getItem("connectedUser")); 

    this.$store.dispatch('getAllActivities',this.connectedUser.id_user);
    },
    computed: {
    ...mapState({
      activityResponse : "activityResponse",
      allActivities : 'allActivities'
    })
    },
    watch : {
        activityResponse(newCount, oldCount) {
      if (newCount === true) {
        //alert("Echec de la commande");
        this.$refs['newActivityBtn'].disabled = false;
        this.$refs['hourglass'].style.display = 'none';
        this.$store.state.activityResponse = false;
      }}
    },
    methods: {
        newActivity(){
            if(this.activity === ''){
                alert('Activité, champ vide...');
                return;
            }
            this.$refs['newActivityBtn'].disabled = true;
            this.$refs['hourglass'].style.display = 'block';
            const obj = {
                newActivity : this.activity,
                user_id : this.connectedUser.id_user
                            }
            this.$store.dispatch("newActivity",obj );
            return
        },
        deleteActivity(str) {
            this.$refs['hourglass'].style.display = 'block';

      //fetching
      this.fetchDeleteActivity(str)
        .then((data) => {
          //result
          if (data["data"].affectedRows) {
              //console.log(data);
            }
          else {alert('Suppression non effective')}
          this.$store.dispatch('getAllActivities',this.connectedUser.id_user);
          this.$refs['hourglass'].style.display = 'none';
        })
        .catch((e) => console.log(e));
    },
    async fetchDeleteActivity(str) {
      let key;
      //key for only web app native request (!postman)
      if (
        (this.connectedUser.a5e789410fd.includes("2f+g2dfg25h2d+f15b1gs5bnj1ki1lo61q1dc16"))
      ) {
        key = "h1d5he15fdhs";
      }
      const requestOptions = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
        body: JSON.stringify({
          activity: str,
          user_id: this.connectedUser.id_user,
          key: key,
        }),
      };

      let response = await fetch(
        appPath+"/activities/delete_activity",
        requestOptions
      );
      if (!response.ok) {
        alert("Une erreur empêche la requête");
        //stop loader
        this.putLoader = false;
        // get error message from body or default to response status
        const error = (data && data.message) || response.status;
        //console.log('not response ok, error : ' + error);
        //console.log(error);
        return Promise.reject(error);
      }
      return await response.json();
    },
    },
}
</script>
<style scoped>
.activity-container{

}
h3{
    font-size: 22px;
}
.activity-subcontainer {
  display: flex;
  flex-direction: column;
  /*align-items: center;*/
  color: white;
  max-width: 100%;
  width: 1100px;
  height: auto;
  font-size: 20px;
  background: #0d444b;
  border-radius: 16px;
  padding: 32px;
   -webkit-box-shadow: 1px 2px 5px 1px #000000; 
  box-shadow: 2px 2px 7px 1px #000000;
}
.activity-card-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    overflow-y: scroll;
}
.activity-card {
    border: 2px solid #f38414;
    padding: 10px;
    margin: 5px;
    height: 25px;
    width: auto;
}
.activity-card i{
    cursor : pointer;
}
.input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.input-container label {
    padding: 5px 20px;
    background-color : #f38414;
     color : #0d444b;
     border-radius: 16px;
     margin : 5px 0 25px 0;
}
.mini-container {
    display: flex;
    margin-bottom: 10px;
}
.input-container input {
    width: 300px;
    height: 30px;
    margin: 5px;
    font-size: 20px;
    font-weight: 600;
    padding-top: 0;
    padding-bottom: 0;
}
.valid-input{
    width: 45px;
    background-color: #f38414;
    font-size: 30px;
    padding-top: 0;
    margin-left: 10px;
    padding-bottom: 0px;
}
.valid-input:hover{
    color: white;
}
.hourglass {
    font-size: 30px;
    margin-left: 20px;
    display: none;
}
.fa-trash-alt {
    margin-left: 20px;
    border: 1px solid white;
    padding: 5px;
    color : #f38414;
}

@media screen and (max-width: 1100px) {
  input,select {
    background-color: white;
  }
.activity-container{
    max-width: 340px;
}
.activity-subcontainer {
  max-width: 100%;
  width: 320px;
  max-height: 380px;
  font-size: 20px;
  border-radius: 16px;
  padding: 2px;
}
.input-container input {
    width: 200px;
}
.activity-card {
    padding: 5px;
    margin: 3px;
}
.fa-trash-alt {
    margin-left: 5px;
    padding: 2px;
}

}

</style>