<template>
  <div class="seestock-container">
    <span v-if="succes03" class="succes-message"> Confirmation...</span>
    <span v-if="succesDelete" class="delete-message succes-message"> Suppression...</span>
    <span v-if="succes02" class="booking-message succes-message"> Annulation...</span>
    <span v-if="succes01" class="return-message succes-message"> Retour au depôt...</span>

    <!--{{myOrdersList}}!-->

    <h2 v-if="Object.values(myReelsList).length != 0">
      TOURETS
    </h2>
    <button class="show-btn"
      v-if="Object.values(myReelsList).length != 0"
      @click="showReels = !showReels"
    >
      Afficher / Masquer
    </button>
    <!-- TOURETS RESERVE ET PRIS !-->
    <div
      class="reel-container"
      v-if="Object.values(myReelsList).length != 0 && showReels"
    >
      <div class="reel-card" v-for="reel in myReelsList" :key="reel" :ref="reel['ref']">
        <h3 class="h3-cable">
          {{ reel["item"] }}
        </h3>
        <div class="booking-reel-div" v-if="reel['period'] === 2">
          Reservation
          <button class="btn-little" @click="confirmBorrowReel(reel)">
            Retirer
          </button>

          <button class="btn-little" @click="cancelBookingReel(reel)">
            Annuler
          </button>
        </div>
        <button v-else class="btn-little" @click="returnReel(reel)"
        :style="isReturning != 'no' ?{width : '100px'}:{}">
          <div v-if="isReturning === 'no'">Rendre au dépôt</div>
          <div v-else>Retour</div>
        </button>

        <img
          class="img"
          v-if="isReturning !== reel['id']"
          :src="reel['image'] || defaultCableImg"
        />
        <div class="switchImg" v-else>
          <div class="infos">
            <label>Redéposé à</label>
            <select v-model="itemLocation" name="type" required>
              <option value="">-- Choisir --</option>
              <option v-for="loc in locationList" :key="loc" :value="loc">{{loc}}</option>
            </select>
          </div>
          <div class="infos">
            <label>Métré fin :</label>
            <span>{{JSON.parse(reel['meter']).end}}</span>
          </div>

          <div class="infos">
            <label>
              Métré début :</label>
            <input
              v-model="JSON.parse(reel['meter']).start"
              :ref="reel['id'].toString()"
              class="number"
              type="number"
              min="0"
              placeholder="0"
              @keydown="checkChars"
              @change="saveValue(reel)"
              required
            />
          </div>
          <div class="infos">
            <button class="black"
              :disabled="isEmpty === true || disableBtn === true"
              @click="confirmReturnReel(reel)"
            >
              VALIDER LE RETOUR
            </button>
          </div>
          <div class="infos">-------------- ou -------------</div>
          <div class="infos">
            <button v-if="isEmpty === false" class="black" @click="isEmpty = true">
              LE TOURET EST VIDE
            </button>
            <button v-if="isEmpty === true" @click="isEmpty = false">
              Annuler
            </button>
            <button v-if="isEmpty === true" @click="deleteReel(reel)">
              VIDE : Confirmer
            </button>
          </div>
        </div>
        <span class="ref-reel"> Réf : {{ reel["ref"] }}</span>

        <div class="qtty-meter">

        <span>
          Stock : 
          {{ reel["qtty_in"] }} m
        </span>
        <br />
        <span>
          Dispo : 
          {{ reel["qtty_available"] }} m
        </span>
        </div>
      </div>
    </div>


    <h2>RESERVATIONS</h2>
    <button class="show-btn" @click="showBookings = !showBookings">Afficher / Masquer</button>
    <button :disabled='seeBookingsDataExtended' class="show-btn" v-if="showBookings" @click="getBookingsDataExtended()">
      Voir anciennes reservations
    </button>
    <button :disabled='!seeBookingsDataExtended' class="show-btn" v-if="showBookings" @click="getBookings()">
      Reservations actuelles
    </button>
    <div
      class="booking-container"
      v-if="Object.values(myBookingsList).length != 0 && showBookings"
    >
    <!-- RESERVATION EN COURS !-->
      <div
        class="booking-card"
        v-for="(booking, index) in bookingNumbers"
        :key="index"
        :style="[
          infosByBookings[booking].status === 'En cours'
            ? { 'background-color': 'rgb(233, 212, 253)' }
            : {},
          infosByBookings[booking].status === 'Retirée'
            ? { 'background-color': 'rgb(202, 226, 202)' }
            : {},
        ]"
      >
        <h3 class='card-title'>
          {{ infosByBookings[booking].date }} /
          {{ infosByBookings[booking].location }}
        </h3>
       <span> {{ infosByBookings[booking].status }}</span>
        <p v-if="showNumber && connectedUser.id_user && booking">N°{{ connectedUser.id_user.toString() + booking.toString() }}</p>
        <button class="basic-btn" v-if="!showNumber" @click="showNumber = true">N°[...]</button>
        <button v-else @click="showNumber = false">↑</button>

        <div
          v-for="(value, innerIndex) in bookingsGrouped(booking)"
          :key="innerIndex"
        >
           <p> {{ value[2] }} x {{ value[1].toString().replace("-", "") }}
            <span v-if="value[3] === 'cable'">mètres
              <br>(réf :{{ value[0] }})</span>
          </p>
        </div>
        <div
          v-if="infosByBookings[booking].status === 'En cours'"
          class="button-container"
        >
          <button class="btn" v-if="!disableButton" @click="confirmBooking(booking)">
            Prendre
          </button>
          <button class="btn" v-if="!disableButton" @click="cancelBooking(booking)">
            Annuler
          </button>
        </div>
      </div>
    </div>
    <div v-else-if="Object.values(myBookingsList).length === 0 && seeBookingsDataExtended">
      Données introuvables ...
    </div>
    <div v-else-if="Object.values(myBookingsList).length === 0">
      Pas de réservations en cours ...
    </div>
    

    

    <h2>COMMANDES</h2>
    <button class="show-btn" @click="showOrders = !showOrders">Afficher / Masquer</button>
     <!-- DERNIERE COMMANDE - DE 1 MOIS !-->
    <div v-if="Object.values(myOrdersList).length != 0 && showOrders" class="order-container">
      <div
        class="order-card"
        v-for="(order, index) in orderNumbers"
        :key="index"
        :style="
          infosByOrders[order].type === 'Retour produit'
            ? { 'background-color': 'rgb(254, 255, 195)' }
            : {}
        "
      >
        <h3 class='card-title'>
          {{ infosByOrders[order].date }} / {{ infosByOrders[order].location }}
        </h3>

        <div
          v-if="!(infosByOrders[order].type === 'Retour produit')"
          class="button-container"
        >
          <button class="btn" @click="backToBasket(order, 'Withdraw')">
            Remettre dans le panier
          </button>
          <button class="btn" @click="backToBasket(order, 'Return')">
            Effectuer un retour
          </button>
        </div>
       <span> {{ infosByOrders[order].type }} : </span>
        <p v-if="showNumber && connectedUser.id_user && order">N°{{ connectedUser.id_user.toString() + order.toString() }}</p>
        <button v-if="!showNumber" class="basic-btn" @click="showNumber = true">N°[...]</button>
        <button v-else @click="showNumber = false">↑</button>
        <div
          v-for="(value, innerIndex) in ordersGrouped(order)"
          :key="innerIndex"
        >
          <p> {{ value[2] }} x {{ value[1].toString().replace("-", "") }}
            <span v-if="value[3] === 'cable'">mètres
              <br>(réf :{{ value[0] }})</span>
          </p>
        </div>
      </div>
    </div>
    <div v-else-if="Object.values(myOrdersList).length === 0">
      Pas de commandes ces 30 derniers jours ...
    </div>

    
    <h2>COMMANDES + DE 1 MOIS</h2>
    <button class="show-btn" @click="showOrdersExt = !showOrdersExt">Afficher / Masquer</button>
   <!-- DERNIERE COMMANDE + DE 1 MOIS, délai à choisir !-->
    <!-- input de recherche !-->
    <div v-if="showOrdersExt">
      <button @click="getOrdersDataExtended()">Chercher</button>
      <input v-model="numberExtended" type="number" min="2" /> derniers mois
    </div>
      <!-- affichages des commandes !-->
    <div class="order-container"
      v-if="Object.values(myOrdersListExtended).length != 0 && showOrdersExt"
    >
      <div
        class="order-card"
        v-for="(order, index) in orderNumbersExtended"
        :key="index"
        :style="
          infosByOrdersExtended[order].type === 'Retour produit'
            ? { 'background-color': 'rgb(254, 255, 195)' }
            : {}
        "
      >
        <h3 class='card-title'>
          {{ infosByOrdersExtended[order].date }} /
          {{ infosByOrdersExtended[order].location }}
        </h3>

        {{ infosByOrdersExtended[order].type }}
        <p v-if="showNumber && connectedUser.id_user && order">N°{{ connectedUser.id_user.toString() + order.toString() }}</p>
        <button class="basic-btn" v-if="!showNumber" @click="showNumber = true">N°[...]</button>
        <button v-else @click="showNumber = false">↑</button>
        <div
          v-for="(value, innerIndex) in ordersGroupedExtended(order)"
          :key="innerIndex"
        >
          <p> {{ value[2] }} x {{ value[1].toString().replace("-", "") }}
            <span v-if="value[3] === 'cable'">mètres
              <br>(réf :{{ value[0] }})</span> </p>
        </div>
      </div>
    </div>
        <div v-else-if="Object.values(myOrdersListExtended).length === 0 && seeOrdersDataExtended">
      Historique introuvable ...
    </div>

    <button id="goTop" @click="toTheTop()">
      <i class="fas fa-arrow-up"></i>
    </button>
  </div>
</template>
<script>
import { mapState } from "vuex";

const myAppPath = require('./../../switchUrl');
const appPath = myAppPath.appPath;

export default {
  name: "OrderHistory",
  data() {
    return {
      infosByOrders: new Object(),
      infosByOrdersExtended: new Object(),
      infosByBookings: new Object(),
      showNumber: false,
      bookingObj: new Object(),
      orderObj: new Object(),
      connectedUser: {},
      defaultCableImg: require("../../assets/touret-img.jpg"),
      qtty: 0,
      itemLocation: "",
      locationList : [],
      putLoader: false,
      disableButton: false,
      showBookings: true,
      showOrders: true,
      showOrdersExt: true,
      showReels: true,
      isReturning: "no",
      isEmpty: false,
      numberExtended: 6,
      succesDelete: false,
      seeOrdersDataExtended : false,
      seeBookingsDataExtended : false,
      disableBtn : false,
    };
  },
  mounted() {
    this.$store.commit("isConnected");
    // récuperer l'utilisateur actuel
    this.connectedUser = JSON.parse(localStorage.getItem("connectedUser"));

    //récupérer la liste des dépôts
    this.$store.dispatch("getAllLocations", this.connectedUser.id_user);
    
    //----------gestion du useMode-------------
    const originalUsername = this.connectedUser.username;
    var connectedUser = this.connectedUser;

    const userMode = JSON.parse(localStorage.getItem('usingMode'));
    const userModeId  = JSON.parse(localStorage.getItem('usingModeI'));
    const userModeUsername = localStorage.getItem('usingModeU');
    if(userMode && userMode === true && userModeId && userModeUsername){
      if(!this.connectedUser.username.includes('USER&MODE')){
      connectedUser.username  = originalUsername + 'USER&MODE' + userModeUsername + '^&^'+userModeId;
      localStorage.setItem('connectedUser',JSON.stringify(connectedUser));
      this.connectedUser = JSON.parse(localStorage.getItem("connectedUser"));}
    }
    //----------END gestion du useMode-------------

    this.getOrdersData();
  },
  computed: {
    //STORE
    ...mapState({
      myOrdersList: "myOrdersList",
      myOrdersListExtended: "myOrdersListExtended",
      myBookingsList: "myBookingsList",
      myReelsList: "myReelsList",
      requestResponse: "requestResponse",
      succes01: "succes01",
      succes02: "succes02",
      succes03: "succes03",
      cableMeter: "cableMeter",
      allLocations : "allLocations"
    }),
    requestResponseCheck() {
      if (this.requestResponse === true) {
        this.disableButton = false;
        return true;
      } else {
        //location.reload()
        return false;
      }
    },
    //filtre pour retourner un set numéro commandes 
    orderNumbers() {
      const setOrders = new Set();
      var infosByOrders = {};
      this.myOrdersList.reverse().forEach((order) => {
        //console.log(index);
        //console.log(order.date);
        const unixTimestamp = order.order_number;
        const milliseconds = unixTimestamp * 1000;
        const dateObject = new Date(milliseconds);
        const humanDateFormat = dateObject.toLocaleString();
        if (order.qtty < 0) {
          infosByOrders[order.order_number] = {
            date: humanDateFormat,
            location: order.storage_location,
            type: "Commande",
          };
        } else {
          infosByOrders[order.order_number] = {
            date: humanDateFormat,
            location: order.storage_location,
            type: "Retour produit",
          };
        }
        setOrders.add(order.order_number);
      });
      //console.log(infosByOrders);
      this.infosByOrders = infosByOrders;
      return Array.from(setOrders);
    },
    orderNumbersExtended() {
      const setOrders = new Set();
      var infosByOrdersExtended = {};
      this.myOrdersListExtended.reverse().forEach((order) => {
        //console.log(index);
        //console.log(order.date);
        const unixTimestamp = order.order_number;
        const milliseconds = unixTimestamp * 1000;
        const dateObject = new Date(milliseconds);
        const humanDateFormat = dateObject.toLocaleString();
        if (order.qtty < 0) {
          infosByOrdersExtended[order.order_number] = {
            date: humanDateFormat,
            location: order.storage_location,
            type: "Commande",
          };
        } else {
          infosByOrdersExtended[order.order_number] = {
            date: humanDateFormat,
            location: order.storage_location,
            type: "Retour produit",
          };
        }
        setOrders.add(order.order_number);
      });
      //console.log(infosByOrders);
      this.infosByOrdersExtended = infosByOrdersExtended;
      return Array.from(setOrders);
    },
    //filtre pour retourner un set numéro reservations
    bookingNumbers() {
      const setOrders = new Set();
      var infosByBookings = {};
      //console.log(this.myBookingsList);
      this.myBookingsList.reverse().forEach((booking) => {
        //console.log(index);
        //console.log(order.date);
        const unixTimestamp = booking.booking_number;
        const milliseconds = unixTimestamp * 1000;
        const dateObject = new Date(milliseconds);
        const humanDateFormat = dateObject.toLocaleString();
        infosByBookings[booking.booking_number] = {
          date: humanDateFormat,
          location: booking.storage_location,
          status: booking.status_,
        };
        setOrders.add(booking.booking_number);
      });
      //console.log(infosByOrders);
      this.infosByBookings = infosByBookings;
      return Array.from(setOrders);
    },
  },

  watch: {
    succes01(newCount, oldCount) {
      if (newCount === true) {
        this.getOrdersData();
      }
    },
    succes02(newCount, oldCount) {
      if (newCount === true) {
        this.getOrdersData();
      }
    },
    succes03(newCount, oldCount) {
      if (newCount === true) {
        this.getOrdersData();
        setTimeout(() => {
          this.disableBtn = false;
        }, 1500);
      }
    },
    succesDelete(newCount, oldCount) {
      if (newCount === true) {
        this.getOrdersData();
      }
    },
    allLocations(newCount, oldCount) {
      if(newCount) {
        //alert(newCount);
        this.locationList = newCount;
        //this.itemLocation = newCount[0];
      }
      },
  },

  methods: {
    confirmBooking(booking) {
      //this.disableButton = true;

      //console.log(booking);
      // creer une bookingList avec uniquement les EN COURS au mounted ?
      for (let i in this.myBookingsList) {
        // vérifer si la location est comprise dans l'objet [ref + '+' + 'location']
        //console.log( this.myBookingsList[i].booking_number);

        if (this.myBookingsList[i].booking_number === booking) {
          const location = this.myBookingsList[i].storage_location;
          const ref_item = this.myBookingsList[i].ref;
          const qtty = this.myBookingsList[i].qtty;
          const item_id = this.myBookingsList[i].item_id;
          var thisArray = [ref_item, qtty, item_id];
          //constuire l'objet orderBasket de façon différente d'orderInProgress
          if (this.bookingObj[location]) {
            this.bookingObj[location].ref.push(thisArray);
          } else {
            this.bookingObj[location] = {
              ref: [thisArray],
            };
          }
        }
      }
      //console.log("CONFIRM BOOKING");
      //console.log(this.bookingObj);
      let username_;
      /*if(this.userModeUsername && this.usernameAndId 
      && this.userModeUsername != '' && this.usernameAndId != 0){
        username_
      }*/
      this.$store.dispatch("userOrder", {
        username: this.connectedUser.username,
        action: "ConfirmBooking",
        user_id: this.connectedUser.id_user,
        orderBasket: this.bookingObj,
      });
      this.bookingObj = {};
    },
    cancelBooking(booking) {
      // creer une bookingList avec uniquement les EN COURS au mounted ?
      for (let i in this.myBookingsList) {
        // vérifer si la location est comprise dans l'objet [ref + '+' + 'location']
        if (this.myBookingsList[i].booking_number === booking) {
          const location = this.myBookingsList[i].storage_location;
          const ref_item = this.myBookingsList[i].ref;
          const qtty = this.myBookingsList[i].qtty;
          const item_id = this.myBookingsList[i].item_id;
          var thisArray = [ref_item, qtty, item_id];
          //constuire l'objet orderBasket de façon différente d'orderInProgress
          if (this.bookingObj[location]) {
            this.bookingObj[location].ref.push(thisArray);
          } else {
            this.bookingObj[location] = {
              ref: [thisArray],
            };
          }
        }
      }
      //console.log("CANCEL BOOKING");
      this.$store.dispatch("userOrder", {
        username: this.connectedUser.username,
        action: "CancelBooking",
        user_id: this.connectedUser.id_user,
        orderBasket: this.bookingObj,
      });
      this.bookingObj = {};
    },
    //filtre de groupement des commandes par numero
    ordersGrouped(order_number) {
      return this.myOrdersList
        .filter((order) => order.order_number === order_number)
        .map((order) => [order.ref, order.qtty, order.item,order.type_]);
    },
    ordersGroupedExtended(order_number) {
      return this.myOrdersListExtended
        .filter((order) => order.order_number === order_number)
        .map((order) => [order.ref, order.qtty,order.item,order.type_]);
    },
    //filtre de groupement des reservations par numero
    bookingsGrouped(booking_number) {
      return this.myBookingsList
        .filter((booking) => booking.booking_number === booking_number)
        .map((booking) => [booking.ref, booking.qtty,booking.item,booking.type_]);
    },
    //---GET order and book data STORE REQUESTS
    getOrdersData() {
      if (Object.keys(this.connectedUser).length != 0) {
        const usernameAndId =
          this.connectedUser.username + "_" + this.connectedUser.id_user;
        this.$store.dispatch("getAllMyOrders", usernameAndId);
      } else {
        alert("Accès bloqué, l'utilisateur n'est pas reconnu !");
      }
    },
    // évite de requeter à nouveau toutes les données et n'actualise que myBookingsList
    getBookings(){
      this.seeBookingsDataExtended = false;
      if (Object.keys(this.connectedUser).length != 0) {
        const usernameAndId =
          "nowBookings" +
          this.connectedUser.username +
          "_" +
          this.connectedUser.id_user;
        this.$store.dispatch("getAllMyOrders", usernameAndId);
      } else {
        alert("Accès bloqué, l'utilisateur n'est pas reconnu !");
      }
    },
    getBookingsDataExtended() {
      this.seeBookingsDataExtended = true;
      if (Object.keys(this.connectedUser).length != 0) {
        const usernameAndId =
          "pastsBookings" +
          this.connectedUser.username +
          "_" +
          this.connectedUser.id_user;
        this.$store.dispatch("getAllMyOrders", usernameAndId);
      } else {
        alert("Accès bloqué, l'utilisateur n'est pas reconnu !");
      }
    },
    getOrdersDataExtended() {
      this.seeOrdersDataExtended = true;
      if (Object.keys(this.connectedUser).length != 0) {
        const usernameAndId =
          this.numberExtended +
          "+" +
          this.connectedUser.username +
          "_" +
          this.connectedUser.id_user;
        this.$store.dispatch("getAllMyOrders", usernameAndId);
      } else {
        alert("Accès bloqué, l'utilisateur n'est pas reconnu !");
      }
    },

    itemLocationFn(location) {
      this.itemLocation = location;
    },
    // Scroll to top
    toTheTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    // Retourner au panier avec une commande pour la repasser ou la retourner
    backToBasket(order, action) {
      for (let i in this.myOrdersList) {
        // vérifer si la location est comprise dans l'objet [ref + '+' + 'location']
        //console.log( this.myBookingsList[i].booking_number);

        if (this.myOrdersList[i].order_number === order) {
          const location = this.myOrdersList[i].storage_location;
          const ref_item = this.myOrdersList[i].ref;
          const qtty = this.myOrdersList[i].qtty.toString().replace("-", "");
          //const item_id = this.myOrdersList[i].item_id;
          //constuire l'objet orderBasket de façon différente d'orderInProgress
          if (this.orderObj[ref_item + "+" + location]) {
            const quant = this.orderObj[ref_item + "+" + location].quantity;
            this.orderObj[ref_item + "+" + location].quantity = quant+parseInt(qtty);
          } else {
            this.orderObj[ref_item + "+" + location] = {
              quantity: parseInt(qtty),
            };
          }
        }
      }
      if (action === "Withdraw") {
        localStorage.removeItem("returnMode");
        localStorage.setItem("orderInProgress", JSON.stringify(this.orderObj));
      } else if (action === "Return") {
        localStorage.setItem("returnMode", JSON.stringify(true));
        localStorage.setItem("returnInProgress", JSON.stringify(this.orderObj));
      }
      this.orderObj = {};
      this.$router.push("/Basket");
    },
    //DOM logic button
    returnReel(reel) {
      const el = document.getElementsByClassName('reel-card');
      
      if (this.isReturning === "no") {
        this.isReturning = reel["id"];
        //console.log(screen.width);
        if(screen.width < 1100){
        for(let i=0; i < el.length; i++){
        el[i].style.height = '400px';
        el[i].style.gridTemplateRows = '1fr 3fr 1fr';
      }}
      } else {
        this.isReturning = "no";
        //console.log(screen.width);
        if(screen.width < 1100){
        for(let i=0; i < el.length-1; i++){
        el[i].style.height = '300px';
        el[i].style.gridTemplateRows = '1fr 1fr 1fr';
      }}
      }
       if(screen.width < 1100){
     // console.log(reel['ref']);
      var element = this.$refs[reel['ref']];
      var top = element.offsetTop;
      window.scrollTo(0, top);}
    },
    saveValue(reel){
      //console.log(reel['id']);
      const id = reel['id'];
      var reelMeter = JSON.parse(reel["meter"]);
      var end = reelMeter.end;
      var start = this.$refs[id].value;
      //console.log(this.$refs[id]);
      //console.log(this.$refs[id].value);
      //console.log(this.$refs[id]);
      
      reel["meter"]={
        start: start,
        end: end.toString(),
      };

      reel["meter"]=JSON.stringify(reel['meter']);
    },
    // REQUEST FOR RETURN THE REEL
    confirmReturnReel(reel) {

      if (this.itemLocation === "") {
        alert("Veuillez choisir un dépôt");
        return;
      }
      
      //console.log(reel['id']);
      const id = reel['id'];
      var reelMeter = JSON.parse(reel["meter"]);
      var start = this.$refs[id].value;
      var end = reelMeter.end;

      if (start < 0) {
        alert("Veuillez rentrer une valeur positive");
        return;
      }
      //console.log(this.$refs[id]);
      //console.log(this.$refs[id].value);
      //console.log(this.$refs[id]);
      
      //disble button
      this.disableBtn = true;

      reel["meter"]={
        start: start,
        end: end.toString()
      };
      reelMeter = reel["meter"];
      reel["meter"]=JSON.stringify(reel['meter']);

      const meter = JSON.stringify(reelMeter);
      const max = Math.max(start, end);
      const min = Math.min(start, end);
      const qtty_in = max - min;
      //const qtty_max = qtty_in;

      this.$store.dispatch("borrowCables", {
        username: this.connectedUser.username,
        item_id: reel["id"],
        location: this.itemLocation,
        meter: meter,
        qtty_in: qtty_in,
        qtty_max: 0,
        user_id: this.connectedUser.id_user,
        action: "return",
        item: reel["item"],
        ref : reel["ref"],
      });
    },
    // REQUEST FOR UNBOOKING THE REEL
    cancelBookingReel(reel) {
      var str = "unbooking";
      this.$store.dispatch("borrowCables", {
        username: this.connectedUser.username,
        item_id: reel["id"],
        user_id: this.connectedUser.id_user,
        action: str,
        item: reel["item"],
        ref : reel["ref"],
        location : reel['item_storage']
      });
    },
    // REQUEST FOR BOOROW IF BOOKED
    confirmBorrowReel(reel) {
      var str = "borrow";
      this.$store.dispatch("borrowCables", {
        username: this.connectedUser.username,
        item_id: reel["id"],
        item : reel['item'],
        ref : reel["ref"],
        location : reel['item_storage'],
        user_id: this.connectedUser.id_user,
        action: str,
      });
    },
    // REQUEST FOR EMPTY REEL
    deleteReel(reel) {
      if (this.itemLocation === "") {
        alert("Veuillez choisir un dépôt");
        return;
      }
      this.fetchDeleteCableBorrowed(reel["id"], reel["item"],this.itemLocation,reel['meter'],reel['ref'])
        .then((data) => {
          if (data["data"]) {
            //alert('touret supprimé')
            //console.log(data['data'])
            this.succesDelete = true;
            setTimeout(() => {
              this.succesDelete = false;
            }, 2000);
          } else {
            alert("requete impossible");
          }
        })
        .catch((e) => console.log(e));
    },
    async fetchDeleteCableBorrowed(item_id, item,location,meter,ref) {
      const requestOptions = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
        body: JSON.stringify({
          item_id: item_id,
          username: this.connectedUser.username,
          user_id: this.connectedUser.id_user,
          item: item,
          meter : meter,
          location : location,
          ref : ref
        }),
      };

      let response = await fetch(
        appPath+"/history/delete_cable_borrowed",
        requestOptions
      );
      if (!response.ok) {
        alert("Une erreur empêche la requête");
        //stop loader
        this.putLoader = false;
        // get error message from body or default to response status
        const error = (data && data.message) || response.status;
        //console.log('not response ok, error : ' + error);
        //console.log(error);
        return Promise.reject(error);
      }
      return await response.json();
    },
  },
};
</script>

<style lang="scss" scoped>
.seestock-container {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.reel-container {
  width: 1100px;
  display : flex;
  flex-wrap : wrap;
}
.booking-container {
  width: 1100px;
  display : flex;
  justify-content: space-evenly;
  flex-wrap : wrap;
}
.order-container {
  width: 1100px;
  display : flex;
  justify-content: space-evenly;
  flex-wrap : wrap;
}
.booking-card {
  border: 1px solid black;
  margin-bottom: 20px;
  max-width: 350px;
  width: 300px;
  background-color: rgb(214, 219, 214);
  border: #f38414 2px solid;
  -webkit-box-shadow: 1px 2px 5px 1px #000000; 
  box-shadow: 2px 2px 7px 1px #000000;
}
.order-card {
  border: 1px solid black;
  margin-bottom: 20px;
  max-width: 350px;
  width: 300px;
  background-color: rgb(192, 248, 177);
  border: #f38414 2px solid;
  -webkit-box-shadow: 1px 2px 5px 1px #000000; 
  box-shadow: 2px 2px 7px 1px #000000;
}

.button-container {
  display: flex;
  justify-content: space-evenly;
}
button {
  margin-bottom: 5px;
  background-color:#f38414;
  color : white;
}

#goTop {
  position: fixed;
  background-color: black;
  right: 100px;
  bottom: 50px;
  width: 50px;
  height: 50px;
}
.fa-arrow-up {
  font-size: 30px;
  color: white;
}
.button {
  margin: 15px;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
}
i {
  border: black 1px solid;
  font-size: 15px;
  padding: 5px;
}
p{
  font-size : 22px;
  font-weight: bold;
}
span {
  font-size : 20px;
  font-weight: bold;
}
.show-btn {
  background-color:#0d444b;
  width: 300px;
  font-size: 18px;
  margin-bottom : 10px;
}
.btn{
font-size: 20px;
padding : 10px;
color : black;
}
.black {
  color : black;
}
.btn-little {
  font-size: 18px;
  padding : 1px;
  margin-right: 8px;
  background-color:#f38414;
}
.reel-card {
  background-color: #0d444b;
  color: white;
  display: grid;
  grid-template-columns: 1fr 1fr 1.5fr;
  grid-template-rows: 1fr 3fr 1fr;
  height: 400px;
  width: 500px;
  margin: auto;
  margin-bottom: 30px;
  align-items: center;
  border: #f38414 2px solid;
  -webkit-box-shadow: 1px 2px 5px 1px #000000; 
  box-shadow: 2px 2px 7px 1px #000000;
}
.img {
  border: black 2px dashed;
  max-width: 300px;
  max-height: 180px;
  justify-self: center;
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 2;
  grid-row-end: 2;
}

.switchImg {
  border: rgb(255, 255, 255) 2px dashed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  justify-self : center;
  width: 280px;
  padding: 15px;
  padding-bottom: 0;
grid-column-start: 1;
  grid-column-end: 4;
}
.qtty-meter {
grid-column-start: 2;
grid-column-end: 4;
  }
  .ref-reel{
justify-self: end;
}
.infos {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  margin-bottom: 15px;
}
select {
  width: 140px;
  font-size: 20px;
}
.number {
  width: 80px;
  font-size: 20px;
}
.h3-cable {
  font-size: 18px;
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 1;
}
.booking-reel-div {
  z-index: 2;
  width: 150px;
  height: 30px;
}
.succes-message {
  width: 300px;
  height: 100px;
  border: 2px rgb(23, 189, 23) solid;
  font-size: 25px;
  font-weight: bold;
  border-radius: 5px;
  color: rgb(23, 189, 23);
  background-color: rgb(255, 255, 255);
  top: 30%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
  position: fixed;
  line-height: 100px;
  z-index : 10;
}
.delete-message {
  border: 2px rgb(23, 189, 23) solid;
  color: red;
  padding : 5px;
  font-size : 22px;
  background-color: white;
}
.booking-message{
  //type of violet
  color : rgb(193, 143, 240);
  border-color : rgb(193, 143, 240);
}
.return-message{
  //type of violet
  color : black;
  border-color : rgb(23, 189, 23);
}
.basic-btn {
  background-color : initial;
  color : black;
}

@media screen and (max-width: 1100px) {
  input,select {
    background-color: white;
  }
  .seestock-container {
    width: 300px;
  }
  .reel-container, .order-container, .booking-container {
  width: 100%;
  display : block;
}
  .reel-card {
  height: 300px;
  width: 300px;
  font-size : 20px;
  grid-template-columns: 1fr 1fr 1.5fr;
  grid-template-rows: 1fr 1fr 1fr;
}
.ref-reel{
justify-self: center;
grid-column-start: 1;
  grid-column-end: 2;}
.img {
  max-width: 200px;
  max-height: 100px;
}
.switchImg{
  max-width: 350px;
  max-height: 230px;
  justify-self: center;
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 2;
  grid-row-end: 2;
}
p{
  font-size : 18px;
}
span {
  font-size : 20px;
}
button {
  font-size: 16px;
}
.show-btn {
  width: auto;
  font-size: 16px;
  margin-bottom : 10px;
}
.btn{
font-size: 16px;
width: 100px;
margin-bottom: 18px;
padding : 5px;
}
.btn-little {
  font-size: 17px;
  padding : 1px;
  margin-right: 8px;
}

  #goTop {
    left: 0px;
    bottom: 10px;
    width: 28px;
    height: 50px;
    padding : 0;
  }
  .fa-arrow-up {
    font-size: 18px;
    padding : 0;
  }
  .order-card, .booking-card{
    padding : 0px 10px;
    width : auto;
  }
}
</style>
