<template>
    <div v-if="!documentEditing">
        <div  v-if="Object.values(this.allAssignements).length !=0" class="activity-container">
            <h3>Stock par activité</h3>
            
            <div class="client-container" v-for="assign,name in assignementFilter" :key="assign" :values="name">
                <h4>{{name}} </h4>
                <div class="material-container" v-for="x in assign" :key="x">
                    <h5>{{x}}</h5>
               <div class="activity-subcontainer"  v-for="a in this.clientsGrouped(x,name)" :key="a">
                   <span>{{a[0]}}</span> : <span>{{a[1]}}</span>
               </div>
            </div></div>
        </div>
    </div>
</template>
<script>
import { mapState } from "vuex";

const myAppPath = require('./../../../switchUrl');
const appPath = myAppPath.appPath;

export default {
  props : ['documentEditing'],
    data() {
      
        return {
          connectedUser: {},  
        }
    },
    mounted() {
    this.$store.commit("isConnected");
    // récuperer l'utilisateur actuel
    this.connectedUser = JSON.parse(localStorage.getItem("connectedUser")); 

    this.$store.dispatch('getAssignementReport',this.connectedUser.id_user);
    },
    computed: {
    ...mapState({
      assignementsResponse : "assignementsResponse",
      allAssignements : 'allAssignements'
    }),
    assignementFilter() {
        if(Object.values(this.allAssignements).length != 0){
      var obj = {};
      var setControl = new Set();
      this.allAssignements.forEach((assign) => {
        //console.log(index);
        //console.log(order.date);
        const str= assign.client+"+"+assign.material;
       if(!setControl.has(str)){
           if(obj[assign.client]){
           obj[assign.client].push(assign.material);}
           else{
               obj[assign.client] = [assign.material]
           }
       }
        setControl.add(str);
        
      });
      return obj
      }
    },
    },
    watch : {
        activityResponse(newCount, oldCount) {
      if (newCount === true) {
        //alert("Echec de la commande");
        this.$refs['newActivityBtn'].disabled = false;
        this.$refs['hourglass'].style.display = 'none';
        this.$store.state.activityResponse = false;
      }}
    },
    methods: {
         //filtre de groupement des commandes par numero
    clientsGrouped(assign,name) {
      return this.allAssignements
        .filter((a) =>a.client === name && a.material === assign)
        .map((a) => [ a.activity, a.qtty]);
    },
    },
}
</script>
<style scoped>
.activity-container{

}
h3{
    font-size: 22px;
}
h4{
  width: 100%;
  font-size: 22px;
}
h5 {
  font-size: 20px;
  background-color: #f38414;

}
.client-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  /*align-items: center;*/
  color: white;
  max-width: 100%;
  width: 1100px;
  height: auto;
  font-size: 20px;
  background: #0d444b;
  border-radius: 16px;
  padding: 32px;
  margin-bottom: 30px;
   -webkit-box-shadow: 1px 2px 5px 1px #000000; 
  box-shadow: 2px 2px 7px 1px #000000;
}
.material-container {
background-color: #0d444b;
color: #0d444b;
border : 4px solid #f38414;
border-radius: 16px;
padding: 7px;
margin-bottom: 10px;
width: auto;
max-width: 400px;
}
.activity-subcontainer{
margin: 4px;
color : white;
}
@media screen and (max-width: 1100px) {
  input,select {
    background-color: white;
  }
.activity-container{
    max-width: 340px;
}
.client-container {
  max-width: 100%;
  width: 320px;
  font-size: 20px;
  border-radius: 16px;
  padding: 2px;
}
.material-container {

}
.activity-subcontainer{

}


}

</style>