<template>
  <div class="history-container">
        <!-- {{globalHistory}} !-->
        <button class="showOptions" v-if="!controlPanel" @click="controlPanel = true">Afficher les options &#x25BC;</button>

        <div class="control-panel-div" v-show="controlPanel">
           <button class="hideOptions"  @click="controlPanel = false">Masquer les options &#x25B2; </button>
         <div @click="exportToExcel()" class="excel-div">
        <button>Export Excel</button>
        <img id="icon1" src="../../../assets/excel.jpg" alt=""/>
        <img id="icon2" src="../../../assets/libreoff.jpg" alt=""/>
        
        </div>

        <div class="search-container interval-container">
          <p>Choisir un période jj/mm/aaaa (par défaut 31 derniers jours)</p>
          <div>
          <label for="date">Entre : </label>
        <input type="search" name="date" ref="date_filter" v-model="choosedStartDate">
        </div>
          <div>
        <label for="date">Et : </label>
        <input type="search" name="date" ref="date_filter" v-model="choosedEndDate">
        </div>
        <button @click="newDateInterval()">Lancer la requête</button>
        <button @click="defaultInterval()">Effacer</button>
        </div>

        <div class="search-container">
          <div>
        <label for="date">Filtrer une date : </label>
        <input type="search" name="date" id="" v-model="choosedDate">
        </div>
        <div>
        <label for="date"> Filtrer une heure : </label>
        <input type="search" name="date" id="" v-model="choosedHour">
        </div>
        </div>
<div class="filters-container">
        <div class="user-filter-container">
          <select name="" id="autoSize" :size="size" @focus="onFocusSelect" @blur="onBlurSelect"
          @change="userFilter($event)">
            <option selected value="inactive">
              -Filtre utilisateur desactivé-
            </option>
            <option :value="user" v-for="user in userSetHistory" :key="user">
              {{user}}
            </option>
          </select>
        </div>
        </div>
</div>

        <h3>
        Dépassement total : {{exceeding2}} € </h3>
        <div class="table-container">
    <table v-if="Object.values(multiFilters).length != 0">
        <thead class="global-history-table" >
            <tr>
          <td>Ligne</td>
          <!--td>color</td!-->
          <td>Utilisateur</td>
          <td>Designation</td>
          <td>Ref</td>
          <td>Type</td>
          <td>Prix</td>
          <td>Délai restant</td>
          <td>Periodicité</td>
          <td>Coût théorique/Jour</td>
          <td>Dépassement</td>
          <td><button v-if="!showHour" @click="showHour = true">date</button>
          <button v-else @click="showHour = false">date + </button></td>
          </tr>
      </thead>

      <tbody class="global-history-table" >
           <tr v-for="row in multiFilters" :key="row"
            >
          <td>{{row.id}}</td>
          <!--td>{{row.color}}</td!-->
          <td>{{row.username}}</td>
          <td>{{row.item}}</td>
          <td>{{row.ref}}</td>
          <td>{{translateFormat(row.type_)}}</td>
          <td >{{row.price||'null'}}</td>
          <td>{{row.day_left}} jours</td>
          <td>{{row.period}} jours</td>
          <td>{{row.theoritical}} €</td>
         <td>{{row.real}} €</td>
          <td v-if="!showHour" class="date" @click="copyFn(dateFormat(row.date))">{{dateFormat(row.date)}} </td>
          <td v-else >{{dateFormatH(row.date)}} </td>
          </tr>
      </tbody>
</table>

<div v-else class="loader" >Recherche de données... &#8987;</div>
  </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
const XLSX = require("xlsx");
const { saveAs } = require('file-saver');

export default {
    name : "HistoryTools",
    props : ["connectedUser"],
    data() {
        return {
            showHour : false,
            choosedDate : '',
            choosedHour : '',
            exceeding : 0,
            exceeding2 : 0,
            activeUserFilter : false,
            choosedUser : '',
            choosedStartDate : '',
            choosedEndDate : '',
            controlPanel : false,
            size : 1
        }
    },
      mounted() {
    this.$store.state.intervalDate = [];
    this.$store.commit("isConnected");
    this.getToolsHistory();
  },

      computed: {
    //STORE
    ...mapState({
      toolsHistory: "toolsHistory",
      intervalDate : "intervalDate"
    }),
    calculateTrueCost(){
      this.exceeding = 0;
      let miror;
      if(Object.values(this.intervalDate).length != 0){
        miror = this.intervalDate;
      }
      else{
        miror = this.toolsHistory;
      };
      miror.forEach(element => {
        if(element.day_left > element.period){
          element.theoritical = 0;
          element.real = 0;
        };
        
        element.theoritical = this.calculateDayCost(element.price, element.period);
        const number = element.day_left * element.theoritical;
        element.real = Math.floor(number).toFixed(2);
        this.exceeding = this.exceeding + parseInt(element.real);
      });

        return miror;
      },
      userSetHistory(){
      var theSet = new Set;
      let miror;
      if(Object.values(this.intervalDate).length != 0){
        miror = this.intervalDate;
      }
      else{
        miror = this.toolsHistory;
      };
      miror.forEach(element => {
        theSet.add(element.username);
      });
      return theSet;
    },

        multiFilters(){
      this.exceeding2 = 0;
      var excChange = false;
      var obj = [];
      var miror = this.calculateTrueCost;
      if(this.choosedDate != ''){
        miror.forEach(element => {
          if(this.dateFormat(element.date) === this.choosedDate){
            excChange = true;
            this.exceeding2 = this.exceeding2 + parseInt(element.real);
            obj.push(element);
          }
        });
        miror = obj;
        obj = [];
      }
      if(this.choosedHour != ''){
        miror.forEach(element => {
          if(this.dateFormatH(element.date).split(',')[1].includes(' '+this.choosedHour)){
            excChange = true;
            this.exceeding2 = this.exceeding2 + parseInt(element.real);
            obj.push(element);
          }
        });
        miror = obj;
        obj = [];
      }
      if(this.activeUserFilter){
        miror.forEach(element => {
          if(element.username === this.choosedUser){
            excChange = true;
            this.exceeding2 = this.exceeding2 + parseInt(element.real);
            obj.push(element);
          }
        });
        miror = obj;
        obj = [];
      }
      if(excChange === false){
        this.exceeding2 = this.exceeding;
      }
      return miror;
    },
    translateForExcel(){
      var jsonObj = [];
      var miror = JSON.parse(JSON.stringify(this.multiFilters));
        //var start = new Date();
        miror.forEach(element => {
          delete Object.assign(element, {['Ligne']: element['id'] })['id'];
          delete Object.assign(element, {['Utilisateur']: element['username'] })['username'];
          delete Object.assign(element, {['Désignation']: element['item'] })['item'];
          delete Object.assign(element, {['Réf']: element['ref'] })['ref'];
          delete Object.assign(element, {['Type']: element['type_'] })['type_'];
          delete Object.assign(element, {['Prix']: element['price'] })['price'];
          delete Object.assign(element, {['Délai restant']: element['day_left'] })['day_left'];
          delete Object.assign(element, {['Périodicité']: element['period'] })['period'];
          delete Object.assign(element, {['Théorie']: element['theoritical'] })['theoritical'];
          delete Object.assign(element, {['Réel']: element['real'] })['real'];
          element.date = this.dateFormat(element.date);
          delete Object.assign(element, {['Date']: element['date'] })['date'];
          delete element.color;
          jsonObj.push(element);
        });
        // 7 milliseconds
        //var time = new Date() - start;
        //console.log(time);
        return jsonObj;
    }
 },
  methods : {
    onFocusSelect(){
      this.size = 4;
    },
    onBlurSelect(){
      this.size = 1;
    },
      getToolsHistory(){
        if (Object.keys(this.connectedUser).length != 0) {
        this.$store.dispatch("getToolsHistory", this.connectedUser.id_user);
      } else {
        alert("Accès bloqué, l'utilisateur n'est pas reconnu !");
      }
      },
      dateFormat(data){
       // console.log( data);
       // console.log(typeof data);
        const dateObject = new Date(data);
        //console.log( dateObject);
        //console.log(typeof dateObject);
        let humanDateFormat;
        if(dateObject.toLocaleString().includes(',')){
        humanDateFormat = dateObject.toLocaleString().split(',')[0];}
        else{
        humanDateFormat = dateObject.toLocaleString().split(' ')[0];  
        }
        //console.log( humanDateFormat.split(',')[0]);
        //console.log(typeof humanDateFormat);
        return humanDateFormat;
      },
    dateFormatH(data){
        const dateObject = new Date(data);
        const humanDateFormat = dateObject.toLocaleString();
        return humanDateFormat;
      },
      userFilter(event){
        //console.log(event.target.value);
        //console.log(typeof event.target.value);

        // si filtre desactivé, revenir à l'affichage classique
        if(event.target.value === 'inactive')
        {
          this.activeUserFilter = false;
          return
        }

        this.activeUserFilter = true;
        this.choosedUser = event.target.value;
        this.size = 1;
      event.target.blur();
      },
      translateFormat(data){
        switch (data) {
          case 'tool':
            return 'outil'
            break;
          default:
            return data
            break;
        }
      },
      calculateDayCost(price, period){
        const nb = price/period;
        const nb2 = Math.floor(nb).toFixed(2);
       return nb2;
      },
      newDateInterval(){
        if(this.choosedStartDate != '' && this.choosedEndDate != ''){

         var j = this.choosedStartDate.split('/')[0];
         var m = this.choosedStartDate.split('/')[1];
         var a = this.choosedStartDate.split('/')[2];
         const choosedStartDate = a+'-'+m+'-'+j;
         j = this.choosedEndDate.split('/')[0];
         m = this.choosedEndDate.split('/')[1];
         a = this.choosedEndDate.split('/')[2];
         const choosedEndDate = a+'-'+m+'-'+j;

         //console.log(j);console.log(m);console.log(a);
         
         const startDate = new Date(choosedStartDate+'Z');
         var endDate0 = new Date(choosedEndDate+'Z');
         endDate0.setUTCHours(22);
         endDate0.setUTCMinutes(59);
         endDate0.setUTCSeconds(59);
         //endDate0 = endDate0.setUTCMinutes(59);
         //console.log(endDate0);
         const endDate = endDate0;
         if(isNaN(Date.parse(startDate))  || isNaN(Date.parse(endDate))){
           alert("erreur de format de date, veuillez respecter ce format : jj/mm/aaaa");
         }
         
         if (Object.keys(this.connectedUser).length != 0) {
           
           const historyData = {
           date : [startDate,endDate],
           compOrigin :'tool_alert',
           username : this.connectedUser.username,
           user_id : this.connectedUser.id_user
         }

        this.$store.dispatch("putIntervalDate", historyData);
         } else {
        alert("Accès bloqué, l'utilisateur n'est pas reconnu !");}

        }else{
          alert("Veuillez remplir tous les champs d'interval de date !");
        }
      },
      copyFn(number) {
  var copyText = number.toString();
  const searchInput = this.$refs['date_filter'];
  searchInput.value = copyText;
  searchInput.focus();
  this.choosedDate = copyText;
      },
      defaultInterval(){
        this.choosedStartDate = '';
        this.choosedEndDate = '';
        this.$store.state.intervalDate = [];
      },
      exportToExcel(){
        var miror = JSON.parse(JSON.stringify(this.translateForExcel));
        //console.log(miror);
        this.downloadAsExcel(miror);
      },
      downloadAsExcel(data){
        let worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = {
          Sheets : {
            'data':worksheet
          },
          SheetNames: ['data']
        };
        const excelBuffer = XLSX.write(workbook, {booktype : 'xlsx', type : 'array'});
        //console.log(excelBuffer);
        this.saveAsExcel(excelBuffer, 'myFile');
      },
      saveAsExcel(buffer,filename){
        const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([buffer],{type:EXCEL_TYPE});
        saveAs(data,filename+'_export_'+new Date().getTime()+EXCEL_EXTENSION);
      }

  }

}
</script>

<style scoped>
table{
  margin : auto;
  overflow: scroll;
}
.table-container{/**/
  margin-top: 80px;
  margin-bottom: 60px;
  max-width: 1400px;
  overflow-x: scroll;
}
thead {
  font-size: 20px;
  font-weight: bold;
}
thead td{
  border: 1px solid black;
  text-overflow: ellipsis;
}
tbody tr:hover{
  background-color: rgb(116, 223, 223);
}
.global-history-table{
margin: 10px;
}
.global-history-table td{
margin: 10px;
}
.search-container {
  display: flex;
  margin: 30px;
  justify-content: space-evenly;
}
.search-container div input{
  width: 100px;
  margin-left : 10px;
  margin-right : 10px;
}
.search-container div label{
  font-size: 22px;
}
.interval-container{
  display: flex;
  font-size: 19px;
  align-content: center;
  padding-left: 10px;
  border: 1px black solid;
}
.interval-container p{
margin-right: 20px;
line-height: 30px;
}
.interval-container div{
margin: 20px;
}
.interval-container button{
margin: 20px;
font-size: 19px;
}
.date{
  cursor: pointer;
}
.date:hover{
  background-color: greenyellow;
}
.excel-div img {
width : 50px;
height: 50px;
margin-left: 20px ;
}
.excel-div {
    display: flex;
    justify-content: center;
}
.excel-div button{
    font-size : 20px;
    color: white;
    background-color: green;
    opacity: 1;
}
.excel-div button:active {
	transform: scale(1.1);
	transition: .1s;
}

.excel-div button:hover {
  opacity : 0.7;
}
.loader {
  margin-top: 80px;
  font-size: 50px;
}
.filters-container {
  display: flex;
  height: 100px;
  margin-bottom : 30px;
  justify-content: space-around;
  align-items: center;
  border: 1px black solid;
}
div select{
  text-align: center;
  width: 290px;
  height: 45px;
  font-size: 18px;
  background-color: rgb(239, 239, 239);
}
.hideOptions {
  margin-bottom: 20px;
  font-size: 18px;
}
.showOptions{
  font-size: 18px;
}
div #autoSize {
height : auto;
}
@media screen and (max-width: 1100px) {
table{
  overflow-x: scroll;
}
.table-container{
  margin-top: 50px;
  max-width: 280px;
  overflow-x: scroll;
}
.history-container{
  max-width: 280px;
  overflow-x: hidden;
}
.control-panel-div{
  max-width: 280px;
  overflow-x: scroll;
}
.search-container {
  flex-direction: column;
  margin: 20px;
}
.search-container div label{
  font-size: 18px;
}
.interval-container{
  flex-direction: column;
}
.loader {
  margin-top: 50px;
  font-size: 20px;
}
.filters-container {
  flex-direction: column;
  height: 140px;
  margin-bottom : 10px;
}
div select{
  width: 240px;
  height: 35px;
}

}
</style>