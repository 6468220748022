<template>
  <form
    ref="theForm"
    class="login__container"
    action="javascript:void(0)"
    @submit="submitMethod()"
  >
    <h2 class="auth__title">
      Inscrire un utilisateur
      <img
        class="userImg"
        alt="ajouter un utilisateur"
        src="../../../assets/new_user.png"
      />
    </h2>

    <div class="form-row">
      <input
        aria-label="entrez votre identifiant"
        type="text"
        name="username"
        required
        v-model="username"
        class="form-row__input"
        id="username-input"
        placeholder="Identifiant"
        autocomplete=""
        pattern="[A-Za-z0-9]{4,16}"
      />
      <span class="error-message">
        L'identifiant doit comprendre entre 4 & 16 lettres/chiffres</span
      >
    </div>
    <!-- Nom Prénom si inscription -->
    <div class="form-row">
      <input
        aria-label="entrez votre nom"
        v-model="name"
        class="form-row__input"
        type="text"
        placeholder="Prénom"
        pattern="[A-Za-z\s'-]{1,50}"
      />
      <input
        aria-label="entrez votre nom de famille"
        v-model="familly_name"
        class="form-row__input"
        type="text"
        placeholder="Nom"
        pattern="[A-Za-z\s'-]{1,50}"
      />
    </div>
    <div class="form-row relative">
      <!-- Password -->
      <input
        aria-label="entrez votre mot de passe"
        v-model="password"
        class="form-row__input"
        id="password-input"
        :type="visibility"
        placeholder="Mot de passe"
        autocomplete=""
        min="8"
        required
        pattern="(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$"
      />

      <div class="show__hide__password__container">
        <!-- show the password -->
        <a
          @click="showPassword()"
          class="show__hide__password"
          v-if="visibility == 'password'"
        >
          <img
            class="show__hide__logo"
            src="../../../assets/closed-eyes.svg"
            alt="Cacher le mot de passe"
          />
        </a>
        <!-- hide the password -->
        <a
          @click="hidePassword()"
          class="show__hide__password"
          v-if="visibility == 'text'"
        >
          <img
            class="show__hide__logo"
            src="../../../assets/vision.svg"
            alt="afficher le mot de passe"
        /></a>
      </div>
      <span class="password-message">
        Le mot de passe doit comprendre au moins 8 carractères (requis : 1
        majuscule, 1 chiffre. Certains carractères spéciaux sont bannis)</span
      >
    </div>

    <div class="form-row">
      <!-- inscription button -->
      <input
        aria-label="cliquez pour vous inscrire"
        class="button grey_btn fa fa-input"
        id="validation"
        type="submit"
        value="&#xf234;  Inscription "
      />
      <transition name="growSucces">
        <span class="succes-message" v-if="newUserMessage"
          >Ajout effectué !</span>
      </transition>
    </div>
  </form>
</template>

<script>
import { mapState } from "vuex";

const myAppPath = require('./../../../switchUrl');
const appPath = myAppPath.appPath;

export default {
  name: "SignIn",
  data: function() {
    return {
      visibility: "password",
      username: "",
      name: "",
      familly_name: "",
      password: "",
      newUserMessage: false,
      connectedUser : ''
    };
  },
  mounted: function() {
        this.$store.commit("isConnected");
        this.connectedUser = JSON.parse(localStorage.getItem("connectedUser"));
  },
  computed: {
    //STORE
    ...mapState({
      allUsers: "allUsers",
    }),
  },
  methods: {
    // SUBMIT FORM ------------------------------------------------
    submitMethod() {
      this.createAccount();
    },
    // INSCRIPTION ------------------------------------------------
    async fetchPostUser() {
      if (
        this.password.includes("<" || ">" || "/" || "\\" || " " || "$" || "=")
      ) {
        alert(
          "les carractères suivants (et les espaces) ne sont pas autorisés : < > / \\ $ ="
        );
        return;
      }

      if (
        !(this.name === "") &&
        !(this.familly_name === "") &&
        !(this.password === "") &&
        !(this.username === "")
      ) {
        let key;
      //key for only web app native request (!postman)
      if (
        (this.connectedUser.a5e789410fd.includes("2f+g2dfg25h2d+f15b1gs5bnj1ki1lo61q1dc16"))
      ) {
        key = "h1d5he15fdhs";
      }

        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            name: this.name,
            familly_name: this.familly_name,
            username: this.username,
            password: this.password,
            key:key,
            data_feeder : this.connectedUser.username,
          }),
        };

        let response = await fetch(
          appPath+"/auth/signup",
          requestOptions
        );
        if (!response.ok) {
          alert(
            "Erreur, identifiants déjà utilisé ou le format des informations est incorrect"
          );
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          //console.log('not response ok, error : ' + error);
          return Promise.reject(error);
        }
        return await response.json();
      } //si un champ est resté vide on ne passe pas dans fetch
      else {
        alert("veuillez remplir tous les champs");
      }
    },

    createAccount() {
      // FETCH
      this.fetchPostUser()
        .then((data) => {
          this.newUserMessage = true;
          setTimeout(
            function() {
              this.cleanSuccesMessage();
            }.bind(this),
            1000
          );
          this.$refs.theForm.reset();
          if (data["data"]) {
            //actualiser la liste allUsers du store après inscription
            let connectedUser = JSON.parse(
              localStorage.getItem("connectedUser")
            );
            this.$store.dispatch("getAllUsers", connectedUser.id_user);
          }
        })
        .catch((e) => console.log(e));
    },
    cleanSuccesMessage() {
      this.newUserMessage = false;
    },

    //FUNCTIONS ---------------------------------------
    showPassword() {
      this.visibility = "text";
    },
    hidePassword() {
      this.visibility = "password";
    },
  },
};
</script>

<style scoped>
.login__container {
  max-width: 100%;
  width: 540px;
  height: 380px;
  font-size: 20px;
  background: #0d444b;
  border-radius: 16px;
  padding: 32px;
   -webkit-box-shadow: 1px 2px 5px 1px #000000; 
  box-shadow: 2px 2px 7px 1px #000000;
}

.auth__title {
  text-align: center;
  font-weight: 800;
  color: #ee821a;
}

.button {
  /*color: black; */
  border: 4px transparent solid;
  border-radius: 8px;
  font-weight: 700;
  font-size: 22px;
  width: 100%;
  padding: 10px;
  color: #ee821a;
  cursor: pointer;
  transition: all .2s ease-in-out;
}
.button:active {
  transform: scale(1.1);
	transition: .1s;
}
.button:hover {
  background-color: white;
}

.form-row {
  display: flex;
  margin: 16px 0px;
  gap: 16px;
  flex-wrap: wrap;
}

.form-row__input {
  padding: 8px;
  border: none;
  border-radius: 8px;
  background: #f2f2f2;
  font-weight: 500;
  font-size: 25px;
  flex: 1;
  min-width: 100px;
  color: black;
}

.form-row__input::placeholder {
  color: #aaaaaa;
}
.error-message {
  display: none;
  color: black;
}
.password-message {
  display: none;
  color: black;
}

#password-input:not(:focus):not(:placeholder-shown):invalid {
  color: red;
}

#password-input:not(:focus):not(:placeholder-shown):invalid
  ~ .password-message {
  display: block;
}
#username-input:not(:focus):not(:placeholder-shown):invalid {
  color: red;
}

#username-input:not(:focus):not(:placeholder-shown):invalid ~ .error-message {
  display: block;
}

.relative {
  position: relative;
}
.show__hide__password__container {
  position: absolute;
  pointer-events: none;
  background-color: transparent;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-end;
}
.show__hide__password {
  pointer-events: visible;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
}
.show__hide__logo {
  position: absolute;
  height: 55%;
  cursor: pointer;
}
.userImg {
  width: 50px;
}
.succes-message {
  background-color: greenyellow;
  width: 100%;
  height: 50px;
  font-size: 25px;
  line-height: 50px;
  z-index : 10;
}
.growSucces-enter-active {
  animation: grow 1s;
}
.growSucces-leave-active {
  animation: grow 1.5s reverse;
}
@keyframes grow {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  80% {
    opacity: 1;
    transform: scale(1);
  }
}

@media screen and (max-width: 1100px) {
  .login__container {
    max-width: 350px;
    width: 300px;
    height: 350px;
    font-size: 15px;
    padding: 16px;
  }

  .button {
    font-size: 15px;
  }

  .form-row__input {
    font-size: 16px;
  }
}
</style>
