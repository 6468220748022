<template lang="">
    <div>
        <h1>LOCKED <i class="fas fa-lock"></i> </h1>
        <p class="security-info"> Veuillez vous reconnecter, votre naviguation n'est pas autorisée ou a expirée
        </p>
        <p><router-link to="/" >Retrouner à la connexion</router-link></p>
    </div>
</template>

<script>
export default {
    
}
</script>

<style scoped>
.security-info{
  font-size : 22px;
  margin : 30px;
}
</style>