<template>
  <div>
    <h2>Injection Excel</h2>
    <div class="flex">
      <label
        >Injecter un fichier :
        <img id="icon1" src="../../../assets/excel.jpg" alt=""/>
        <img id="icon2" src="../../../assets/libreoff.jpg" alt=""
      /></label>

      <input
        type="file"
        ref="fileInput"
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        @change="previewFiles"
      />

      <div class="vertical-margin">
        Page choisie
        <button
          :style="pickedPage === 0 ? { 'background-color': '#0d444b', 'color' : 'white'} : {}"
          @click="pickedPage = 0"
        >
          1
        </button>
        <button
          :style="pickedPage === 1 ? { 'background-color': '#0d444b', 'color' : 'white' } : {}"
          @click="pickedPage = 1"
        >
          2
        </button>
        <button
          :style="pickedPage === 2 ? { 'background-color': '#0d444b', 'color' : 'white' } : {}"
          @click="pickedPage = 2"
        >
          3
        </button>
      </div>

      <div class="vertical-margin">
         <span>Conseils d'utilisation  </span>
        
        <button class='colored-btn' v-if="!showAdvices" @click="showAdvices = true">Afficher</button>
        <button v-else @click="showAdvices = false">Masquer</button>

        <div v-if="showAdvices">
       - L'injection Excel est recommandée pour la création et l'ajout de stock 
        mais est également utilisable pour des modifications.
        <br><br>
       - La colonne quantité ajoutera toujours la quantité à un produit donné
        mais ne la remplacera pas.
        <br><br> 
       - Attention, vous ne pouvez pas modifier la
        référence interne d'un produit existant avec l'injection excel, cela
        créera un nouveau produit. 
        <br><br>
       - Si vous souhaitez modifier la référence
        interne d'un produit veuillez utiliser l'éditeur au dessus. 
        <br><br>
       - Si vous modifiez le prix ou le fournisseur avec excel cela créera une ref
        jumelle.
        <br><br>
       - Pour la création d'un cable laissez la colonne "Réf interne" vide, 
        pour la modification d'un cable, rentrez la réf. du cable concernée.
        </div>
      </div>

      <ul class="results">
        Resultats :
        <li v-for="result in excelResult" :key="result">{{ result }}</li>
      </ul>
  <div class="get-type-excel">
      <button @click="exportToExcel()">Récupérer le fichier type</button>
      <select name="" id="" @change="choosenType = $event.target.value">
        <option selected value="consumable">Consommable</option>
        <option value="tool">Outil</option>
        <option value="cable">Cable</option>
        <option value="client">Client</option>
      </select>
</div>
      <h4>Colonnes interprétées :</h4>

      <div class="notice">
        <div>
          <button class='colored-btn' @click="seeFormatConsOut = !seeFormatConsOut">
            Consommables et outils
          </button>
          <ul v-show="seeFormatConsOut">
            <li><strong>Réf interne</strong></li>
            <li><strong>Désignation</strong></li>
            <li><strong>Catégorie</strong></li>
            <li>Sous-Catégorie<strong></strong></li>
            <li>Périodicité (outil)</li>
            <li><strong>Quantité</strong></li>
            <li>Sortie Max</li>
            <li>Conditionnement Sortie</li>
            <li><strong>Dépôt</strong></li>
            <li><strong>Tarif</strong></li>
            <li>Réf fournisseur</li>
            <li><strong>Nom fournisseur</strong></li>
            <li>Fournisseur par défaut</li>
            <li>Stock min</li>
            <li><strong>Conditionnement Entrée</strong></li>
          </ul>
        </div>
        <div>
          <button class='colored-btn' @click="seeFormatCable = !seeFormatCable">Cables</button>
          <ul v-show="seeFormatCable">
            <li><strong>Réf interne</strong></li>
            <li><strong>Désignation</strong></li>
            <li><strong>Catégorie</strong></li>
            <li>Sous-Catégorie<strong></strong></li>
            <li>Métré début</li>
            <li>Métré fin</li>
            <li><strong>Longueur restante</strong></li>
            <li><strong>Dépôt</strong></li>
            <li>Réf Client</li>
            <li><strong>Nom Client</strong></li>
            <li>Stock min</li>
            <li><strong>Conditionnement Entrée</strong></li>
          </ul>
        </div>
        <div>
          <button class='colored-btn' @click="seeFormatClient = !seeFormatClient">Client</button>
          
          <ul v-show="seeFormatClient">
            <li><strong>Réf interne</strong></li>
            <li><strong>Désignation</strong></li>
            <li><strong>Catégorie</strong></li>
            <li>Sous-Catégorie<strong></strong></li>
            <li><strong>Quantité</strong></li>
            <li>Conditionnement Sortie</li>
            <li><strong>Dépôt</strong></li>
            <li>Réf fournisseur</li>
            <li><strong>Nom fournisseur</strong></li>
            <li>Stock min</li>
          </ul>
          <span v-show="seeFormatClient"> "fournisseur" est laissé volontairement plutôt que "client" pour pouvoir injecter
            des produits clients et fournisseurs à partir de la même feuille.
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const XLSX = require("xlsx");
const { saveAs } = require('file-saver');
const myAppPath = require('./../../../switchUrl');
const appPath = myAppPath.appPath;
// item with image
class Item {
  constructor(
    ref,
    item,
    type_,
    sub_type,
    period,
    qtty_in,
    qtty_max,
    out_packaging,
    item_storage,
    price,
    ref_supplier,
    supplier,
    def_supplier,
    qtty_min,
    packaging,
    meter,
    username,
    data_feeder
  ) {
    this.ref = ref;
    this.item = item;
    this.type_ = type_;
    this.sub_type = sub_type;
    this.period = period;
    this.qtty_in = qtty_in;
    this.qtty_max = qtty_max;
    this.out_packaging = out_packaging;
    this.item_storage = item_storage;
    this.price = price;
    this.ref_supplier = ref_supplier;
    this.supplier = supplier;
    this.def_supplier = def_supplier;
    this.qtty_min = qtty_min;
    this.packaging = packaging;
    this.meter = meter;
    this.username = username;
    this.data_feeder = data_feeder;
  }
}

export default {
  name: "ExcelItem",
  data() {
    return {
      stopLoop : false,
      qtty_in: "",
      connectedUser: {},
      seeFormatConsOut: false,
      seeFormatCable: false,
      seeFormatClient: false,
      objectInside: {},
      bigArray: [],
      pickedPage: 0,
      excelResult: ['En attente d\'utilisation',
      'En attente d\'utilisation',
      'En attente d\'utilisation',
      'En attente d\'utilisation'],
      noModifMessage: null,
      choosenType : 'consumable',
      showAdvices : false
    };
  },
  mounted() {
    // récuperer l'utilisateur actuel
    this.connectedUser = JSON.parse(localStorage.getItem("connectedUser"));
  },
  methods: {
    //interprétation de l'excell
    previewFiles(e) {
      // vider les resultats
      this.excelResult = [];
      //commencer la lecture
      var files = e.target.files,
        f = files[0];
      var reader = new FileReader();
      let self = this;
      reader.onload = function(e) {
        var data = new Uint8Array(e.target.result);
        var workbook = XLSX.read(data, { type: "array" });
        //console.log(self.pickedPage);
        //console.log(typeof self.pickedPage);
        let sheetName = workbook.SheetNames[self.pickedPage];
        /* DO SOMETHING WITH workbook HERE */
        //console.log(workbook);
        let worksheet = workbook.Sheets[sheetName];
        const EXCEL_DATA = XLSX.utils.sheet_to_json(worksheet);
        self.checkExcelData(EXCEL_DATA);
      };
      reader.readAsArrayBuffer(f);
    },
    //envoyer les données vers une fonction selon leur catégorie
    checkExcelData(data) {
      //console.log(this.objectInside);
      for (let i in data) {
        //console.log(data[i]);
        var row = data[i];
        
        //Nettoyer les espaces inutiles avant et apres le mot
        for (let i of (Object.keys(row))){
          //console.log(row[i]);
          if(typeof row[i] === 'string'){
            //console.log(row[i].trim());
            row[i] = row[i].trim();
          }
        }

        if (!row["Catégorie"]) {
          alert("Colonne Catégorie introuvable");
          return;
        }
        //console.log(Object.keys(row));
        var type_ = this.capitalizeFirstLetter(row["Catégorie"]);
        switch (type_) {
          case "Consommable":
            //console.log('CONSOMMABLE');
            this.saveConsOut(row);
            break;
          case "Outil":
            //console.log('OUTILLAGE');
            this.saveConsOut(row);
            break;
          case "Cable":
            //console.log('CABLE');
            this.saveCable(row);
            break;
          case "Client":
            //console.log('CLIENT');
            this.saveClient(row);
            break;

          default:
            alert("Catégorie non reconnue");
            this.$refs.fileInput.value = '';
            return
            break;
        }

        if(this.stopLoop === true){
          this.stopLoop =false;
          return;
        }

      }
      //console.log("bigArray");
      //console.log(this.bigArray);
      const multiObject = this.bigArray;
      this.bigArray = [];
      //console.log(multiObject);
      this.excelItem(multiObject);
    },
    //trier les données si Consommable ou Outils
    saveConsOut(row) {
      let type_;
      let period;
      if ((this.capitalizeFirstLetter(row["Catégorie"])) === "Consommable") {
        type_ = "consumable";
        period = 0;
      } else if ((this.capitalizeFirstLetter(row["Catégorie"])) === "Outil") {
        type_ = "tool";
        period = row["Périodicité"];
        if(!row["Périodicité"]){
          
          alert("veuillez rentrer la période pour l'outil");
          this.$refs.fileInput.value = '';this.stopLoop = true;
          return
        }
      }
      //vérifier les données indispensables sont présentes

      if (!row["Réf interne"]){
        alert('donnée manquante Réf interne');
         this.$refs.fileInput.value = '';this.stopLoop = true;
        return
      }
      if (!row["Désignation"]){
        alert('donnée manquante Désignation');
         this.$refs.fileInput.value = '';this.stopLoop = true;
        return
      }
      if (!row["Sous-Catégorie"]){
        alert('donnée manquante Sous-Catégorie');
         this.$refs.fileInput.value = '';this.stopLoop = true;
        return
      }
      if (row["Quantité"] < 0 ){
        alert('donnée manquante Quantité');
         this.$refs.fileInput.value = '';this.stopLoop = true;
        return
      }
      else{
        this.qtty_in = 0;
      }
      if (!row["Sortie Max"]){
        alert('donnée manquante Sortie Max');
         this.$refs.fileInput.value = '';this.stopLoop = true;
        return
      }
      if (!row["Conditionnement Sortie"]){
        alert('donnée manquante Conditionnement Sortie');
         this.$refs.fileInput.value = '';this.stopLoop = true;
        return
      }
      if (!row["Dépôt"]){
        alert('donnée manquante Dépôt');
         this.$refs.fileInput.value = '';this.stopLoop = true;
        return
      }
      if (!row["Tarif"]){
        alert('donnée manquante Tarif');
         this.$refs.fileInput.value = '';this.stopLoop = true;
        return
      }
      if (!row["Réf fournisseur"]){
        alert('donnée manquante Réf fournisseur');
         this.$refs.fileInput.value = '';this.stopLoop = true;
        return
      }
      if (!row["Nom fournisseur"]){
        alert('donnée manquante Nom fournisseur');
         this.$refs.fileInput.value = '';this.stopLoop = true;
        return
      }
      if (!row["Fournisseur par défaut"]){
        alert('donnée manquante Fournisseur par défaut');
         this.$refs.fileInput.value = '';this.stopLoop = true;
        return
      }
      if (!row["Stock min"]){
        alert('donnée manquante Stock min');
         this.$refs.fileInput.value = '';this.stopLoop = true;
        return
      }
      if (!row["Conditionnement Entrée"]){
        alert('donnée manquante Conditionnement Entrée');
         this.$refs.fileInput.value = '';this.stopLoop = true;
        return
      }

      //console.log(row['Tarif ']);
      var createItem = new Item(
        row["Réf interne"],
        row["Désignation"],
        type_,
        row["Sous-Catégorie"],
        //row['Périodicité']
        period,
        row["Quantité"]||this.qtty_in,
        row["Sortie Max"],
        row["Conditionnement Sortie"],
        this.capitalizeFirstLetter(row["Dépôt"]),
        row["Tarif"],
        row["Réf fournisseur"],
        this.capitalizeFirstLetter(row["Nom fournisseur"]),
        row["Fournisseur par défaut"],
        row["Stock min"],
        row["Conditionnement Entrée"],
        //row['metter']
        null,
        this.connectedUser.username+' from_console X',
        this.connectedUser.username+' from_console X',
      );

      createItem = this.casseFilter(createItem);

      //console.log(createItem);
      this.bigArray.push(createItem);
    },
    //trier les données si Cable
    saveCable(row) {
      var meter = {
        start: row["Métré début"],
        end: row["Métré fin"],
      };
      meter = JSON.stringify(meter);
      const max = Math.max(row["Métré début"], row["Métré fin"]);
      const min = Math.min(row["Métré début"], row["Métré fin"]);
      const qtty_in = max - min;
      //const qtty_max = qtty_in;
      const qtty_max = 0;
      let ref;
      //console.log(row['Tarif ']);
    if (!row["Réf interne"]){
        ref = 'none';
         //this.$refs.fileInput.value = '';this.stopLoop = true;
        //return
      }
      else {
        ref = row["Réf interne"];
      }
      if (!row["Désignation"]){
        alert('donnée manquante Désignation');
         this.$refs.fileInput.value = '';this.stopLoop = true;
        return
      }
      if (!row["Sous-Catégorie"]){
        alert('donnée manquante Sous-Catégorie');
         this.$refs.fileInput.value = '';this.stopLoop = true;
        return
      }
      if (!row["Dépôt"]){
        alert('donnée manquante Dépôt');
         this.$refs.fileInput.value = '';this.stopLoop = true;
        return
      }
      /*if (!row["Tarif"]){
        alert('donnée manquante Tarif');
         this.$refs.fileInput.value = '';this.stopLoop = true;
        return
      }*/
      if (!row["Réf Client"]){
        alert('donnée manquante Réf fournisseur');
         this.$refs.fileInput.value = '';this.stopLoop = true;
        return
      }
      if (!row["Nom Client"]){
        alert('donnée manquante Nom fournisseur');
         this.$refs.fileInput.value = '';this.stopLoop = true;
        return
      }/*
      if (!row["Stock min"]){
        alert('donnée manquante Stock min');
         this.$refs.fileInput.value = '';this.stopLoop = true;
        return
      }
      if (!row["Conditionnement Entrée"]){
        alert('donnée manquante Conditionnement Entrée');
         this.$refs.fileInput.value = '';this.stopLoop = true;
        return
      }*/

      var createItem = new Item(
        ref,
        row["Désignation"],
        "cable",
        row["Sous-Catégorie"],
        //row['Périodicité']
        0,
        //row['Quantité']
        qtty_in,
        //row['Sortie Max']
        qtty_max,
        //row['Conditionnement Sortie']
        1,
        this.capitalizeFirstLetter(row["Dépôt"]),
        //row['Tarif ']
        0,
        row["Réf Client"],
        this.capitalizeFirstLetter(row["Nom Client"]),
        //row['Fournisseur par défaut']
        "",
        row["Stock min"]|| 0,
        row["Conditionnement Entrée"] || 0,
        //row['metter'] CALCUL A FAIRE
        meter,
        this.connectedUser.username+' from_console X',
        this.connectedUser.username+' from_console X',
      );

      //console.log(createItem);
      this.bigArray.push(createItem);
    },
    //trier les données si Client
    saveClient(row) {
      //console.log(row['Tarif ']);
      if (!row["Réf interne"]){
        alert('donnée manquante Réf interne');
         this.$refs.fileInput.value = '';this.stopLoop = true;
        return
      }
      if (!row["Désignation"]){
        alert('donnée manquante Désignation');
         this.$refs.fileInput.value = '';this.stopLoop = true;
        return
      }
      if (!row["Sous-Catégorie"]){
        alert('donnée manquante Sous-Catégorie');
         this.$refs.fileInput.value = '';this.stopLoop = true;
        return
      }
      if (row["Quantité"] < 0 ){
        alert('donnée manquante Quantité');
         this.$refs.fileInput.value = '';this.stopLoop = true;
        return
      }
      else{
        this.qtty_in = 0;
      }
      if (!row["Conditionnement Sortie"]){
        alert('donnée manquante Conditionnement Sortie');
         this.$refs.fileInput.value = '';this.stopLoop = true;
        return
      }
      if (!row["Dépôt"]){
        alert('donnée manquante Dépôt');
         this.$refs.fileInput.value = '';this.stopLoop = true;
        return
      }
      if (!row["Réf fournisseur"]){
        alert('donnée manquante Réf fournisseur');
         this.$refs.fileInput.value = '';this.stopLoop = true;
        return
      }
      if (!row["Nom fournisseur"]){
        alert('donnée manquante Nom fournisseur');
         this.$refs.fileInput.value = '';this.stopLoop = true;
        return
      }
      if (!row["Stock min"]){
        alert('donnée manquante Stock min');
         this.$refs.fileInput.value = '';this.stopLoop = true;
        return
      }
  
      
      var createItem = new Item(
        row["Réf interne"],
        row["Désignation"],
        "client",
        row["Sous-Catégorie"],
        //row['Périodicité']
        0,
        row["Quantité"]||this.qtty_in,
        //row['Sortie Max']
        0,
        row["Conditionnement Sortie"],
        this.capitalizeFirstLetter(row["Dépôt"]),
        //row['Tarif ']
        0,
        row["Réf fournisseur"],
        this.capitalizeFirstLetter(row["Nom fournisseur"]),
        //row['Fournisseur par défaut']
        "",
        row["Stock min"],
        //row['Conditionnement Entrée']
        0,

        //row['metter']
        null,
        this.connectedUser.username+' from_console X',
        this.connectedUser.username+' from_console X'
      );

      //console.log(createItem);
      this.bigArray.push(createItem);
    },
    //permet d'obtenir la meme casse que sur une edition
    casseFilter(item){
      for (var i in item) {
          //console.log(i);
          //console.log(typeof i);
          if (item.type_ === 'cable' && typeof item[i] === "string" && i === "item") {
            item[i] = (item[i].trim()).toUpperCase();
          }
          else if (typeof item[i] === "string" && i != "type_") {
            item[i] = this.capitalizeFirstLetter(item[i].trim());
          }
          else if (typeof  item[i] === "string" &&
            i === "type_"){
              item[i] = item[i].trim();
            }
            
        }
        return item
    },
    //STORE REQUEST SEND EXCEL DATA
    //AND PRINT RESULT AFTER RESPONSE
    excelItem(multiObject) {
      this.fetchExcelItem(multiObject)
        .then((data) => {
          //console.log(data);
          // rafraichir les données
          if (data["data"]) {
            if (data["data"] === "Erreur, commande en cours") {
              alert(
                "Erreur, l'une vos ligne concerne une commande en cours, utilisation excel impossible"
              );
              this.$refs['fileInput'].value = '';
              return;
            }
            if(data['data'].includes('ERREUR')){
          //console.log(data['data']);
          alert('Injection Excel échouée');
          this.$refs['fileInput'].value = '';
          return
        };

        this.$emit('refreshing2', true);
            //console.log(data['data']);
            for (let i in data["data"]) {
              //console.log(i);
              //seul les clefs impairs contiennent l'information souhaitée
              if (this.numIsImpair(i)) {
                //console.log(data["data"][i]);
                if (this.noModifMessage === null) {
                  this.excelResult.push(data["data"][i]);
                } else {
                  this.excelResult.push(this.noModifMessage);
                }
                this.noModifMessage = null;
              } else {
                // items_table
                //console.log('ITEM LINE '+ i.toString());
                //console.log(data['data'][i][0]);
                //console.log(data['data'][i][0].changedRows);
                //details
                //console.log('DETAILS LINE ' + i.toString());
                //console.log(data['data'][i][1]);
                //console.log(data['data'][i][1].changedRows);

                if (
                  data["data"][i][0].insertId === 0 &&
                  data["data"][i][0].changedRows === 0 &&
                  data["data"][i][1].changedRows <= 1
                ) {
                  this.noModifMessage =
                    "Ligne " +
                    (i.toString() / 2 + 1) +
                    " : Aucune modification détéctée";
                } else {
                  //console.log('ITEM LINE '+ i.toString());
                  //console.log(data['data'][i][0]);
                  //console.log(data['data'][i][0].changedRows);
                  //details
                  //console.log('DETAILS LINE ' + i.toString());
                  //console.log(data['data'][i][1]);
                  //console.log(data['data'][i][1].changedRows);
                }
              }
            }
            this.$refs.fileInput.value = "";

            //alert('SUCCES');
          } else {
            alert("UNE ERREUR EMPECHE L'INJECTION EXCEL");
            this.$refs.fileInput.value = "";
          }
        })
        .catch((e) => console.log(e));
    },
    async fetchExcelItem(multiObject) {
      let key;
      //key for only web app native request (!postman)
      if (
        (this.connectedUser.a5e789410fd.includes("2f+g2dfg25h2d+f15b1gs5bnj1ki1lo61q1dc16"))
      ) {
        key = "h1d5he15fdhs";
      }
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
        body: JSON.stringify({
          multiObject: multiObject,
          user_id: this.connectedUser.id_user,
          key: key,
        }),
      };

      let response = await fetch(
        appPath+"/items/excel_item",
        requestOptions
      );
      if (!response.ok) {
        // get error message from body or default to response status
        const error = (data && data.message) || response.status;
        //console.log('not response ok, error : ' + error);
        alert("une erreur innattendue s'est produite");
        return Promise.reject(error);
      }
      return await response.json();
    },
    // gérer la casse des string
    capitalizeFirstLetter(string) {
      //console.log(typeof string);
      if (typeof string === "string") {
        const str = string.toLowerCase();
        //console.log(str.charAt(0).toUpperCase() + str.slice(1) === 'Consommable');
        return str.charAt(0).toUpperCase() + str.slice(1);
      } else {
        return string;
      }
    },
    //vérifier si un nombre est impair
    numIsImpair(n) {
      return n & 1 ? true : false;
    },
    //methodes pour récupérer un excel vide avec les colonnes
    exportToExcel(){
        //var miror = JSON.parse(JSON.stringify(this.translateForExcel));
        const consObject = [];
        if(this.choosenType === 'consumable'){
          consObject.push(JSON.parse(JSON.stringify({
          'Réf interne' :null,
          'Désignation':null,
          'Catégorie':null,
          'Sous-Catégorie':null,
          'Quantité':null,
          'Sortie Max':null,
          'Conditionnement Sortie':null,
          'Dépôt':null,
          'Tarif':null,
          'Réf fournisseur':null,
          'Nom fournisseur':null,
          'Fournisseur par défaut':null,
          'Stock min':null,
          'Conditionnement Entrée':null
        })));
        }
        else if(this.choosenType === 'tool'){
          consObject.push(JSON.parse(JSON.stringify({
          'Réf interne' :null,
          'Désignation':null,
          'Catégorie':null,
          'Sous-Catégorie':null,
          'Périodicité':null,
          'Quantité':null,
          'Sortie Max':null,
          'Conditionnement Sortie':null,
          'Dépôt':null,
          'Tarif':null,
          'Réf fournisseur':null,
          'Nom fournisseur':null,
          'Fournisseur par défaut':null,
          'Stock min':null,
          'Conditionnement Entrée':null
        })));
        }
        else if(this.choosenType === 'cable'){
          consObject.push(JSON.parse(JSON.stringify({
          'Réf interne' :null,
          'Désignation':null,
          'Catégorie':null,
          'Sous-Catégorie':null,
          'Métré début':null,
          'Métré fin':null,
          //'Longueur restante':null,
          'Dépôt':null,
          'Réf Client':null,
          'Nom Client':null
          /*
          'Stock min':null,
          'Conditionnement Entrée':null*/
        })));
        }
        else if(this.choosenType === 'client'){
          consObject.push(JSON.parse(JSON.stringify({
          'Réf interne' :null,
          'Désignation':null,
          'Catégorie':null,
          'Sous-Catégorie':null,
          'Quantité':null,
          'Conditionnement Sortie':null,
          'Dépôt':null,
          'Réf fournisseur':null,
          'Nom fournisseur':null,
          'Stock min':null,
        })));
        }

        //console.log(consObject);
        //return
        this.downloadAsExcel(consObject);
      },
      downloadAsExcel(data){
        let worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = {
          Sheets : {
            'data':worksheet
          },
          SheetNames: ['data']
        };
        const excelBuffer = XLSX.write(workbook, {booktype : 'xlsx', type : 'array'});
        console.log(excelBuffer);
        this.saveAsExcel(excelBuffer, 'myFile');
      },
      saveAsExcel(buffer,filename){
        const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([buffer],{type:EXCEL_TYPE});
        saveAs(data,filename+'_export_'+new Date().getTime()+EXCEL_EXTENSION);
      }

  },
};
</script>

<style scoped>
.flex {
  display: flex;
  border: green 5px solid;
  padding: 10px;
  width: 600px;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: auto;
  margin-bottom: 20px;
}
label {
  display: flex;
  justify-content: center;
  text-align: left;
  font-size: 25px;
}
#icon1 {
  max-height: 50px;
  margin-right: 20px;
  margin-left: 10px;
}
#icon2 {
  max-height: 50px;
}
.results {
  background-color: #f38414;
  color : white;
  font-size: 20px;
  width: 65%;
}
.results > li {
  text-align: start;
}
.notice {
  width: 100%;
  display: flex;
}
h4 {
  width: 600px;
}
.notice > div {
  margin: 0;
  width: 200px;
}

input[type=file], /* FF, IE7+, chrome (except button) */
input[type=file]::-webkit-file-upload-button {
  /* chromes and blink button */
  cursor: pointer;
  background-color: #0d444b;
  color : white;
  height: 40px;
}
.colored-btn {
  background-color: #f38414;
  color : white;
}
.get-type-excel{
  margin: 10px;
  padding: 20px;
  background-color: #0d444b;
}
.get-type-excel button{
  font-size: 19px;
}
.get-type-excel select{
  font-size: 19px;
}
.vertical-margin {
  margin : 20px 0;
}
@media screen and (max-width: 1100px) {
  .flex {
    width: 300px;
  }
  .notice {
  flex-direction: column;
  align-items: center;
}
button {
  margin: 5px;
}
ul{
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.results{
  margin: auto;
  width: 250px;
}
.results > li {
  font-size: 15px;
}
li{
  font-size: 20px;
  margin-right: 60px;
}
}
</style>
