<template>
<div v-if="loader" ref="main" class="flex-container">
  <!--{{cables}}!-->
  <!--{{activitiesHistory[0]}}}
  <br><br><br>
  {{activitiesHistory[1]}}!-->
  <!--{{booking}}!-->
  <!-- check total booking !-->
    <!-- ALERT MESSAGES !-->
  <div  class="total-not-ok card-alert" v-if="totalBooking === false">
    <span class="warning">&#9888;</span> Les disponibilités totales reservations 
    ne correspondent pas avec les reservations.
  </div>
  <!-- SUCCES MESSAGE !-->
  <div class="total-ok card-alert" v-else>
    Les disponibilités totales (réservations) correspondent  <span class="warning">&#10003;</span>
  </div>
<div class="flex-container" v-if="Object.values(booking).length != 0">
  <h3>* Alertes reservations *</h3> 
  <h4>(Quantités table 1)</h4>
 <!-- ALERT MESSAGES !-->
      <div  v-for="item in totalBookingDetails" :key="item">
        <div class="booking-alert card-alert" :style="item==='le nombre de ref concernés est différent' ? { backgroundColor: '#E1DFDE' } : {}">
         {{item}}
        </div>
        
       <!-- {{item.qtty_in}} {{item.qtty_in_bis}} <br>
        {{item.qtty_available}} {{item.qtty_available_bis}} !-->
      </div>
      <!-- SUCCES MESSAGE !-->
       <div class="total-ok card-alert" v-if="Object.values(totalBookingDetails).length === 0">
    Aucune incohérence dans les reservations <span class="warning">&#10003;</span>
      </div>

      <h4>(Quantités table 2)</h4>
 <!-- ALERT MESSAGES !-->
      <div class="booking-alert card-alert" v-for="item in totalBookingDetails2" :key="item">
        <div :style="item==='le nombre de ref concernés est différent' ? { backgroundColor: '#E1DFDE' } : {}">
         {{item}}
        </div>
        
       <!-- {{item.qtty_in}} {{item.qtty_in_bis}} <br>
        {{item.qtty_available}} {{item.qtty_available_bis}} !-->
      </div>
      <!-- SUCCES MESSAGE !-->
       <div class="total-ok card-alert" v-if="Object.values(totalBookingDetails2).length === 0">
    Aucune incohérence dans les reservations <span class="warning">&#10003;</span>
      </div>

</div>
<div class="flex-container" v-else-if="Object.values(booking).length === 0 && totalBooking === false">
  <h3>* Alertes reservations *</h3> 
  <div class="booking-alert card-alert">
         Les reservations en cours sont vides, pourtant 
    certains objets ont une quantité réelle et commandée différentes
        </div>
            <div  v-for="item in totalBookingDetails" :key="item">
        <div class="booking-alert card-alert" >
         {{item}}
        </div>
       <!-- {{item.qtty_in}} {{item.qtty_in_bis}} <br>
        {{item.qtty_available}} {{item.qtty_available_bis}} !-->
      </div>
      <!-- SUCCES MESSAGE !-->
       <div class="total-ok card-alert" v-if="Object.values(totalBookingDetails).length === 0">
    Erreur inattendue sur les réservations, veuillez en informer le développeur (vous pouvez aussi allumer le Bat-Signal !)<span class="warning">&#10003;</span>
      </div>

</div>

   <!-- check quantity !-->
    <div class="flex-container" v-if="Object.values(allTheStock).length != 0">
      <h3>* Alertes quantités & cohérence*</h3>
      <!-- ALERT MESSAGES !-->
      <div  v-for="item in filterAllTheStock(allTheStock)" :key="item">
        <div class="card-alert" v-if="item.report.includes('in')">
          ALERTE : [id : {{item.id}}] -- [REF {{item.ref}}]  -> qtté != qtté détails
        </div>
        <div class="card-alert" v-if="item.report.includes('available')">
          ALERTE : [id : {{item.id}}] -- [REF : {{item.ref}}] -> qtté dispo != qtté dispo détails
        </div>
        <div v-if="item.report.includes('ref error')"
        class="total-not-ok card-alert">
          ALERTE CRITIQUE : [id : {{item.id}}] -- [REF : {{item.ref}}] -> Erreur critique la référence est différente entre deux tables
        </div>
        <div v-if="item.report.includes('location error')"
        class="total-not-ok card-alert">
          ALERTE CRITIQUE : [id : {{item.id}}] -- [REF : {{item.ref}}] -> Erreur critique le lieu de stockage est différent entre deux tables
        </div>
       <!-- {{item.qtty_in}} {{item.qtty_in_bis}} <br>
        {{item.qtty_available}} {{item.qtty_available_bis}} !-->
      </div>
      <!-- SUCCES MESSAGE !-->
       <div class="total-ok card-alert" v-if="Object.values(filterAllTheStock(allTheStock)).length === 0">
    Aucune erreur de quantités dans le stock <span class="warning">&#10003;</span>
      </div>

    </div>

       <!-- row count and compare !-->
    <div class="flex-container" v-if="Object.values(rowsCounter).length != 0">
      <h3>* Alertes cohérence global*</h3>
      <!-- ALERT MESSAGES !-->
      <div  v-for="item in rowsCounterCompare(rowsCounter)" :key="item">
        <div class="card-alert">
          ALERTE : [ {{item}} -> le nombres de lignes ne correspondent pas]
        </div>
      </div>
      <!-- SUCCES MESSAGE !-->
       <div class="total-ok card-alert" v-if="Object.values(rowsCounterCompare(rowsCounter)).length === 0">
    Aucune incohérence globale <span class="warning">&#10003;</span>
      </div>

    </div>

           <!-- cable meter vs qtty !-->
    <div class="flex-container" v-if="Object.values(cables).length != 0">
      <h3>* Alertes mesures cables*</h3>
      <!-- ALERT MESSAGES !-->
      <div  v-for="item in cableMeasure(cables)" :key="item">
        <div v-if="item['movement']" class="card-alert">
          ALERTE CABLE : [ {{item['item']}} {{item['ref']}} 
          -> Une erreur a été detectée sur le suivi de localisation du touret ]
        </div>

        <div v-if="item['mesure']" class="card-alert">
          ALERTE CABLE : [ {{item['item']}} {{item['ref']}} 
          -> les mesures ne correspondent pas avec la quantités]
        </div>
        <div v-else-if="!item['meter'] || !JSON.parse(item['meter']).start" class="card-alert">
          ALERTE CABLE : [ {{item['item']}} {{item['ref']}}  
          -> les metrés sont nuls]
        </div>
      </div>
      <!-- SUCCES MESSAGE !-->
       <div class="total-ok card-alert" v-if="Object.values(cableMeasure(cables)).length === 0">
    Les mesures de cables et les quantités concordent, pas d'erreurs de localisation <span class="warning">&#10003;</span>
      </div>

    </div>

      <!-- check on order !-->
    <div class="flex-container" v-if="Object.values(cables).length != 0">
      <h3>* Alertes Commandes Admin en cours*</h3>
      <!-- ALERT MESSAGES !-->
      <div  v-for="item in onOrderChecking(allTheStock)" :key="item">
        <div class="card-alert">
          ALERTE COMMANDES ADMIN : [ {{item['item']}} {{item['ref']}} 
          -> Une erreur d'actualisation a été detectée sur le statut d'un produit ]
        </div>
      </div>
      <!-- SUCCES MESSAGE !-->
       <div class="total-ok card-alert" v-if="Object.values(onOrderChecking(allTheStock)).length === 0">
    Les statuts "en commande" sont cohérents <span class="warning">&#10003;</span>
      </div>

    </div>

      <!-- check activities !-->
    <div class="flex-container" v-if="Object.values(cables).length != 0">
      <h3>* Alertes Suivi d'Activité *</h3>
      <!-- ALERT MESSAGES !-->
      <div  v-for="item in activitiesChecking()" :key="item">
        <div class="card-alert">
          ALERTE ACTIVITES : {{item}} 
          -> Un problème a été detecté pour le suivi de consommation du chantier et de l'objet
        </div>
      </div>
      <!-- SUCCES MESSAGE !-->
       <div class="total-ok card-alert" v-if="Object.values(activitiesChecking()).length === 0">
    Les suivis quantités chantier sont cohérents <span class="warning">&#10003;</span>
      </div>

    </div>

     <!-- check twins ref !-->
     <h3>* Alertes refs jumelles *</h3>
    <div class="flex-container" v-if="Object.values(twinsRef).length != 0">
      <span v-if="Object.values(filterTwinsRef(twinsRef)).length != 0"> <strong> Les refs jumelles ne doivent 
        avoir que 5 valeurs différentes possibles : </strong> 
        Le prix, l'endroit de stockage, le fournisseur 
        et dans certains cas la ref fournisseur & le conditionnement fournisseur.</span>
        <span v-if="Object.values(filterTwinsRef(twinsRef)).length != 0">Pour le reste il est impératif d'éviter toutes différences entre les refs jumelles 
          sous peine d'avoir des erreurs de rendus et/ou de contrôle sur votre application</span>
          <br>
      <!-- ALERT MESSAGES !-->
      <div class="twins-alert card-alert" v-for="item, name in filterTwinsRef(twinsRef)" :key="item" :value="name">
            <div>
              <h4> Référence concernée = "{{name}}"</h4>
              <ul>
                <li v-for="i in item" :key="i">
                  {{i}}
                </li>
              </ul>
              </div>

      </div>
       <!-- SUCCES MESSAGE !-->
       <div class="total-ok card-alert" v-if="Object.values(filterTwinsRef(twinsRef)).length === 0">
        Cohérence des références jumelles respectée <span class="warning">&#10003;</span>
      </div>
      <!-- SUCCES MESSAGE !-->
       <div class="twins-neutral card-alert" v-if="Object.values(twinsRef).length === 0">
        Aucune ref jumelle dans le stock ...
      </div>

    </div>

         <!-- check twins ref !-->
     <h3>* Nettoyage refs jumelles *</h3>
    <div class="flex-container" v-if="Object.values(twinsRef).length != 0 ">
      <div v-show="showCleaning">
          <button @click="showCleaning = false"> Masquer </button>
      <!-- ALERT MESSAGES !-->
      <div  class="twins-info card-alert" v-for="item,name,index  in filterTwinsRefPrice(twinsRef)" 
      :key="item" :value="name" :index="index">
            <div>
              <h4> Référence concernée = "{{name.replace(';',' / ')}}"</h4>
              <ul>
                <li v-for="i in item" :key="i">
                  {{i}}
                </li>
              </ul>
              <h4>FIFO antérieur & Quantités à zéro : </h4>
              <div v-for="x in this.twinsRefForClean[name].oldRef" :key="x"
              >
               <span :ref="name +' '+x"> {{formatTwin(name,x)}}</span>
               <span class="confirmDelete" v-if="this.globalEventHandler.includes(name +'btn '+x)"
              >Suppression effectuée</span>
                <button v-else
                style="margin-left : 20px" :ref="name +'btn '+x" @click="cleanOldTwinRef(name +' '+x,name +'btn '+x)">Supprimer</button>
              </div>
              <span v-if="this.twinsRefForClean[name].oldRef.size == 0"
              style="color : green"> Pas de référence 'à stock zéro' prête pour suppression...</span>
              
           
              </div>

      </div>
       <!-- SUCCES MESSAGE !-->
       <div class="total-ok card-alert" v-if="Object.values(filterTwinsRefPrice(twinsRef)).length === 0">
        Pas de nettoyage envisagé <span class="warning">&#10003;</span>
      </div>
      <!-- SUCCES MESSAGE !-->
       <div
       class="twins-neutral card-alert" v-if="Object.values(twinsRef).length === 0">
        Aucune ref jumelle dans le stock ...
      </div>

    </div></div>
    <button v-if="!showCleaning" @click="showAndScroll()"> Afficher </button>
      <span>version 1.18 </span>
</div>
<div v-else class="big-loader"> Création du rapport ... &#8987;</div>
</template>

<script>
import { mapState } from "vuex";

const myAppPath = require('./../../../switchUrl');
const appPath = myAppPath.appPath;

export default {
  name: "Report",
  data: function() {
    return {
      connectedUser : {},
      allTheStock : {},
      booking : {},
      twinsRef : {},
      showCleaning : false,
      rowsCounter : {},
      cables : [],
      loader : false,
      twinsRefForClean : {},
      globalEventHandler : [],
      activitiesWarning : []
    };
  },

  mounted() {
    this.$store.commit("isConnected");
  this.connectedUser = JSON.parse(localStorage.getItem("connectedUser"));
  this.allTypesDetails();
  //console.log('test 01');
  this.bookingInProgress();
  this.allTwinsRef();
  this.getCountRows();
  this.getReelsMovements();
  this.getOrderInProgress();
  this.getActivitiesHistory();
  //console.log('test 02');
  setTimeout(() => {
    this.loader = true;
  }, 1300);
  //console.log('test 03');
  },
  computed: {
    //STORE
    ...mapState({
      reelsMovement : "reelsMovement",
      myReceptions : "myReceptions",
      myRemainders : "myRemainders",
      activitiesHistory : "activitiesHistory"
    }),
    totalBooking(){
      const data = this.allTheStock;
      var totalStep = 0;
      var totalBooked = 0;
      for(let i in data)
      {
        if(data[i].qtty_in != data[i].qtty_available){
          totalStep = totalStep + data[i].qtty_in - data[i].qtty_available;
        }
      };
      //console.log('test 04');
      for(let i in this.booking){
        totalBooked = totalBooked + Math.abs(this.booking[i].qtty);
      }
      if(totalStep === totalBooked){
        return true
      }
      else {
        return false
      }
      
    },
    // pour table item_table
    totalBookingDetails(){
      const data = this.allTheStock;
      var reportDetails = [];
      var totalStep = {};
      var totalBooked = {};
      // boucle sur data
      for(let i in data){
        if(data[i].qtty_in != data[i].qtty_available){
          totalStep[data[i].id] = data[i].qtty_in - data[i].qtty_available;
        }
      }
      //console.log('test 05');
      if(Object.values(this.booking).length != 0){
      // boucle sur booking
      for(let i in this.booking){
        const id = this.booking[i].item_id;
        if(!totalBooked[id]){
        totalBooked[id] = Math.abs(this.booking[i].qtty);
      }else {
        totalBooked[id] = totalBooked[id]+ Math.abs(this.booking[i].qtty);;
      }
      }
      //console.log('test 06');
      if(Object.values(totalBooked).length != Object.values(totalStep).length)
      {
        reportDetails.push('le nombre de ref concernés est différent');
      };
      //comparer les resultats des deux objets
      for(let i in totalBooked){
        if(i in totalStep){
          //console.log(i +' ' + totalStep[i]+ ' ' + totalBooked[i]);
          if(!(totalStep[i] === totalBooked[i])){
            console.log('les quantités reservés et dispo de l\'id : '+i+' ne correspondent pas');
            reportDetails.push('les quantités reservés et dispo de l\'id : '+i+' ne correspondent pas');
          }
        }else{
          //console.log('la ref portant l\'id : '+i+' est reservée mais pourtant 100% dispo' );
          reportDetails.push('la ref portant l\'id : '+i+' est reservée mais pourtant 100% dispo' );
        }
      }
     // console.log('test 07');
      for(let i in totalStep){
        if(i in totalBooked){
          if(!(totalStep[i] === totalBooked[i])){
            //console.log('les quantités reservés et dispo de l\'id : '+i+' ne correspondent pas (2)');
            reportDetails.push('les quantités reservés et dispo de de l\'id : '+i+' ne correspondent pas (2)');
          }
        }else{
          //console.log('la ref portant l\'id : '+i+' est indispo mais n\'apparait pas dans les reservations' );
          reportDetails.push('la ref portant l\'id : '+i+' est indispo mais n\'apparait pas dans les reservations' );
        }
      }
      return reportDetails
      }
      else if ((Object.values(this.booking).length === 0) && (this.totalBooking === false)){
        for(let i in totalStep){
          //console.log('la ref portant l\'id : '+i+' est indispo mais n\'apparait pas dans les reservations' );
          reportDetails.push('la ref portant l\'id : '+i+' est indispo mais n\'apparait pas dans les reservations' );
      }
      return reportDetails
      }
    },
    // pour table détails
    totalBookingDetails2(){
      const data = this.allTheStock;
      var reportDetails = [];
      var totalStep = {};
      var totalBooked = {};
      // boucle sur data
      for(let i in data){
        if(data[i].qtty_in_bis != data[i].qtty_available_bis){
          totalStep[data[i].id] = data[i].qtty_in_bis - data[i].qtty_available_bis;
        }
      }
      //console.log('test 08');
      // boucle sur booking
      for(let i in this.booking){
        const id = this.booking[i].item_id;
        if(!totalBooked[id]){
        totalBooked[id] = Math.abs(this.booking[i].qtty);
      }else {
        totalBooked[id] = totalBooked[id]+ Math.abs(this.booking[i].qtty);;
      }
      }
      if(Object.values(totalBooked).length != Object.values(totalStep).length)
      {
        reportDetails.push('le nombre de ref concernés est différent');
      };
      //console.log('test 09');
      //comparer les resultats des deux objets
      for(let i in totalBooked){
        if(i in totalStep){
          //console.log(i +' ' + totalStep[i]+ ' ' + totalBooked[i]);
          if(!(totalStep[i] === totalBooked[i])){
            //console.log('les quantités reservés et dispo de l\'id : '+i+' ne correspondent pas');
            reportDetails.push('les quantités reservés et dispo de l\'id : '+i+' ne correspondent pas');
          }
        }else{
          //console.log('la ref portant l\'id : '+i+' est reservée mais pourtant 100% dispo' );
          reportDetails.push('la ref portant l\'id : '+i+' est reservée mais pourtant 100% dispo' );
        }
      }
      //console.log('test 10');
      for(let i in totalStep){
        if(i in totalBooked){
          if(!(totalStep[i] === totalBooked[i])){
            //console.log('les quantités reservés et dispo de l\'id : '+i+' ne correspondent pas (2)');
            reportDetails.push('les quantités reservés et dispo de de l\'id : '+i+' ne correspondent pas (2)');
          }
        }else{
          //console.log('la ref portant l\'id : '+i+' est indispo mais n\'apparait pas dans les reservations' );
          reportDetails.push('la ref portant l\'id : '+i+' est indispo mais n\'apparait pas dans les reservations' );
        }
      }
      //console.log('test 11');
      return reportDetails
    },
  },
  methods: {
    rowsCounterCompare(data){
      var obj = [];
      //console.log(data)
      //console.log(data[0])
      //console.log(data[1][0])
      //console.log(data[1][0]["COUNT(id_details)"])
      if(data[0][0]["COUNT(id)"] != data[1][0]["COUNT(id_details)"]){
        obj.push(data[0][0]["COUNT(id)"]+'- bad_count -'+data[1][0]["COUNT(id_details)"]);
      }
      else{
      for(let i in data[2]){
        if(data[2][i].id != data[3][i].id_details){
          /*obj.push([data[i].id,data[i].ref, 'in'])*/
          obj.push(data[2][i].id+'divergence_count'+data[3][i].id);
        }
      }}
    return obj
    },
    cableMeasure(data){
       var obj = [];
      var miror = {};
      for (let i in data){
        var validator = false;
        // vérifier la cohérence des mouvements
        if(data[i].period != 0){
          
          for( let x in this.reelsMovement){
            if(this.reelsMovement[x]['id_reel'] === data[i]['id_details']){
              validator = true;
            }
          }
          if(!validator){
            miror[i] = JSON.parse(JSON.stringify(data[i]));
            miror[i]['movement'] = true;
            obj.push(miror[i]);
          }
        }
        else{
           for( let x in this.reelsMovement){
            if(this.reelsMovement[x]['id_reel'] === data[i]['id_details']){
              validator = true;
            }
          }
          if(validator){
            miror[i] = JSON.parse(JSON.stringify(data[i]));
            miror[i]['movement'] = true;
            obj.push(miror[i]);
          }
        };

        const meter = JSON.parse(data[i].meter);
        // vérifier si meter est nul
        if(!meter || !meter['start']){
          miror[i] = JSON.parse(JSON.stringify(data[i]));
          if(!obj.includes(miror[i])){
          obj.push(miror[i]);
          continue;
          }
        }
        const start = meter['start'];
        const end = meter['end'];
        const qtty = data[i].qtty_in;
        //verfier la mesure par rapport qtty
        if(qtty === start-end || qtty === end-start){
          //ok
        }
        else{
          miror[i] = JSON.parse(JSON.stringify(data[i]));
          miror[i]['mesure'] = true;
          if(!obj.includes(miror[i])){
          obj.push(miror[i]);}
        }
      }
      //console.log('test 12');
      return obj;
    },
    onOrderChecking(data){
      var obj = [];
      var arrNames = [];
      // verifier dans les deux sens si on_order & receptions/reliquats correspondent
      for (let i in data){
        var validator = false;
        if(data[i]['on_order'] != 0 ){
          for (let x in this.myReceptions){
            if(this.myReceptions[x]['ref'] === data[i]['ref']){
              validator = true;
            }
          }
          for (let x in this.myRemainders){
            if(this.myRemainders[x]['ref'] === data[i]['ref']){
              validator = true;
            }
          }
          if(!validator && !obj.includes(data[i]) && !arrNames.includes(data[i]['ref'])){
            arrNames.push(data[i]['ref']);
          obj.push(data[i]);}
        }
        else {
          for (let x in this.myReceptions){
            if(this.myReceptions[x]['ref'] === data[i]['ref']){
              validator = true;
            }
          }
          for (let x in this.myRemainders){
            if(this.myRemainders[x]['ref'] === data[i]['ref']){
              validator = true;
            }
          }
          if(validator && !obj.includes(data[i]) && !arrNames.includes(data[i]['ref'])){
            arrNames.push(data[i]['ref']);
          obj.push(data[i]);}
        }
        }
        //console.log('test 13');
      return obj;
    },
    getActivitiesHistory(){
      this.$store.dispatch('getActivitiesHistory',this.connectedUser.id_user);
    },
    activitiesChecking(){
      //console.log('function');
      var obj = JSON.parse(JSON.stringify(this.activitiesHistory[0]));
      var arrayWarning = [];
      
        this.activitiesHistory[1].forEach(element => {
          for(let i in obj){
            if(obj[i].construction_nb === element.construction_nb 
            && obj[i].item === element.item){
              if(obj[i]['SUM(qtty)'] === element.qtty){
                delete obj[i];
                //console.log(obj);
                continue
              }
              else{
                arrayWarning.push('chantier '+element.construction_nb+' ! '+element.item)
              }
            }
          }
          
        });  
        //console.log(arrayWarning);
        this.activitiesWarning = arrayWarning;
        return arrayWarning
    },
    filterAllTheStock(data){
      //console.log(data);
      var obj = {};
      for(let i in data){
        if(data[i].qtty_in != data[i].qtty_in_bis){
          /*obj.push([data[i].id,data[i].ref, 'in'])*/
          //data[i].report = [];
          //data[i].report.push('in');
          obj[i] = JSON.parse(JSON.stringify(data[i]));;
          obj[i].report = [];
          obj[i].report.push('in');
        }
        if(data[i].qtty_available != data[i].qtty_available_bis){
          /*obj.push([data[i].id,data[i].ref, 'available'])*/
          
          obj[i] = JSON.parse(JSON.stringify(data[i]));;
          if(!obj[i].report){
          obj[i].report = []};
          obj[i].report.push('available');
        }
        if(data[i].ref != data[i].internal_ref){
          /*obj.push([data[i].id,data[i].ref, 'available'])*/
          /*if(!data[i].report){
          data[i].report = []};
          data[i].report.push('ref error');*/
          obj[i] = JSON.parse(JSON.stringify(data[i]));;
          if(!obj[i].report){
          obj[i].report = []};
          obj[i].report.push('ref error');
        }

        if(data[i].item_storage != data[i].item_storage_bis){
          /*obj.push([data[i].id,data[i].ref, 'available'])*/
         /* if(!data[i].report){
          data[i].report = []};
          data[i].report.push('location error');*/
          obj[i] = JSON.parse(JSON.stringify(data[i]));
          if(!obj[i].report){
          obj[i].report = []};
          obj[i].report.push('location error');
        }
      }
      //console.log('test 14');
      //console.log(obj);
      return obj
    },
    filterTwinsRef(data){
      var array = [];
      var saveRef = {};
      var miror = {};
      var warningRef = {};
      for(let i in data){
        const ref = data[i].ref
        if(!array.includes(ref)){
          array.push(ref);
          miror[i] = JSON.parse(JSON.stringify(data[i]));
          saveRef[ref] = miror[i];
        }
        // comparaison de deux twins
        else{
          const twin = data[i];
          const obj = saveRef[ref];
          
          if(!warningRef[ref]){
          warningRef[ref] = new Set;}

          if(obj.item != twin.item){
            warningRef[ref].add('Désignation(s) différente(s)');
          }
          if(obj.image != twin.image){
            warningRef[ref].add('Image(s) différente(s)');
          }
          if(obj.qtty_max != twin.qtty_max){
            warningRef[ref].add('Quantité(s) maximum de commande différente(s)');
          }
          if(obj.type_ != twin.type_){
            warningRef[ref].add('Catégorie(s) différente(s)');
          }
          if(obj.sub_type != twin.sub_type){
            warningRef[ref].add('Sous catégorie(s) différente(s)');
          }
          if(obj.period != twin.period){
            warningRef[ref].add('Périodicité(s) différente(s)');
          }
          if(obj.out_packaging != twin.out_packaging){
            warningRef[ref].add('Conditionnement(s) de sortie différent(s)');
          }
          if(obj.qtty_min != twin.qtty_min){
            warningRef[ref].add('Quantité(s) minimum différente(s)');
          }
          if(obj.def_supplier != twin.def_supplier){
            warningRef[ref].add('Fournisseur(s) par défaut différent(s)');
          }
          //dans le cas où c'est le même fournisseur
          if(obj.supplier === twin.supplier){
            if(obj.ref_supplier != twin.ref_supplier){
            warningRef[ref].add('Référence(s) fournisseur différente(s) pour le même fournisseur');
          }
          }
          
        }
      }
      //console.log('test 15');
      //supprimer les refs ou rien à signaler
      for(let i in warningRef){
        if(warningRef[i].size == 0){
          delete warningRef[i];
        }
      }
      return warningRef;
    },
    // search same supplier, different price
    filterTwinsRefPrice(data){
      /*
      Attention le filtre semble tourner en boucle, ce qui affecte les itérations qui sont passés plusieurs fois*/
      var array = [];
      var saveRef = {};
      var warningRef = {};
      for(let i in data){
        const ref = JSON.parse(JSON.stringify(data[i].ref));
        const supplier = JSON.parse(JSON.stringify(data[i].supplier));

        if(!array.includes(ref+';'+supplier)){
          array.push(ref+';'+supplier);
          saveRef[ref+';'+supplier] = JSON.parse(JSON.stringify(data[i]));
          const twin = JSON.parse(JSON.stringify(data[i]));
          //console.log(ref+';'+supplier)
          //console.log(data[i])

          if(!this.twinsRefForClean[ref+';'+supplier]){
            //console.log('1) nouvelle ref : ' +ref+';'+ twin.price+';'+twin.supplier+';'+twin.item_storage_bis);
            this.twinsRefForClean[ref+';'+supplier] = {twins : new Set,
            fifo : '',toKeep : '', oldRef : new Set, firstRef : '',oldRefDouble : new Set, oldRefDoubleFifoDate : ''};
            this.twinsRefForClean[ref+';'+supplier].twins.add(twin.price+';'+twin.supplier+';'+twin.item_storage_bis+';'+twin.id);
            this.twinsRefForClean[ref+';'+supplier].fifo = twin.fifo_date;
            this.twinsRefForClean[ref+';'+supplier].toKeep = twin.price+';'+twin.supplier+';'+twin.item_storage_bis+';'+twin.id;
            if(twin.qtty_in === 0 && twin.qtty_available === 0){
            this.twinsRefForClean[ref+';'+supplier].firstRef = twin.price+';'+twin.supplier+';'+twin.item_storage_bis+';'+twin.id;}
          } 
        }
        // comparaison de deux twins
        else{
          const twin = JSON.parse(JSON.stringify(data[i]));
          const obj = saveRef[ref+';'+supplier];
          //console.log('1)deja repertorié ref : ' +ref+';'+twin.price+';'+twin.supplier+';'+twin.item_storage_bis+';'+twin.id );
          

          if(!warningRef[ref+';'+supplier]){
          warningRef[ref+';'+supplier] = new Set;}

            if(obj.price != twin.price){
            warningRef[ref+';'+supplier].add(`Plusieurs prix différents pour un même fournisseur *${obj.supplier}*, 
            pensez à supprimer la référence au prix le plus ancien une fois la quantité tombée à zéro`);
          
            this.twinsRefForClean[ref+';'+supplier].twins.add(twin.price+';'+twin.supplier+';'+twin.item_storage_bis+';'+twin.id);
            
            var doublon = false;
            if(this.twinsRefForClean[ref+';'+supplier].fifo < twin.fifo_date)
            {
              //vérifier si pas de doublons avec depot différent
              if(this.twinsRefForClean[ref+';'+supplier].toKeep.includes(+twin.price+';'+twin.supplier)){
                doublon = true;
              }
              //console.log('2)date plus récente à conserver : ' +ref+';'+twin.price+';'+twin.supplier+';'+twin.item_storage_bis+';'+twin.id );
            this.twinsRefForClean[ref+';'+supplier].fifo = twin.fifo_date;
            this.twinsRefForClean[ref+';'+supplier].toKeep = twin.price+';'+twin.supplier+';'+twin.item_storage_bis+';'+twin.id;
            }
            // il arrive que deux refs jumelles aient la date de création/reception (doublons date)
            else if (this.twinsRefForClean[ref+';'+supplier].fifo === twin.fifo_date){
              doublon = true;
              //console.log('2)date identiques : ' +ref+';'+twin.price+';'+twin.supplier+';'+twin.item_storage_bis+';'+twin.id);
            }

          //si ce n'est pas le prix le plus récent
          //ajouter la valeur de la ref ancienne dans oldRef
          if(twin.price+';'+twin.supplier+';'+twin.item_storage_bis+';'+twin.id != this.twinsRefForClean[ref+';'+supplier].toKeep){
            
            if(this.twinsRefForClean[ref+';'+supplier].toKeep.includes(twin.price+';'+twin.supplier)){
              //console.log('3)jeter la ref dans oldDoubleRef : ' +ref+';'+twin.price+';'+twin.supplier+';'+twin.item_storage_bis+';'+twin.id);
              if(twin.qtty_in === 0 && twin.qtty_available === 0){
              this.twinsRefForClean[ref+';'+supplier].oldRefDouble.add(twin.price+';'+twin.supplier+';'+twin.item_storage_bis+';'+twin.id);
            }}
            else{
              //console.log('3)jeter la ref dans oldRef : ' +ref+';'+twin.price+';'+twin.supplier+';'+twin.item_storage_bis+';'+twin.id);
              if(twin.qtty_in === 0 && twin.qtty_available === 0){
               this.twinsRefForClean[ref+';'+supplier].oldRef.add(twin.price+';'+twin.supplier+';'+twin.item_storage_bis+';'+twin.id);
            }}
          
          }
          //sinon si la nouvelle est plus recente
          else{
              //ajouter la valeur de la ref ancienne qui vient de se faire détroner dans oldRef
              if(!doublon)
              {
                //console.log('3)jeter la ref detronée dans oldRef : ' +this.twinsRefForClean[ref+';'+supplier].firstRef);
                if(this.twinsRefForClean[ref+';'+supplier].firstRef != ''){
              this.twinsRefForClean[ref+';'+supplier].oldRef.add(this.twinsRefForClean[ref+';'+supplier].firstRef);}
              //si il y a une/plsrs ref dans les doubles memorisés elles sautent
              if(this.twinsRefForClean[ref+';'+supplier].oldRefDouble.length != 0)
              {
                //console.log('4)jeter les doublons memorisés la ref detronée dans oldRef : ');
                for(let i in this.twinsRefForClean[ref+';'+supplier].oldRefDouble){
                  this.twinsRefForClean[ref+';'+supplier].oldRef.add(this.twinsRefForClean[ref+';'+supplier].oldRefDouble[i]);
                }
                this.twinsRefForClean[ref+';'+supplier].oldRefDouble = new Set;
                }
              }
              // si doublon le mémoriser au cas ou son jumeau saute par la suite
              else{
                //console.log('3)memoriser le doublon dans oldRefDouble : ' +this.twinsRefForClean[ref+';'+supplier].firstRef);
                this.twinsRefForClean[ref+';'+supplier].oldRefDouble.add(this.twinsRefForClean[ref+';'+supplier].firstRef);
              }

              //Mémoriser la nouvelle valeur de la ref dans toKeep au cas ou elle se fait detroner ensuite
              if(twin.qtty_in === 0 && twin.qtty_available === 0){
              this.twinsRefForClean[ref+';'+supplier].firstRef = twin.price+';'+twin.supplier+';'+twin.item_storage_bis+';'+twin.id;}
          }
          
          
          
          
        }
        else if(obj.price === twin.price &&
          obj.item_storage === twin.item_storage){
            warningRef[ref+';'+supplier].add('Erreur Critique, deux références strictement identiques détéctés dans le stock');
          }
      }
      }
      //console.log('test 16');
      //supprimer les refs ou rien à signaler
      for(let i in warningRef){
        if(warningRef[i].size == 0){
          delete warningRef[i];
        }
      }

      return warningRef;
    },
    cleanOldTwinRef(ref,ref2){
      var el = this.$refs[ref].innerText;
      const item_id = el.split('-')[1];
      //console.log(item_id);
      
      this.fetchCleanOdlTwinRef(item_id).then((data) => {
          if (data["data"]) {
              //console.log('ref cleaned');
              //console.log(data["data"]);
              this.globalEventHandler.push(ref);
              this.globalEventHandler.push(ref2);
              this.allTwinsRef();
              
          }
        })
        .catch((e) => console.log(e));
    },
    async fetchCleanOdlTwinRef(item_id){
      let key;
      //key for only web app native request (!postman)
      if (
        (this.connectedUser.a5e789410fd.includes("2f+g2dfg25h2d+f15b1gs5bnj1ki1lo61q1dc16"))
      ) {
        key = "h1d5he15fdhs";
      }
      const requestOptions = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
        body: JSON.stringify({
          id: item_id,
          username: this.connectedUser.username+' from_console',
          user_id: this.connectedUser.id_user,
          key: key,
        }),
      };

      let response = await fetch(
        appPath+"/items/delete_item",
        requestOptions
      );
      if (!response.ok) {
        alert("Une erreur empêche la requête");
        //stop loader
        this.putLoader = false;
        // get error message from body or default to response status
        const error = (data && data.message) || response.status;
        //console.log('not response ok, error : ' + error);
        //console.log(error);
        return Promise.reject(error);
      }
      return await response.json();
    },
    //return data un format for easy read
    formatTwin(ref,x){
      //return ref + ' ' +x
      return ref.replace(';',' ') + ' ' + x.split(';')[0] + '€' + ' ' + x.split(';')[2] + '-'+ x.split(';')[3];
    },
        // request all twins ref
    allTwinsRef(){
        const id = this.connectedUser.id_user;
    this.fetchGetAllTwinsRef(id)
        .then((data) => {
          /*this.newUserMessage = true;
          setTimeout(
            function() {
              this.cleanSuccesMessage();
            }.bind(this),
            1000
          );*/
          if (data["data"]) {
              //console.log('ALL TWINS REF');
              //console.log(data["data"]);
              this.twinsRef = data["data"];
          }
        })
        .catch((e) => console.log(e));
    },
    // FETCH 
    async fetchGetAllTwinsRef(id) {
  let response = await fetch(appPath+"/items/all_twins_ref/" + id, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    },
  });
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (data && data.message) || response.status;
    //console.log('not response ok, error : ' + error);
    alert("une erreur innattendue s'est produite");
    return Promise.reject(error);
  }
  return await response.json();
    },
    // request all type details
    allTypesDetails(){
        const id = this.connectedUser.id_user;
    this.fetchGetAllTypesDetails(id)
        .then((data) => {
          /*this.newUserMessage = true;
          setTimeout(
            function() {
              this.cleanSuccesMessage();
            }.bind(this),
            1000
          );*/
          if (data["data"]) {
              //console.log('ALL TYPES DETAILS');
              //console.log(data["data"]);
              this.allTheStock = data["data"];
              for(let i in this.allTheStock){
                if(this.allTheStock[i].type_ === 'cable'){
                  this.cables.push(this.allTheStock[i]);}
              }
          }
        })
        .catch((e) => console.log(e));
    },
    // FETCH ALL TYPES DETAILS
    async fetchGetAllTypesDetails(id) {
  let response = await fetch(appPath+"/items/all_types_details/" + id, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    },
  });
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (data && data.message) || response.status;
    //console.log('not response ok, error : ' + error);
    alert("une erreur innattendue s'est produite");
    return Promise.reject(error);
  }
  return await response.json();
    },
    // request booking en cours
    bookingInProgress(){
        const id = this.connectedUser.id_user;
    this.fetchBookingInProgress(id)
        .then((data) => {
          if (data["data"]) {
              //console.log('BOOKING IN PROGRESS');
              //console.log(data["data"]);
              this.booking = data["data"];
          }
        })
        .catch((e) => console.log(e));
    },
    // FETCH
    async fetchBookingInProgress(id) {
  let response = await fetch(appPath+"/items/booking_in_progress/" + id, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    },
  });
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (data && data.message) || response.status;
    //console.log('not response ok, error : ' + error);
    alert("une erreur innattendue s'est produite");
    return Promise.reject(error);
  }
  return await response.json();
    },
        // request booking en cours
    getCountRows(){
        const id = this.connectedUser.id_user;
    this.fetchGetCountRows(id)
        .then((data) => {
          if (data["data"]) {
              //console.log('COUNT ROWS');
              //console.log(data["data"]);
              this.rowsCounter = data["data"];
          }
        })
        .catch((e) => console.log(e));
    },
    getReelsMovements(){
      const id = this.connectedUser.id_user;
      this.$store.dispatch("getReelsHistory", this.connectedUser.id_user);
    },
    getOrderInProgress(){
      const id = this.connectedUser.id_user;
      this.$store.dispatch("getAdminReceptions", this.connectedUser.id_user);
    },
    // FETCH
    async fetchGetCountRows(id) {
  let response = await fetch(appPath+"/items/count_rows/" + id, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    },
  });
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (data && data.message) || response.status;
    //console.log('not response ok, error : ' + error);
    alert("une erreur innattendue s'est produite");
    return Promise.reject(error);
  }
  return await response.json();
    },
    showAndScroll(){
    this.showCleaning = true;
    const tRef = this.$refs['main'];
    //console.log(tRef.clientHeight);
    setTimeout(() => {
      window.scrollTo({
        top: tRef.clientHeight,
        behavior: "smooth",
      });
    }, 500);
    },


  },
};
</script>

<style scoped>
.flex-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.card-alert{
  line-height : 30px;
  margin-bottom: 20px;
  padding : 10px;
  font-weight: bold;
  background-color: yellow;
  border: 2px black solid;
}
.booking-alert{
  background-color: rgb(255, 214, 252);
}
.warning {
margin-right: 10px;
font-size: 30px;
padding: 5px;
}
.total-not-ok{
  background-color: rgb(250, 176, 176);
  padding: 10px;
}
.total-ok{
  background-color: rgb(206, 253, 214);
}
.twins-neutral{
 background-color: white;
}
.twins-alert{
 background-color: rgb(255, 238, 141);
}
.twins-info {
  background-color: rgb(226, 226, 226);
  max-width: 600px;
}
button {
  margin-bottom: 30px;
}
.big-loader {
font-size: 25px;
}
.confirmDelete {
  color : red;
  margin : 20px;
}
@media screen and (max-width: 1100px) {
  
}
</style>
