<template>
  <div class="history-container">

        <!-- {{reelsHistory}} !-->
       <!-- {{reelsMovement}} !-->
       <button class="showOptions" v-if="!controlPanel" @click="controlPanel = true">Afficher les options &#x25BC;</button>

       <div class="control-panel-div" v-show="controlPanel">
           <button class="hideOptions"  @click="controlPanel = false">Masquer les options &#x25B2; </button>
         <div @click="exportToExcel()" class="excel-div">
        <button>Export Excel</button>
        <img id="icon1" src="../../../assets/excel.jpg" alt=""/>
        <img id="icon2" src="../../../assets/libreoff.jpg" alt=""/>
        
        </div>

       <div class="search-container interval-container">
          <p>Choisir un période jj/mm/aaaa (par défaut 31 derniers jours)</p>
          <div>
          <label for="date">Entre : </label>
        <input type="search" name="date" ref="date_filter" v-model="choosedStartDate">
        </div>
          <div>
        <label for="date">Et : </label>
        <input type="search" name="date" ref="date_filter" v-model="choosedEndDate">
        </div>
        <button @click="newDateInterval()">Lancer la requête</button>
        <button @click="defaultInterval()">Effacer</button>
        </div>

       <div class="search-container">
          <div>
        <label for="date">Filtrer une date : </label>
        <input type="search" name="date" id="" v-model="choosedDate">
        </div>
        <div>
        <label for="date"> Filtrer une heure : </label>
        <input type="search" name="date" id="" v-model="choosedHour">
        </div>
        </div>
        </div>

           <button class="seeNow" v-if="!seeReelsMovements" @click="seeReelsMovements = true"> Voir les utilisations en cours</button>
        <button class="seeNow" v-else @click="seeReelsMovements = false"> Voir l'historique</button>
<div class="table-container">
    <table v-if="Object.values(multiFilters).length != 0">


        <thead class="global-history-table" >
            <tr>
          <td>Ligne</td>
          <!--td>color</td!-->
          <td>Utilisateur</td>
          <td>Designation</td>
          <td>Réf</td>
          <td>Dépôt</td>
          <td>Id</td>
          <td>Statut</td>
          <td><button v-if="!showHour" @click="showHour = true">date</button>
          <button v-else @click="showHour = false">date + </button></td>
          
          </tr>
      </thead>

      <tbody  class="global-history-table" v-for="row in multiFilters" :key="row">
           <tr v-if="(choosedDate === '' && choosedHour === '')
            || (dateFormat(row.date) === choosedDate && choosedHour === '')
            || (dateFormatH(row.date).split(',')[1].includes(' '+choosedHour) && choosedDate === '')
            || (dateFormat(row.date) === choosedDate && dateFormatH(row.date).split(',')[1].includes(' '+choosedHour))"
            >
          <td>{{row.id_row}}</td>
          <!--td>{{row.color}}</td!-->
          <td>{{row.username}}</td>
          <td>{{row.item}}</td>
          <td>{{row.ref}}</td>
          <td>{{row.storage_location}}</td>
          <td>{{row.id_reel}}</td>
          <td>{{translator(row.status_)}}</td>
          <td v-if="!showHour" class="date" @click="copyFn(dateFormat(row.date))">{{dateFormat(row.date)}} </td>
          <td v-else >{{dateFormatH(row.date)}} </td>
          

          </tr>
      </tbody>
</table>
<div v-else class="loader" >Recherche de données... &#8987;</div>
  </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
const XLSX = require("xlsx");
const { saveAs } = require('file-saver');

export default {
    name : "HistoryReels",
    props : ["connectedUser"],
    data() {
        return {
            showHour : false,
            choosedDate : '',
            choosedHour : '',
            seeReelsMovements : false,
            choosedStartDate : '',
            choosedEndDate : '',
            controlPanel : false
        }
    },
      mounted() {
    this.$store.state.intervalDate = [];
    this.$store.commit("isConnected");
    this.getReelsHistory();
  },

      computed: {
    //STORE
    ...mapState({
      reelsHistory: "reelsHistory",
      reelsMovement: "reelsMovement",
      intervalDate : "intervalDate"
    }),

        multiFilters(){
      var obj = [];
      let miror;
      if(this.seeReelsMovements === true){
      miror = this.reelsMovement;
      }else{
      miror = this.reelsHistory;
      }
      if(Object.values(this.intervalDate).length != 0){
        miror = this.intervalDate;
      }
      if(this.choosedDate != ''){
        miror.forEach(element => {
          if(this.dateFormat(element.date) === this.choosedDate){
            obj.push(element);
          }
        });
        miror = obj;
        obj = [];
      }
      if(this.choosedHour != ''){
        miror.forEach(element => {
          if(this.dateFormatH(element.date).split(',')[1].includes(' '+this.choosedHour)){
            obj.push(element);
          }
        });
        miror = obj;
        obj = [];
      }
      return miror;
    },
    translateForExcel(){
      var jsonObj = [];
      var miror = JSON.parse(JSON.stringify(this.multiFilters));
        //var start = new Date();
        miror.forEach(element => {
          delete Object.assign(element, {['Ligne']: element['id_row'] })['id_row'];
          delete Object.assign(element, {['Utilisateur']: element['username'] })['username'];
          delete Object.assign(element, {['Désignation']: element['item'] })['item'];
          delete Object.assign(element, {['Id_objet']: element['id_reel'] })['id_reel'];
          delete Object.assign(element, {['Dépôt']: element['storage_location'] })['storage_location'];
          delete Object.assign(element, {['Statut']: element['status_'] })['status_'];
          switch (element['Statut']) {
            case 'borrowed':
            element['Statut'] = 'Emprunté'
            break;
          case 'booked':
            element['Statut'] = 'Reservé'
            break;
          case 'unbooked':
            element['Statut'] = 'Reservation annulé'
            break;
          case 'returned':
            element['Statut'] = 'Rendu'
            break;
          case 'drained':
            element['Statut'] = 'Vidé'
            break;
            case 'in_use':
            element['Statut'] = 'Utilisé'
            break;
        
          
            default:
              break;
          }
          element.date = this.dateFormat(element.date);
          delete Object.assign(element, {['Date']: element['date'] })['date'];
          delete element.color;
          jsonObj.push(element);
        });
        // 7 milliseconds
        //var time = new Date() - start;
        //console.log(time);
        return jsonObj;
    }
 },

  methods : {
      getReelsHistory(){
        if (Object.keys(this.connectedUser).length != 0) {
        this.$store.dispatch("getReelsHistory", this.connectedUser.id_user);
      } else {
        alert("Accès bloqué, l'utilisateur n'est pas reconnu !");
      }
      },
      dateFormat(data){
       // console.log( data);
       // console.log(typeof data);
        const dateObject = new Date(data);
        //console.log( dateObject);
        //console.log(typeof dateObject);
        let humanDateFormat;
        if(dateObject.toLocaleString().includes(',')){
        humanDateFormat = dateObject.toLocaleString().split(',')[0];}
        else{
        humanDateFormat = dateObject.toLocaleString().split(' ')[0];  
        }
        //console.log( humanDateFormat.split(',')[0]);
        //console.log(typeof humanDateFormat);
        return humanDateFormat;
      },
    dateFormatH(data){
        const dateObject = new Date(data);
        const humanDateFormat = dateObject.toLocaleString();
        return humanDateFormat;
      },
      translator(data){
        switch (data) {
          case 'borrowed':
            return 'Emprunté'
            break;
          case 'booked':
            return 'Reservé'
            break;
          case 'unbooked':
            return 'Reservation annulé'
            break;
          case 'returned':
            return 'Rendu'
            break;
          case 'drained':
            return 'Vidé'
            break;
            case 'in_use':
            return 'Utilisé'
            break;
        
          default:
            break;
        }
      },
      newDateInterval(){
        if(this.choosedStartDate != '' && this.choosedEndDate != ''){

         var j = this.choosedStartDate.split('/')[0];
         var m = this.choosedStartDate.split('/')[1];
         var a = this.choosedStartDate.split('/')[2];
         const choosedStartDate = a+'-'+m+'-'+j;
         j = this.choosedEndDate.split('/')[0];
         m = this.choosedEndDate.split('/')[1];
         a = this.choosedEndDate.split('/')[2];
         const choosedEndDate = a+'-'+m+'-'+j;

         //console.log(j);console.log(m);console.log(a);
         
         const startDate = new Date(choosedStartDate+'Z');
         var endDate0 = new Date(choosedEndDate+'Z');
         endDate0.setUTCHours(22);
         endDate0.setUTCMinutes(59);
         endDate0.setUTCSeconds(59);
         //endDate0 = endDate0.setUTCMinutes(59);
         //console.log(endDate0);
         const endDate = endDate0;
         if(isNaN(Date.parse(startDate))  || isNaN(Date.parse(endDate))){
           alert("erreur de format de date, veuillez respecter ce format : jj/mm/aaaa");
         }
         
         if (Object.keys(this.connectedUser).length != 0) {
           
           const historyData = {
           date : [startDate,endDate],
           compOrigin :'reel_history',
           username : this.connectedUser.username,
           user_id : this.connectedUser.id_user
         }

        this.$store.dispatch("putIntervalDate", historyData);
         } else {
        alert("Accès bloqué, l'utilisateur n'est pas reconnu !");}

        }else{
          alert("Veuillez remplir tous les champs d'interval de date !");
        }
      },
      defaultInterval(){
        this.choosedStartDate = '';
        this.choosedEndDate = '';
        this.$store.state.intervalDate = [];
      },
      copyFn(number) {
  var copyText = number.toString();
  const searchInput = this.$refs['date_filter'];
  searchInput.value = copyText;
  searchInput.focus();
  this.choosedDate = copyText;
      },
      exportToExcel(){
        var miror = JSON.parse(JSON.stringify(this.translateForExcel));
        //console.log(miror);
        this.downloadAsExcel(miror);
      },
      downloadAsExcel(data){
        let worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = {
          Sheets : {
            'data':worksheet
          },
          SheetNames: ['data']
        };
        const excelBuffer = XLSX.write(workbook, {booktype : 'xlsx', type : 'array'});
        //console.log(excelBuffer);
        this.saveAsExcel(excelBuffer, 'myFile');
      },
      saveAsExcel(buffer,filename){
        const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([buffer],{type:EXCEL_TYPE});
        saveAs(data,filename+'_export_'+new Date().getTime()+EXCEL_EXTENSION);
      }

  }

}
</script>

<style scoped>
table{
  margin : auto;
  overflow: scroll;
}
.table-container{/**/
  margin-top: 80px;
  margin-bottom: 60px;
  max-width: 1400px;
  overflow-x: scroll;
}
thead {
  font-size: 20px;
  font-weight: bold;
}
thead td{
  border: 1px solid black;
  text-overflow: ellipsis;
}
tr td {
    padding: 5px;
}
tbody tr:hover{
  background-color: rgb(116, 223, 223);
}
.global-history-table{
margin: 10px;
}
.global-history-table td{
margin: 10px;
}
.search-container {
  display: flex;
  margin: 30px;
  justify-content: space-evenly;
}
.search-container div input{
  width: 100px;
  margin-left : 10px;
  margin-right : 10px;
}
.search-container div label{
  font-size: 22px;
}
.interval-container{
  display: flex;
  font-size: 19px;
  align-content: center;
  padding-left: 10px;
  border: 1px black solid;
}
.interval-container p{
margin-right: 20px;
line-height: 30px;
}
.interval-container div{
margin: 20px;
}
.interval-container button{
margin: 20px;
font-size: 19px;
}
.date{
  cursor: pointer;
}
.date:hover{
  background-color: greenyellow;
}
.excel-div img {
width : 50px;
height: 50px;
margin-left: 20px ;
}
.excel-div {
    display: flex;
    justify-content: center;
}
.excel-div button{
    font-size : 20px;
    color: white;
    background-color: green;
    opacity: 1;
}
.excel-div button:active {
	transform: scale(1.1);
	transition: .1s;
}

.excel-div button:hover {
  opacity : 0.7;
}
.loader {
  margin-top: 80px;
  font-size: 50px;
}
.hideOptions {
  margin-bottom: 20px;
  font-size: 18px;
}
.showOptions{
  font-size: 18px;
  margin-right: 20px;
}
.seeNow{
font-size : 18px;
}
@media screen and (max-width: 1100px) {
table{
  overflow-x: scroll;
}
.table-container{
  margin-top: 50px;
  max-width: 280px;
  overflow-x: scroll;
}
.history-container{
  max-width: 280px;
  overflow-x: hidden;
}
.control-panel-div{
  max-width: 280px;
  overflow-x: scroll;
}
.search-container {
  flex-direction: column;
  margin: 20px;
}
.search-container div label{
  font-size: 18px;
}
.interval-container{
  flex-direction: column;
}
.loader {
  margin-top: 50px;
  font-size: 20px;
}
.filters-container {
  flex-direction: column;
  height: 140px;
  margin-bottom : 10px;
}
div select{
  width: 240px;
  height: 35px;
}

}
</style>