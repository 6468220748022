<template>
  <div class="page_container">

      <div class="header">
        <img alt="logo digitech" src="../assets/Digitech.jpg" />
        <button @click="this.$router.push('/')">Déconnexion </button>
        <div class="presentation">
      <h1> Commande </h1>
      <h2 :class="{redColor : userMode}"><i class="fas fa-user-circle"></i> 
      {{userName}} <span class="green" v-if="userMode">{{usedName}}</span>
       <span class="exArr" v-if="userMode" @click="$store.commit('exitUseMode',true)">x</span></h2>
      </div>
      </div>
    <ItemMain></ItemMain>

  </div>

</template>

<script>

import ItemMain from "@/components/User/ItemMain.vue";
import { mapState } from "vuex";

export default {
  name: 'Items',
  components: {
   ItemMain
  },
  data() {
    return {
      userName : 'utilisateur',
      connectedUser : {},
      userMode : false,
      usedName : ''
    }
  },
  methods: {
    userExistChecking(){
       if(!this.userExist['username']){
      alert('Utilisateur inexistant');
    }
  }
  },
  created : function (){
    setTimeout(function () { this.userExistChecking(); }.bind(this), 6000);
    
  },
  computed: {
    ...mapState({
      userExist : "userExist",
    })
    },
  mounted() {
    // récuperer l'utilisateur actuel
    const user = JSON.parse(localStorage.getItem('connectedUser'));

    const userMode = JSON.parse(localStorage.getItem('usingMode'));
    if(userMode && userMode === true){
      this.userMode = true;
    }
    this.userName = user.name;
    this.usedName = localStorage.getItem('usingModeN');
    this.connectedUser = user;

    // rafraichir la page régulièrement (toute les heures)
    setTimeout(function() { location.reload(); }, 3600000);

   // vérifier que l'utilisateur n'a pas été supprimé de la base de donnée
    const ids = user.id_user; + "_" + user.id_user;
    this.$store.dispatch("getOneUser", ids);
  },
}
</script>
<style scoped>
.page_container {
  display: flex;
  margin-top: 0px;
  flex-direction: column;
  align-items: center;
}

.header{
display: grid;
width:80%;
margin: 0 0 20px 0;
font-weight: 600;
grid-template-columns: 1fr 1fr;
}
img {
  max-width: 80px;
}
button {
width: 200px;
height: 50px;
font-size: 20px;
justify-self: right;
align-self: center;
background-color: #0d444b;
  color : rgb(238, 130, 26);
}
.presentation{
  grid-column-start: 1;
  grid-column-end: 3;
}
#logo__title {
  font-size: 27px;
}
.redColor {
  color : red;
}
.redColor > .green {
    color : green;
  }
.exArr {
  margin-left: 10px;
  border: red 1px solid;
  border-radius: 25px;
  cursor : pointer;
  padding: 1px 10px 3px 10px;
}
.exArr:active {
  color :black;
  border: black 1px solid;
}

@media screen and (max-width: 1100px) {

  #logo__title {
    font-size: 19px;
  }
  img {
  max-width: 40px;
  justify-self: center;
  align-self : center;
}
button {
width: 95px;
height: 30px;
font-size: 14px;
}

.header{
width:95%;
grid-template-columns: 1fr 2fr 1fr;
}

.presentation{
  grid-row-start: 1;
  grid-column-start: 2;
  font-size: 10px;
  justify-self: center;
}
}
</style>