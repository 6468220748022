<template >
    <div class="main-container">
        <h2>Receptions client & Affectations par activités</h2>
           <button class="showHideBtn" @click="showReceptions = !showReceptions">
      Afficher / Masquer
    </button>
        <div class="reception-container" v-if="showReceptions">
            <div  class="mini-container different-margin">
                <button class="mini-info-icon"
                 @blur="displayMessage(0)" @click="displayMessage(1)"> &#8505;</button>
                <div ref="mini_message" class="mini-info">
                    Stock virtuel activités = quantités sur l'OS
                    <br>
                    Stock réel entrepôts = déduire stock resté dans le camion chantier
                    <br>
                    Injecter uniquement du stock virtuel → décocher Stock
                    <br>
                    Injecter uniquement du stock réél → ne pas créer de carte activité
                </div>
                <label for="stock">Stock</label>
           <input class="checker" name="stock" type="checkbox" :checked="stockInjection" @change="this.stockInjection = !this.stockInjection">
            </div>
            <div  class="mini-container">
                <!-- {{this.materialObj}} !-->
                
                
           <label for="numero os">Numéro OS</label>
           <span style="font-size : 18px">OS-</span>
           <span class="relative">
           <input class="OSinput" name="numero os" type="text" v-model="this.clientOS"> </span>       
           </div>
           <div  class="mini-container">
                    <select class="selectAct" placeholder="" 
       :size3="size3" @focus="onFocusSelect(2)" @blur="onBlurSelect(2)"
       @change="clientSelected($event)"
       :style="[firstStyle3? {'color' : 'black', 'background-color' : 'white'}:{}]">
        <option> Client </option>
        <option v-for="choice in clients" :key="choice"
        >{{choice}}</option>
      </select>     
           </div>

            <div class="mini-container">
                <!--h4 class="category-picked" > {{currentMaterial}} </h4!-->
                    <select class="selectMat" placeholder="" 
       :size="size" @focus="onFocusSelect(1)" @blur="onBlurSelect(1)"
       @change="materialSelected($event)" @click="checkClient()"
       :style="[firstStyle ? {'color' : 'black', 'background-color' : 'white'}:{}]">
        <option>--Liste--</option>
        <option v-for="choice in materialsFilter" :key="choice"
        >{{choice.item}}</option>
      </select>
      
           <button class="add-card-material" @click="addMaterialCard()">
               Ajouter un materiel client</button>
           </div>
            <div class="materials-container">
           <div ref="material-0" class="material-card" 
           v-for="material,materialIndex in this.materialObj"
               :key="material" :index="materialIndex">
               
               <h3 class="material-picked">{{materialIndex}}
                   <button class="crossQuit" @click="deleteMaterialCard(materialIndex,activityIndex)">x</button>
                
               </h3>

               

               <div class="mini-container flex-row disctinct-container"><h4 id="colored">Stock Physique :</h4>
               <div class="mini-container " v-for="location in this.locationList" :key="location">
                   <label for="qtty_depot">{{location}}</label>
               <input class="number" :ref="materialIndex+location" placeholder="0" 
               @change="locationQtty($event,material,location)" :value="material[location]" name="qtty_depot" type="number">
               </div>
               </div>

                <!--span>{{currentActivity}}</span!-->
                <div class="disctinct-container">
              <div class="mini-container"> 
                <h4 id="colored">Stock Virtuel :</h4>
                 <select class="selectAct" placeholder="" 
       :size="size2" @focus="onFocusSelect(0)" @blur="onBlurSelect(0)"
       @change="activitySelected($event)"
       :style="[firstStyle2 ? {'color' : 'black', 'background-color' : 'white'}:{}]">
        <option>--Liste--</option>
        <option v-for="choice in allActivities" :key="choice"
        >{{choice.activity}}</option>
      </select>
               <button class="add-card" @click="addActivityCard(materialIndex)">
                   Ajouter une activité</button></div>
               <div class="flex-cards-container ">
               <div ref="activity-00" class="activity-card" 
               v-for="activity,activityIndex in material['activities']" :key="activity" :index="activityIndex">
                    <h4 class="activity-picked" > {{activityIndex}} </h4>
               <div  class="mini-container"><label class="label-compact" for="qtty_activity">Quantité affectée</label>
               <input :ref="materialIndex+activityIndex"
               class="number hide-arrow" placeholder="0" @change="activityQtty($event,material,activityIndex)" 
               :value="activity" name="qtty_activity" type="number"></div>
               
               <button @click="deleteActivityCard(materialIndex,activityIndex)">Supprimer</button>
               </div></div></div>
               
           </div> </div>
            <div class="succes-message" v-if="receptionClient">
               Les affectations d'activités pour le matériel client ont bien étés ajoutées
               <button @click="confirmeResults(1)">ok</button>
               </div>
            <div class="delete-message succes-message" v-else-if="receptionClientERROR">
                Attention, l'affectaion d'activités pour le matériel client n'a pas fonctionné.
                <button @click="confirmeResults(0)">ok</button>
            </div>
           <div class="succes-message" v-if="receptionClientStock">
               La réception des quantités client dans le stock entrepôt est finalisée
               <button @click="confirmeResults2(1)">ok</button>
           </div>
           <div class="delete-message succes-message" v-else-if="receptionClientStockERROR">
               Attention, la réception des quantités client dans le stock entrepôt n'a pas fonctionné.
               <button @click="confirmeResults2(0)">ok</button>
           </div>
          
           <button v-if="!waitingRequest" :disabled="receptionClient || receptionClientERROR || receptionClientStock || receptionClientStockERROR" 
           class="validate" @click="buildFinalObject()">Valider l'OS</button>
           <button v-else class="validate"> Validation ⌛</button>
           </div>
    </div>
</template>
<script>
import { mapState } from "vuex";

export default {
    name: "ActivityReceptions",
    data() {
        return {
        materialObj : {},
        connectedUser : {},
        firstStyle : true,
        firstStyle2 : true,
        firstStyle3 : true,
        size :1,
        size2 :1,
        size3 :1,
        currentActivity : '',
        currentMaterial : '',
        clientPicked: '',
        newItemClients : {},
        locationList : [],
        clientLocked : '',
        clientOS : '',
        stockInjection : true,
        showReceptions : false,
        waitingRequest : false
        }
    },
    mounted() {
    this.$store.commit("isConnected");
    // récuperer l'utilisateur actuel
    this.connectedUser = JSON.parse(localStorage.getItem("connectedUser")); 

    //récupérer la liste des dépôts
    this.$store.dispatch("getAllLocations", this.connectedUser.id_user);

    this.$store.dispatch('getAllActivities',this.connectedUser.id_user);
    this.$store.dispatch('getOnlyClientsItems',this.connectedUser.id_user);
    this.$store.dispatch('getClientFromServices',this.connectedUser.id_user);
    },
    computed: {
    ...mapState({
      activityResponse : "activityResponse",
      allActivities : 'allActivities',
      itemClients: "itemClientsAndDetails",
      clientFromServices : "clientFromServices",
      receptionClient : "receptionClient",
      receptionClientStock : "receptionClientStock",
      receptionClientERROR :"receptionClientERROR",
      receptionClientStockERROR :"receptionClientStockERROR",
      requestEnd : "requestEnd",
      allLocations : "allLocations"
    }),
    clients() {
      var thisSet = new Set();
      if(Object.values(this.clientFromServices).length != 0){
        this.clientFromServices.forEach(element => {
          //console.log(element.supplier);

          thisSet.add(element.client);
        });}
      return thisSet;
    },
    materialsFilter(){
        var newItemClients = {};
        var thisSet = new Set();
        //filtre pour eviter les refs jumelles en choissisant un client
        for(let i in this.itemClients){
            //console.log(this.itemClients[i].supplier);
            if(this.itemClients[i].supplier === this.clientPicked){
            // choisir également par défaut une ref sur les deux dépôt en evitant les doublons
            if(thisSet.has(this.itemClients[i].ref)){
                continue
            }
            thisSet.add(this.itemClients[i].ref);
                newItemClients[i] = this.itemClients[i];
            }
        }

        return newItemClients;
    }
    },
     watch : {
        requestEnd(newCount, oldCount) {
      if (newCount === true) {
        //alert("Echec de la commande");
        this.waitingRequest = false;
        this.$store.state.requestEnd = false;
      }},
      allLocations(newCount, oldCount) {
      if(newCount) {
        //alert(newCount);
        this.locationList = newCount;
      }
    },
    },
    methods: {
    buildFinalObject(){
        this.waitingRequest = true;
        for(let material in this.materialObj){
           for(let activity in this.materialObj[material]['activities']){
               const quantity = this.$refs[material+activity].value;
               this.materialObj[material]['activities'][activity] = quantity;
               //console.log(quantity);
               if(quantity <= 0){
                   alert('Erreur activité: quantité négative ou égal à zéro');
                   this.waitingRequest = false;
               return
               }
           }
           var total = 0;
           for(let i in this.locationList){
               const location = this.locationList[i];
               const qtty = this.$refs[material+location].value;
               //console.log(qtty);
               if(qtty < 0){
                   alert('Erreur stock dépot : quantité négative');
                   this.waitingRequest = false;
               return
               }
               total += parseInt(qtty);
            this.materialObj[material][location] = qtty;
           }
           if(total === 0 && this.stockInjection){
               alert('Erreur stock dépot : Aucune quantité n\'a été choisie pour au moins un dépôt...');
               this.waitingRequest = false;
               return
           }
           }
           var materialMiror = JSON.parse(JSON.stringify(this.materialObj));
           materialMiror['id'] = this.connectedUser['id_user'];
           if(this.clientLocked === ''){
               alert('Erreur : client non reconnu');
               this.waitingRequest = false;
               return
           }
           materialMiror['client'] = this.clientLocked;
           if(this.clientOS === ''){
               alert('Veuillez entrer un numero OS');
               this.waitingRequest = false;
               return
           }
           materialMiror['OS'] = 'OS-'+this.clientOS;
           materialMiror['username'] = this.connectedUser.username;
           if(!this.stockInjection){
            materialMiror['noInjection'] = true;
           }
           this.$store.dispatch('receptionClient', materialMiror);
           
        },
    addActivityCard(materialIndex){
        if(this.currentActivity === '' || this.currentActivity === '--Liste--'){
            alert('Choisissez une activité');
            return
        }
        //alert(materialIndex);
        if(Object.values(this.materialObj[materialIndex]['activities']).length === 0){
        const str = this.currentActivity;
        this.materialObj[materialIndex]['activities'][str] = 0;
        return
        }
        for(let i in this.materialObj[materialIndex]['activities']){
            if(this.materialObj[materialIndex]['activities'].hasOwnProperty(this.currentActivity)){
                alert('Activté déjà affectée');
                return
            }
        }
        const str = this.currentActivity;
        this.materialObj[materialIndex]['activities'][str] = 0;
    },
    deleteActivityCard(materialIndex,activityIndex){
        //alert(index);
        //alert(materialIndex);
        //console.log(this.materialObj['material0'][index])
        delete this.materialObj[materialIndex]['activities'][activityIndex] ;
    },
    activitySelected(event){
        this.currentActivity = event.target.value;
      //this.materialObj[materialIndex][activityIndex] = event.target.value;
      if(this.firstStyle2 === true){
        this.firstStyle2 = false
      }
      this.size2 = 1;
      event.target.blur();
    },
    locationQtty(event,material,location){
        for(let i in this.materialObj){
            if(this.materialObj[i] === material){
                this.materialObj[i][location] = event.target.value;
            }
        }
    },
    activityQtty(event,material,activity){
        for(let i in this.materialObj){
            if(this.materialObj[i] === material){
                this.materialObj[i]['activities'][activity] = event.target.value;
            }
        }
    },
    addMaterialCard(){
        if(this.currentMaterial === '' || this.currentMaterial === '--Liste--'){
            alert('Choisissez un matériel dans la liste');
            return
        }
        let ref;
        //pour l'histo
        let refSupplier;
        let item_id;

        this.clientLocked = this.clientPicked;
        //alert(materialIndex);
        var thisSet = new Set();
        for(let i in this.materialObj){
            //console.log(i);
            thisSet.add(i);
        }
        if(thisSet.has(this.currentMaterial)){
            alert('Matériel déjà en affectation');
            return
        }
        else {
            this.materialObj[this.currentMaterial] = {activities : {}};

            for(let i in this.itemClients){
            //console.log(this.itemClients[i].supplier);
            if(this.itemClients[i].supplier === this.clientPicked){
                if(this.itemClients[i].item === this.currentMaterial){
                    ref = this.itemClients[i].ref;
                    refSupplier = this.itemClients[i].ref_supplier;
                    item_id = this.itemClients[i].id_details;
                }
            }
            }
            if(!ref){
                alert('ref introuvable');
                return
            }
            this.materialObj[this.currentMaterial]['ref'] = ref;
            this.materialObj[this.currentMaterial]['refSupplier'] = refSupplier;
            this.materialObj[this.currentMaterial]['item_id'] = item_id;
            for(let i in this.locationList){
                const location = this.locationList[i];
                this.materialObj[this.currentMaterial][location]  = 0;
            }
            
        }
    },
    deleteMaterialCard(materialIndex){
        //alert(materialIndex);
        delete this.materialObj[materialIndex];
        if(Object.values(this.materialObj).length === 0 ){
            this.clientLocked = '';
        };
    },
    materialSelected(event){
        this.currentMaterial = event.target.value;
      //this.materialObj[materialIndex][activityIndex] = event.target.value;
      if(this.firstStyle === true){
        this.firstStyle = false
      }
      this.size = 1;
      event.target.blur();
    },
    checkClient(){
        if(this.clientPicked === '' || this.clientPicked === 'Client'){
            alert('Veuillez choisir un client au préalable');
        }
    },
    clientSelected(event){
        if(this.clientLocked != '' && this.clientLocked != event.target.value){
            alert('un client par reception');
            event.target.value = this.clientPicked;
            return
        }
      this.clientPicked = event.target.value;
      if(this.firstStyle3 === true){
        this.firstStyle3 = false
      }
      this.size3 = 1;
      event.target.blur();
    },
    //size select function
    onFocusSelect(bool){
      if(bool){
          if(bool === 2 ){
              this.size3 = 4;
              return
          }
          if(this.clientPicked === '' || this.clientPicked === 'Client'){
            return
        }
      this.size = 4;
      return}
      this.size2 = 6;
    },
    //size select function
    onBlurSelect(bool){
      if(bool){
          if(bool === 2 ){
              this.size3 = 1;
              return
          }
          if(this.clientPicked === '' || this.clientPicked === 'Client'){
            return
        }
      this.size = 1;
      return}
      this.size2 = 1;
    },
    confirmeResults(bool){
        //nettoyer les inputs et l'obj
        if(bool){
            for(let material in this.materialObj){
           for(let activity in this.materialObj[material]['activities']){
               delete this.materialObj[material]['activities'][activity] ;
               //console.log(quantity);
           }
           for(let i in this.locationList){
            var count = 0;
            const location = this.locationList[i];
            if(this.materialObj[material][location]){
            count += 1;
           }
           //si la requete stock physique est passée ou vide également, on supprime l'objet
           if(count === 0){
            delete this.materialObj[material];
           }
            
           }
           
           
           }
        }
        
        if(Object.values(this.materialObj).length === 0){
            this.clientOS = '';
            this.clientLocked = '';
        }
        this.$store.state.receptionClient = false;
        this.$store.state.receptionClientERROR = false;
    },
    confirmeResults2(bool){
        //nettoyer les inputs et l'obj
         if(bool || (this.stockInjection === false && !bool)){
            for(let material in this.materialObj){
           for(let i in this.locationList){
            const location = this.locationList[i];
            delete this.materialObj[material][location] ;
           }
           //si la requete activite est passée ou vide également, on supprime l'objet
           if(!this.materialObj[material]['activites']){
            delete this.materialObj[material];
           }
           }
        }
         if(Object.values(this.materialObj).length === 0){
            this.clientOS = '';
            this.clientLocked = '';
        }
        this.$store.state.receptionClientStock = false;
        this.$store.state.receptionClientStockERROR = false;
    },
    displayMessage(bool){
        if(bool)
        this.$refs['mini_message'].style.display = 'block';
        else
        this.$refs['mini_message'].style.display = 'none';
    }
    },
}
</script>
<style scoped>
.reception-container {
display: flex;
  flex-direction: column;
  /*align-items: center;*/
  color: white;
  max-width: 100%;
  width: 1100px;
  height: auto;
  font-size: 20px;
  background: #0d444b;
  border-radius: 16px;
  padding: 32px;
   -webkit-box-shadow: 1px 2px 5px 1px #000000; 
  box-shadow: 2px 2px 7px 1px #000000;
}
.materials-container{
display: flex;
flex-direction: column-reverse;
flex-wrap: nowrap;
}
label {
    background-color : #f38414;
     color : #0d444b;
     border-radius: 16px;
     padding : 5px 10px;
     margin : 5px 30px 25px 0;
     width : 100px;
}
.label-compact{
margin : 5px 10px 25px 0;
}
.relative{
    position: relative;
    padding-left:2px ;
}
.OSinput{
   position: absolute;
   top: -1px;
   font-size: 18px;
}
.material-card{
    border: 1px solid rgba(210, 210, 205, 1);
    margin: 50px 0;
    -webkit-box-shadow: 1px 2px 5px 1px #000000; 
  box-shadow: 2px 2px 7px 1px #000000;
  
  
}
.flex-cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding-bottom: 30px;
}
.activity-card {
    border: 1px solid black;
    width: 300px;
    margin-top : 30px;
    background-color: #0d444b;
    padding-bottom: 15px;
}
.showHideBtn{
  margin-bottom : 20px;
  font-size: 18px;
  background-color: #0d444b;
  color : white;
}
.add-card{
width: 300px;
font-size: 20px;
background-color : #0d444b;
color : white;
border: 2px solid #f38414;
}
.add-card-material {
width: 300px;
font-size: 20px;
background-color: #f38414;
border: 2px solid #0d444b;
color: #0d444b;
}
.mini-container {
    margin: 30px;
    position: relative;
}

.number{
  width: 40px;
  text-align: center;
  margin-left : 10px;
  font-size : 20px;
}
/* empecher bug chrome sur les fleches, qui persiste uniquement sur cet input*/
@media screen and (-webkit-min-device-pixel-ratio:0) { 
    .hide-arrow::-webkit-outer-spin-button,
.hide-arrow::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}}
@-moz-document url-prefix() {
.number{
  width: 50px;
  margin-top : 20px;
}}
.material-picked {
    font-size: 25px;
    color: #0d444b;
    background-color: #f38414;
    border: 2px solid #0d444b;
    max-width: 500px;
    margin: auto;
    margin-top : 20px;
    position: relative;
}
#colored {
    color : #0d444b;
}
.activity-picked {
    font-size: 20px;
}
.selectMat {
    width : 200px;
}
.crossQuit {
    font-size: 12px;
    font-weight: bold;
    position: absolute;
    right: 0;
}
select{
    font-size: 22px;
    position: absolute;
    left: 120px;
}
.validate {
    margin: auto;
    margin-top : 20px;
    width: 200px;
    font-size: 22px;
}
.flex-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
}
.succes-message {
  width: auto;
  padding: 20px;
  margin-top: 20px;
  border: 2px rgb(23, 189, 23) solid;
  font-size: 25px;
  font-weight: bold;
  border-radius: 5px;
  color: rgb(23, 189, 23);
  background-color: rgb(255, 255, 255);
 z-index : 10;
}
.delete-message {
  border: 2px red solid;
  color: red;
  background-color: white;
}
.checker {
    filter: invert(75%) hue-rotate(18deg) brightness(1.7);
}
.different-margin{
    padding : 5px 5px;
    margin : 0px 10px 0px 0;
    position : relative;
}
.mini-info {
    display : none;
    z-index: 5;
    color : black;
    padding: 10px;
    background-color: white;
    position : absolute;
    left : 25%;
    top : 60px;
}
.mini-info-icon {
border-radius : 25px;
width: 30px;
height: 30px;
line-height: 6px;
font-size : 25px;
margin-right : 30px;
padding: 0;
background-color: #f38414;
color: #0d444b;
}
.disctinct-container{
    border: black 1px solid;
    margin: 30px;
    /* ff 3.6+ */
    background:-moz-linear-gradient(180deg, rgba(210, 210, 205, 1) 0%, rgba(241, 243, 243, 1) 50%, rgba(210, 210, 205, 1) 100%); 

    /* safari 5.1+,chrome 10+ */
    background:-webkit-linear-gradient(180deg, rgba(210, 210, 205, 1) 0%, rgba(241, 243, 243, 1) 50%, rgba(210, 210, 205, 1) 100%);

    /* opera 11.10+ */
    background:-o-linear-gradient(180deg, rgba(210, 210, 205, 1) 0%, rgba(241, 243, 243, 1) 50%, rgba(210, 210, 205, 1) 100%);

    /* ie 6-9 */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#D2D2CD', endColorstr='#D2D2CD', GradientType=1 );

    /* ie 10+ */
    background:-ms-linear-gradient(180deg, rgba(210, 210, 205, 1) 0%, rgba(241, 243, 243, 1) 50%, rgba(210, 210, 205, 1) 100%);

    /* global 94%+ browsers support */
    background:linear-gradient(180deg, rgba(210, 210, 205, 1) 0%, rgba(241, 243, 243, 1) 50%, rgba(210, 210, 205, 1) 100%);
}
@media screen and (max-width: 1100px) {

input,select {
    background-color: white;
  }
    .reception-container {
  max-width: 100%;
  width: 300px;
  height: auto;
  padding: 12px;
  margin : auto;
}
label {
     padding : 5px 5px;
     margin : 0px 10px 0px 0;
     width : 100px;
     font-size: 15px;
}
.label-compact{
margin : 0px 5px 0px 0;
height: 15px;
padding : 5px 5px;
font-size: 16px;

}
.material-card{
    margin: 20px 0;}

.flex-cards-container {
    padding-bottom: 15px;
}
.activity-card {
    width: 210px;
    margin-top : 20px;
}

.add-card{
width: 80px;
height: 30px;
font-size: 18px;
overflow : hidden;}

.add-card-material {
width: 100px;
height: 33px;
overflow: hidden;
}
.mini-container {
    margin: 10px;
    display: flex;
    justify-content : center;
    align-items: center;
}
.mini-container input {
    height: 30px;
}
.number{
  font-size : 20px;
}

.material-picked {
    font-size: 21px;
    max-wrelativeabsoluteidth: 300px;
}
.activity-picked {
    font-size: 20px;
}
.selectMat {
    width : 200px;
}
select{
    font-size: 22px;
    position: relative;
    left: 0px;
}
.validate {
    margin-top : 10px;
}
.succes-message {
  padding: 10px;
  margin-top: 10px;
  font-size: 20px;
}
}
</style>