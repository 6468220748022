<template>
  <div class="seestock-container">
    <span v-if="succesDelete" class="delete-message succes-message"> Suppression...</span>
    <span v-if="succes03" class="succes-message">Confirmation...</span>
    <span v-if="succes02" class="return-message succes-message"> Annulation...</span>
      
      <!--{{myReceptions}}
      ++ <br><br>
      {{myReceptionsTwins}}
      ++ <br><br>
      {{myRemainders}}
      ++ <br><br>
      {{myRemaindersTwins}}!-->
      
    <div>
      <button v-if="ticketMode === false" @click="ticketMode = !ticketMode">
        -> mode Ticket
      </button>
      <button v-else @click="ticketMode = !ticketMode">
        -> mode Reception
      </button>
    </div>
    <!-- RECEPTIONS !-->
    <h2>Récéptions</h2>
    <button class="showHideBtn" @click="showReceptions = !showReceptions">
      Afficher / Masquer
    </button>
    <div
      class="reception-container"
      v-if="Object.values(myReceptions).length != 0 && showReceptions"
    >
      <div v-if="!ticketMode">
        <div
          class="reception-card"
          v-for="(reception, index) in sortByOrderNumber"
          :key="index"
        >
          <h3 class="reception-header">
          {{infosByReceptions[reception].supplier.toUpperCase()}}  N°{{ reception }} 
          </h3>
          <div class="order_number">
            <p > {{ infosByReceptions[reception].date }}</p>
          </div>
          <div>
            <div
              class="reception-flex-row"
              v-for="(value, innerIndex) in receptionsGrouped(reception)"
              :key="innerIndex"
            >
              <p>{{ value[0] }}</p>
              <p>ref {{ value[1] }}</p>
              <p>{{ value[5] }}</p>
              <button class="see-twin-details"
                v-if="value[6]"
                @click="showTwinsInOut(reception + value[1])"
              >
                details
              </button>
              <p class="twin-ref-qtty-container" v-show="showTwinsCheck(reception + value[1])">
                <span class="twin-ref-qtty" v-for="twin in value[6]" :key="twin"
                  >{{ twin.item_storage }}
                  <input
                    type="number"
                    :ref="twin.ref_location + twin.order_number"
                    :max="twin.qtty_needed"
                    min="0"
                    :value="twin.qtty"
                    :v-model="twin.qtty"
                    @change="
                      partValues(
                        value[1],
                        twin.ref_location,
                        twin.order_number,
                        'reception'
                      )
                    "
                  />
                / {{ twin.qtty_needed }} </span
                >
              </p>
              <!--p> id {{ value[2]}}  </p!-->
              <p>
                <button
                class="maxBtn"
                  @click="
                    maxValues(
                      value[1],
                      reception,
                      reception + value[5] + value[1],
                      'reception'
                    )
                  "
                >
                  Max
                </button>
                <input
                  type="number"
                  class="number"
                  min="0"
                  :max="value[4]"
                  :ref="reception + value[5] + value[1]"
                  :value="value[3]"
                  @change="
                    totalValues(
                      value[1],
                      reception,
                      reception + value[5] + value[1],
                      'reception'
                    )
                  "
                />
                sur {{ value[4] }}
              </p>
            </div>
          </div>
          <div class="valid-btn-container">
            <button 
              v-if="!disableButton" class="valid-btn"
              @click="confirmReception(reception, 'reception')"
            >
              Récéptionner
            </button>
            <button v-if="!disableButton" class="valid-btn" @click="cancelReception(reception)">
              Supprimer
            </button>
            <button class="AllMax valid-btn" @click="AllMax(reception, 'reception')">
              100%
            </button>
          </div>
        </div>
      </div>

      <!-- ------------------------MODE TICKET--------------- !-->
      <div v-else>
        <div
          class="reception-card"
          :id="'capture_reception' + reception"
          v-for="(reception, index) in sortByOrderNumber"
          :key="index"
        >
          <h3 class="reception-header">
           {{infosByReceptions[reception].supplier.toUpperCase()}} N°{{ reception }} 
            
          </h3>
          <div class="order_number">
            <p>{{ infosByReceptions[reception].date }}</p>
          </div>
          <div>
            <div
              class="reception-flex-row"
              v-for="(value, innerIndex) in receptionsGroupedTicket(reception)"
              :key="innerIndex"
            >
              <p>{{ value[0] }}</p>
              <p>{{ value[7] }} {{ value[1] }}</p>
              <p v-if="value[5] != 'Mixte'">{{ value[5] }}</p>
              <p>
                <span v-for="twin in value[6]" :key="twin"
                  >{{ twin.item_storage }} {{ twin.qtty_needed }} &nbsp;</span
                >
              </p>
              <!--p> id {{ value[2]}}  </p!-->
              <p>{{ value[4] }}</p>
            </div>
          </div>
          <button @click="printTicket('capture_reception' + reception)">
            imprimer
          </button>
        </div>
      </div>
    </div>
    <div v-else-if="Object.values(myReceptions).length === 0">
      Pas de récéptions en attente ...
    </div>

    <!-- RELIQUATS !-->
    <h2>Reliquats</h2>
    <button class="showHideBtn" @click="showRemainders = !showRemainders">
      Afficher / Masquer
    </button>
    
      <div
        class="reception-container"
        v-if="Object.values(myRemainders).length != 0 && showRemainders"
      >
      <div v-if="!ticketMode">
        <div
          class="reception-card"
          v-for="(remainder, index) in sortByOrderNumber2"
          :key="index"
        >
          <h3 class="reception-header">
            {{infosByRemainders[remainder].supplier.toUpperCase()}} N°{{ remainder }}  
          </h3>
          <div class="order_number">
            <p > {{ infosByRemainders[remainder].date }} </p>
          </div>

          <div>
            <div
              class="reception-flex-row"
              v-for="(value, innerIndex) in remaindersGrouped(remainder)"
              :key="innerIndex"
            >
              <p>{{ value[0] }}</p>
              <p>ref {{ value[1] }}</p>
              <p>{{ value[5] }}</p>
              <button class="see-twin-details"
                v-if="value[6]"
                @click="showTwinsInOut(remainder + value[1])"
              >
                details
              </button>
              <p  v-show="showTwinsCheck(remainder + value[1])" class="twin-ref-qtty-container">
                <span class="twin-ref-qtty" v-for="twin in value[6]" :key="twin"
                  >{{ twin.item_storage }}
                  <input
                    type="number"
                    :ref="twin.ref_location + twin.order_number"
                    :max="twin.qtty_needed"
                    min="0"
                    :value="twin.qtty"
                    :v-model="twin.qtty"
                    @change="
                      partValues(
                        value[1],
                        twin.ref_location,
                        twin.order_number,
                        'remainder'
                      )
                    "
                  />
                 / {{ twin.qtty_needed }} </span
                >
              </p>
              <!--p> id {{ value[2]}}  </p!-->
              <p>
                <button
                class="maxBtn"
                  @click="
                    maxValues(
                      value[1],
                      remainder,
                      remainder + value[5] + value[1],
                      'remainder'
                    )
                  "
                >
                  Max
                </button>
                <input
                  type="number"
                  class="number"
                  min="0"
                  :max="value[4]"
                  :ref="remainder + value[5] + value[1]"
                  :value="value[3]"
                  @change="
                    totalValues(
                      value[1],
                      remainder,
                      remainder + value[5] + value[1],
                      'remainder'
                    )
                  "
                />
                sur {{ value[4] }}
              </p>
            </div>
          </div>
          <div class="valid-btn-container">
            <button
              v-if="!disableButton" class="valid-btn"
              @click="confirmReception(remainder, 'remainder')"
            >
              Récéptionner
            </button>
            <button v-if="!disableButton" class="valid-btn"
            @click="cancelReception(remainder)">
              Supprimer
            </button>
            <button class="AllMax valid-btn" @click="AllMax(remainder, 'remainder')">
              100%
            </button>
          </div>
        </div>
      </div>
      <!-- ------------------------MODE TICKET--------------- !-->
      <div v-else>
        <div
          class="reception-card"
          :id="'capture_remainder' + remainder"
          v-for="(remainder, index) in sortByOrderNumber2"
          :key="index"
        >
          <h3 class="reception-header">
            {{infosByRemainders[remainder].supplier.toUpperCase()}} N°{{ remainder }}  
           
          </h3>
          <div class="order_number">
            <p> {{ infosByRemainders[remainder].date }}</p>
          </div>
          <div>
            <div
              class="reception-flex-row"
              v-for="(value, innerIndex) in remaindersGroupedTicket(remainder)"
              :key="innerIndex"
            >
              <p>{{ value[0] }}</p>
              <p>{{ value[7] }} {{ value[1] }}</p>
              <p v-if="value[5] != 'Mixte'">{{ value[5] }}</p>
              <p>
                <span v-for="twin in value[6]" :key="twin"
                  >{{ twin.item_storage }} {{ twin.qtty_needed }} &nbsp;</span
                >
              </p>
              <!--p> id {{ value[2]}}  </p!-->
              <p>{{ value[4] }}</p>
            </div>
          </div>
          <button @click="printTicket('capture_remainder' + remainder)">
            imprimer
          </button>
        </div>
      </div>
    </div>
    <div v-else-if="Object.values(myRemainders).length === 0">
      Pas de récéptions en attente ...
    </div>

    <button id="goTop" @click="toTheTop()">
      <i class="fas fa-arrow-up"></i>
    </button>
  </div>
</template>
<script>
import { mapState } from "vuex";
import html2canvas from "html2canvas";

export default {
  name: "SeeAdminOrders",
  data() {
    return {
      infosByRemainders: new Object(),
      infosByReceptions: new Object(),
      myReceptionsTwins: [],
      myRemaindersTwins: [],
      connectedUser: {},
      itemLocation: "",
      disableButton: false,
      showReceptions: true,
      showRemainders: true,
      succesDelete: false,
      showTwins: [],
      ticketMode: false,
    };
  },
  mounted() {
    this.$store.commit("isConnected");
    // récuperer l'utilisateur actuel
    this.connectedUser = JSON.parse(localStorage.getItem("connectedUser"));
    this.getOrdersData();

    // requete régulière pour nettoyer les reservations expirées
    this.$store.dispatch("refreshBookingInProgress", this.connectedUser.id_user);
  },
  computed: {
    //STORE
    ...mapState({
      myReceptions: "myReceptions",
      myRemainders: "myRemainders",
      requestResponse: "requestResponse",
      succes02: "succes02",
      succes03: "succes03",
      cableMeter: "cableMeter",
    }),
    
    requestResponseCheck() {
      if (this.requestResponse === true) {
        this.disableButton = false;
        return true;
      } else {
        //location.reload()
        return false;
      }
    },
    sortByOrderNumber() {
      const setOrders = new Set();
      var infosByReceptions = {};
      //console.log('myObject');
      //console.log(myObject);
      //console.log(this.myReceptions);
      this.myReceptions.forEach((reception) => {
        //console.log(index);
        //console.log(order.date);
        
        var orderDate= reception.order_number.split('-')[1];
        var orderHour= reception.order_number.split('-')[2];

        const year = orderDate.substring(0,2);
        const m = orderDate.substring(2,4);
        const d = orderDate.substring(4,6);
        orderDate = d + '/' + m + '/' + year;;

        const h = orderHour.substring(0,2);
        const min = orderHour.substring(2,4);
        orderHour = h + ':' + min ;

        const humanDateFormat = orderDate + " "+ orderHour;

        /*const unixTimestamp = reception.order_number;
        const milliseconds = unixTimestamp * 1000;
        const dateObject = new Date(milliseconds);
        const humanDateFormat = dateObject.toLocaleString();*/


        infosByReceptions[reception.order_number] = {
          date: humanDateFormat,
          location: reception.item_storage,
          status: reception.recep_status,
          username: reception.received_username,
          supplier : reception.supplier
        };
        setOrders.add(reception.order_number);
      });
      //console.log(infosByReceptions);
      //console.log(this.infosByReceptions);
      this.infosByReceptions = infosByReceptions;
      return Array.from(setOrders);
    },
    sortByOrderNumber2() {
      const setOrders = new Set();
      var infosByRemainders = {};
      //console.log(this.myReceptions);
      this.myRemainders.forEach((reception) => {
        //console.log(index);
        //console.log(order.date);
        var orderDate= reception.order_number.split('-')[1];
        var orderHour= reception.order_number.split('-')[2];

        const year = orderDate.substring(0,2);
        const m = orderDate.substring(2,4);
        const d = orderDate.substring(4,6);
        orderDate = d + '/' + m + '/' + year;;

        const h = orderHour.substring(0,2);
        const min = orderHour.substring(2,4);
        orderHour = h + ':' + min ;

        const humanDateFormat = orderDate + " "+ orderHour;

        /*const unixTimestamp = reception.order_number;
        const milliseconds = unixTimestamp * 1000;
        const dateObject = new Date(milliseconds);
        const humanDateFormat = dateObject.toLocaleString();*/
        infosByRemainders[reception.order_number] = {
          date: humanDateFormat,
          location: reception.item_storage,
          status: reception.recep_status,
          username: reception.received_username,
          supplier : reception.supplier
        };
        setOrders.add(reception.order_number);
      });
      //console.log(infosByOrders);
      this.infosByRemainders = infosByRemainders;
      return Array.from(setOrders);
    },
  },

  watch: {
    succes02(newCount, oldCount) {
      if (newCount === true) {
        this.getOrdersData();
      }
    },
    succes03(newCount, oldCount) {
      if (newCount === true) {
        this.getOrdersData();
      }
    },
    succesDelete(newCount, oldCount) {
      if (newCount === true) {
        this.getOrdersData();
      }
    },
    //watch pour ajouter dynamiquement pour chaque ref jumelle une nouvelle ligne avec le total
    myReceptions(newCount, oldCount) {
      if (newCount) {
        //console.log('newcount');
        var arrayTwins = [];
        var totalByRef = {};
        var box = {};
        var twinsRef = [];
        //console.log(this.myReceptions);
        this.myReceptions.forEach((reception) => {
          const r = reception.ref + reception.order_number;
          //memoriser les ref jumelles
          if (box[r]) {
            twinsRef.push(r);
          } else {
            box[r] = r;
          }
        });
        this.myReceptions.forEach((reception) => {
          reception.twins_ref = false;
          //console.log(reception.ref);
          //console.log(order.date);
          const ref = reception.ref + reception.order_number;
          if (twinsRef.includes(ref)) {
            //l'objet existe déjà
            if (totalByRef[ref]) {
              totalByRef[ref].qtty = 0;
              totalByRef[ref].qtty_needed =
                totalByRef[ref].qtty_needed + reception.qtty_needed;
              totalByRef[ref].item_storage = "Mixte";
              totalByRef[ref].twins_ref.push({
                ref: reception.ref,
                order_number: reception.order_number,
                item: reception.item,
                item_id: reception.item_id,
                qtty: 0,
                qtty_needed: reception.qtty_needed,
                ref_location: reception.ref_location,
                item_storage: reception.item_storage,
                price: reception.price,
                ref_supplier: reception.ref_supplier,
                supplier: reception.supplier,
              });
            }
            //on doit créer l'objet
            else {
              totalByRef[ref] = {
                ref: reception.ref,
                order_number: reception.order_number,
                item: reception.item,
                item_id: reception.item_id,
                qtty: 0,
                qtty_needed: reception.qtty_needed,
                ref_location: reception.ref_location,
                item_storage: reception.item_storage,
                price: reception.price,
                ref_supplier: reception.ref_supplier,
                supplier: reception.supplier,
                twins_ref: [
                  {
                    ref: reception.ref,
                    order_number: reception.order_number,
                    item: reception.item,
                    item_id: reception.item_id,
                    qtty: 0,
                    qtty_needed: reception.qtty_needed,
                    ref_location: reception.ref_location,
                    item_storage: reception.item_storage,
                  },
                ],
              };
            }
          }
          // si pas de ref jumelles
          else {
            totalByRef[ref] = {
              ref: reception.ref,
              order_number: reception.order_number,
              item: reception.item,
              item_id: reception.item_id,
              qtty: 0,
              qtty_needed: reception.qtty_needed,
              ref_location: reception.ref_location,
              item_storage: reception.item_storage,
              price: reception.price,
              ref_supplier: reception.ref_supplier,
              supplier: reception.supplier,
              twins_ref: false,
            };
          }
        });
        //console.log('totalByRef');
        //console.log(totalByRef);
        for (let r in totalByRef) {
          arrayTwins.push(totalByRef[r]);
        }
        this.myReceptionsTwins = arrayTwins;
       
      }
    },
    myRemainders(newCount, oldCount) {
      if (newCount) {
        var arrayTwins = [];
        var totalByRef = {};
        var box = {};
        var twinsRef = [];
        //console.log(this.myReceptions);
        this.myRemainders.forEach((remainder) => {
          //récupérer liste twins ref dans twinsRef
          const r = remainder.ref + remainder.order_number;
          if (box[r]) {
            twinsRef.push(r);
          } else {
            box[r] = r;
          }
        });
        this.myRemainders.forEach((remainder) => {
          remainder.twins_ref = false;
          //console.log(index);
          //console.log(order.date);
          const ref = remainder.ref + remainder.order_number;
          if (twinsRef.includes(ref)) {
            // si la clef existe deja (ce n'est pas la premiere itération)
            if (totalByRef[ref]) {
              totalByRef[ref].qtty = 0;
              totalByRef[ref].qtty_needed =
                totalByRef[ref].qtty_needed + remainder.qtty_needed;
              totalByRef[ref].item_storage = "Mixte";
              totalByRef[ref].twins_ref.push({
                ref: remainder.ref,
                order_number: remainder.order_number,
                item: remainder.item,
                item_id: remainder.item_id,
                qtty: 0,
                qtty_needed: remainder.qtty_needed,
                ref_location: remainder.ref_location,
                item_storage: remainder.item_storage,
                price: remainder.price,
                ref_supplier: remainder.ref_supplier,
                supplier: remainder.supplier,
              });
            } 
            // créer la clef
            else {
              totalByRef[ref] = {
                ref: remainder.ref,
                order_number: remainder.order_number,
                item: remainder.item,
                item_id: remainder.item_id,
                qtty: 0,
                qtty_needed: remainder.qtty_needed,
                ref_location: remainder.ref_location,
                item_storage: remainder.item_storage,
                price: remainder.price,
                ref_supplier: remainder.ref_supplier,
                supplier: remainder.supplier,
                twins_ref: [
                  {
                    ref: remainder.ref,
                    order_number: remainder.order_number,
                    item: remainder.item,
                    item_id: remainder.item_id,
                    qtty: 0,
                    qtty_needed: remainder.qtty_needed,
                    ref_location: remainder.ref_location,
                    item_storage: remainder.item_storage,
                    price: remainder.price,
                    ref_supplier: remainder.ref_supplier,
                    supplier: remainder.supplier,
                  },
                ],
              };
            }
          } else {
            totalByRef[ref] = {
              ref: remainder.ref,
              order_number: remainder.order_number,
              item: remainder.item,
              item_id: remainder.item_id,
              qtty: 0,
              qtty_needed: remainder.qtty_needed,
              ref_location: remainder.ref_location,
              item_storage: remainder.item_storage,
              price: remainder.price,
              ref_supplier: remainder.ref_supplier,
              supplier: remainder.supplier,
              twins_ref: false,
            };
          }
        });
        //console.log('totalByRef');
        //console.log(totalByRef);
        for (let r in totalByRef) {
          arrayTwins.push(totalByRef[r]);
        }
        this.myRemaindersTwins = arrayTwins;
      }
    },
  },

  methods: {
    // ticket de reception
    printTicket(id) {
      html2canvas(document.querySelector("#" + id))
        .then((canvas) => {
          document.body.appendChild(canvas);
          return canvas;
        })
        .then((canvas) => {
          const image = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
          const a = document.createElement("a");
          a.setAttribute("download", "my-image.png");
          a.setAttribute("href", image);
          a.click();
          canvas.remove();
        });
    },
    //calcul d'un input total à partir de 2 sous input, dynamique
    partValues(ref, ref_location, reception, type) {
      let reObj;
      if (type === "reception") {
        reObj = this.myReceptionsTwins;
      } else if (type === "remainder") {
        reObj = this.myRemaindersTwins;
      } else {
        alert("Erreur critique");
        return;
      }
      var total = 0;
      //console.log(typeof ref_location);
      //console.log(this.$refs[ref_location]);
      const val = parseInt(this.$refs[ref_location + reception].value);
      //console.log(val);
      for (let i in reObj) {
        if (reObj[i].ref === ref && reObj[i].order_number === reception) {
          //console.log('condition 1');
          for (let x in reObj[i].twins_ref) {
            if (reObj[i].twins_ref[x].ref_location === ref_location) {
              // console.log('condition 2');
              reObj[i].twins_ref[x].qtty = val;
            }
            //console.log(typeof reObj[i].qtty);
            //console.log(typeof reObj[i].twins_ref[x].qtty);
            //console.log(diff);
            total = total + parseInt(reObj[i].twins_ref[x].qtty);
          }
          reObj[i].qtty = total;
          if (type === "reception") {
            this.myReceptionsTwins = reObj;
          } else if (type === "remainder") {
            this.myRemaindersTwins = reObj;
          }
        }
      }
    },
    // ajuster les sous input en fonction du total, selon un ratio, dynamique
    totalValues(ref, reception, input_ref, type) {
      //var total = 0;
      let reObj;
      if (type === "reception") {
        reObj = this.myReceptionsTwins;
      } else if (type === "remainder") {
        reObj = this.myRemaindersTwins;
      } else {
        alert("totalValues function, argument non reconnu");
        return;
      }
      var total_list = [];
      var count = 0;
      const val = parseInt(this.$refs[input_ref].value);
      //console.log(val);
      for (let i in reObj) {
        if (reObj[i].ref === ref && reObj[i].order_number === reception) {
          //console.log('condition 1');
          for (let x in reObj[i].twins_ref) {
            // console.log('condition 2');
            total_list.push(parseInt(reObj[i].twins_ref[x].qtty_needed));
          };
          //somme des qtty_needed
          //console.log(total_list);
          var sum = total_list.reduce(function(a, b) {
            return a + b;
          }, 0);
          //console.log(sum);

          //créer un ratio de répartition égal
          for (let numb in total_list) {
            var ratio = total_list[numb] / sum;
            var result = val * ratio;
            if (Number.isInteger(result)) {
              reObj[i].twins_ref[numb].qtty = result;
            } else {
              //si nombres à virgules, arrondi supérieur le premier et ensuite inférieur,
              //voir si robuste avec plus de deux location ?
              if (count === 0) {
                reObj[i].twins_ref[numb].qtty = Math.ceil(result);
                count++;
              } else {
                reObj[i].twins_ref[numb].qtty = Math.floor(result);
              }
            }
          }
          reObj[i].qtty = val;
          if (type === "reception") {
            this.myReceptionsTwins = reObj;
          } else if (type === "remainder") {
            this.myRemaindersTwins = reObj;
          }
        }
      }
    },
    // ajouter la valeur maximum à un input concerné
    maxValues(ref, reception, input_ref, type) {
      let reObj;
      if (type === "reception") {
        reObj = this.myReceptionsTwins;
      } else if (type === "remainder") {
        reObj = this.myRemaindersTwins;
      } else {
        alert("Erreur critique");
        return;
      }
      var total_list = [];
      var count = 0;
      //console.log(val);
      for (let i in reObj) {
        if (reObj[i].ref === ref && reObj[i].order_number === reception) {
          //console.log('condition 1');
          this.$refs[input_ref].value = reObj[i].qtty_needed;
          const val = parseInt(reObj[i].qtty_needed);
          for (let x in reObj[i].twins_ref) {
            // console.log('condition 2');
            total_list.push(parseInt(reObj[i].twins_ref[x].qtty_needed));
          }
          //somme des qtty_needed
          //console.log(total_list);
          var sum = total_list.reduce(function(a, b) {
            return a + b;
          }, 0);
          //console.log(sum);

          //créer un ratio de répartition égal
          for (let numb in total_list) {
            var ratio = total_list[numb] / sum;
            var result = val * ratio;
            if (Number.isInteger(result)) {
              reObj[i].twins_ref[numb].qtty = result;
            } else {
              if (count === 0) {
                reObj[i].twins_ref[numb].qtty = Math.round(result);
                count++;
              } else {
                reObj[i].twins_ref[numb].qtty = Math.floor(result);
              }
            }
          }
          reObj[i].qtty = val;
          if (type === "reception") {
            this.myReceptionsTwins = reObj;
          } else if (type === "remainder") {
            this.myRemaindersTwins = reObj;
          }
        }
      }
    },
    // ajouter la valeur maximum à tous les input d'une commande
    AllMax(reception, type) {
      let reObj;
      if (type === "reception") {
        reObj = this.myReceptionsTwins;
      } else if (type === "remainder") {
        reObj = this.myRemaindersTwins;
      } else {
        alert("Erreur critique");
        return;
      }
      for (let i in reObj) {
        if (reObj[i].order_number === reception) {
          var input = this.$refs[
            reception + reObj[i].item_storage + reObj[i].ref
          ];
          input.value = reObj[i].qtty_needed;
          reObj[i].qtty = reObj[i].qtty_needed;
          if (type === "reception") {
            this.myReceptionsTwins = reObj;
          } else if (type === "remainder") {
            this.myRemaindersTwins = reObj;
          }
          //console.log('twins ref');
          //console.log(reObj[i].twins_ref);
          //console.log(reObj[i].twins_ref.length);
          //console.log(reObj[i].twins_ref === 0);
          //console.log(reObj[i].twins_ref === undefined);
          if (reObj[i].twins_ref) {
            const theRef = reObj[i].ref;
            const theLocation = reObj[i].item_storage;
            this.maxValues(
              theRef,
              reception,
              reception + theLocation + theRef,
              type
            );
          }
        }
      }
    },
    //store request après avoir vérifié, subdiviser les jumelles, enregistré, complété.
    confirmReception(reception, type) {
      let reObj;
      //détérminer le type reception ou reliquat
      if (type === "reception") {
        reObj = this.myReceptionsTwins;
      } else if (type === "remainder") {
        reObj = this.myRemaindersTwins;
      } else {
        alert("Erreur critique");
        return;
      }
      var totalQtty = {};
      var totalQttyNeeded = {};
      var saveQttyReport = {};
      //boucler sur chaque item recep... uniquement si correspond à la commande
      for (let i in reObj) {
        //...uniquement si correspond à la commande 
        //on va enregistrer les données restructurées et vérifiées dans saveQttyReport
        //on va mémoriser le total de la commande
        if (reObj[i].order_number === reception) {
          //récupérer la ref de l'input pour le produit concerné
          var input = this.$refs[
            reception + reObj[i].item_storage + reObj[i].ref
          ];
          //console.log(input);
          //console.log(input.value);

          //vérifer la valeur n'est pas supérieur sur le total
          if (input.value > reObj[i].qtty_needed) {
            alert("Une valeur est supérieure à la quantité attendue");
            input.style.backgroundColor = "red";
            setTimeout(() => {
              input.style.backgroundColor = "white";
            }, 5000);
            return;
          }
          //vérifer la valeur n'est pas supérieur sur ref jumelles si existantes
          //& enregistrer les valeurs refs jumelles
          if (reObj[i].twins_ref) {
            //console.log('twin');
            //console.log(reObj[i].twins_ref);
            for (let x in reObj[i].twins_ref) {
              const ref_twin = reObj[i].twins_ref[x].ref_location + reception;
              //console.log(this.$refs[ref_twin]);
              //console.log(this.$refs[ref_twin].value);

              //Si supérieur au total, alerté et return
              if (
                this.$refs[ref_twin].value > reObj[i].twins_ref[x].qtty_needed
              ) {
                alert("Une valeur est supérieure à la quantité attendue dans les détails");
                this.$refs[ref_twin].style.backgroundColor = "red";
                setTimeout(() => {
                  this.$refs[ref_twin].style.backgroundColor = "white";
                }, 5000);
                return;
              }
              const ref_location = reObj[i].twins_ref[x].ref_location;
              //enregistrer la ref jumelle (depot) en reliquat ou complete
              if (
                reObj[i].twins_ref[x].qtty != reObj[i].twins_ref[x].qtty_needed
              ) {
                saveQttyReport[ref_location] = [
                  ref_location,
                  reObj[i].twins_ref[x].qtty,
                ];
                saveQttyReport[ref_location] = [
                  ref_location,
                  reObj[i].twins_ref[x].qtty,
                  reObj[i].ref,
                  reObj[i].supplier,
                  reObj[i].ref_supplier,
                  reObj[i].price,
                  reObj[i].twins_ref[x].item_storage,
                  "remainder",
                ];
              } else {
                saveQttyReport[ref_location] = [
                  ref_location,
                  reObj[i].twins_ref[x].qtty,
                ];
                saveQttyReport[ref_location] = [
                  ref_location,
                  reObj[i].twins_ref[x].qtty,
                  reObj[i].ref,
                  reObj[i].supplier,
                  reObj[i].ref_supplier,
                  reObj[i].price,
                  reObj[i].twins_ref[x].item_storage,
                  "completed",
                ];
              }
            }
          }

          //On récupère la valeur de l'input
          reObj[i].qtty = input.value;

          //AU FUR ET A MESURE DES ITERATIONS ON CONSERVE LES QUANTITES POUR VERIFIER LE TOTAL
          //l'objet totalQtty exsite
          if (totalQtty[reception]) {
            totalQtty[reception] = totalQtty[reception] + parseInt(input.value);
          }
          //l'objet doit être intitalisé
          else {
            totalQtty[reception] = parseInt(input.value);
          }
          //l'objet totalQttyNeeded exsite
          if (totalQttyNeeded[reception]) {
            totalQttyNeeded[reception] =
              totalQttyNeeded[reception] + reObj[i].qtty_needed;
          }
          //l'objet doit être intitalisé
          else {
            totalQttyNeeded[reception] = reObj[i].qtty_needed;
          }
          
          const ref_location = reObj[i].ref + reObj[i].item_storage;
          //enregistrer les valeurs dans saveQttyReport en précisantle inner_status
          // c'est à dire complet ou reliquat
          if (reObj[i].qtty != reObj[i].qtty_needed && !reObj[i].twins_ref) {
            saveQttyReport[ref_location] = [
              ref_location,
              reObj[i].qtty,
              reObj[i].ref,
              reObj[i].supplier,
              reObj[i].ref_supplier,
              reObj[i].price,
              reObj[i].item_storage,
              "remainder",
            ];
          } else {
            saveQttyReport[ref_location] = [
              ref_location,
              reObj[i].qtty,
              reObj[i].ref,
              reObj[i].supplier,
              reObj[i].ref_supplier,
              reObj[i].price,
              reObj[i].item_storage,
              "completed",
            ];
          }
        }
      }

      //On vérifie que le total ne soit pas vide
      if (totalQtty[reception] === 0) {
        //console.log(totalQttyNeeded[reception]);
        //console.log(totalQtty[reception]);
        alert("VIDE");
        return;
      }
      // Store request, 2 cas
      // CAS reception complete 
      if (totalQttyNeeded[reception] === totalQtty[reception]) {
         //console.log(saveQttyReport); 
        //console.log(totalQttyNeeded[reception]);
        //console.log(totalQtty[reception]);
        this.$store.dispatch("updateReception", {
          username: this.connectedUser.username,
          user_id: this.connectedUser.id_user,
          order_number: reception,
          saveQttyReport: saveQttyReport,
          status: "completed",
        });
        //alert("completed");
       
      }
      // CAS reliquat
      else {
        //console.log(totalQttyNeeded[reception]);
        //console.log(totalQtty[reception]);
        //console.log(saveQttyReport); 
        this.$store.dispatch("updateReception", {
          username: this.connectedUser.username,
          user_id: this.connectedUser.id_user,
          order_number: reception,
          saveQttyReport: saveQttyReport,
          status: "remainder",
        });
        //alert("reliquat");
      }
    },
    //Store request
    cancelReception(reception) {
      this.$store.dispatch("updateReception", {
        username: this.connectedUser.username,
        user_id: this.connectedUser.id_user,
        order_number: reception,
        status: "canceled",
      });
    },
    //filtre de groupement en fonction du numéro de commande
    remaindersGrouped(order_number) {
      return this.myRemaindersTwins
        .filter((remainder) => remainder.order_number === order_number)
        .map((remainder) => [
          remainder.item,
          remainder.ref,
          remainder.item_id,
          remainder.qtty,
          remainder.qtty_needed,
          remainder.item_storage,
          remainder.twins_ref,
        ]);
    },
    receptionsGrouped(order_number) {
      return this.myReceptionsTwins
        .filter((reception) => reception.order_number === order_number)
        .map((reception) => [
          reception.item,
          reception.ref,
          reception.item_id,
          reception.qtty,
          reception.qtty_needed,
          reception.item_storage,
          reception.twins_ref,
        ]);
    },
    receptionsGroupedTicket(order_number) {
      return this.myReceptionsTwins
        .filter((reception) => reception.order_number === order_number)
        .map((reception) => [
          reception.item,
          reception.ref_supplier,
          reception.item_id,
          reception.qtty,
          reception.qtty_needed,
          reception.item_storage,
          reception.twins_ref,
          reception.supplier,
        ]);
    },
    remaindersGroupedTicket(order_number) {
      return this.myRemaindersTwins
        .filter((remainder) => remainder.order_number === order_number)
        .map((remainder) => [
          remainder.item,
          remainder.ref_supplier,
          remainder.item_id,
          remainder.qtty,
          remainder.qtty_needed,
          remainder.item_storage,
          remainder.twins_ref,
          remainder.supplier,
        ]);
    },

    //---GET order and book data
    getOrdersData() {
      if (Object.keys(this.connectedUser).length != 0) {
        this.$store.dispatch("getAdminReceptions", this.connectedUser.id_user);
      } else {
        alert("Accès bloqué, l'utilisateur n'est pas reconnu !");
      }
    },
    // logique d'affichage des jumelles ou non
    showTwinsInOut(ref) {
      //console.log(ref);
      if (this.showTwins.includes(ref)) {
        const index = this.showTwins.indexOf(ref);
        this.showTwins.splice(index, 1);
      } else {
        this.showTwins.push(ref);
      }
    },
    showTwinsCheck(ref) {
      //console.log(ref);
      if (this.showTwins.includes(ref)) {
        return true;
      } else {
        return false;
      }
    },
    // Scroll to top
    toTheTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.seestock-container {
  width: 80%;
  display: flex;
  flex-direction: column;
}
.reception-flex-row {
  display: flex;
  //width: 900px;
  align-items: center;
  font-size : 21px;
  justify-content: space-between;
  padding: 0px 20px;
  border: solid black 1px;
}
.reception-card {
  border: 1px solid black;
  margin-bottom : 30px;
  -webkit-box-shadow: 1px 2px 5px 1px #000000; 
  box-shadow: 2px 2px 7px 1px #000000;
  /* ff 3.6+ */
    background:-moz-linear-gradient(180deg, rgba(210, 210, 205, 1) 0%, rgba(241, 243, 243, 1) 50%, rgba(210, 210, 205, 1) 100%); 

    /* safari 5.1+,chrome 10+ */
    background:-webkit-linear-gradient(180deg, rgba(210, 210, 205, 1) 0%, rgba(241, 243, 243, 1) 50%, rgba(210, 210, 205, 1) 100%);

    /* opera 11.10+ */
    background:-o-linear-gradient(180deg, rgba(210, 210, 205, 1) 0%, rgba(241, 243, 243, 1) 50%, rgba(210, 210, 205, 1) 100%);

    /* ie 6-9 */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#D2D2CD', endColorstr='#D2D2CD', GradientType=1 );

    /* ie 10+ */
    background:-ms-linear-gradient(180deg, rgba(210, 210, 205, 1) 0%, rgba(241, 243, 243, 1) 50%, rgba(210, 210, 205, 1) 100%);

    /* global 94%+ browsers support */
    background:linear-gradient(180deg, rgba(210, 210, 205, 1) 0%, rgba(241, 243, 243, 1) 50%, rgba(210, 210, 205, 1) 100%);
}
.reception-header {
  background-color: #f38414;
  padding : 10px;
  border: 1px black solid;
  -webkit-box-shadow: 1px 2px 5px 1px #000000; 
  box-shadow: 1px 2px 5px 1px #000000;
}

.button-container {
  display: flex;
  justify-content: space-evenly;
}
button {
  margin-bottom: 5px;
}
.reception-card div {
  position : relative;
}
.AllMax {
  position: absolute;
  right: 0px;
  width: 200px;
  background-color: rgb(212, 247, 160);
}
.order_number {
  margin-bottom: 20px;
}
#goTop {
  position: fixed;
  background-color: black;
  right: 100px;
  bottom: 50px;
  width: 50px;
  height: 50px;
}
.fa-arrow-up {
  font-size: 30px;
  color: white;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
}
i {
  border: black 1px solid;
  font-size: 15px;
  padding: 5px;
}

.number {
  width: 80px;
  font-size: 20px;
}
.twin-ref-qtty-container{
display: flex;
flex-direction : column;
}
.twin-ref-qtty {
  margin: 5px;
}
.see-twin-details {
  height: 40px;
  margin : 0;
  font-size: 18px;
  background-color:  #f38414;
  color : white
}
.succes-message {
  width: 300px;
  height: 100px;
  border: 2px rgb(23, 189, 23) solid;
  font-size: 25px;
  font-weight: bold;
  border-radius: 5px;
  color: rgb(23, 189, 23);
  background-color: rgb(255, 255, 255);
  top: 30%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
  position: fixed;
  line-height: 100px;
  z-index : 10;
}
.delete-message {
  border: 2px black solid;
  color: red;
  padding : 5px;
  font-size : 22px;
  background-color: white;
}
.return-message{
  //type of violet
  color : black;
  border-color : red;
}
.valid-btn {
  font-size: 18px;
  margin: 5px;
  background-color: #0d444b;
  color : white;
}
.showHideBtn{
  margin-bottom : 20px;
  font-size: 18px;
  background-color: #0d444b;
  color : white;
}
.maxBtn{
  font-size: 18px;
  background-color: #0d444b;
  color : white;
}
@media screen and (max-width: 1100px) {
  .seestock-container {
    width: 300px;
  }
  input,select {
    background-color: white;
  }

  #goTop {
    left: 0px;
    bottom: 10px;
    width: 28px;
    height: 50px;
  }
  .fa-arrow-up {
    font-size: 16px;
  }
.reception-flex-row{
  flex-direction : column;
  padding: 0px 5px;
}
button {
  margin-bottom: 8px;
}
.AllMax {
  width: 70px;
}
i {
  border: none;
  padding: 0px;
}
.valid-btn-container {
display: flex;
}
.valid-btn {
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.number {
  width: 40px;
  font-size: 18px;
}
p {
  margin : 5px;
}
}
</style>
