<template>
  <div class="page_container" v-if="(userExist['username']) && 
  (connectedUser['a5e789410fd'] === this.rand)"
  id='main'>

        <div class="header">
        <img alt="logo digitech" src="../assets/Digitech.jpg" />
        <h1><i class="fas fa-user-circle"></i> {{userName}}</h1>
        <button class="button" @click="this.$router.push('/')">Déconnexion </button>
      </div>
  <!--div class="export-div">
    <h3> Exporter mes bases de données</h3>
      <a href="http://localhost:3000/exp_ds" download>Stock <i class="fa fa-download" ></i></a>
      <a href="http://localhost:3000/exp_da" download>Activité <i class="fa fa-download" ></i></a>
      </div!-->
      <ExportDb></ExportDb>
    <SignIn ></SignIn>
    <Users ></Users>
    <Addresses></Addresses>
<button id="goTop" @click="toTheTop()">
      <i class="fas fa-arrow-up"></i>
    </button>
  </div>
  <div v-else class="security-info">Vérification de vos accès ...
    Si les données n'apparaissent pas reconnectez vous.
     <p><router-link to="/" >Retrouner à la connexion</router-link></p>
  </div>
</template>

<script>

import SignIn from "@/components/Admin/Home/SignIn.vue";
import Users from "@/components/Admin/Home/Users.vue";
import ExportDb from "@/components/Admin/Home/ExportDb.vue";
import Addresses from "@/components/Admin/Home/Addresses.vue";
import { mapState } from "vuex";
import timerAcces from "./timer_access";

export default {
  name: 'Home',
  components: {
    SignIn, Users,Addresses,ExportDb
  },
  data() {
    return {
      userName : 'utilisateur',
      connectedUser : {},
      interval0 : null,
      tOut : null
    }
  },
  methods: {
    userExistChecking(){
       if(!this.userExist['username']){
      alert('Utilisateur inexistant');
    }
  },
      // Scroll to top
    toTheTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
        rand : '',
      });
    }
  },
  created : function (){
    setTimeout(function () { this.userExistChecking(); }.bind(this), 6000);
    this.rand = '2f+g2dfg25h2d+f15b1gs5bnj1ki1lo61q1dc16'+JSON.parse(localStorage.getItem('token'))+'1202'+JSON.parse(localStorage.getItem('token')).substr(12, 42);
  },
  computed: {
    ...mapState({
      userExist : "userExist",
      t_Key : "t_Key",
    })
    },
    beforeUnmount() {
    //console.log('beforeUnmount');
    
    clearInterval(this.interval0)
    clearTimeout(this.tOut);
  },
  mounted() {
    // récuperer l'utilisateur actuel
    const user = JSON.parse(localStorage.getItem('connectedUser'));

    // important
    this.$store.commit('exitUseMode',false)

    //----------NO useMode ---> EXIT-------------
    if(user.username.includes('USER&MODE')){
      if(!localStorage.getItem('usingMode')){
        this.connectedUser= user;
      this.connectedUser.username = user.username.split('USER&MODE')[0];
      localStorage.setItem('connectedUser',JSON.stringify(this.connectedUser));
      }
    }
    else {
      this.connectedUser = user;
    }

    this.userName = this.connectedUser.name;
    
    // requete permettant de récuperer les users pour Users.vue
    this.$store.dispatch("getAllUsers", this.connectedUser.id_user);

    // rafraichir la page régulièrement (toute les heures)
    setTimeout(function() { location.reload(); }, 3600000);

   // vérifier que l'utilisateur n'a pas été supprimé de la base de donnée
    const ids = user.id_user + "_" + user.id_user;
    this.$store.dispatch("getOneUser", ids);

    //----------timer access---------
        const special_id = 'tkrqst_'+ user.id_user ;
        //console.log('getTimerAccess');
        this.$store.dispatch('getTimerAccess', special_id );
        this.tOut = setTimeout(() => {
        if(timerAcces.verifyAccess(this.t_Key) === false){
          //console.log("false acces")
          //Quitter la connexion
          this.$router.push('/')
        };
        // revérifer toutes les 5 minutes
        this.interval0 = setInterval(() => {
        if(timerAcces.verifyAccess(this.t_Key) === false){
          //console.log("false acces")
          //Quitter la connexion
          this.$router.push('/')
        };
        }, 300000);
        }, 10000);
    //-------end timer access -----------
  },
}
</script>
<style scoped>
.page_container {
  display: flex;
  margin-top: 0px;
  flex-direction: column;
  align-items: center;
}

.header{
display: grid;
width:80%;
margin: 20px 0 20px 0;
font-weight: 600;
grid-template-columns: 1fr 1fr 1fr;
}
img {
  max-width: 80px;
}
button {
width: 200px;
height: 50px;
font-size: 20px;
justify-self: right;
align-self: center;
background-color: #0d444b;
  color : rgb(238, 130, 26);
}

#logo__title {
  font-size: 27px;
}
#goTop {
  position: fixed;
  background-color: black;
  right: 100px;
  bottom: 50px;
  width: 50px;
  height: 50px;
}
.fa-arrow-up {
  font-size: 30px;
  color: white;
}
.security-info{
  font-size : 22px;
  margin : 30px;
}
.export-div {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 120px;
  margin-bottom: 50px;
  border: black 1px solid;
  padding: 7px;
  background-color: #0d444b;
  color: rgb(238, 130, 26);
  border-radius: 10px;
}
.export-div a {
  color: rgb(238, 130, 26);
}
@media screen and (max-width: 1100px) {

  #logo__title {
    font-size: 19px;
  }
  img {
  max-width: 40px;
}
.header {
  width: 95%;
}
h1 {
  font-size: 19px;
}
button {
width: 95px;
height: 30px;
font-size: 14px;
}
#goTop {
    left: 0px;
    bottom: 10px;
    width: 18px;
    height: 50px;
    padding : 0;
  }
.fa-arrow-up {
    font-size: 12px;
    padding : 0;
  }
}
</style>