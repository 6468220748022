<template>
  <div class="seestock-container">
    <!--{{itemConsumablesMemorize}}!-->
    <!--{{ toolsLimitUser }}!-->
    <!--{{allLocations}}!-->
    <span v-if="succes03" class="succes-message">Retrait du depôt...</span>
    <span v-if="succes04" class="succes-message">Réservation...</span>
    <!-- LOCATION AND MODE BUTTON !-->
<div class="type-button-container">

     <select class="select-return" @change="returnModeSwitcher()"
     :style="returnMode === true ? {'backgroundColor' : 'red'}:{}">
     <option class="orderOption" :selected="!returnMode"  value="Mode commande">Mode commande</option>
     <option value="Retour matériel" :selected="returnMode">Retour matériel</option>
   </select>
    <!--button class="type-button testbutton" @click="fetchTest()">FETCH TEST</button!-->
   
   <!-- Location button !-->
   <select v-model="itemLocation" @change="itemLocationFn($event)">
     <option v-for="loc in locationList" :key="loc" :value="loc">{{loc}}</option>
   </select>
  </div>  

    <!-- see order and hide 0 button !-->
    <div class="type-button-container">

      <button
        v-if="!returnMode"
        class="see-order-button"
        @click="$router.push('Basket')"
      >
        Voir la commande
      </button>

      <button
        v-else
        class="see-order-button"
        @click="$router.push('Basket')"
      >
        Voir le retour
      </button>
      <span>
      <span>  Masquer les stocks à zéro</span>
            <label class="switch">
              <input checked type="checkbox" ref='zeroHide' @change="zeroHide($event)" />
              <span class="slider round"></span>
            </label></span>
          
    </div>
    <!-- TYPE !-->
    <div class="type-button-container">
      <button
        class="type-button"
        v-if="Object.values(itemConsumables).length != 0"
        :style="
          typeSelected === 'consumables' ? { 'background-color': 'yellow' } : {}
        "
        @click="showItems('consumables')"
      >
        <h3>Conso.</h3>
      </button>
      <span v-else> Consommables [...]</span>

      <button
        class="type-button"
        v-if="Object.values(itemTools).length != 0"
        :style="
          typeSelected === 'tools' ? { 'background-color': 'yellow' } : {}
        "
        @click="showItems('tools')"
      >
        <h3>Outils</h3>
      </button>
      <span v-else> Outils [...]</span>

      <button
        class="type-button"
        v-if="Object.values(itemCables).length != 0"
        :style="
          typeSelected === 'cables' ? { 'background-color': 'yellow' } : {}
        "
        @click="showItems('cables')"
      >
        <h3>Cables</h3>
      </button>
      <span v-else> Cables [...]</span>

      <button
        class="type-button"
        v-if="Object.values(itemClients).length != 0"
        :style="
          typeSelected === 'clients' ? { 'background-color': 'yellow' } : {}
        "
        @click="showItems('clients')"
      >
        <h3>Clients</h3>
      </button>
      <span v-else> Clients [...]</span>
    </div>

    <!-- SUBTYPE !-->
    <div class="type-button-container">
      <div v-if="showConsumables">
        <button
          class="subtype-button"
          :style="
            subTypeSelected === ''
              ? { 'background-color': 'yellow', color: 'black' }
              : {}
          "
          @click="subTypeSelected = ''"
        >
          <h4>TOUT</h4>
        </button>
        <button
        :disabled="searchFilter"
          class="subtype-button"
          v-for="items in itemFilterConsumables"
          :key="items"
          :style="
            subTypeSelected === items
              ? { 'background-color': 'yellow', color: 'black' }
              : {}
          "
          @click="subTypeSelected = items"
        >
          <h4>{{ items }}</h4>
        </button>
      </div>
      <div v-else-if="showTools">
        <button
          class="subtype-button"
          :style="
            subTypeSelected === ''
              ? { 'background-color': 'yellow', color: 'black' }
              : {}
          "
          @click="subTypeSelected = ''"
        >
          <h4>TOUT</h4>
        </button>
        <button
        :disabled="searchFilter"
          class="subtype-button"
          v-for="items in itemFilterTools"
          :key="items"
          :style="
            subTypeSelected === items
              ? { 'background-color': 'yellow', color: 'black' }
              : {}
          "
          @click="subTypeSelected = items"
        >
          <h4>{{ items }}</h4>
        </button>
      </div>
      <div v-else-if="showCables">
        <button
          class="subtype-button"
          :style="
            subTypeSelected === ''
              ? { 'background-color': 'yellow', color: 'black' }
              : {}
          "
          @click="subTypeSelected = ''"
        >
          <h4>TOUT</h4>
        </button>
        <button
        :disabled="searchFilter"
          class="subtype-button"
          v-for="items in itemFilterCables"
          :key="items"
          :style="
            subTypeSelected === items
              ? { 'background-color': 'yellow', color: 'black' }
              : {}
          "
          @click="subTypeSelected = items"
        >
          <h4>{{ items }}</h4>
        </button>
      </div>
      <div v-if="showClients">
        <button
          class="subtype-costumer-button"
          :style="
            clientNameSelected === ''
              ? { 'background-color': 'greenyellow' }
              : {}
          "
          @click="clientNameSelected = ''"
        >
          <h4>TOUT</h4>
        </button>
        <button
        :disabled="searchFilter"
          class="subtype-costumer-button"
          v-for="items in itemFilterClientsNames"
          :key="items"
          :style="
            clientNameSelected === items
              ? { 'background-color': 'greenyellow' }
              : {}
          "
          @click="clientNameSelected = items"
        >
          <h4>{{ items }}</h4>
        </button>
      </div>
      <div v-if="showClients">
        <button
          class="subtype-button"
          :style="
            subTypeSelected === ''
              ? { 'background-color': 'yellow', color: 'black' }
              : {}
          "
          @click="subTypeSelected = ''"
        >
          <h4>TOUT</h4>
        </button>
        <button
        :disabled="searchFilter"
          class="subtype-button"
          v-for="items in itemFilterClients(
            itemClients[itemLocation],
            clientNameSelected
          )"
          :key="items"
          :style="
            subTypeSelected === items
              ? { 'background-color': 'yellow', color: 'black' }
              : {}
          "
          @click="subTypeSelected = items"
        >
          <h4>{{ items }}</h4>
        </button>
      </div>
    </div>

    <!-- refresh and see order button !-->
    <div class="type-button-container">
      <button id="show" class="refresh-button" @click="getStocksByLocation();startA()">
        <i class="fas fa-sync-alt rotate"
        :style="startAnim ? { 'animation-play-state': 'running'} : {'animation-play-state': 'paused'}"></i>
      </button>

      <!--button>Annuler les quantités</button!-->
      <!-- chercher un objet -->
      <div class="search-div"><input class="search-input" type="text" placeholder="Rechercher un objet" @change='wordFilter($event)' > 
      <button v-if="searchFilter===false" class="search-icon" @click="wordFilter2()">&#128269;</button>
      <button  v-else  class="search-icon" @click="searchFilter = false">X</button>  </div>
    </div>

    <!-- DATA !-->
    <!-- Consumable !-->
    <div class="seestock-sub-container">
      <div
        v-if="
          Object.values(itemConsumables).length != 0 && showConsumables === true
        "
      >
        <div
          class="item"
          :style="returnMode ? { backgroundColor: 'red' } : {}"
          v-for="(items, index) in subtypeFilter(
            itemConsumables[itemLocation],
            subTypeSelected
          )"
          :key="items"
          :index="index"
        >
          <h3>
            <span v-if="returnMode" style="color : yellow"
              >RETOUR MATERIEL</span
            >
            {{ items["item"] }}
          </h3>

          <img :src="items['image'] || defaultImg" />

          <span>
            <span> Palier : {{ items["out_packaging"] }} </span>
            <label class="switch">
              <input :ref="'consumable' + 'Step' + index" type="checkbox" />
              <span class="slider round"></span>
            </label>
          </span>

          <span>
            Stock :
            {{ items["qtty_in"] }}
          </span>
          <span>
            Dispo :
            {{ items["qtty_available"] }}
          </span>
          <span v-if="items['qtty_max'] != 0">
            Max :
            {{ items["qtty_max"] }}
          </span>
          <span v-else>
            Max : non
          </span>

          <div v-if="!returnMode" class="mini-grid">
            <button
              class="qtty"
              id="qtty-"
              :ref="'-consumable' + index"
              @click="modifyQtty('-', 'consumable', index, items)"
            >
              -
            </button>
            <button
              class="qtty"
              id="qtty2"
              :ref="'+consumable' + index"
              @click="modifyQtty('+', 'consumable', index, items)"
            >
              +
            </button>
          </div>

          <div v-else class="mini-grid">
            <button
              class="qtty"
              id="qtty-"
              :ref="'-consumable' + index"
              @click="modifyQttyForReturn('-', 'consumable', index, items)"
            >
              -
            </button>
            <button
              class="qtty"
              id="qtty2"
              :ref="'+consumable' + index"
              @click="modifyQttyForReturn('+', 'consumable', index, items)"
            >
              +
            </button>
          </div>

          <div v-if="!returnMode" class="mini-grid">
            <!-- dynamic value succès !-->
            <input
              v-if="orderInProgress[items['ref'] + '+' + items['item_storage']]"
              :value="
                orderInProgress[items['ref'] + '+' + items['item_storage']]
                  .quantity
              "
              min="0"
              :max="maxArrow(items)"
              :ref="'consumable' + index"
              class="number"
              type="number"
              placeholder="0"
              @focusout="qttyLimit(index, items, 'consumable')"
            />

            <input
              v-else
              value="0"
              min="0"
              :max="items['qtty_max']"
              :ref="'consumable' + index"
              class="number"
              type="number"
              placeholder="0"
              @focusout="qttyLimit(index, items, 'consumable')"
            />
          </div>

          <div v-else class="mini-grid">
            <!-- dynamic value succès !-->
            <input
              v-if="
                returnInProgress[items['ref'] + '+' + items['item_storage']]
              "
              :value="
                returnInProgress[items['ref'] + '+' + items['item_storage']]
                  .quantity
              "
              min="0"
              :ref="'consumable' + index"
              class="number"
              type="number"
              placeholder="0"
              @focusout="qttyLimitForReturn(index, items, 'consumable')"
            />

            <input
              v-else
              value="0"
              min="0"
              :ref="'consumable' + index"
              class="number"
              type="number"
              placeholder="0"
              @focusout="qttyLimitForReturn(index, items, 'consumable')"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- Tool !-->
    <div class="seestock-sub-container">
      <div v-if="Object.values(itemTools).length != 0 && showTools === true">
        <div
          class="tool-item item"
          :style="returnMode ? { backgroundColor: 'red' } : {}"
          v-for="(items, index) in subtypeFilter(
            itemTools[itemLocation],
            subTypeSelected
          )"
          :key="items"
          :index="index"
        >
          <h3>
            <span v-if="returnMode" style="color : yellow"
              >RETOUR MATERIEL</span
            >
            {{ items["item"] }}
          </h3>

          <img :src="items['image'] || defaultImg" />

          <div class="tool-period">
            Periodicité :
            {{ items["period"] }} <span style="font-size : 14px">/ Jour(s)</span>

            <span
              v-if="checkPeriodLimit(items['period'], items['ref'])"
              class="tool-alert"
            >
              <span class="tick-icon">&#10004;</span>
            </span>
            <span v-else class="tool-alert">
              <span class="warning-icon">&#9888;</span>
              <button
                v-if="notKeyExist(items['ref'])"
                @click="createKey(items['ref'])"
              >
                voir le délai restant
              </button>
              <span  style="font-size : 14px" v-else>
                Jour(s) restants :
                {{ calculatePeriodLimit(items["period"], items["ref"]) }}</span
              >
            </span>
          </div>

          <span>
            <span> Palier : {{ items["out_packaging"] }} </span>
            <label class="switch">
              <input type="checkbox" :ref="'tool' + 'Step' + index" />
              <span class="slider round"></span>
            </label>
          </span>

          <span>
            Stock :
            {{ items["qtty_in"] }}
          </span>
          <span>
            Dispo :
            {{ items["qtty_available"] }}
          </span>
          <span v-if="items['qtty_max'] != 0">
            Max :
            {{ items["qtty_max"] }}
          </span>
          <span v-else>
            Max : non
          </span>
          <div v-if="!returnMode" class="mini-grid">
            <button
              class="qtty"
              id="qtty-"
              :ref="'-tool' + index"
              @click="modifyQtty('-', 'tool', index, items)"
            >
              -
            </button>
            <button
              class="qtty"
              id="qtty2"
              :ref="'+tool' + index"
              @click="modifyQtty('+', 'tool', index, items)"
            >
              +
            </button>
          </div>

          <div v-else class="mini-grid">
            <button
              class="qtty"
              id="qtty-"
              :ref="'-tool' + index"
              @click="modifyQttyForReturn('-', 'tool', index, items)"
            >
              -
            </button>
            <button
              class="qtty"
              id="qtty2"
              :ref="'+tool' + index"
              @click="modifyQttyForReturn('+', 'tool', index, items)"
            >
              +
            </button>
          </div>

          <div v-if="!returnMode" class="mini-grid">
            <!-- dynamic value succès !-->
            <input
              v-if="orderInProgress[items['ref'] + '+' + items['item_storage']]"
              :value="
                orderInProgress[items['ref'] + '+' + items['item_storage']]
                  .quantity
              "
              min="0"
              :max="items['qtty_max']"
              :ref="'tool' + index"
              class="number"
              type="number"
              placeholder="0"
              @focusout="qttyLimit(index, items, 'tool')"
            />

            <input
              v-else
              value="0"
              min="0"
              :max="items['qtty_max']"
              :ref="'tool' + index"
              class="number"
              type="number"
              placeholder="0"
              @focusout="qttyLimit(index, items, 'tool')"
            />
          </div>

          <div v-else class="mini-grid">
            <!-- dynamic value succès !-->
            <input
              v-if="
                returnInProgress[items['ref'] + '+' + items['item_storage']]
              "
              :value="
                returnInProgress[items['ref'] + '+' + items['item_storage']]
                  .quantity
              "
              min="0"
              :ref="'tool' + index"
              class="number"
              type="number"
              placeholder="0"
              @focusout="qttyLimitForReturn(index, items, 'tool')"
            />

            <input
              v-else
              value="0"
              min="0"
              :ref="'tool' + index"
              class="number"
              type="number"
              placeholder="0"
              @focusout="qttyLimitForReturn(index, items, 'tool')"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- Cable !-->
    <div class="seestock-sub-container">
      <div v-if="Object.values(itemCables).length != 0 && showCables === true">
        <div
          class="item item-cable"
          :style="returnMode ? { backgroundColor: 'red' } : {}"
          v-for="(items, index) in subtypeFilter(
            itemCables[itemLocation],
            subTypeSelected
          )"
          :key="items"
          :index="index"
        >
          <h3 class="h3-cable">
            <span v-if="returnMode" style="color : yellow"
              >RETOUR MATERIEL</span
            >
            {{ items["item"] }}
          </h3>
          <span class="ref-cable"> Touret {{ items["ref"] }}</span>
          <!--div v-if="items['period'] === 0">
            <button
            class="cable-btn"
              :disabled="returnMode"
              @click="borrowReel(items['id'], items['item'],items['item_storage'])"
            >
              Retirer 
            </button>
            <button
            class="cable-btn"
              :disabled="returnMode"
              @click="bookingReel(items['id'], items['item'],items['item_storage'])"
            >
              Reserver 
            </button>
          </div!-->
          <span v-if="items['period'] === 0" class="status-cable">
            Libre ✅
          </span>
          <span v-else-if="items['period'] === 1" class="status-cable"
            >Sur chantier

            <button
              v-if="
                Object.values(borrowMan).length === 0 ||
                  borrowMan[items['id']] == undefined
              " 
              @click="searchBorrow(items['id'])"
            >
              Qui ?
            </button>
            <span
              v-else-if="
                Object.values(borrowMan).length != 0 && borrowMan[items['id']]
              " class="status-cable"
            >
              Emprunté par : {{ borrowMan[items["id"]].reel }}</span
            >
          </span>

          <span v-else-if="items['period'] === 2" class="status-cable"
            > En réservation

            <button
              v-if="
                Object.values(borrowMan).length === 0 ||
                  borrowMan[items['id']] == undefined
              "
              @click="searchBorrow(items['id'])"
            >
              Qui ? 
            </button>
            <span
              v-else-if="
                Object.values(borrowMan).length != 0 && borrowMan[items['id']]
              " class="status-cable"
            >
              Reservé par : {{ cleanStr(borrowMan[items["id"]].reel) }}</span
            >
          </span>

          <!--sur demande pas d'image cable-->
          <!--img :src="items['image'] || defaultImg" /!-->

          <div class="centerOfCard" v-if="items['qtty_in'] != 0">
            <button v-if="takeMeter != items['ref']" :disabled="items['period'] === 1"
            @click="takeMeter = items['ref']">Couronne</button>
            <button v-else :disabled="items['period'] === 1"
           @click="takeMeter = false">Retour</button>
          <button :disabled="items['period'] === 1 || items['period'] === 2 || takeMeter === items['ref'] || returnMode"
           @click="borrowReel(items['id'], items['item'],items['item_storage'])">Retirer</button>

          <button :disabled="items['period'] === 1 || items['period'] === 2 || takeMeter === items['ref'] || returnMode"
          @click="bookingReel(items['id'], items['item'],items['item_storage'])">Reserver</button>
          </div>
          <div v-else class="centerOfCard" > <button disabled style="margin-left:auto; margin-right:auto;">Quantité à zéro</button> </div>
          <span>
            <span> Palier : non </span>
            <label class="switch">
              <input
                :disabled="true"
                type="checkbox"
                checked="true"
                :ref="'cable' + 'Step' + index"
              />
              <span class="slider round"></span>
            </label>
          </span>

          <span>
            Stock : <br />
            {{ items["qtty_in"] }} m
          </span>
          <span>
            Dispo : <br />
            {{ items["qtty_available"] }} m
          </span>
          <span v-if="items['qtty_max'] != 0">
            Max :
            {{ items["qtty_max"] }}
          </span>
          <span v-else>
            Max : non
          </span>

          <div v-if="!returnMode" class="mini-grid">
            <button
              :disabled="items['period'] === 1 || takeMeter != items['ref']"
              class="qtty"
              id="qtty-"
              :ref="'-cable' + index"
              @click="modifyQtty('-', 'cable', index, items)"
            >
              -
            </button>
            <button
              :disabled="items['period'] === 1 || takeMeter != items['ref']"
              class="qtty"
              id="qtty2"
              :ref="'+cable' + index"
              @click="modifyQtty('+', 'cable', index, items)"
            >
              +
            </button>
          </div>

          <div v-else class="mini-grid">
            <button disabled
              class="qtty"
              id="qtty-"
              :ref="'-cable' + index"
              @click="modifyQttyForReturn('-', 'cable', index, items)"
            >
              -
            </button>
            <button disabled
              class="qtty"
              id="qtty2"
              :ref="'+cable' + index"
              @click="modifyQttyForReturn('+', 'cable', index, items)"
            >
              +
            </button>
          </div>

          <div v-if="!returnMode" class="mini-grid">
            <!-- dynamic value succès !-->
            <input
              v-if="orderInProgress[items['ref'] + '+' + items['item_storage']]"
              :disabled="items['period'] === 1 || takeMeter != items['ref']"
              :value="
                orderInProgress[items['ref'] + '+' + items['item_storage']]
                  .quantity
              "
              min="0"
              :max="items['qtty_max']"
              :ref="'cable' + index"
              class="number"
              type="number"
              placeholder="0"
              @focusout="qttyLimit(index, items, 'cable')"
            />

            <input
              v-else
              :disabled="items['period'] === 1 ||  takeMeter != items['ref']"
              :value="0"
              min="0"
              :max="items['qtty_max']"
              :ref="'cable' + index"
              class="number"
              type="number"
              placeholder="0"
              @focusout="qttyLimit(index, items, 'cable')"
            />
          </div>

          <div v-else class="mini-grid">
            <!-- dynamic value succès !-->
            <input disabled
              v-if="
                returnInProgress[items['ref'] + '+' + items['item_storage']]
              "
              :value="
                returnInProgress[items['ref'] + '+' + items['item_storage']]
                  .quantity
              "
              min="0"
              :ref="'cable' + index"
              class="number"
              type="number"
              placeholder="0"
              @focusout="qttyLimitForReturn(index, items, 'cable')"
            />

            <input disabled
              v-else
              value="0"
              min="0"
              :ref="'cable' + index"
              class="number"
              type="number"
              placeholder="0"
              @focusout="qttyLimitForReturn(index, items, 'cable')"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- Client !-->
    <div class="seestock-sub-container">
      <div
        v-if="Object.values(itemClients).length != 0 && showClients === true"
      >
        <div
          class="item"
          :style="returnMode ? { backgroundColor: 'red' } : {}"
          v-for="(items, index) in subtypeClientFilter(
            itemClients[itemLocation],
            subTypeSelected,
            clientNameSelected
          )"
          :key="items"
          :index="index"
        >
          <h3>
            <span v-if="returnMode" style="color : yellow"
              >RETOUR MATERIEL</span
            >
            {{ items["item"] }}
          </h3>

          <img :src="(items['image']!= null) ? items['image']: defaultImg" />

          <span>
            <span> Palier : {{ items["out_packaging"] }} </span>
            <label class="switch">
              <input :ref="'client' + 'Step' + index" type="checkbox" />
              <span class="slider round"></span>
            </label>
          </span>

          <span>
            Stock :
            {{ items["qtty_in"] }}
          </span>
          <span>
            Dispo :
            {{ items["qtty_available"] }}
          </span>
          <span v-if="items['qtty_max'] != 0">
            Max :
            {{ items["qtty_max"] }}
          </span>
          <span v-else>
            Max : non
          </span>

          <div v-if="!returnMode" class="mini-grid">
            <button
              class="qtty"
              id="qtty-"
              :ref="'-client' + index"
              @click="modifyQtty('-', 'client', index, items)"
            >
              -
            </button>
            <button
              class="qtty"
              id="qtty2"
              :ref="'+client' + index"
              @click="modifyQtty('+', 'client', index, items)"
            >
              +
            </button>
          </div>

          <div v-else class="mini-grid">
            <button
              class="qtty"
              id="qtty-"
              :ref="'-client' + index"
              @click="modifyQttyForReturn('-', 'client', index, items)"
            >
              -
            </button>
            <button
              class="qtty"
              id="qtty2"
              :ref="'+client' + index"
              @click="modifyQttyForReturn('+', 'client', index, items)"
            >
              +
            </button>
          </div>

          <div v-if="!returnMode" class="mini-grid">
            <!-- dynamic value succès !-->
            <input
              v-if="orderInProgress[items['ref'] + '+' + items['item_storage']]"
              :value="
                orderInProgress[items['ref'] + '+' + items['item_storage']]
                  .quantity
              "
              min="0"
              :max="maxArrow(items)"
              :ref="'client' + index"
              class="number"
              type="number"
              placeholder="0"
              @focusout="qttyLimit(index, items, 'client')"
            />

            <input
              v-else
              value="0"
              min="0"
              :max="items['qtty_max']"
              :ref="'client' + index"
              class="number"
              type="number"
              placeholder="0"
              @focusout="qttyLimit(index, items, 'client')"
            />
          </div>

          <div v-else class="mini-grid">
            <!-- dynamic value succès !-->
            <input
              v-if="
                returnInProgress[items['ref'] + '+' + items['item_storage']]
              "
              :value="
                returnInProgress[items['ref'] + '+' + items['item_storage']]
                  .quantity
              "
              min="0"
              :ref="'client' + index"
              class="number"
              type="number"
              placeholder="0"
              @focusout="qttyLimitForReturn(index, items, 'client')"
            />

            <input
              v-else
              value="0"
              min="0"
              :ref="'client' + index"
              class="number"
              type="number"
              placeholder="0"
              @focusout="qttyLimitForReturn(index, items, 'client')"
            />
          </div>
        </div>
      </div>
    </div>

    <button id="goTop" @click="toTheTop()">
      <i class="fas fa-arrow-up"></i>
    </button>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "ItemMain",
  data() {
    return {
      orderInProgress: new Object(),
      returnInProgress: new Object(),
      connectedUser: {},
      showConsumables: false,
      showTools: false,
      showCables: false,
      showClients: false,
      defaultImg: require("../../assets/photo.png"),
      qtty: 0,
      itemLocation: "",
      locationList: [],
      ref: "",
      item: "",
      subTypesConsumables: new Set([]),
      subTypesTools: new Set([]),
      subTypesCables: new Set([]),
      image: "",
      price: "",
      supplier: "",
      qtty_min: "",
      qtty_max: "",
      packaging: "",
      type_: "",
      period: "",
      qtty_in: "",
      qtty_available: "",
      data_feeder: "",
      item_id: "",
      putLoader: false,
      typeSelected: "",
      subTypeSelected: "",
      clientNameSelected: "",
      returnMode: false,
      fetchDataLimitPeriod: false,
      memoriseLimitTools: {},
      alertPeriodLimit: {},
      showDaysLeft: {},
      itemConsumablesMemorize : {},
      memorisedWord : '',
      disableSearch : false,
      searchFilter : false,
      hideZero : false,
      isMounted: false,
      takeMeter : 'none',
      startAnim : false
    };
  },
  mounted() {
    this.$store.commit("isConnected");
    // récuperer l'utilisateur actuel
    this.connectedUser = JSON.parse(localStorage.getItem("connectedUser"));

    //récupérer la liste des dépôts
    this.$store.dispatch("getAllLocations", this.connectedUser.id_user);


    //----------gestion du useMode-------------
    const originalUsername = this.connectedUser.username;
    var connectedUser = this.connectedUser;

    const userMode = JSON.parse(localStorage.getItem('usingMode'));
    const userModeId  = JSON.parse(localStorage.getItem('usingModeI'));
    const userModeUsername = localStorage.getItem('usingModeU');
    if(userMode && userMode === true && userModeId && userModeUsername){
      if(!this.connectedUser.username.includes('USER&MODE')){
      connectedUser.username  = originalUsername + 'USER&MODE' + userModeUsername + '^&^'+userModeId;
      localStorage.setItem('connectedUser',JSON.stringify(connectedUser));
      this.connectedUser = JSON.parse(localStorage.getItem("connectedUser"));}
    }
    //----------END gestion du useMode-------------
    
    // récupérer la commande ou le retour
    if (JSON.parse(localStorage.getItem("orderInProgress"))) {
      this.orderInProgress = JSON.parse(
        localStorage.getItem("orderInProgress")
      );
    }
    if (JSON.parse(localStorage.getItem("returnInProgress"))) {
      this.returnInProgress = JSON.parse(
        localStorage.getItem("returnInProgress")
      );
    }
    // vérifier si on est en mode retour ou commande
    if (JSON.parse(localStorage.getItem("returnMode"))) {
      this.returnMode = JSON.parse(localStorage.getItem("returnMode"));
    }

    /*la requete est desormais déclenché sur un watcher
    this.getStocksByLocation();*/

    this.isMounted = true;
  },
  beforeUnmount() {
    this.fetchDataLimitPeriod = false;
  },

  computed: {
    //STORE
    ...mapState({
      itemConsumables: "itemConsumablesByLocation",
      itemTools: "itemToolsByLocation",
      itemCables: "itemCablesByLocation",
      itemClients: "itemClientsByLocation",
      toolsLimitUser: "toolsLimitUser",
      borrowMan: "borrowMan",
      succes03: "succes03",
      succes04: "succes04",
      allLocations : "allLocations"
    }),

    //filtrer les sous types dans un set
    itemFilterConsumables() {
      var obj = this.itemConsumables[this.itemLocation];
      var theSet = new Set();
      //console.log('THE SET');
      //console.log(theSet);
      //console.log('THE OBJECT');
      //console.log(obj);
      //console.log(Object.values(obj));
      for (let i in obj) {
        theSet.add(obj[i]["sub_type"]);
      }
      //console.log('THE SET after')
      //console.log(theSet)
      return theSet;
    },
    itemFilterTools() {
      var obj = this.itemTools[this.itemLocation];
      var theSet = new Set();
      for (let i in obj) {
        theSet.add(obj[i]["sub_type"]);
      }
      return theSet;
    },
    itemFilterCables() {
      var obj = this.itemCables[this.itemLocation];
      var theSet = new Set();
      for (let i in obj) {
        theSet.add(obj[i]["sub_type"]);
      }
      return theSet;
    },
    //filtrer les names dans un set
    itemFilterClientsNames() {
      //console.log(this.itemClients);
      var obj = this.itemClients[this.itemLocation];
      var theSet = new Set();
      for (let i in obj) {
        theSet.add(obj[i]["supplier"]);
      }
      return theSet;
    },
  },
  watch: {
    succes03(newCount, oldCount) {
      if (newCount === true) {
        this.getStocksByLocationOnRefresh(this.itemLocation);
      }
    },
    succes04(newCount, oldCount) {
      if (newCount === true) {
        this.getStocksByLocationOnRefresh(this.itemLocation);
      }
    },
    allLocations(newCount, oldCount) {
      if(newCount) {
        //alert(newCount);
        this.locationList = newCount;
        this.itemLocation = newCount[0];
        this.getStocksByLocation();
      }
    },
  },

  methods: {
    /* fetchTest(){
         this.$store.dispatch("getAllItems", this.connectedUser.id_user);
      },*/

    //Mémoriser la location pour la page panier
    itemLocationFn(event) {
      //console.log(event.target.value);
      this.itemLocation = event.target.value;
      localStorage.setItem("memoriseLoc", JSON.stringify(event.target.value));
      this.subTypeSelected = "";
      this.clientNameSelected = "";
    },
    //filtre qui retourne une liste d'objet uniquement si en lien avec le subtype cliqué
    subtypeFilter(obj, filter) {
      //console.log(filter);
      //console.log(obj);
      //console.log(this.itemConsumables[this.itemLocation]);
      var newObj = {};
      if(this.searchFilter === true){
        //console.log('1');
        let Obj;
        if(this.showConsumables){
          Obj = this.itemConsumables;
        }
        else if(this.showTools){
          Obj = this.itemTools;
        }
        else if(this.showCables){
          Obj = this.itemCables;
        }
        else if(this.showClients){
          Obj = this.itemClients;
        }

        return Obj[this.itemLocation].filter((Ob) => {
          if(this.hideZero === true){
        if(Ob.qtty_in != 0){
          return Ob.item
            .toLowerCase()
            .includes(this.memorisedWord.toLowerCase());
        }
      } else {
          return Ob.item
            .toLowerCase()
            .includes(this.memorisedWord.toLowerCase());}
        });
      }
      else if (filter != "") {
        //console.log('2');
        for (let i in obj) {
          //console.log(obj[i]);
          if (obj[i]["sub_type"] === filter && this.hideZero) {
            if(obj[i].qtty_in != 0){
            newObj[i] = obj[i];}
          }
          else if (obj[i]["sub_type"] === filter ) {
            newObj[i] = obj[i];
          }
        }
      } 
      else {
        //console.log('3');
        if(this.hideZero === true){
          for(let i in obj){
            if(obj[i].qtty_in != 0){
              //console.log(obj[i])
              newObj[i] = obj[i];
            }
          }
          return newObj
        }else{
          return obj;
        }
        
      }
      return newObj;
    },
    //filtre qui [...] en lien avec le subtype & le name cliqué
    subtypeClientFilter(obj, filter, name) {
      var newObj = {};
            if(this.searchFilter === true){
        let Obj;
        if(this.showClients){
          Obj = this.itemClients;
        }
        return Obj[this.itemLocation].filter((Ob) => {
          if(this.hideZero === true){
        if(Ob.qtty_in != 0){
          return Ob.item
            .toLowerCase()
            .includes(this.memorisedWord.toLowerCase());
        }
      }else{
          return Ob.item
            .toLowerCase()
            .includes(this.memorisedWord.toLowerCase());}
        });
      }
      if (filter === "" && name === "") {
        if(this.hideZero === true){
          for(let i in obj){
            if(obj[i].qtty_in != 0){
              //console.log(obj[i])
              newObj[i] = obj[i];
            }
          }
          return newObj
        }else{
          return obj;
        }
      } else if (filter != "" && name === "") {
        for (let i in obj) {
          if(this.hideZero === true){
            if(obj[i].qtty_in != 0 && obj[i]["sub_type"] === filter){
              //console.log(obj[i])
              newObj[i] = obj[i];
          }
        }
        else {
          //console.log(obj[i]['sub_type']);
          if (obj[i]["sub_type"] === filter) {
            newObj[i] = obj[i];
          }}
        }
      } else if (filter === "" && name != "") {
        for (let i in obj) {
          if(this.hideZero === true){
            if (obj[i].qtty_in != 0 && obj[i]["supplier"] === name) {
            newObj[i] = obj[i];
          }
          }
          else{
          //console.log(obj[i]['sub_type']);
          if (obj[i]["supplier"] === name) {
            newObj[i] = obj[i];
          }}
        }
      } else if (filter != "" && name != "") {
        for (let i in obj) {
          //console.log(obj[i]['sub_type']);
          if(this.hideZero === true){
          if(obj[i].qtty_in != 0 && obj[i]["supplier"] === name && obj[i]["sub_type"] === filter){
            newObj[i] = obj[i];
          }
          }
          else{
          if (obj[i]["supplier"] === name && obj[i]["sub_type"] === filter) {
            newObj[i] = obj[i];
          }}
        }
      }
      return newObj;
    },
    //filtrer les sous types dans un set à partir des names
    itemFilterClients(obj, name) {
      var newObj = {};
      if (name != "") {
        for (let i in obj) {
          //console.log(obj[i]['sub_type']);
          if (obj[i]["supplier"] === name) {
            newObj[i] = obj[i];
          }
        }
        var theSet = new Set();
        for (let i in newObj) {
          theSet.add(newObj[i]["sub_type"]);
        }
        return theSet;
      } else {
        var theSet = new Set();
        for (let i in obj) {
          theSet.add(obj[i]["sub_type"]);
        }
        return theSet;
      }
    },
    wordFilter(event){
        this.memorisedWord = event.target.value;
    },
    wordFilter2(){
        this.searchFilter = true;
    },
    //fonction de filtre et controle pour l'input quantité, reliée au local storage
    qttyLimit(index, items, type_) {
      var ref = this.$refs[type_ + index];
      var checkBox = this.$refs[type_ + "Step" + index];
      //console.log('checkBox');
      //console.log(checkBox);
      //console.log(checkBox.checked);
      let itemSelected;
      let thisBoolean;

      if (this.showConsumables === true) {
        itemSelected = this.itemConsumables;
      } else if (this.showTools === true) {
        //si c'est un outil qui dont la période n'est pas respecté le mémoriser
        if (this.alertPeriodLimit[items["ref"]] != undefined) {
          this.alertBasketToolLimit(
            this.alertPeriodLimit[items["ref"]].period,
            items["ref"]
          );
        }
        itemSelected = this.itemTools;
      } else if (this.showCables === true) {
        itemSelected = this.itemCables;
      } else if (this.showClients === true) {
        itemSelected = this.itemClients;
      }

      //check if max =0;
      if (itemSelected[this.itemLocation][index].qtty_max != 0) {
        if (ref.value > itemSelected[this.itemLocation][index].qtty_max) {
          thisBoolean = true;
        } else {
          thisBoolean = false;
        }
      } else {
        thisBoolean = false;
      }

      //console.log(itemSelected);
      if (
        ref.value > itemSelected[this.itemLocation][index].qtty_in ||
        ref.value > itemSelected[this.itemLocation][index].qtty_available ||
        thisBoolean ||
        (!checkBox.checked &&
          ref.value % itemSelected[this.itemLocation][index].out_packaging != 0)
      ) {
        ref.value = 0;
        this.addMultiplesItems(items, 0);
        ref.style.backgroundColor = "red";
        setTimeout(() => {
          ref.style.backgroundColor = "white";
        }, 500);
      } else {
        this.addMultiplesItems(items, ref.value);
      }
    },
    // pareil mais en mode retour
    qttyLimitForReturn(index, items, type_) {
      var ref = this.$refs[type_ + index];
      //console.log('checkBox');
      //console.log(checkBox);
      //console.log(checkBox.checked);
      const UNIQUE_KEY = items["ref"] + "+" + items["item_storage"];
      if (this.returnInProgress[UNIQUE_KEY]) {
        this.returnInProgress[UNIQUE_KEY].quantity = parseInt(ref.value);
      } else {
        this.returnInProgress[UNIQUE_KEY] = {
          quantity: parseInt(ref.value),
        };
      }
      localStorage.setItem(
        "returnInProgress",
        JSON.stringify(this.returnInProgress)
      );
    },
    startA(){
      this.startAnim = true;
      setTimeout(() => {
        this.startAnim = false;
      }, 2000);
    },
    //---GET STOCKS DATA WITH STORE
    getStocksByLocation() {
      
      if (Object.keys(this.connectedUser).length != 0) {
        for (let i in this.locationList) {
          const locationAndId =
            this.locationList[i] + "_" + this.connectedUser.id_user;
          this.$store.dispatch("getAllItemsByLocation", locationAndId);
        }
      } else {
        alert("Accès bloqué, l'utilisateur n'est pas reconnu !");
      }
    },

    getStocksByLocationOnRefresh(location) {
      if (Object.keys(this.connectedUser).length != 0) {
        const locationAndId = location + "_" + this.connectedUser.id_user;
        this.$store.dispatch("getAllItemsByLocation", locationAndId);
      } else {
        alert("Accès bloqué, l'utilisateur n'est pas reconnu !");
      }
    },
    // Scroll to top
    toTheTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    // SHOW ITEMS FOR type_ SELECTED
    showItems(type_) {
      this.subTypeSelected = "";
      this.clientNameSelected = "";
      this.typeSelected = type_;
      switch (type_) {
        case "consumables":
          this.showTools = false;
          this.showCables = false;
          this.showClients = false;
          this.showConsumables = true;
          break;
        case "tools":
          this.showCables = false;
          this.showConsumables = false;
          this.showClients = false;
          this.showTools = true;
          if (!this.fetchDataLimitPeriod) {
            this.getToolsLimitUser();
          }
          //this.fetchDataLimitPeriod = true;
          break;
        case "cables":
          this.showTools = false;
          this.showConsumables = false;
          this.showClients = false;
          this.showCables = true;
          break;
        case "clients":
          this.showTools = false;
          this.showConsumables = false;
          this.showCables = false;
          this.showClients = true;
          break;

        default:
          break;
      }
    },
    showSubType(sub_type) {
      this.subTypeSelected = sub_type;
    },
    // HANDLER FOR + & -
    modifyQtty(str, type_, i, item) {
      //console.log(str);
      //console.log(this.$refs['consumable'+i]);
      let number;
      
      const UNIQUE_KEY = item["ref"] + "+" + item["item_storage"];
      if (this.orderInProgress[UNIQUE_KEY]) {
        number = this.orderInProgress[UNIQUE_KEY].quantity;
      } else {
        number = 0;
      }
      //console.log(typeof number);
      var buttonSous = this.$refs["-" + type_ + i];
      var buttonAdd = this.$refs["+" + type_ + i];
      var checkBox = this.$refs[type_ + "Step" + i];
      number = parseInt(number);
      //console.log(typeof number);
      //console.log(number+item['out_packaging']);
      //console.log(item['qtty_available']);
      //console.log((number + item['out_packaging'] <= item['qtty_in']));
      //console.log((number+item['out_packaging'] <= item['qtty_max']));
      //-----AJOUT


      // !checkbox.checked === green
      if (str === "+" && !checkBox.checked) {
        if (
          number + item["out_packaging"] <= item["qtty_available"] &&
          number + item["out_packaging"] <= item["qtty_in"] &&
          (number + item["out_packaging"] <= item["qtty_max"] ||
            item["qtty_max"] == 0)
        ) {
          //console.log(number);
          this.addItemToLocalStorage(item,1);
          //this.$refs[type+i].value = number;
          if (buttonSous.disabled === true) {
            buttonSous.disabled = false;
          }
        } else if (
          number === item["qtty_available"] ||
          number === item["qtty_max"] ||
          number + item["out_packaging"] > item["qtty_max"] ||
          number + item["out_packaging"] > item["qtty_available"]
        ) {
          buttonAdd.disabled = true;
          buttonSous.disabled = false;
        } else {
          //console.log("out condition");
        }
      }
      else if(str === "+" && checkBox.checked){
        if (
          number + 1 <= item["qtty_available"] &&
          number + 1 <= item["qtty_in"] &&
          (number + 1 <= item["qtty_max"] ||
            item["qtty_max"] == 0)
        ) {
          //console.log(number);
          this.addItemToLocalStorage(item,0);
          //this.$refs[type+i].value = number;
          if (buttonSous.disabled === true) {
            buttonSous.disabled = false;
          }
        } else if (
          number === item["qtty_available"] ||
          number === item["qtty_max"] ||
          number +1 > item["qtty_max"] ||
          number + 1 > item["qtty_available"]
        ) {
          buttonAdd.disabled = true;
          buttonSous.disabled = false;
        } else {
          //console.log("out condition");
        }
      }
      //------SOUSTRACTION
      //  !checkbox.checked === green
      else if (str === "-" && !checkBox.checked) {
        //console.log(number);
        if (number - 1 >= 0) {
          //console.log(number);
          this.takeOffItemFromLocalStorage(item,1);
          //this.$refs[type+i].value = number;
          if (buttonAdd.disabled === true) {
            buttonAdd.disabled = false;
          }
        } else if (number - 1 < 0) {
          buttonSous.disabled = true;
          buttonAdd.disabled = false;
        }
        //this.$refs[type+i].value = number;
      }
      else if (str === "-" && checkBox.checked) {
        //console.log(number);
        if (number - 1 >= 0) {
          //console.log(number);
          this.takeOffItemFromLocalStorage(item,0);
          //this.$refs[type+i].value = number;
          if (buttonAdd.disabled === true) {
            buttonAdd.disabled = false;
          }
        } else if (number - 1 < 0) {
          buttonSous.disabled = true;
          buttonAdd.disabled = false;
        }
        //this.$refs[type+i].value = number;
      }
    },
    // HANDLER FOR + & - return mode
    modifyQttyForReturn(str, type_, i, item) {
      //console.log(str);
      //console.log(this.$refs['consumable'+i]);
      let number;
      const UNIQUE_KEY = item["ref"] + "+" + item["item_storage"];
      if (this.returnInProgress[UNIQUE_KEY]) {
        number = this.returnInProgress[UNIQUE_KEY].quantity;
      } else {
        number = 0;
      }
      //console.log(typeof number);
      var buttonSous = this.$refs["-" + type_ + i];
      var buttonAdd = this.$refs["+" + type_ + i];
      number = parseInt(number);

      //-----AJOUT
      if (str === "+") {
        const UNIQUE_KEY = item["ref"] + "+" + item["item_storage"];
        if (this.returnInProgress[UNIQUE_KEY]) {
          this.returnInProgress[UNIQUE_KEY].quantity += item["out_packaging"];
        } else {
          this.returnInProgress[UNIQUE_KEY] = {
            quantity: item["out_packaging"],
          };
        }
        localStorage.setItem(
          "returnInProgress",
          JSON.stringify(this.returnInProgress)
        );

        if (buttonSous.disabled === true) {
          buttonSous.disabled = false;
        }
      }
      //------SOUSTRACTION
      else if (str === "-") {
        //console.log(number);
        if (number - 1 >= 0) {
          //console.log(number);
          const UNIQUE_KEY = item["ref"] + "+" + item["item_storage"];

          if (this.returnInProgress[UNIQUE_KEY]) {
            if (
              this.returnInProgress[UNIQUE_KEY].quantity >=
                item["out_packaging"] &&
              this.returnInProgress[UNIQUE_KEY].quantity != 0
            ) {
              this.returnInProgress[UNIQUE_KEY].quantity -=
                item["out_packaging"];
              if (this.returnInProgress[UNIQUE_KEY].quantity === 0) {
                delete this.returnInProgress[UNIQUE_KEY];
              }
            } else {
              return;
            }
          } else {
            return;
          }
          localStorage.setItem(
            "returnInProgress",
            JSON.stringify(this.returnInProgress)
          );
          //this.$refs[type+i].value = number;
          if (buttonAdd.disabled === true) {
            buttonAdd.disabled = false;
          }
        } else if (number - 1 < 0) {
          buttonSous.disabled = true;
          buttonAdd.disabled = false;
        }
      }
    },
    // ajout via function modifyQtty 
    addItemToLocalStorage(item,bool) {
      // bool 1 palier ok, bool 0 palier désactivé
      if(bool){
      //si c'est un outil qui dont la période n'est pas respecté le mémoriser
      if (item["type_"] === "tool") {
        if (this.alertPeriodLimit[item["ref"]] != undefined) {
          //console.log(item["ref"]);
          this.alertBasketToolLimit(
            this.alertPeriodLimit[item["ref"]].period,
            item["ref"]
          );
        }
      }
      // logique d'ajout
      const UNIQUE_KEY = item["ref"] + "+" + item["item_storage"];
      if (this.orderInProgress[UNIQUE_KEY]) {
        this.orderInProgress[UNIQUE_KEY].quantity += item["out_packaging"];
      } else {
        this.orderInProgress[UNIQUE_KEY] = {
          quantity: item["out_packaging"],
        };
      }
      localStorage.setItem(
        "orderInProgress",
        JSON.stringify(this.orderInProgress)
      );
      }
      else {

        //si c'est un outil qui dont la période n'est pas respecté le mémoriser
      if (item["type_"] === "tool") {
        if (this.alertPeriodLimit[item["ref"]] != undefined) {
          //console.log(item["ref"]);
          this.alertBasketToolLimit(
            this.alertPeriodLimit[item["ref"]].period,
            item["ref"]
          );
        }
      }
      // logique d'ajout
      const UNIQUE_KEY = item["ref"] + "+" + item["item_storage"];
      if (this.orderInProgress[UNIQUE_KEY]) {
        this.orderInProgress[UNIQUE_KEY].quantity += 1;
      } else {
        this.orderInProgress[UNIQUE_KEY] = {
          quantity: 1,
        };
      }
      localStorage.setItem(
        "orderInProgress",
        JSON.stringify(this.orderInProgress)
      );

      }
    },
    // retrait via function modifyQtty
    takeOffItemFromLocalStorage(item,bool) {
      if(bool){
      const UNIQUE_KEY = item["ref"] + "+" + item["item_storage"];
      if (this.orderInProgress[UNIQUE_KEY]) {
        if (
          this.orderInProgress[UNIQUE_KEY].quantity >= item["out_packaging"] &&
          this.orderInProgress[UNIQUE_KEY].quantity != 0
        ) {
          this.orderInProgress[UNIQUE_KEY].quantity -= item["out_packaging"];
          if (this.orderInProgress[UNIQUE_KEY].quantity === 0) {
            delete this.orderInProgress[UNIQUE_KEY];

            //si c'est un outil qui dont la période n'est pas respecté enlever l'alerte car 0
            if (item["type_"] === "tool") {
              if (this.alertPeriodLimit[item["ref"]] !== undefined) {
                delete this.memoriseLimitTools[item["ref"]];
                localStorage.setItem(
                  "memoriseLimitTools",
                  JSON.stringify(this.memoriseLimitTools)
                );
              }
            }
          }
        } else {
          return;
        }
      } else {
        return;
      }
      localStorage.setItem(
        "orderInProgress",
        JSON.stringify(this.orderInProgress)
      );
      }
      else  {
        const UNIQUE_KEY = item["ref"] + "+" + item["item_storage"];
      if (this.orderInProgress[UNIQUE_KEY]) {
        if (
          this.orderInProgress[UNIQUE_KEY].quantity >= 1 &&
          this.orderInProgress[UNIQUE_KEY].quantity != 0
        ) {
          this.orderInProgress[UNIQUE_KEY].quantity -= 1;
          if (this.orderInProgress[UNIQUE_KEY].quantity === 0) {
            delete this.orderInProgress[UNIQUE_KEY];

            //si c'est un outil qui dont la période n'est pas respecté enlever l'alerte car 0
            if (item["type_"] === "tool") {
              if (this.alertPeriodLimit[item["ref"]] !== undefined) {
                delete this.memoriseLimitTools[item["ref"]];
                localStorage.setItem(
                  "memoriseLimitTools",
                  JSON.stringify(this.memoriseLimitTools)
                );
              }
            }
          }
        } else {
          return;
        }
      } else {
        return;
      }
      localStorage.setItem(
        "orderInProgress",
        JSON.stringify(this.orderInProgress)
      );
      }
    },
    // ajout/modif directement via input
    addMultiplesItems(item, value) {
      const UNIQUE_KEY = item["ref"] + "+" + item["item_storage"];
      if (this.orderInProgress[UNIQUE_KEY]) {
        this.orderInProgress[UNIQUE_KEY].quantity = parseInt(value);
        if (this.orderInProgress[UNIQUE_KEY].quantity === 0) {
          delete this.orderInProgress[UNIQUE_KEY];
          //si c'est un outil qui dont la période n'est pas respecté enlever l'alerte car 0
          if (item["type_"] === "tool") {
            if (this.alertPeriodLimit[item["ref"]] !== undefined) {
              delete this.memoriseLimitTools[item["ref"]];
              localStorage.setItem(
                "memoriseLimitTools",
                JSON.stringify(this.memoriseLimitTools)
              );
            }
          }
        }
      } else {
        this.orderInProgress[UNIQUE_KEY] = {
          quantity: parseInt(value),
        };
        if (this.orderInProgress[UNIQUE_KEY].quantity === 0) {
          delete this.orderInProgress[UNIQUE_KEY];
          //si c'est un outil qui dont la période n'est pas respecté enlever l'alerte car 0
          if (item["type_"] === "tool") {
            if (this.alertPeriodLimit[item["ref"]] !== undefined) {
              delete this.memoriseLimitTools[item["ref"]];
              localStorage.setItem(
                "memoriseLimitTools",
                JSON.stringify(this.memoriseLimitTools)
              );
            }
          }
        }
      }
      localStorage.setItem(
        "orderInProgress",
        JSON.stringify(this.orderInProgress)
      );
    },
    //définir dynamiquement le max sur un input
    maxArrow(item) {
      if (item["qtty_max"] != 0) {
        var lowNumber = Math.min(
          item["qtty_in"],
          item["qtty_max"],
          item["qtty_available"]
        );
      } else {
        var lowNumber = Math.min(item["qtty_in"], item["qtty_available"]);
      }
      return lowNumber;
    },
    //Switcher mode retour ou commande
    returnModeSwitcher() {
      if (this.returnMode === false) {
        this.returnMode = true;
        localStorage.setItem("returnMode", JSON.stringify(true));
      } else if (this.returnMode === true) {
        this.returnMode = false;
        localStorage.setItem("returnMode", JSON.stringify(false));
        //localStorage.removeItem("returnMode");
      }
    },
    // retirer un touret un complet STORE REQUEST
    borrowReel(id, item,location) {
      var str = "borrow";
      this.$store.dispatch("borrowCables", {
        username: this.connectedUser.username,
        item_id: id,
        user_id: this.connectedUser.id_user,
        action: str,
        item: item,
        location : location
      });
      //console.log(id)
    },
    // reserver un touret complet STORE REQUEST
    bookingReel(id, item,location) {
      var str = "booking";
      this.$store.dispatch("borrowCables", {
        username: this.connectedUser.username,
        item_id: id,
        user_id: this.connectedUser.id_user,
        action: str,
        item: item,
        location : location
      });
      //console.log(id)
    },
    // STORE REQUEST for find user who take the borrow
    searchBorrow(id) {
      const ids = id + "_" + this.connectedUser.id_user;
      this.$store.dispatch("searchBorrow", ids);
    },
    // STORE REQUEST va renvoyer un objet avec les refs et les dates pour un user donné
    getToolsLimitUser() {
      const ids =
        this.connectedUser.username + "_" + this.connectedUser.id_user;
      this.$store.dispatch("getToolsLimitUser", ids);
    },
    // détérminer si l'utilisateur peut retirer en comparant la date, l'objet et toolLimitUser
    checkPeriodLimit(number, ref) {
      if (this.toolsLimitUser[ref] === undefined) {
        return true;
      } else {
        const date = new Date(this.toolsLimitUser[ref].date);
        //console.log(date.getTime());
        const tstamp = date.getTime();
        const today = Date.now();
        //console.log(today);
        const periodFormat = parseInt(number) * 86400000;
        if (tstamp + periodFormat > today) {
          this.alertPeriodLimit[ref] = {
            period: number,
          };
          return false;
        } else {
          return true;
        }
      }
    },
    // déterminer le nombre de jour restant avant de pouvoir retirer
    calculatePeriodLimit(number, ref) {
      const date = new Date(this.toolsLimitUser[ref].date);
      //console.log(date.getTime());
      const tstamp = date.getTime();
      const today = Date.now();
      //console.log(today);
      const periodFormat = parseInt(number) * 86400000;
      const soustr = periodFormat - (today - tstamp);
      const daysLeft = Math.ceil(soustr / 86400000);
      return daysLeft;
    },
    // Mémoriser si l'utilisateur a pris un objet hors délai
    alertBasketToolLimit(number, ref) {
      const date = new Date(this.toolsLimitUser[ref].date);
      //console.log(date.getTime());
      const tstamp = date.getTime();
      const today = Date.now();
      //console.log(today);
      const periodFormat = parseInt(number) * 86400000;
      const soustr = periodFormat - (today - tstamp);
      const daysLeft = Math.ceil(soustr / 86400000);
      this.memoriseLimitTools[ref] = {
        dLeft: daysLeft,
      };
      localStorage.setItem(
        "memoriseLimitTools",
        JSON.stringify(this.memoriseLimitTools)
      );
    },
    // logique de bouton pour afficher le délai restant
    notKeyExist(ref) {
      if (this.showDaysLeft[ref] === undefined /*|| !(ref in this.showDaysLeft) fonctionne p-e mieux*/) {
        return true;
      } else {
        return false;
      }
    },
    // logique de bouton pour afficher le délai restant
    createKey(ref) {
      this.showDaysLeft[ref] = {
        bool: true,
      };
      //console.log(this.showDaysLeft[ref]);
    },
    zeroHide(event){
      //console.log(event.target);
      //console.log(event.target.checked);
      if (event.target.checked){
        this.hideZero = false;
      }
      else {
        this.hideZero = true;
      }
    },
    cleanStr(username){
      if(username != null && username.includes('from_console')){
        return username.split('from_console')[0];}
        else if(username != null && username.includes(' creatOnRet')){
        return username.split(' creatOnRet')[0];}
        else if(username != null && username.includes('expiredBooking')){
        return username.split('expiredBooking')[0]+'Expirée';}
        else if(username != null && username.includes('autoCreatedOnReception')){
        return username.split('autoCreatedOnReception')[0]+'Reception';}
        else if(username != null && username.includes('from_user')){
        return username.split('from_user')[0];}
        return username;
    }
  },
};
</script>

<style lang="scss" scoped>
.seestock-container {
  width: 80%;
  display: flex;
  flex-direction: column;
}
.seestock-sub-container{
margin  : auto;
}
.seestock-sub-container > div {
display : flex;
flex-direction : row;
flex-wrap : wrap;
width : 1100px;
}
select{
  height: 50px;
  width: 200px;
  font-size: 20px;
}
.item {
  background-color: #0d444b;
  color: white;
  display: grid;
  //grid-template-columns: 1fr 1.2fr 1fr;
  grid-template-rows: 1fr 3fr 2fr 2fr;
  height: 400px;
  width: 330px;
  margin: auto;
  margin-bottom: 30px;
  align-items: center;
  padding : 10px;
  border: #f38414 2px solid;
  -webkit-box-shadow: 1px 2px 5px 1px #000000; 
  box-shadow: 2px 2px 7px 1px #000000;
}
.item span {
    font-size : 22px;
  }
.item .status-cable {
  font-size : 19px;
}
.item .ref-cable {
  border: 4px solid #f38414;
  width : 110px;
  margin : auto;
}
.tool-item {
  height: 450px;
  grid-template-rows: 1fr 3fr 2fr 1.5fr 2fr;
}
.tool-period {
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 3;
  grid-row-end: 3;
}
img {
  border: black 2px dashed;
  max-width: 200px;
  max-height: 100px;
  justify-self: center;
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 2;
  grid-row-end: 2;
}
.centerOfCard {
  width: 300px;
  height: 60px;
  max-width: 400px;
  max-height: 230px;
  justify-self: center;
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 2;
  grid-row-end: 2;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.centerOfCard button{
font-size :20px; 
background-color :#f3983c;
}
.centerOfCard button:disabled{
background-color :grey;
}
h3 {
  margin: 0;
  font-size: 25px;
  margin-top: 15px;
  margin-bottom: 15px;
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 1;
}
  h3 > span {
    font-size: 15px;
  }
.h3-cable {
  word-break: break-all;
  font-size: 18px;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 1;
}
.type-button-container {
  display: flex;
  width: 500px;
  margin: auto;
  justify-content: space-between;
  margin-bottom: 20px;
}
.type-button {
  background-color: rgb(13, 68, 75);
  color: #f38414;
  width: 150px;
  overflow: hidden;
}
.subtype-button {
  background-color: white;
  color: #0d444b;
  width: auto;
  padding: 0 20px;
  margin: 2px 10px;
  height: auto;
  font-size: 18px;
}
.subtype-button:disabled {
  opacity : 0.1;
}
.subtype-costumer-button {
  //background-color: #0d444b;
  //color: white;
  width: auto;
  padding: 0 20px;
  margin: 2px 10px;
  height: auto;
  font-size: 18px;
}
.mini-grid {
    display: flex;
    justify-content: space-evenly;
  }
.orderOption{
  background-color: white;
}
.see-order-button {
  background-color:#f38414;
  width: 160px;
  height: 50px;
  border: 5px black solid;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
  opacity: 0.6;
	transition: all .2s ease-in-out;
}
.see-order-button:active {
	transform: scale(1.1);
	transition: .1s;
}

.see-order-button:hover {
  opacity: 1;
}

.refresh-button {
  width: 80px;
  height: 50px;
  font-size: 20px;
  font-weight: bold;
}
.refresh-button:active {
	transform: scale(0.8);
	transition: .1s;
}

.refresh-button:hover {
  opacity : 0.7;
}
// refresh button
#show {
  background-color: #0d444b;
  color :#f38414;
  
}
.qtty {
  width: 50px;
  height: 50px;
  margin: 15px;
  font-size: 30px;
  background-color: #f38414;
}
//enelver les fleches sur input
input[type=number] {
    -moz-appearance: textfield;
}
.number {
  padding: 0;
  width: 80px;
  font-size: 30px;
}
#goTop {
  position: fixed;
  background-color: black;
  right: 100px;
  bottom: 50px;
  width: 50px;
  height: 50px;
}
.fa-arrow-up {
  font-size: 30px;
  color: white;
}
.button {
  margin: 5px;
}
.search-div{
  display : flex;
}
.search-input {
  height : 30px;
  margin :0;
}
.search-icon {
  height : 36px;
  font-size : 20px;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
}
//--------BOUTON ON OFF
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  margin-left: 20px;
  width: 40px;
  height: 20px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(92, 243, 61);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 3px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #ccc;
}

input:focus + .slider {
  box-shadow: 0 0 1px #ccc;
}

input:checked + .slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
/*
.testbutton {
  height : 150px;
   margin : auto;
}*/
.succes-message {
  width: 300px;
  height: 100px;
  border: 2px rgb(23, 189, 23) solid;
  font-size: 25px;
  font-weight: bold;
  border-radius: 5px;
  color: rgb(23, 189, 23);
  background-color: rgb(255, 255, 255);
  position: fixed;
  line-height: 100px;
  z-index : 10;
}
.tick-icon {
  color: green;
  background-color: white;
  font-size: 15px;
  font-weight: bold;
  margin-left: 10px;
  padding: 6px 10px;
  border-radius: 25px;
}
.warning-icon {
  color: rgb(255, 0, 0);
  background-color: yellow;
  font-size: 22px;
  font-weight: bold;
  margin: auto 10px;
  padding: 4px 8px;
  border-radius: 25px;
}
.rotate {
  animation: rotation 2s infinite linear;
  animation-play-state: paused
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@media screen and (max-width: 1100px) {
  input,select {
    background-color: white;
  }
  .seestock-container {
    width: 300px;
  }
  select{
  height: 50px;
  width: 120px;
  font-size: 20px;
}
.select-return{
  height: 50px;
  width: 150px;
  font-size: 15px;
}
.seestock-sub-container > div {
display : flex;
flex-direction : column;
flex-wrap : wrap;
max-width :320px;
}
  .item {
    //grid-template-columns: 1fr 1.2fr 1fr;
    grid-template-rows: 1fr 2.5fr 2fr 2.5fr;
    height: 300px;
    width: 280px;
    margin : 0;
    margin-bottom: 30px;
    
  }
  .item span {
    font-size : 16px;
  }
  .item .status-cable {
  font-size : 17px;
}
  .item .ref-cable {
  border: 3px solid #f38414;
  width : 85px;
  font-size : 18px;
}
  .item-cable {
    grid-template-rows: 2.5fr 3fr 2fr 2fr;
    height: 350px;
  }
  .h3-cable {
  font-size: 15px;
  margin : 2px;}
  .cable-btn{
    font-size: 17px;
    margin: 7px 5px 5px 5px;
  }
  img {
    max-width: 200px;
    max-height: 80px;
  }
  .centerOfCard {
  max-width: 270px;
  max-height: 130px;
}
.centerOfCard button{
font-size :17px; 
}
  h3 {
    font-size: 23px;
    margin: 0;
  }
  h3 > span {
    font-size: 15px;
  }
  .seestock-subcontainer {
    width: 300px;
  }
  .see-order-button {
    width: 200px;
    height: 40px;
    border: 5px black solid;
  }
  .refresh-button {
    width: 40px;
    height: 40px;
    font-size: 18px;
    font-weight: bold;
  }
  .type-button-container {
    width: 300px;
    margin: auto;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }
  .type-button {
    width: 130px;
    height: 40px;
    overflow : visible;
    /* padding: 0px 20px 0px 20px; */
    margin: 3px 10px 3px 10px;
  }
  .subtype-button {
    line-height: 0px;
    height: 35px;
    padding: 0 5px;
    margin: 2px 5px;
    font-size: 14px;
  }

  .subtype-button > h4 {
    margin: 0;
  }
  .subtype-costumer-button {
    line-height: 0px;
    height: 35px;
    padding: 0 5px;
    margin: 2px 5px;
    font-size: 14px;
  }
  .mini-grid {
    display: flex;
    justify-content: space-between;
  }
  .qtty {
    width: 40px;
    height: 40px;
    margin: 5px;
  }
  #qtty- {
    justify-self: start;
  }
  #qtty2 {
    justify-self: end;
  }
  .number {
    padding: 0;
    margin: auto;
    width: 60px;
    //font-size: 28px;
  }
  #goTop {
    left: 0px;
    bottom: 10px;
    width: 28px;
    height: 50px;
    padding : 0;
  }
  .fa-arrow-up {
    font-size: 18px;
  }
}
</style>
