<template>
  <div v-if="!orderInProgress" class="flex-container">
    <!-- {{ itemListObj }} !-->
   <!--  <button @click="getInfosForAdminBasket()">click</button>
    {{adminBasketInfos}}!-->
    <!--{{theSuppliersSet}}!-->
    <!--{{supplierFilter}}!-->
    <!--{{ mirorObj}} !-->
    <!--{{itemListObjWithoutOnOrder}}!-->
    <!--{{myStockAlerts[1]}}!-->
    <!--<div v-for="x in myStockAlerts[1]" :key="x">{{x}}
      <p v-for="row in x" :key="row">
                 à {{ row.item_storage }} reste {{ row.total }}
               </p>
    </div>!-->
    

    <h2>Mes Alertes Consomables & Outils</h2>
    <div class="flex-menu"> 
    <button class="order-button" @click="order()">Commander</button>
     <button v-if="loading" :disabled="!isRefOnOrder" class="btn" >
      Recherche des références déjà en commande...
    </button>
    <div v-else-if="onOrder" ><button  :disabled="!isRefOnOrder" ref="filter-onOrder-btn" class="btn" @click="hideOnOrder('after')">
      Masquer les références déjà en commande 
    </button> <span v-if="onOrder" class="number-on-order" @mouseover="showOnOrder()"
    @mouseleave="hideListShowOnOrder()">{{nbOfOnOrder}}
      <ul ref="absolute_list" class="absolute-list">
        <li v-for="thing in listOfOnOrder" :key="thing" >{{thing}}</li>
      </ul>
    </span>
    </div>
    <button v-else ref="filter-onOrder-btn" class="btn" @click="onOrder = true">
      Montrer les références déjà en commande
    </button>
    

    <div class="option-container">
        <div class="multiplier-container">
          <div>
    <label for="multi">En dessous de stock min x </label>
    <input id="multiplicateur" name="multi" type="number" v-model="lowStockMultiplier" />
    <button class="mini-btn" @click="myAlerts()">🔎</button>
        </div>
        <div>
    <label for="multi">Multiplicateur Commande</label>
    <input id="multiplicateur" name="multi" type="number" v-model="multiplicateur" /></div></div>
    <div class="select-container"> <select :disabled="loading" v-model="supplierFilter" @click="warningBtn()">
      <option v-show="onOrder" value="-Tous les fournisseurs-">-Tous les fournisseurs-</option> 
      <option  v-show="onOrder" v-for="suppl in theSuppliersSet" :key="suppl" :value="suppl">{{suppl}}</option>
    </select> 
    <span class="supplier-number-info">{{supplierNbOfAlert}} / {{nbOfAlerts}} produits répertoriés</span>
    </div>
    </div> 
    </div>
    <!-- AFFICHER TOUTES LES ALERTES (CONS & TOOL) !-->
    <div v-if="Object.values(myStockAlerts).length != 0 && onOrder" class="alerts-container">
      <div v-for="(a, i, x) in myStockAlerts[0]" :key="i" :index="x">
        <div class="alert-by-location-card">
          <h3 class="location-title">{{ i }}</h3>
          <div v-if="Object.values(itemListObj).length != 0 && itemListObj[i] != undefined && itemListObj[i].array != undefined"
          class="alerts-subcontainer">
            <div
              class="alert-by-item-card"
              v-for="(item, index) in itemListObj[i].array"
              :key="item"
              :index="index"
              :style="[item.freeze ? { 'opacity': '0.2' } : {}]"
            >
            <span>
                <input type="checkbox" id="ordered" name="ordered" @change="modifyOrder($event,item,i)"
                :checked="!item.freeze">
                <label class="ordered-label" for="ordered"><i class="fas fa-shopping-basket"></i></label></span>

              <div class="card-flex-container">
                <img :src="item.image || defaultImg" alt="" />
                <h4>{{ item.item }}</h4>
              </div>

              <div class="location-list">

                <span class="qtty-remaining"> Reste {{ item.total }} </span>
                <span class="order-qtty"
                  > Commande :
                  <input
                    disabled
                    class="number"
                    type="number"
                    min="0"
                    v-model="item.orderQtty"
                />
                
                </span>
                <!--{{x+index}}!-->
                <div v-for="(loc, x) in locationList" :key="loc" :index="x">
                  {{ loc }}
                  <input
                    class="number"
                    min="0"
                    type="number"
                    v-model="item.input[x]"
                  />
                </div>
              </div>

          <p class="defSupp ref">Fournisseur par défaut : {{ item.def_supplier }}</p>
          <select
            name="supplier"
            v-model="item.choosed_supplier"
            @change="setSupplier($event,item)"
          >
            <option v-for="supplier in item.suppliers" :key="supplier">
              {{ supplier[0] }} / {{ supplier[1] }} €
            </option>
          </select>

              <p>
                Min. {{ item.qtty_min }} / Conditionnement {{ item.packaging }}
                <span
                  v-if="item.orderQtty % item.packaging === 0"
                  class="tick-icon"
                  >&#10004;</span
                >
                <span v-else class="warning-icon" ref="warning">&#9888;</span>
              </p>
              <p class="ref">Ref : {{ item.ref }}</p>
              <p
                class="see-other-location"
                v-for="stock in twinsRef(item, 'consumable')"
                :key="stock"
              >        
                 à {{ stock.item_storage }} reste {{ stock.total }}
               
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- MASQUER LES ELEMENTS ACTUELLEMENT EN COMMANDE !-->
    <div v-else-if="Object.values(myStockAlerts).length != 0 && 
    Object.values(itemListObjWithoutOnOrder).length != 0 && !onOrder"
    class="alerts-container">
      <div v-for="(a, i, x) in myStockAlerts[0]" :key="i" :index="x">
        <div class="alert-by-location-card">
          <h3 class="location-title">{{ i }}</h3>
          <div v-if="itemListObjWithoutOnOrder[i] != undefined && itemListObjWithoutOnOrder[i].array != undefined"
          class="alerts-subcontainer">
          <div
            class="alert-by-item-card"
            v-for="(item, index) in itemListObjWithoutOnOrder[i].array"
            :key="item"
            :index="index"
            :style="[item.freeze ? { 'opacity': '0.3' } : {}]"
          >
          <span>
            <input type="checkbox" id="ordered" name="ordered" @change="modifyOrder($event,item,i)"
                :checked="!item.freeze">
            <label class="ordered-label" for="ordered"><i class="fas fa-shopping-basket"></i></label>
                </span>
          

            <div class="card-flex-container">
              <img :src="item.image || defaultImg" alt="" />
              <h4>{{ item.item }}</h4>
            </div>

            <div class="location-list">
              <span class="qtty-remaining"> Reste {{ item.total }} </span>
              <span class="order-qtty"
                >- Commande :
                <input
                  disabled
                  class="number"
                  type="number"
                  min="0"
                  v-model="item.orderQtty"
              />
              
              </span>

              <!--{{x+index}}!-->
              <div v-for="(loc, x) in locationList" :key="loc" :index="x" >
                {{ loc }}
                <input
                  class="number"
                  min="0"
                  type="number"
                  v-model="item.input[x]"
                />
              </div>
            </div>
           
            <p class="defSupp ref">Fournisseur par défaut : {{ item.def_supplier }}</p>
          <select
            name="supplier"
            v-model="item.choosed_supplier"
            @change="setSupplier($event,item)"
          >
            <option v-for="supplier in item.suppliers" :key="supplier">
              {{ supplier[0] }} / {{ supplier[1] }} €
            </option>
          </select>

              <p>
                Min. {{ item.qtty_min }} / Conditionnement {{ item.packaging }}
              <span
                v-if="item.orderQtty % item.packaging === 0"
                class="tick-icon"
                >&#10004;</span
              >
              <span v-else class="warning-icon" ref="warning">&#9888;</span>
            </p>
            <p class="ref">Ref : {{ item.ref }}</p>
            <p
              class="see-other-location"
              v-for="stock in twinsRef(item, 'consumable')"
              :key="stock"
            >
               à {{ stock.item_storage }} reste {{ stock.total }}
            </p>
          </div>
          </div>
        </div>
      </div>
    </div>

    <span v-else>Aucune alerte, tout est en ordre</span>
    
    <!-- CABLES ALERTS!-->
    <h2>Mes Alertes Cables</h2>
    <div
      v-if="
        (Object.values(myEmptyCables).length != 0 ||
          Object.values(myCablesAlerts).length != 0) &&
          showCablesAlerts
      "
      class="alerts-container"
    >
      <div v-if="Object.values(myCablesAlerts).length != 0">
        <div v-for="(a, i, x) in myCablesAlerts[0]" :key="i" :index="x">
          <div class="alert-by-location-card">
            <h3 class="location-title">{{ i }}</h3>
            <div v-if="myCablesAlerts[0][i] != undefined && myCablesAlerts[0][i].array != undefined"
            class="alerts-subcontainer">
            <div
            id="little"
              class="alert-by-item-card"
              v-for="item in myCablesAlerts[0][i].array"
              :key="item"
            >
              <div class="card-flex-container">
                <img :src="item.image || defaultCableImg" alt="" />
                <h4>{{ item.item }}</h4>
              </div>
              <p>Reste {{ item.total }} m</p>
              <p class="ref">Ref : {{ item.ref }}</p>
            </div>
            </div>
          </div>
        </div>
      </div>
      <!--<div v-if="Object.values(myEmptyCables).length != 0">
        <div
          class="emtpy-cables alert-by-location-card"
          v-for="item in myEmptyCables"
          :key="item"
        >
          <h4>{{ item.item }}</h4>
          <p>id : {{ item.id_reel }}</p>
          <p>Quantité à 0, touret supprimé</p>
          <p>Vidé par {{ item.username }}</p>
          <p class="ref">Date : {{ formatDate(item.date) }}</p>
        </div>
      </div>!-->
    </div>
    <span v-else>Aucune alerte, tout est en ordre</span>
    
    <!-- CLIENTS ALERTS !-->
    <h2>Mes Alertes Clients</h2>

    <div v-if="Object.values(myClientsAlerts).length != 0 && showClientsAlerts"
    class="alerts-container">
      <div v-for="(a, i, x) in myClientsAlerts[0]" :key="i" :index="x">
        <div class="alert-by-location-card">
          <h3 class="location-title">{{ i }}</h3>
          <div v-if="myClientsAlerts[0][i] != undefined && myClientsAlerts[0][i].array != undefined"
          class="alerts-subcontainer">
          <div
          id="little"
            class="alert-by-item-card"
            v-for="(item, index) in myClientsAlerts[0][i].array"
            :key="item"
            :index="index"
          >
            <div class="card-flex-container">
              <img :src="item.image || defaultImg" alt="" />
              <h4>{{ item.item }}</h4>
            </div>
            <p>Reste {{ item.total }}</p>
            <p>Min. {{ item.qtty_min }}</p>
            <p class="ref">Ref : {{ item.ref }}</p>
            <p
              class="see-other-location"
              v-for="stock in twinsRef(item, 'client')"
              :key="stock" 
            >
            
              à {{ stock.item_storage }} reste {{ stock.total }}
            </p>
          </div>
          </div>
        </div>
      </div>
    </div>
    <span v-else>Aucune alerte, tout est en ordre</span>
    <button id="goTop" @click="toTheTop()">
      <i class="fas fa-arrow-up"></i>
    </button>
  </div>

  <!-- ORDER IN PROGRESS !-->

  <div v-else class="flex-column">
    <span v-if="succes03" class="succes-message">Confirmation...</span>
    <div class="basket-summary-column" ref="basket_summary_column">
     <!-- {{itemListObjOrderFormat}}
      <br> <br>
      {{adminOrder}}!-->
      <h2>Finaliser la commande</h2>
      <button class="order-button" :disabled="launchOrder"
       @click="orderInProgress = false">
        Retour
      </button>
      <button v-if="!launchOrder" class="order-button" @click="orderConfirm()">Confirmer</button>
      <button class="hourglass-icon" v-else>⏳</button>
      <div v-if="Object.values(myStockAlerts).length != 0" class="basket-summary-subcolumn">
        <div
          class="order-card"
          v-for="(item, index) in itemListObjOrderFormat"
          :key="item"
          :index="index"
        >
          <div class="card-flex-container">
            <img :src="item.image || defaultImg" alt="" />
            <h4>{{ item.item }}</h4>
            <button class="trash" @click="deleteItem(index)">&#10006;</button>
          </div>

          <span class="order-qtty">- Commande : {{ item.orderQtty }} </span>
          <p class="ref">Fournisseur : {{ item.choosed_supplier }}</p>

          <p class="ref">Ref : {{ item.ref }}</p>
          <div>
            <span
              class="qtty-by-location"
              v-for="(loc, x) in locationList"
              :key="loc"
              :index="x"
            >
              {{ loc }}
              {{ item.input[x] }}
            </span>
          </div>
        </div>
      </div>
      <span v-else>Aucune alerte, tout est en ordre</span>
    </div>
    <button @click="showHideEl('admin_basket_container')" id="showHideArrow">
      <span v-if="showBasketColumn == true"><i class="fas fa-shopping-basket"></i>»</span>
      <span v-else><i class="fas fa-shopping-basket"></i>«</span> </button>
    <!-- PANIER ADMINISTRATEUR !-->
    <div ref="admin_basket_container" class="admin-basket-container" >
      <button class="basket-btn" @click="showAdminOrder()">Voir mon panier</button>
      <button class="basket-btn" @click="trashAdminOrder()">Vider</button>
      <div
        class="basket-card"
        v-for="(item, x) in adminOrder"
        :key="item"
        :index="x"
      >
        <div class="card-flex-container">
          <img :src="item.image || defaultImg" alt="" />
          <h4>{{ item.item }}</h4>
          <button class="trash" @click="deleteAdminBasketItem(x)">
            &#10006;
          </button>
        </div>
        <p class="order-qtty">
          - en stock : {{ item.qtty_in }} / Total : {{ item.sum_in }}
        </p>
        <p class="order-qtty">
          - dispo : {{ item.qtty_available }} / Total : {{ item.sum_available }}
        </p>
        <p class="ref">Ref : {{ item.ref }}</p>
        <p v-if="item.order">Commande : {{ item.order }}</p>

         <p class="defSupp ref">Fournisseur par défaut : {{ item.def_supplier }}</p>
          <select
            name="supplier"
            v-model="item.choosed_supplier"
            @change="setSupplier($event,item)"
          >
            <option v-for="supplier in item.suppliers" :key="supplier">
              {{ supplier[0] }} / {{ supplier[1] }} €
            </option>
          </select>
            <span>total : {{item.orderQtty}}</span>
              <p>
                Min. {{ item.qtty_min }} / Condition.{{ item.packaging }}
                <span
                  v-if="item.orderQtty % item.packaging === 0"
                  class="tick-icon"
                  >&#10004;</span
                >
                <span v-else class="warning-icon" ref="warning">&#9888;</span>
              </p>

        <div v-for="(loc, index) in locationList" :key="loc" :index="index">
          Pour {{ loc }} :
          <input
            :ref="item['ref'] + index"
            class="number"
            name="qttyOrder"
            type="number"
            min="0"
            value="0"
          />
          <button @click="addQtty(item['ref'], index, loc, x)">+</button>
          <p v-if="item[loc]">Commande {{ loc }} : {{ item[loc] }}</p>

          

          <div
            v-if="index === locationList.length - 1"
            class="button-container"
          >
            <button class="order-icon" @click="addItem(x)">&cularrp;</button>
            <button class="order-icon" @click="resetQtty(x)">&orarr;</button>
          </div>
        </div>
      </div>
    </div>
    
     <button id="goTop" @click="toTheTop()">
      <i class="fas fa-arrow-up"></i>
    </button>
  </div>
</template>
<script>

import { image, signature } from "../../../assets/logo.js";
import pdfMake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { mapState } from "vuex";

export default {
  name: "AlertAndOrder",
  data() {
    return {
      connectedUser: "",
      locationList: [],
      itemListObj: {},
      itemListObjWithoutOnOrder: {},
      itemListObjOrderFormat: [],
      itemList: [],
      locationInputs: [],
      adminOrder: [],
      multiplicateur: 10,
      defaultImg: require("../../../assets/photo.png"),
      defaultCableImg: require("../../../assets/touret-img.jpg"),
      showCablesAlerts: true,
      showClientsAlerts: true,
      orderInProgress: false,
      twinsList: [],
      onOrder: true,
      isRefOnOrder : false,
      loading : true,
      listRefsBasket : [],
      theSuppliersSet : new Set(),
      supplierFilter : '-Tous les fournisseurs-',
      mirorObj : [],
      nbOfAlerts : 0,
      supplierNbOfAlert : 0,
      nbOfOnOrder : '',
      listOfOnOrder : [],
      launchOrder : false,
      supplierForNameFile : 'BC',
      bcNumber : "",
      showBasketColumn : false,
      lowStockMultiplier : 1,
    };
  },
  mounted() {
    this.$store.commit("isConnected");
    // récuperer l'utilisateur actuel
    this.connectedUser = JSON.parse(localStorage.getItem("connectedUser"));
    //récupérer la liste des dépôts
    this.$store.dispatch("getAllLocations", this.connectedUser.id_user);

    this.myAlerts();
    this.getOrdersData();
    this.getInfosForAdminBasket();
  },
  computed: {
    //STORE
    ...mapState({
      myStockAlerts: "myStockAlerts",
      myStock: "myStockAlerts",
      myCablesAlerts: "myCablesAlerts",
      myEmptyCables: "myEmptyCables",
      myClientsAlerts: "myClientsAlerts",
      succes03: "succes03",
      succes05: "succes05",
      requestResponse: "requestResponse",
      allAddresses: "allAddresses",
      myReceptions: "myReceptions",
      myRemainders: "myRemainders",
      adminBasketInfos : "adminBasketInfos",
      allLocations : "allLocations"
    }),
  },

  watch: {
    succes03(newCount, oldCount) {
      if (newCount === true) {
        //console.log("Commande réussie");
        this.launchOrder = false;
        setTimeout(() => {
          location.reload();
        }, 1500);
        
      }
    },
    //ici succes 5 -> receptions and remainders data ok, checkOnOrder
    succes05(newCount, oldCount) {
      if (newCount === true) {
        //alert('succes 5');
        setTimeout(() => {
          this.hideOnOrder('start');
        }, 1500);
      
      }
    },
    requestResponse(newCount, oldCount) {
      if (newCount === true) {
        //alert("Echec de la commande");
        this.launchOrder = false;
      }
    },
    allLocations(newCount, oldCount) {
      if(newCount) {
        //alert(newCount);
        this.locationList = newCount;
      }
    },
    supplierFilter:{
      handler(newValue, oldValue) {
        var obj = {};
        var supplierNbOfAlert = 0;
      if(newValue != '-Tous les fournisseurs-'){
        //alert('detection');
        if(Object.values(this.mirorObj).length != 0){
          //alert('detection2');
          this.itemListObj = this.mirorObj;
        }
              for (let i in this.locationList) {
          for (let x in this.itemListObj[this.locationList[i]].array) {
            var thisSet = new Set();
            const itemList = this.itemListObj[this.locationList[i]].array[x];
            //console.log(itemList['suppliers'][0][0]);
            for(let s in itemList['suppliers']){
              // si la liste des fournisseurs de l'obj inclus le fournisseur en filtre
            if (itemList['suppliers'][s][0] === newValue && !thisSet.has(newValue)) 
              {
                thisSet.add(newValue);

                //essayer d'ajouter le supplier correspondant       
                //pour rappel itemListObj[i].array[x] = itemList
                for (let i in itemList.suppliers) {
                //console.log(this.itemListObjOrderFormat[index].ref_suppliers);
                const suppl = itemList.suppliers[i][0];
                const price = itemList.suppliers[i][1];
                // si le fournisseur de la liste correspond a celui choisi...
                if (newValue == suppl) {
              //...alors on met a jour la ligne la fournisseur + prix dans le select
                itemList.choosed_supplier = suppl + " / " + price + " €";
                // ainsi que la ref, etc, inspiré de la function setSupplier().
                itemList.choosed_ref = itemList.ref_suppliers[i];
                itemList.packaging = itemList.packagings[i][0];
                //on quitte la boucle
                break;
                  }
                }


                if(obj[this.locationList[i]]){
                  if(obj[this.locationList[i]].array){
                    obj[this.locationList[i]]['array'].push(itemList);
                  }
                }
                else{
                  obj[this.locationList[i]] = {
                  array : []
                };
                obj[this.locationList[i]]['array'].push(itemList);
                }

            // cb d'alertes compatible avec ce fournisseur
            supplierNbOfAlert = 
            supplierNbOfAlert + 1;
                
                //obj.push(itemList);
              //console.log(this.itemListObj[this.locationList[i]].array[x]['item']);
            }
            }
          }
           
        }     
        // cb d'alertes compatible avec ce fournisseur
        this.supplierNbOfAlert = supplierNbOfAlert;

        this.mirorObj = this.itemListObj
        this.itemListObj = obj;
              }
              else{
                if(Object.values(this.mirorObj).length != 0){
          this.itemListObj = this.mirorObj;
        }
        this.supplierNbOfAlert = this.nbOfAlerts;
              }
    }
    },
    multiplicateur: {
      handler(newValue, oldValue) {
        //console.log('multi');
        // changer le multiplicateur de commande pour tous les items
        for (let i in this.locationList) {
          for (let x in this.itemListObj[this.locationList[i]].array) {
            if (
              this.locationList[i] ==
              this.itemListObj[this.locationList[i]].array[x].item_storage
            ) {
              this.itemListObj[this.locationList[i]].array[x].input[i] =
                this.itemListObj[this.locationList[i]].array[x].qtty_min *
                newValue;
            }
          }
        }
      },
    },
    // watch myStockAlerts pour le transformer en itemListObj
    //et lui ajouter les v-models deep dynamic list pour inputs
    //Ajouter dynamiquement les fournisseurs/prix et packaging aux items 
    myStock: {
      handler(newValue, oldValue) {
        if (Object.values(this.myStockAlerts).length != 0) {
          var nbOfAlerts = 0;
          //console.log(this.myStockAlerts[0]);
          for (let i in this.myStockAlerts[0]) {
            if(this.myStockAlerts[0][i]){
              if(this.myStockAlerts[0][i].array){
                nbOfAlerts = nbOfAlerts + parseInt(this.myStockAlerts[0][i].array.length);
              }
            }
            for (let x in this.myStockAlerts[0][i].array) {
              
              var newV = newValue;
              var product = this.myStockAlerts[0][i].array[x];
              //console.log(this.myStockAlerts[0][i].array[x].item);

              // placer la quantité requise dans la bonne location
              for (let h in this.locationList) {
                if (
                  this.locationList[h] ==
                  this.myStockAlerts[0][i].array[x].item_storage
                ) {
                  this.locationInputs.push(
                    product.qtty_min * this.multiplicateur
                  );
                } else {
                  this.locationInputs.push(0);
                }
              }

              let def_choosed;
              let ref_choosed;
              let packaging;

              //Boucle interne pour choosed_ref et def_choosed & packaging
              for (let s in product.suppliers) {
            if (
              product.suppliers[s][0].includes(
                product.def_supplier
              )
            ) {
              const suppl = product.suppliers[s][0];
              const price = product.suppliers[s][1];
              def_choosed = suppl + " / " + price + " €";
              //console.log(this.itemListObjOrderFormat[index].ref_suppliers);
              ref_choosed = product.ref_suppliers[s];
              packaging = product.packagings[s][0];

              //break; inutile ? ne sait plus
            } else {
              //console.log(this.itemListObj[i].array[x].suppliers);
              //console.log(this.itemListObj[i].array[x].def_supplier);
              //console.log('non inclus')
              const suppl = product.suppliers[0][0];
              const price = product.suppliers[0][1];
              def_choosed = suppl + " / " + price + " €";
              ref_choosed = product.ref_suppliers[0];
            }
            // ajouter au filtre de la page chaque supplier
              this.theSuppliersSet.add(product.suppliers[s][0]);
              
              }
              
              this.itemList.push({
                id: product.id,
                ref: product.ref,
                item: product.item,
                image: product.image,
                total: product.total,
                qtty_min: product.qtty_min,
                packagings: product.packagings,
                packaging: packaging,
                item_storage: product.item_storage,
                def_supplier: product.def_supplier,
                choosed_supplier : def_choosed,
                choosed_ref :ref_choosed,
                suppliers: product.suppliers,
                ref_suppliers: product.ref_suppliers,
                orderQtty: product.total * this.multiplicateur,
                input: this.locationInputs,
              });
              
              this.itemListObj[product.item_storage] = {
                array: this.itemList,
              
              }
              this.locationInputs = [];
            }
            this.itemList = [];
          };
          this.nbOfAlerts = nbOfAlerts;
          if(this.supplierFilter === '-Tous les fournisseurs-'){
            this.supplierNbOfAlert = this.nbOfAlerts;
          }
        }
      },
    },
    //deep true
    itemListObj: {
      // watch itemListObj pour calculer automatiquement la quantité de commande
      //en fonction des inputs dynamiquement crée par le nombre d'entrepôt
      handler(newValue, oldValue) {
        //console.log('new value 01');
        // newValue.forEach((key,item) => {
        for (const [key, value] of Object.entries(newValue)) {
          //console.log(`${key}: ${value}`);
          //console.log('newValue[key].array');
          //console.log(newValue[key].array);
          for (let i in newValue[key].array) {
            //console.log(newValue[key].array[i]);
            //console.log(newValue[key].array[i].orderQtty);
            //console.log(newValue[key].array[i].input);
            var sum = 0;
            for (let x in this.locationList) {
              //console.log(newValue[key].array[i].input[x]);
              sum = sum + newValue[key].array[i].input[x];
            }
            newValue[key].array[i].orderQtty = sum;
          }
          //console.log(value);
        }
      },
      deep: true,
    },
    //Ajouter dynamiquement les fournisseurs/prix et packaging aux items du panier
    adminBasketInfos : {
      handler(newValue, oldValue) {
        //console.log('WATCHER adminBasketInfos');
        var adminOrder = JSON.parse(localStorage.getItem("adminOrder"));
        //console.log('adminOrder');
        //console.log(Object.keys(adminOrder).length);
        //console.log(adminOrder);
        if(this.listRefsBasket.length === Object.keys(adminOrder).length){
          //console.log('adminOrder2');
        for(let x in this.listRefsBasket){
        //console.log(newValue);
        adminOrder[x].suppliers = newValue[x][0];
        adminOrder[x].ref_suppliers = newValue[x][1];
        adminOrder[x].packagings = newValue[x][2];
        adminOrder[x].orderQtty = 0;


        let def_choosed;
        let ref_choosed;
        let packaging;
        //Boucle interne pour choosed_ref et def_choosed & packaging
        for (let s in adminOrder[x].suppliers) {
            if (
              adminOrder[x].suppliers[s][0].includes(
                adminOrder[x].def_supplier
              )
            ) {
              const suppl = adminOrder[x].suppliers[s][0];
              const price = adminOrder[x].suppliers[s][1];
              def_choosed = suppl + " / " + price + " €";
              //console.log(this.itemListObjOrderFormat[index].ref_suppliers);
              ref_choosed = adminOrder[x].ref_suppliers[s];
              packaging = adminOrder[x].packagings[s][0] ;

              //break; inutile ? ne sait plus
            } else {
              //console.log(this.itemListObj[i].array[x].suppliers);
              //console.log(this.itemListObj[i].array[x].def_supplier);
              //console.log('non inclus')
              const suppl = adminOrder[x].suppliers[0][0];
              const price = adminOrder[x].suppliers[0][1];
              def_choosed = suppl + " / " + price + " €";
              ref_choosed = adminOrder[x].ref_suppliers[0];
              packaging = adminOrder[x].packagings[0][0] ;
            }}

         adminOrder[x].packaging = packaging;
         adminOrder[x].choosed_supplier = def_choosed;
         adminOrder[x].choosed_ref = ref_choosed;
}
        
        localStorage.setItem("adminOrder", JSON.stringify(adminOrder));
        }
        else {
          //console.log('ERREUR sur adminBasketInfos watcher');
        }
      }
    }
  },
  methods: {
    showHideEl(refName){
      
      if(this.showBasketColumn === true){
        this.showBasketColumn = false;
        this.$refs['basket_summary_column'].style.width = 'auto';
        this.$refs[refName].style.display = 'none';

      }
      else {
        this.showBasketColumn = true;
        this.$refs['basket_summary_column'].style.width =' 650px';
        this.$refs[refName].style.display = 'block';
      }
    },
    hideOnOrder(action) {
      var nbOfOnOrder = 0;
      var listOfOnOrder = [];
      this.itemListObjWithoutOnOrder = {};
      var orderRef = new Set();
      this.myReceptions.forEach((item) => {
        //console.log(item.ref);
        orderRef.add(item.ref);
      });
      this.myRemainders.forEach((item) => {
        //console.log(item.ref);
        orderRef.add(item.ref);
      });
      //console.log(orderRef);
      //console.log(this.myReceptions[0].ref);
      //console.log(this.myRemainders);
      //console.log(this.itemListObj);
      //console.log(Object.keys(this.myReceptions));
      //console.log(Object.values(this.myReceptions));
      for (let i in this.locationList) {
        if(this.itemListObj[this.locationList[i]]){
        const location = this.locationList[i];
        const Obj = this.itemListObj[location]["array"];
        //console.log(this.itemListObj[this.locationList[i]]);
        Obj.forEach((item) => {
          //console.log(item.ref);
          //console.log(orderRef.has(item.ref));
          if (orderRef.has(item.ref) === false) {
            if (this.itemListObjWithoutOnOrder[location]) {
              if (
                !this.itemListObjWithoutOnOrder[location].array.includes(item)
              ) {
                this.itemListObjWithoutOnOrder[location].array.push(item);
              }
            } else {
              this.itemListObjWithoutOnOrder[location] = { array: [item] };
            }
          }
          //mémoriser si il y a des refs en commande qui devraient s'afficher
          else {
            nbOfOnOrder = nbOfOnOrder +1;
            listOfOnOrder.push(item.item);
            this.isRefOnOrder = true;
          }
        });
      }}
      this.nbOfOnOrder = nbOfOnOrder;
      this.listOfOnOrder = listOfOnOrder;
      if(action === 'start'){
        this.onOrder = true;
        this.loading = false;
        return
      }
      this.onOrder = false;
    },
    // PDF GENERATOR
    download() {
      var myAddress = "Adresse de l'entreprise [...]";
      var myInfos = "Adresse & Infos de l'entreprise [...]";
      var supplierAddress = "Adresse & Infos de l'entreprise [...]";
      var myFooter = "informations bas de page de l'entreprise";
      //console.log(this.allAddresses);
      for (let i in this.allAddresses) {
        if (this.allAddresses[i].entity === "Digitech-adresse") {
          myAddress = this.allAddresses[i].address;
        }
        if (this.allAddresses[i].entity === "Digitech") {
          myInfos = this.allAddresses[i].address;
        }
        if (this.allAddresses[i].entity === "Digitech-footer") {
          myFooter = this.allAddresses[i].address;
        }
        if (
          this.allAddresses[i].entity ===
          this.itemListObjOrderFormat[0].choosed_supplier.split(" / ", 2)[0]
        ) {
          supplierAddress = this.allAddresses[i].address;
        }
      }
      if (supplierAddress === "Adresse & Infos de l'entreprise [...]") {
        alert("L'adresse de ce fournisseur n'est pas répertoriée");
        this.launchOrder = false;
        return "error";
      }
      //console.log(myAddress);
      //console.log(myInfos);

      // Générer un numéro de BC
      const dateObj = new Date(Date.now());
      const month = ("0"+(dateObj.getMonth()+ 1)).slice(-2);
      const day = String(dateObj.getDate()).padStart(2, "0");
      const year = dateObj.getFullYear().toString().substring(2, 4);
      //"0" + slice(-2) permet d'obtenir heure, minutes tj avec le zéro
      const hourMinuts = ("0"+dateObj.getHours()).slice(-2) + "" + ("0"+dateObj.getMinutes()).slice(-2);
      const seconds = ("0"+dateObj.getSeconds()).slice(-2);
      const BC_NUMBER =
        "CDE-" + year + "" + month + "" + day + "-" + hourMinuts + ""+ seconds;
      this.bcNumber = BC_NUMBER;

      var docDefinition = {
        pageBreakBefore: function(currentNode) {
          if (currentNode.headlineLevel === 19) {
            return true;
          } else if (currentNode.headlineLevel % 48 === 0) {
            return true;
          }
          return false;
        },
        pageMargins: [20, 60, 20, 60],

        footer: function(currentPage, pageCount) {
          return {
            columns: [
              {
                margin: [100, 0, 0, 0],

                layout: {
                  vLineWidth: function(i) {
                    return 0;
                  },
                },
                table: {
                  body: [
                    [
                      {
                        alignment: "center",
                        fontSize: 10,
                        text: myFooter,
                      },
                    ],
                  ],
                },
              },
              {
                width: "10%",
                text: currentPage.toString() + "/" + pageCount,
              },
            ],
          };
        },
        content: [
          //TITRES
          {
            //Bon de commande
            columns: [
              {
                // you'll most often use dataURI images on the browser side
                // if no width/height/fit is provided, the original size will be used
                image: image.img,
                width: 250,
              },
              {
                width: "40%",
                margin: [83, 0, 0, 0],

                table: {
                  widths: [162],
                  body: [
                    [
                      {
                        background: "#F7E6D9",
                        fillColor: "#F7E6D9",
                        fontSize: 16,
                        text: "BON DE COMMANDE",
                        alignment : 'center',
                      },
                    ],
                  ],
                },

                layout: {
                  paddingLeft: function(i, node) {
                    return 20;
                  },
                  paddingRight: function(i, node) {
                    return 20;
                  },
                  paddingTop: function(i, node) {
                    return 10;
                  },
                  paddingBottom: function(i, node) {
                    return 10;
                  },
                },
              },
            ],
          },
          // adresse numero date
          {
            columns: [
              {
                //Adresse
                fontSize: 14,
                text: myAddress,
              },
              {
                width: "40%",

                table: {
                  headerRows: 1,
                  widths: [65, 120],

                  body: [
                    [
                      {
                        background: "#F7E6D9",
                        fillColor: "#F7E6D9",
                        fontSize: 16,
                        text: "Numéro",
                      },
                      {
                        background: "white",
                        fillColor: "white",
                        fontSize: 12,
                        text: BC_NUMBER,
                      },
                    ],
                    [
                      {
                        background: "#F7E6D9",
                        fillColor: "#F7E6D9",
                        fontSize: 16,
                        text: "Date",
                      },
                      {
                        background: "white",
                        fillColor: "white",
                        fontSize: 16,
                        text: new Date(Date.now())
                          .toLocaleString()
                          .split(" ")[0],
                      },
                    ],
                  ],
                },
              },
            ],
          },
          //SPACE
          {
            text: "space",
            color: "white",
            lineHeight: 2,
          },
          // Fournisseur livraison
          {
            columns: [
              {
                width: "56.5%",

                table: {
                  headerRows: 1,
                  widths: [120],

                  body: [
                    [
                      {
                        background: "#F7E6D9",
                        fillColor: "#F7E6D9",
                        fontSize: 20,
                        text: "Fournisseur",
                        alignment : 'center',
                      },
                    ],
                  ],
                },
                layout: {
                  paddingLeft: function(i, node) {
                    return 50;
                  },
                  paddingRight: function(i, node) {
                    return 50;
                  },
                },
              },
              {
                table: {
                  // headers are automatically repeated if the table spans over multiple pages
                  // you can declare how many rows should be treated as headers
                  headerRows: 1,
                  widths: [120],

                  body: [
                    [
                      {
                        background: "#F7E6D9",
                        fillColor: "#F7E6D9",
                        fontSize: 20,
                        alignment: "center",
                        text: "Livraison",
                      },
                    ],
                  ],
                },
                layout: {
                  paddingLeft: function(i, node) {
                    return 50;
                  },
                  paddingRight: function(i, node) {
                    return 50;
                  },
                },
              },
            ],
          },
          // texte informatif
          {
            columns: [
              {
                fontSize: 12,
                alignment: "center",
                margin: [10, 10, 45, 10],
                text: supplierAddress,
              },
              {
                fontSize: 12,
                margin: [45, 10, 10, 10],
                alignment: "center",
                text: myInfos,
              },
            ],
          },
          //header
          {
            columns: [
              {
                margin: [0, 20, 0, 0],

                table: {
                  headerRows: 1,
                  widths: [100, 140, 80, 80, 100],

                  body: [
                    [
                      {
                        background: "#F7E6D9",
                        fillColor: "#F7E6D9",
                        fontSize: 15,
                        text: "REFERENCE",
                      },
                      {
                        background: "#F7E6D9",
                        fillColor: "#F7E6D9",
                        fontSize: 15,
                        text: "DESIGNATION",
                      },
                      {
                        background: "#F7E6D9",
                        fillColor: "#F7E6D9",
                        fontSize: 15,
                        text: "Q'TE",
                      },
                      {
                        background: "#F7E6D9",
                        fillColor: "#F7E6D9",
                        fontSize: 15,
                        text: "TARIF",
                      },
                      {
                        background: "#F7E6D9",
                        fillColor: "#F7E6D9",
                        fontSize: 15,
                        text: "TOTAL HT",
                      },
                    ],
                  ],
                },
              },
            ],
          },
        ],
        defaultStyle: {
          alignment: "justify",
        },
      };
      let n;
      let totalPrice = 0;
      // INJECTION DYNAMIQUE
      for (let i in this.itemListObjOrderFormat) {
        n = i + 1;
        const price = this.itemListObjOrderFormat[i].choosed_supplier.split(
          " / ",
          2
        )[1];
        const intPrice = parseInt(price.split("€"));
        totalPrice =
          totalPrice + this.itemListObjOrderFormat[i].orderQtty * intPrice;
        docDefinition.content.push({
          columns: [
            {
              table: {
                widths: [100, 140, 80, 80, 100],

                body: [
                  [
                    {
                      fontSize: 12,
                      headlineLevel: n,
                      text: this.itemListObjOrderFormat[i].choosed_ref,
                    },
                    {
                      fontSize: 12,
                      headlineLevel: n,
                      text: this.itemListObjOrderFormat[i].item,
                    },
                    {
                      fontSize: 12,
                      headlineLevel: n,
                      text: this.itemListObjOrderFormat[i].orderQtty,
                    },
                    {
                      fontSize: 12,
                      headlineLevel: n,
                      text: price,
                    },
                    {
                      fontSize: 12,
                      headlineLevel: n,
                      text:
                        this.itemListObjOrderFormat[i].orderQtty * intPrice +
                        " €",
                    },
                  ],
                ],
              },
            },
          ],
        });
      }
      // signature et total
      docDefinition.content.push({
        columns: [
          {
            image: signature.image,
            width: 200,
            margin: [20, 20, 0, 0],
          },
          {
            width: "37.5%",
            margin: [147, 30, 0, 0],

            table: {
              headerRows: 1,
              widths: [80, 100],

              body: [
                [
                  {
                    background: "#F7E6D9",
                    fillColor: "#F7E6D9",
                    fontSize: 13,
                    text: "TOTAL HT",
                  },
                  {
                    background: "#F7E6D9",
                    fillColor: "#F7E6D9",
                    fontSize: 13,
                    text: (totalPrice).toFixed(2) + " €",
                  },
                ],
                [
                  {
                    background: "#F7E6D9",
                    fillColor: "#F7E6D9",
                    fontSize: 13,
                    text: "TVA",
                  },
                  {
                    background: "#F7E6D9",
                    fillColor: "#F7E6D9",
                    fontSize: 13,
                    text: (totalPrice * 0.2).toFixed(2) + " €",
                  },
                ],
                [
                  {
                    background: "#F7E6D9",
                    fillColor: "#F7E6D9",
                    fontSize: 13,
                    text: "TOTAL TTC",
                  },
                  {
                    background: "#F7E6D9",
                    fillColor: "#F7E6D9",
                    fontSize: 13,
                    text: (totalPrice + totalPrice * 0.2).toFixed(2) + " €",
                  },
                ],
              ],
            },
          },
        ],
      });

      //CREATION
      const nameFile = this.supplierForNameFile+'-Digitech-'+BC_NUMBER;
      pdfMake.createPdf(docDefinition).download(nameFile+'.pdf');
      return true;
    },
    // ON INPUT SELECT CHANGE, format and save supplier change
    setSupplier(event,item) {
      //console.log(item);
      //console.log(event.target.value);
      item.choosed_supplier = event.target.value;
      for (let i in item.suppliers) {
        //console.log(this.itemListObjOrderFormat[index].ref_suppliers);
        const suppl = item.suppliers[i][0];
        const price = item.suppliers[i][1];
        if (event.target.value == suppl + " / " + price + " €") {
          item.choosed_ref = item.ref_suppliers[i];
          //console.log(item.packagings);
          //console.log(item.packagings[i]);
          item.packaging = item.packagings[i][0];
        }
      }
    },
    showAdminOrder() {
      this.adminOrder = JSON.parse(localStorage.getItem("adminOrder"));
    },
    trashAdminOrder() {
      localStorage.removeItem("adminOrder");
      this.adminOrder = [];
      //location.reload();
    },
    //STORE REQUEST récupérer les infos supplémentaire pour le panier, 
    //ensuite passera dans watcher -> adminBasketInfos
    getInfosForAdminBasket(){
       const adminBasket = JSON.parse(localStorage.getItem("adminOrder"));
      // store request getPricesAndSuppliers
      if(adminBasket){
      if(Object.values(adminBasket).length != 0){
      var arr = [];
      for(let i in adminBasket){
      arr.push(adminBasket[i].ref)
      }
      this.listRefsBasket = arr;
      //console.log('this.listRefsBasket');
      //console.log(this.listRefsBasket);
      const Obj = [arr, this.connectedUser.id_user]
      this.$store.dispatch("getPricesAndSuppliers", Obj);
      //console.log(this.adminOrder);
      }
      else {
        //console.log('LE PANIER ADMINISTRATEUR EST VIDE');
      }
      }
      else {
        //console.log('LE PANIER ADMINISTRATEUR EST VIDE');
      }
    },
    // freeze ou unfreeze un item pour modifier le contenu de la commande
    modifyOrder(event,item,i){
      //console.log(event.target.checked);

      if(event.target.checked === true){
           for(let x in this.itemListObj[i].array){
          if(this.itemListObj[i].array[x] === item){
          delete this.itemListObj[i].array[x].freeze ;
          }
          }
      }
      else{
        for(let x in this.itemListObj[i].array){
          if(this.itemListObj[i].array[x] === item){
            this.itemListObj[i].array[x].freeze = true;
          }
          }
        //this.itemListObj[i] = miror[i];
        
      }

    },

    // TRANSFORM this.itemListObj in this.itemListObjOrderFormat
    order() {
      let Obj0;
      //Récupérer les adresses pour le BC
      this.$store.dispatch("getAddresses", this.connectedUser.id_user);

      //Choisir sur quel objet on va boucler ( avec ou sans les commandes en cours)
      if (this.onOrder === false) {
        Obj0 = this.itemListObjWithoutOnOrder;
      } else {
        Obj0 = this.itemListObj;
      }
      const Obj = Obj0;
      
      //Bloquer si le conditionnement n'est pas bon
      /*if(this.$refs.warning){
            alert('un conditionnement n\'est pas conforme');
            return
        }*/

      //variables pour les boucles à venir
      var finalObj = [];
      var withDuplicates = [];
      var alreadyPushed = [];
      var bcFormat = [];

      //console.log("this.myStockAlerts");
      //console.log(this.myStockAlerts);

      /*Boucler sur l'Objet pour : 
        - Ajouter toutes les refs dans une [] WithDuplicates
        - Ajouter les valeurs choosed_ref & def_choosed pour la prise en compte
          automatique d'un prix et d'un fournisseur sur la génération de BC
        - Ajouter toutes les valeurs dans un objet miroir simplifié bcFormat */
      for (let i in this.myStockAlerts[0]) {
        if(Obj[i] != undefined && Obj[i].array != undefined){
        for (let x in Obj[i].array) {         
          if(Obj[i].array[x].freeze === true){
            continue;
          }
          withDuplicates.push(Obj[i].array[x].ref);
          bcFormat.push(Obj[i].array[x]);
        }
      }}
      //Mémoriser l'ensemble des refs
      this.twinsList = withDuplicates;

      //Créer un set sans doublons
      const noDuplicates = new Set(withDuplicates);

      /* Filtrer withDuplicates à partir de noDuplicates, 
      ne laisser que les refs jumelles en évidence*/
      noDuplicates.forEach((item) => {
        const i = withDuplicates.indexOf(item);
        withDuplicates = withDuplicates
          .slice(0, i)
          .concat(withDuplicates.slice(i + 1, withDuplicates.length));
      });
      
      //console.log(withDuplicates);

      /*Si Duplicats, on boucle sur withDuplicats => 
      on va créer un objet duplObj contenant uniquement les refs jumelles,
      les autres seront envoyés à finalObj.
      On va créer un objet newObj à partir de duplObj qui va regrouper 
      les refs jum au depot différent pour n'afficher qu'une carte en pré-commande
      Si pas Duplicats, on envoie tout directement à finalObj.
       */
      if (withDuplicates.length != 0) {
        //console.log("withDuplicates find !");
        for (let wd in withDuplicates) {
          // duplObj va contenir les doublons
          var duplObj = [];
          for (let y in bcFormat) {
            if (withDuplicates[wd] === bcFormat[y].ref) {
              //reunion des duplicats
              duplObj.push(bcFormat[y]);
            } else {
              //nouvel objet, transfert des valeurs valides
              if (
                !withDuplicates.includes(bcFormat[y].ref) &&
                !alreadyPushed.includes(bcFormat[y].ref)
              ) {
                finalObj.push(bcFormat[y]);
                alreadyPushed.push(bcFormat[y].ref);
              }
            }
          }
          //gestion avec un nouvel objet indspensable pour éviter les modifs directs proxy
          var newObj = {};
          var count = 0;
          //fusionner les différents objets en quantités
          for (let obj in duplObj) {
            if (count === 0) {
              newObj = {
                id: duplObj[obj].id,
                ref: duplObj[obj].ref,
                item: duplObj[obj].item,
                image: duplObj[obj].image,
                total: duplObj[obj].total,
                qtty_min: duplObj[obj].qtty_min,
                packaging: duplObj[obj].packaging,
                item_storage: duplObj[obj].item_storage,
                def_supplier: duplObj[obj].def_supplier,
                suppliers: duplObj[obj].suppliers,
                ref_suppliers: duplObj[obj].ref_suppliers,
                choosed_supplier: duplObj[obj].choosed_supplier,
                choosed_ref: duplObj[obj].choosed_ref,
                orderQtty: duplObj[obj].orderQtty,
                input: duplObj[obj].input,
              };
              count++;
            } else {
              // l'objet existe déjà, seul les quantités sont modifiés (regroupement refs jum)
              newObj.orderQtty = newObj.orderQtty + duplObj[obj].orderQtty;
              //empêcher la commande si deux refs jumelles n'ont pas strictement le même fournisseur
              if(newObj.choosed_supplier != duplObj[obj].choosed_supplier){
                //console.log(newObj.choosed_supplier,duplObj[obj].choosed_supplier);
                alert('Deux refs jumelles n\'ont pas le même fournisseur/prix');
                return
              };
              var newArr = [];
              //additionner les quantités par dépots entre différentes ref jumelles
              for (let loc in newObj.input) {
                newArr.push(newObj.input[loc] + duplObj[obj].input[loc]);
                //newObj.input[loc] = newObj.input[loc] + duplObj[obj].input[loc];
              }
              newObj.input = newArr;
            }
          }
          //console.log('newObj');
          //console.log(newObj);
          finalObj.push(newObj);
        }
      }
      //Boucle rapide si aucun dupliquat, uniquement avec finalObj.
      else {
        for (let y in bcFormat) {
          //nouvel objet, transfert des valeurs valides
          if (!alreadyPushed.includes(bcFormat[y].ref)) {
            finalObj.push(bcFormat[y]);
            alreadyPushed.push(bcFormat[y].ref);
          }
        }
      }

      this.itemListObjOrderFormat = finalObj;
      //console.log("this.itemListObjOrderFormat");
      //console.log(this.itemListObjOrderFormat);
      this.orderInProgress = true;
    },
    // VALIDER la commande -> subdiviser les jumelles + BC + envoi au store
    orderConfirm() {
      this.launchOrder = true;
      var finalOrder = [];
      var box = [];
      // Vérifier, enregistrer fournisseur + rediviser en objets jumeaux
      for (let z in this.itemListObjOrderFormat) {
        //vérifier la présence du fournisseur
        if (!this.itemListObjOrderFormat[z].choosed_supplier) {
          alert(
            this.itemListObjOrderFormat[z].item + " -> fournisseur introuvable"
          );
          return;
        };
        //enregistrer le fournisseur
        box.push(
          this.itemListObjOrderFormat[z].choosed_supplier.split(" / ", 1)
        );
        this.supplierForNameFile = this.itemListObjOrderFormat[z].choosed_supplier.split(" / ", 1);
        //vérifier qu'aucun autre fournisseur ne sera différent du premier de la liste
        if (!box[0].includes(this.itemListObjOrderFormat[z].choosed_supplier
              .split(" / ", 1)
              .join()
          )
        ) {
          //Si plusieurs fournisseurs STOP
          alert("Un fournisseur unique par BC");
          this.launchOrder = false;
          return;
        };

        const orderItem = this.itemListObjOrderFormat[z];
        //console.log(orderItem.orderQtty);
        /* Récupérer à nouveau les quantités par location, 
          on redivise notre carte unique en autant d'objet que de location,
          dans le but de les envoyer à la bdd, pour cela on boucle
          sur locationList, l'array avec les quantités est dans le même ordre */
        if (orderItem.orderQtty != 0) {
          for (let loc in this.locationList) {
            if (orderItem.input[loc] != 0) {
              //newObjForProtectProxyFromChanges
              var newObjFPPFC = Object.assign({}, orderItem);
              //console.log(alertObj);
              newObjFPPFC.orderQtty = orderItem.input[loc];
              newObjFPPFC.item_storage = this.locationList[loc];
              //newObjForProtectProxyFromChanges
              const newObjFPPFC2 = Object.assign({}, newObjFPPFC);
              finalOrder.push(newObjFPPFC2);
            }
          }
        };
      }
      // Générer le BC et envoyer la commande au store
      if (Object.values(finalOrder).length != 0) {
        //console.log(finalOrder);
        
        //Générer le BC
        const generateBc = this.download();
        //Enregistrer la commande
        if (generateBc === true) {
          finalOrder["id"] = this.connectedUser.id_user;
          finalOrder["username"] = this.connectedUser.username;
          finalOrder["bc_number"] = this.bcNumber;
          this.$store.dispatch("saveAdminOrder", finalOrder);
          this.bcNumber = "";
        } else {
          this.bcNumber = "";
          return;
        }
      } else {
        alert("il n'y a rien à commander !");
      }
    },
    // Appuyer sur la croix, enlever une carte
    deleteItem(index) {
      var miror = [];
      for (let i in this.itemListObjOrderFormat) {
        if (i != index) {
          miror.push(this.itemListObjOrderFormat[i]);
        }
      }
      this.itemListObjOrderFormat = miror;
    },
    // store request
    myAlerts() {
      // store request
      if (Object.keys(this.connectedUser).length != 0) {
        const ids = this.lowStockMultiplier + "_" + this.connectedUser.id_user;
        this.$store.dispatch("getMyAlerts", ids);
      } else {
        alert("Accès bloqué, l'utilisateur n'est pas reconnu !");
      }
    },
    //store request for get Receptions and Remainders
    getOrdersData() {
      if (Object.keys(this.connectedUser).length != 0) {
        this.$store.dispatch("getAdminReceptions", this.connectedUser.id_user);
      } else {
        alert("Accès bloqué, l'utilisateur n'est pas reconnu !");
      }
    },
    //Filtre qui permet de renvoyer les quantités dans chaque depot pour une ref
    twinsRef(item, type) {
      let miror;
       switch (type) {
        // RAPPEL this.myStockAlerts[1] != this.myStockAlerts[0]
        //this.myStockAlerts[1] liste des refs jumelles dans d'autres depots
        //this.myStockAlerts[0] objet de base
        case "consumable":
         miror = this.myStockAlerts[1];
          break; 
        case "client":
          miror = this.myClientsAlerts[1];
          break;

        default:
          break; }
      // set for no double
      var uniqRow = new Set();
      var returnRow = [];
      for(let array in miror){
        //console.log(miror[array]);
        for(let row_ in miror[array]){
          var row = miror[array][row_];
          //console.log(miror[array][row_].ref);
          if(row.ref === item.ref  &&
          row.item_storage != item.item_storage){
            if(!uniqRow.has(row.item_storage)){
              uniqRow.add(row.item_storage);
              returnRow.push(row);
            }
            
          }
        }
      }
  //console.log(uniqRow);
      return returnRow
     
    },
    formatDate(date) {
      var thisDate = new Date(date);
      //console.log(typeof thisDate);
      return thisDate.toLocaleDateString("fr-FR");
    },
    //Gestion du panier Admin (gérer qtté par dépot)
    addQtty(ref, index, location, x) {
      const number = parseInt(this.$refs[ref + index].value);
      //ajouter une quantité selon la location pour un item venant du panier
      if (this.adminOrder[x][location]) {
        this.adminOrder[x][location] = this.adminOrder[x][location] + number;
      } else {
        this.adminOrder[x][location] = number;
      }
      var sum = 0;
      this.adminOrder[x].input = [];
      for (let loc in this.locationList) {
        //console.log(this.adminOrder[x]);

        // Compléter la structure de l'objet pour qu'elle s'adapte au cartes
        if (this.adminOrder[x][this.locationList[loc]]) {
          // créer l'input des locations(qttés) pour avoir un objet identique à celui créer sur le DOM
          this.adminOrder[x].input.push(
            this.adminOrder[x][this.locationList[loc]]
          );
          sum = sum + this.adminOrder[x][this.locationList[loc]];
        } else {
          this.adminOrder[x].input.push(0);
        }
      }
      this.adminOrder[x].orderQtty = sum;
    },
    //Gestion du panier Admin (ajout de l'objet à la commande)
    addItem(x) {
      if(this.adminOrder[x].orderQtty != 0){
        this.itemListObjOrderFormat.push(this.adminOrder[x]);
        // x === index
        this.deleteAdminBasketItem(x);}
        else{
          alert("impossible la quantité est à 0")
        }
    },
    //Gestion du panier Admin, reset les qttés
    resetQtty(x) {
      //const number = parseInt(this.$refs[ref+index].value);
      for (let loc in this.locationList) {
        if (this.adminOrder[x][this.locationList[loc]]) {
          this.adminOrder[x][this.locationList[loc]] = 0;
        } else {
          this.adminOrder[x][this.locationList[loc]] = 0;
        }
      }
      this.adminOrder[x].orderQtty = 0;
    },
    //Sortir l'objet du panier avec une boucle exclusive
    deleteAdminBasketItem(index) {
      var miror = [];
      for (let i in this.adminOrder) {
        if (i != index) {
          miror.push(this.adminOrder[i]);
        }
      }
      this.adminOrder = miror;
    },
    // Scroll to top
    toTheTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    warningBtn(){
      if(!this.onOrder){
        this.$refs['filter-onOrder-btn'].style.backgroundColor = 'yellow';
        setTimeout(() => {
          this.$refs['filter-onOrder-btn'].style.backgroundColor = 'initial';
        }, 1000);
      }
    },
    showOnOrder(){
        this.$refs['absolute_list'].style.display = 'inline-block';
      },
    hideListShowOnOrder(){
        this.$refs['absolute_list'].style.display = 'none';
      }
  },
};
</script>
<style scoped>
.alerts-container {
  width : 100%
}
.alerts-subcontainer{
  width : 100%;
display : flex;
flex-wrap: wrap;
justify-content : space-evenly;
}
.location-title {
  width : 30%;
}
.alert-by-location-card {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: auto;
  margin-bottom: 40px;
}
.alert-by-location-card h3{
  font-size : 25px;
  background-color: #0d444b;
  color : white;
  padding : 10px;
}
.location-list {
  display : flex;
  flex-wrap : wrap;
  justify-content : space-evenly;
}
.location-list div {
width : 160px;
}
.qtty-remaining {
  font-size : 20px;
  background-color : yellow;
  padding : 5px;
  margin-bottom : 20px;
  border-radius: 25px;
}
.emtpy-cables {
  border: 1px black solid;
}
.basket-card {
  width: 360px;
  height: auto;
  padding: 20px;
  margin-bottom: 20px;
  -webkit-box-shadow: 1px 2px 5px 1px #000000; 
  box-shadow: 2px 2px 7px 1px #000000;
  /* ff 3.6+ */
    background:-moz-linear-gradient(180deg, rgba(210, 210, 205, 1) 0%, rgba(241, 243, 243, 1) 50%, rgba(210, 210, 205, 1) 100%); 

    /* safari 5.1+,chrome 10+ */
    background:-webkit-linear-gradient(180deg, rgba(210, 210, 205, 1) 0%, rgba(241, 243, 243, 1) 50%, rgba(210, 210, 205, 1) 100%);

    /* opera 11.10+ */
    background:-o-linear-gradient(180deg, rgba(210, 210, 205, 1) 0%, rgba(241, 243, 243, 1) 50%, rgba(210, 210, 205, 1) 100%);

    /* ie 6-9 */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#D2D2CD', endColorstr='#D2D2CD', GradientType=1 );

    /* ie 10+ */
    background:-ms-linear-gradient(180deg, rgba(210, 210, 205, 1) 0%, rgba(241, 243, 243, 1) 50%, rgba(210, 210, 205, 1) 100%);

    /* global 94%+ browsers support */
    background:linear-gradient(180deg, rgba(210, 210, 205, 1) 0%, rgba(241, 243, 243, 1) 50%, rgba(210, 210, 205, 1) 100%);
  border: 1px solid black;
}
.order-icon {
  width: 50px;
  height: 50px;
  font-size: 40px;
  margin-right: 120px;
  background-color: black;
  color: white;
}
.alert-by-item-card {
  /*border: 3px solid rgb(3, 19, 160);*/
  width: 400px;
  height: auto;
  margin-bottom: 50px;
  -webkit-box-shadow: 1px 2px 5px 1px #000000; 
  box-shadow: 2px 2px 7px 1px #000000;
/* ff 3.6+ */
    background:-moz-linear-gradient(180deg, rgba(210, 210, 205, 1) 0%, rgba(241, 243, 243, 1) 50%, rgba(210, 210, 205, 1) 100%); 

    /* safari 5.1+,chrome 10+ */
    background:-webkit-linear-gradient(180deg, rgba(210, 210, 205, 1) 0%, rgba(241, 243, 243, 1) 50%, rgba(210, 210, 205, 1) 100%);

    /* opera 11.10+ */
    background:-o-linear-gradient(180deg, rgba(210, 210, 205, 1) 0%, rgba(241, 243, 243, 1) 50%, rgba(210, 210, 205, 1) 100%);

    /* ie 6-9 */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#D2D2CD', endColorstr='#D2D2CD', GradientType=1 );

    /* ie 10+ */
    background:-ms-linear-gradient(180deg, rgba(210, 210, 205, 1) 0%, rgba(241, 243, 243, 1) 50%, rgba(210, 210, 205, 1) 100%);

    /* global 94%+ browsers support */
    background:linear-gradient(180deg, rgba(210, 210, 205, 1) 0%, rgba(241, 243, 243, 1) 50%, rgba(210, 210, 205, 1) 100%);
  border: 1px solid black;
}
.order-card {
  font-size : 18px;
  width: 300px;
  height: 280px;
  margin : auto 10px;
  margin-bottom: 20px;
  -webkit-box-shadow: 1px 2px 5px 1px #000000; 
  box-shadow: 2px 2px 7px 1px #000000;
/* ff 3.6+ */
    background:-moz-linear-gradient(180deg, rgba(210, 210, 205, 1) 0%, rgba(241, 243, 243, 1) 50%, rgba(210, 210, 205, 1) 100%); 

    /* safari 5.1+,chrome 10+ */
    background:-webkit-linear-gradient(180deg, rgba(210, 210, 205, 1) 0%, rgba(241, 243, 243, 1) 50%, rgba(210, 210, 205, 1) 100%);

    /* opera 11.10+ */
    background:-o-linear-gradient(180deg, rgba(210, 210, 205, 1) 0%, rgba(241, 243, 243, 1) 50%, rgba(210, 210, 205, 1) 100%);

    /* ie 6-9 */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#D2D2CD', endColorstr='#D2D2CD', GradientType=1 );

    /* ie 10+ */
    background:-ms-linear-gradient(180deg, rgba(210, 210, 205, 1) 0%, rgba(241, 243, 243, 1) 50%, rgba(210, 210, 205, 1) 100%);

    /* global 94%+ browsers support */
    background:linear-gradient(180deg, rgba(210, 210, 205, 1) 0%, rgba(241, 243, 243, 1) 50%, rgba(210, 210, 205, 1) 100%);
  border: 1px solid black;
}
h2 {
  background-color: #f38414;
  padding : 10px;
  width : 100%;
  border: 1px black solid;
  -webkit-box-shadow: 5px 5px 15px 5px #000000; 
  box-shadow: 5px 5px 15px 5px #000000;
}
#little {
  height: auto;
}
h4 {
  font-size: 25px;
  margin: 10px 0 0 0;
}
.alert-by-item-card > p {
  font-size: 20px;
}
.alert-by-item-card > .ref {
  font-size: 20px;
  font-weight : bold;
}
.alert-by-item-card > .defSupp {
  font-size: 15px;
}

.alert-by-item-card > .see-other-location {
  font-size: 15px;
  background-color: black;
  color: rgb(255, 255, 255);
}
.number {
  height: 35px;
  width: 70px;
  font-size: 22px;
}
.order-qtty {
  margin-left: 20px;
}
.tick-icon {
  color: green;
  background-color: rgb(156, 247, 250);
  font-size: 15px;
  font-weight: bold;
  margin-left: 10px;
  padding: 6px 10px;
  border-radius: 25px;
}
.warning-icon {
  color: rgb(255, 0, 0);
  background-color: yellow;
  font-size: 22px;
  font-weight: bold;
  margin: auto 10px;
  padding: 4px 8px;
  border-radius: 25px;
}
.ordered-label {
  font-size: 22px;
  margin-left : 15px;
}
#ordered {
  transform: scale(1.5);
  margin-left : 10px;
}
.trash {
  background-color: black;
  color: white;
  justify-self: end;
}
img {
  border: black 2px dashed;
  max-width: 60px;
  max-height: 60px;
}
.flex-column {
  display: flex;
  width: 1100px;
  justify-content: space-between;
}
.basket-summary-column {
  width : auto;
}
.basket-summary-subcolumn {
  display : flex;
  flex-direction : row;
  flex-wrap : wrap;
}
.admin-basket-container {
  margin-top: 150px;
  width : 400px;
  display : none;
}
.flex-container {
  display: flex;
  width : 80%;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.button-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 18px;
}
.card-flex-container {
  display: flex;
  align-items: center;
  padding: 0 20px;
  margin-bottom : 25px;
  justify-content: space-between;
}
.flex-menu {
  display : flex;
  width : 100%;
  justify-content : space-between;
}
.order-button {
  max-width: 300px;
  height: 70px;
  font-size: 20px;
  margin-bottom: 50px;
  margin: 20px;
  order : 3;
  margin-right : 80px;
}
.mini-btn {
  height: 29px;
  width : 30px;
  font-size : 15px;
  padding : 0;
}
.qtty-by-location {
  margin: 5px;
  font-size: 17px;
}
.btn {
  margin: 10px;
  margin-top : 65px;
}
.basket-btn{
  width : 120px;
  margin: 20px;
}
#goTop {
  position: fixed;
  background-color: black;
  right: 100px;
  bottom: 50px;
  width: 50px;
  height: 50px;
}
#showHideArrow {
  height : 200px;
  max-width : 40px;
}
#showHideArrow span{
  font-size : 40px;
}
#showHideArrow i{
  font-size : 20px;
}
.fa-arrow-up {
  font-size: 30px;
  color: white;
}
#multiplicateur{
  width: 40px;
  text-align: center;
  margin-left : 10px;
  font-size : 20px;
}
@-moz-document url-prefix() {
#multiplicateur{
  width: 50px;
}}
.multiplier-container {
  display : flex;
  justify-content : space-between;
  width: 700px;
}
.option-container{
display: flex;
flex-direction: column;
justify-content: space-around;
height: 100px;
margin-right : 100px;
}
.option-container div select{
  font-size : 20px;
}
.option-container div label{
  font-size : 20px;
}
    
.supplier-number-info{
color : white;
background-color : black;
padding : 4px 3px;
padding-bottom : 2px;
font-weight : bold;
}
.number-on-order{
padding: 2px 7px;
font-size: 16px;
border-radius : 50%;
background-color: rgb(239, 239, 239);
border : black 1px solid;
font-weight : bold;
position : relative;
cursor : pointer;
}
.absolute-list{
  display : none;
  position : absolute;
  width : 100px;
  padding : 0;
  left : -40px;
  top : 15px;
  background-color : white;
  border : black 1px solid;
}
.absolute-list li {
  padding : 6px 6px;
  font-size : 12px;
  list-style: none;
}
.succes-message {
  width: 300px;
  height: 100px;
  border: 2px rgb(23, 189, 23) solid;
  font-size: 25px;
  font-weight: bold;
  border-radius: 5px;
  color: rgb(23, 189, 23);
  background-color: rgb(255, 255, 255);
  top: 37%;
  left: 37%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
  position: fixed;
  line-height: 100px;
  z-index : 10;
}
.hourglass-icon {
  font-size : 25px;
  padding : 0px 30px 5px 30px;
  height : 63px;
}
@media screen and (max-width: 1100px) {
  input,select {
    background-color: white;
  }
  .alerts-container {
  width : auto;
}
.alerts-subcontainer{
  width : auto;
display : block;
}
.location-title {
  width : auto;
}
.location-list {
  display : flex;
  flex-wrap : wrap;
  justify-content : space-evenly;
}
.location-list div {
width : 160px;
}
.qtty-remaining {
  font-size : 20px;
  background-color : yellow;
  padding : 5px;
  margin-bottom : 20px;
  border-radius: 25px;
}
  .alert-by-location-card {
  width: 280px;
  margin-bottom: 30px;
  display: block;
}
.flex-menu {
  display : block;
}
.order-button {
  margin: 20px;
  /*order : 3;*/
  margin-right : 20px;
}
.btn {
  margin-top : 10px;
}
.basket-card {
  width: 240px;
  height: auto;
  padding: 20px;
}
.order-icon {
  width: 35px;
  height: 35px;
  font-size: 25px;
  margin-right: 80px;
}
#little {
  height: auto;
}
.alert-by-item-card {
  width: 280px;
  height: auto;
  margin-bottom: 20px;
}
.order-card {
  width: 280px;
  height: 250px;
  margin : auto;
  margin-bottom: 20px;
  background-color: rgb(245, 243, 243);
  border: 1px solid black;
}
.multiplier-container {
  margin : 20px 0;
  width: 300px;
}
h4 {
  font-size: 22px;
}
.alert-by-item-card > p {
  font-size: 15px;
}
.alert-by-item-card > .ref {
  font-size: 17px;
}
.alert-by-item-card > .see-other-location {
  font-size: 14px;
}
.number {
  height: 30px;
  margin : 5px;
  font-size: 18px;
}
.order-qtty {
  margin-left: 10px;
}
.flex-column {
  flex-direction: column;
  width : 300px;
} 

.basket-btn{
  margin: 10px;
}
.admin-basket-container {
  margin-top: 10px;
  display: flex;
  flex-direction : column;
  align-items: center;
}
  #goTop {
    left: 0px;
    bottom: 10px;
    width: 28px;
    height: 50px;
  }
  .fa-arrow-up {
    font-size: 16px;
  }
.option-container .select-container{
  display : flex;
  flex-direction: column;
}
.option-container{
display: block;
margin-right : auto;
margin-top : 20px;
}
.option-container div select{
  font-size : 18px;
}
.option-container div label{
  font-size : 18px;
}
.absolute-list{

  left : -100px;
  top : 15px;
}
.absolute-list li {
  padding : 6px 6px;
  font-size : 12px;
  list-style: none;
}
.succes-message {
  left: 50%;
}
}
</style>
