<template>
  <div>
    <h2>Injection Excel</h2>
    <div class="flex">
      <label
        >Injecter un fichier :
        <img id="icon1" src="../../../assets/excel.jpg" alt=""/>
        <img id="icon2" src="../../../assets/libreoff.jpg" alt=""
      /></label>

      <input
        type="file"
        ref="fileInput"
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        @change="previewFiles"
      />

      <div class="vertical-margin">
        Page choisie
        <button
          :style="pickedPage === 0 ? { 'background-color': '#0d444b', 'color' : 'white'} : {}"
          @click="pickedPage = 0"
        >
          1
        </button>
        <button
          :style="pickedPage === 1 ? { 'background-color': '#0d444b', 'color' : 'white' } : {}"
          @click="pickedPage = 1"
        >
          2
        </button>
        <button
          :style="pickedPage === 2 ? { 'background-color': '#0d444b', 'color' : 'white' } : {}"
          @click="pickedPage = 2"
        >
          3
        </button>
      </div>

      <div class="vertical-margin">
        <span> Conseils d'utilisation </span>
        
        <button class='colored-btn' v-if="!showAdvices" @click="showAdvices = true">Afficher</button>
        <button v-else @click="showAdvices = false">Masquer</button>

        <div v-if="showAdvices">
       -Ici l'injection Excel est uniquement configuré pour la création de prestation.
        <br>
        Pour apporter des modification veuillez utiliser la console de gestion ci desssus.
        <br>
        Vous ne pouvez pas créer un article déjà existant.
      
        </div>
      </div>

      <ul class="results">
        Resultats :
        <li v-for="result in excelResult" :key="result">{{ result }}</li>
      </ul>
  <div class="get-type-excel">
      <button @click="exportToExcel()">Récupérer le fichier type</button>
</div>
      <h4>Colonnes interprétées :</h4>

      <div class="notice">
       
          <ul>
             <li><strong>Client</strong></li>
             <li><strong>Catégorie</strong></li>
             <li><strong>Désignation</strong></li>
            <li><strong>Réf client</strong></li>
            <li><strong>Tarif</strong></li>
          </ul>
         
      </div>
    </div>
  </div>
</template>

<script>
const XLSX = require("xlsx");
const { saveAs } = require('file-saver');
const myAppPath = require('../../../switchUrl');
const appPath = myAppPath.appPath;
// item 
class Item {
  constructor(
    client,
    type_,
    name,
    ref,
    price,
   
  ) {
    this.client = client;
    this.type_ = type_;
    this.name = name;
    this.ref = ref;
    this.price = price;
  }
}

export default {
  name: "ServicesInjection",
  data() {
    return {
      stopLoop : false,
      connectedUser: {},
      bigArray: [],
      pickedPage: 0,
      excelResult: ['En attente d\'utilisation',
      'En attente d\'utilisation',
      'En attente d\'utilisation',
      'En attente d\'utilisation'],
      noModifMessage: null,
      showAdvices : false
    };
  },
  mounted() {
    // récuperer l'utilisateur actuel
    this.connectedUser = JSON.parse(localStorage.getItem("connectedUser"));
  },
  methods: {
    //interprétation de l'excell
    previewFiles(e) {
      // vider les resultats
      this.excelResult = [];
      //commencer la lecture
      var files = e.target.files,
        f = files[0];
      var reader = new FileReader();
      let self = this;
      reader.onload = function(e) {
        var data = new Uint8Array(e.target.result);
        var workbook = XLSX.read(data, { type: "array" });
        //console.log(self.pickedPage);
        //console.log(typeof self.pickedPage);
        let sheetName = workbook.SheetNames[self.pickedPage];
        /* DO SOMETHING WITH workbook HERE */
        //console.log(workbook);
        let worksheet = workbook.Sheets[sheetName];
        const EXCEL_DATA = XLSX.utils.sheet_to_json(worksheet);
        self.checkExcelData(EXCEL_DATA);
      };
      reader.readAsArrayBuffer(f);
    },
    //envoyer les données vers une fonction selon leur catégorie
    checkExcelData(data) {
      for (let i in data) {
        //console.log(data[i]);
        var row = data[i];
        
        //Nettoyer les espaces inutiles avant et apres le mot
        for (let i of (Object.keys(row))){
          //console.log(row[i]);
          if(typeof row[i] === 'string'){
            //console.log(row[i].trim());
            row[i] = row[i].trim();
          }
        }

        //console.log(Object.keys(row));
        this.saveBenefits(row);
        if(this.stopLoop === true){
          this.stopLoop =false;
          return;
        }
      }
      //console.log("bigArray");
      //console.log(this.bigArray);
      const benefitsObject = this.bigArray;
      this.bigArray = [];
      //console.log(benefitsObject);
      this.excelItem(benefitsObject);
    },
    //trier les données si Consommable ou Outils
    saveBenefits(row) {
      //vérifier les données indispensables sont présentes
       if (!row["Client"]){
        alert('donnée manquante Client');
         this.$refs.fileInput.value = '';
         this.stopLoop = true;
        return
      }
      if (!row["Catégorie"]) {
          alert("Colonne Catégorie introuvable");
          this.$refs.fileInput.value = '';
          this.stopLoop = true;
          return;
        }
        if (!row["Désignation"]){
        alert('donnée manquante Désignation');
         this.$refs.fileInput.value = '';
         this.stopLoop = true;
        return
      }
      if (!row["Réf client"]){
        alert('donnée manquante Réf client');
         this.$refs.fileInput.value = '';
         this.stopLoop = true;
        return
      }
      if (!row["Tarif"]){
        alert('donnée manquante Tarif');
         this.$refs.fileInput.value = '';
         this.stopLoop = true;
        return
      }

      //console.log(row['Tarif ']);
      var createItem = new Item(
        row["Client"],
        row["Catégorie"],
        row["Désignation"],
        row["Réf client"],
        row["Tarif"]
      );

      //console.log(createItem);
      this.bigArray.push(createItem);
    },
    //STORE REQUEST SEND EXCEL DATA
    //AND PRINT RESULT AFTER RESPONSE
    excelItem(benefitsObject) {
      this.fetchExcelItem(benefitsObject)
        .then((data) => {
          //console.log(data);
          // rafraichir les données
          if (data["data"]) {
            if(data['data'].includes('ERREUR')){
          //console.log(data['data']);
          if(data['data'].includes('ERREUR01')){
            alert('Injection Excel échouée, vous essayez de créer un article déjà existant');
          this.$refs['fileInput'].value = '';
          return
          }
          alert('Injection Excel échouée');
          this.$refs['fileInput'].value = '';
          return
        };
        this.$emit('refreshing', true);
            //console.log(data['data']);
            for (let i in data["data"]) {
              //console.log(i);
              //console.log(data["data"][i]);
              //seul les clefs impairs contiennent l'information souhaitée
              if (this.numIsImpair(i)) {
                //console.log(data["data"][i]);
                if (this.noModifMessage === null) {
                  this.excelResult.push(data["data"][i]);
                } else {
                  this.excelResult.push(this.noModifMessage);
                }
                this.noModifMessage = null;
              } else {
                // items_table
                //console.log('ITEM LINE '+ i.toString());
                //console.log(data['data'][i][0]);
                //console.log(data['data'][i][0].changedRows);
                //details
                //console.log('DETAILS LINE ' + i.toString());
                //console.log(data['data'][i][1]);
                //console.log(data['data'][i][1].changedRows);

                if (
                  data["data"][i].insertId === 0 &&
                  data["data"][i].changedRows === 0 &&
                  data["data"][i].changedRows <= 1
                ) {
                  this.noModifMessage =
                    "Ligne " +
                    (i.toString() / 2 + 1) +
                    " : Aucune modification détéctée";
                } else {
                  //console.log('ITEM LINE '+ i.toString());
                  //console.log(data['data'][i][0]);
                  //console.log(data['data'][i][0].changedRows);
                  //details
                  //console.log('DETAILS LINE ' + i.toString());
                  //console.log(data['data'][i][1]);
                  //console.log(data['data'][i][1].changedRows);
                }
              }
            }
            this.$refs.fileInput.value = "";

            //alert('SUCCES');
          } else {
            alert("UNE ERREUR EMPECHE L'INJECTION EXCEL");
            this.$refs.fileInput.value = "";
          }
        })
        .catch((e) => console.log(e));
    },
    async fetchExcelItem(benefitsObject) {
      let key;
      //key for only web app native request (!postman)
      if (
        (this.connectedUser.a5e789410fd.includes("2f+g2dfg25h2d+f15b1gs5bnj1ki1lo61q1dc16"))
      ) {
        key = "h1d5he15fdhs";
      }
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
        body: JSON.stringify({
          benefitsObject: benefitsObject,
          user_id: this.connectedUser.id_user,
          key: key,
        }),
      };

      let response = await fetch(
        appPath+"/activities/excel_services",
        requestOptions
      );
      if (!response.ok) {
        // get error message from body or default to response status
        const error = (data && data.message) || response.status;
        //console.log('not response ok, error : ' + error);
        alert("une erreur innattendue s'est produite");
        return Promise.reject(error);
      }
      return await response.json();
    },
    // gérer la casse des string
    capitalizeFirstLetter(string) {
      //console.log(typeof string);
      if (typeof string === "string") {
        const str = string.toLowerCase();
        //console.log(str.charAt(0).toUpperCase() + str.slice(1) === 'Consommable');
        return str.charAt(0).toUpperCase() + str.slice(1);
      } else {
        return string;
      }
    },
    //vérifier si un nombre est impair
    numIsImpair(n) {
      return n & 1 ? true : false;
    },
    //methodes pour récupérer un excel vide avec les colonnes
    exportToExcel(){
        //var miror = JSON.parse(JSON.stringify(this.translateForExcel));
        const consObject = [];
          consObject.push(JSON.parse(JSON.stringify({
          'Client' :null,
          'Catégorie':null,
          'Désignation':null,
          'Réf client':null,
          'Tarif':null,
        })));
        //console.log(consObject);
        //return
        this.downloadAsExcel(consObject);
      },
      downloadAsExcel(data){
        let worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = {
          Sheets : {
            'data':worksheet
          },
          SheetNames: ['data']
        };
        const excelBuffer = XLSX.write(workbook, {booktype : 'xlsx', type : 'array'});
        //console.log(excelBuffer);
        this.saveAsExcel(excelBuffer, 'myFile');
      },
      saveAsExcel(buffer,filename){
        const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([buffer],{type:EXCEL_TYPE});
        saveAs(data,filename+'_export_'+new Date().getTime()+EXCEL_EXTENSION);
      }

  },
};
</script>

<style scoped>
.flex {
  display: flex;
  border: green 5px solid;
  padding: 10px;
  width: 600px;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: auto;
  margin-bottom: 20px;
  
}
label {
  display: flex;
  justify-content: center;
  text-align: left;
  font-size: 25px;
}
#icon1 {
  max-height: 50px;
  margin-right: 20px;
  margin-left: 10px;
}
#icon2 {
  max-height: 50px;
}
.results {
  background-color: #f38414;
  color : white;
  font-size: 20px;
  width: 65%;
}
.results > li {
  text-align: start;
}
.notice {
  width: 100%;
  display: flex;
}
h4 {
  width: 600px;
}
.notice > ul {
      display: flex;
    width: 500px;
    justify-content: space-between;
}

input[type=file], /* FF, IE7+, chrome (except button) */
input[type=file]::-webkit-file-upload-button {
  /* chromes and blink button */
  cursor: pointer;
  background-color: #0d444b;
  color : white;
  height: 40px;
}
.colored-btn {
  background-color: #f38414;
  color : white;
}
.get-type-excel{
  margin: 10px;
  padding: 20px;
  background-color: #0d444b;
}
.get-type-excel button{
  font-size: 19px;
}
.get-type-excel select{
  font-size: 19px;
}
.vertical-margin {
  margin : 20px 0;
}
@media screen and (max-width: 1100px) {
  .flex {
    width: 300px;
  }
  .notice {
  flex-direction: column;
  align-items: center;
}
button {
  margin: 5px;
}
ul{
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.results{
  margin: auto;
  width: 250px;
}
.results > li {
  font-size: 15px;
}
li{
  font-size: 20px;
  margin-right: 60px;
}
}
</style>
