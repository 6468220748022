<template>
  <div class="history-container">
        <!-- {{globalHistory}} !-->
        <button class="showOptions" v-if="!controlPanel" @click="controlPanel = true">Afficher les options &#x25BC;</button>

        <div class="control-panel-div" v-show="controlPanel">
           <button class="hideOptions"  @click="controlPanel = false">Masquer les options &#x25B2; </button>
         <div @click="exportToExcel()" class="excel-div">
        <button>Export Excel</button>
        <img id="icon1" src="../../../assets/excel.jpg" alt=""/>
        <img id="icon2" src="../../../assets/libreoff.jpg" alt=""/>
        
        </div>

        <div class="search-container interval-container">
          <p>Choisir un période jj/mm/aaaa (par défaut 31 derniers jours)</p>
          <div>
          <label for="date">Entre : </label>
        <input type="search" name="date" ref="date_filter" v-model="choosedStartDate">
        </div>
          <div>
        <label for="date">Et : </label>
        <input type="search" name="date" ref="date_filter" v-model="choosedEndDate">
        </div>
        <button @click="newDateInterval()">Lancer la requête</button>
        <button @click="defaultInterval()">Effacer</button>
        </div>
        
       <div class="search-container">
          <div>
        <label for="date">Filtrer une date : </label>
        <input type="search" name="date" id="" v-model="choosedDate">
        </div>
        <div>
        <label for="date"> Filtrer une heure : </label>
        <input type="search" name="date" id="" v-model="choosedHour">
        </div>
        </div>
        </div>

         <div class="legend-container" @mouseover="showLegend = true" 
 @mouseleave="showLegend = false">Légende des icones
   <div class="show-legend" v-if="showLegend">
     <ul>
       <li>&#9889; console administrateur</li>
       <li>&#10062; injection excel</li>
       <li>&#128035; création</li>
       <li>&#10060; suppression</li>
       <li>&#9997; modification</li>
       <li>&#9194; retour matériel</li>
       <li>&#128101; gestionnaire utilisateur</li>
       <li>&#128273; mot de passe</li>
       <li>&#10133; upgrade</li>
       <li>&#10134; retrograde</li>
       
     </ul>
   </div>
 </div>

        <div class="table-container">
    <table v-if="Object.values(multiFilters).length != 0">
        <thead class="global-history-table" >
            <tr>
          <td>Ligne</td>
          <td>Utilisateur</td>
          <!--td>color</td!-->
          <td>Ref</td>
          <td>Id objet</td>
          <td>Quantité</td>
          <td>Dépôt</td>
          
          <td><button v-if="!showHour" @click="showHour = true">date</button>
          <button v-else @click="showHour = false">date + </button></td>
          <td>Statut</td>
          <td>modification</td>
          <td>Avant</td>
          <td>Après</td>
          </tr>
      </thead>

      <tbody class="global-history-table" >
           <tr v-for="row in multiFilters" :key="row"
            :class="row.color"
            >
          <td>{{row.id}}</td>
           <td v-if="row.data_feeder !=null &&row.data_feeder.includes('from_console X')"> {{cutString(row.data_feeder)}} &#9889; &#10062;</td>
          <td v-else-if="row.data_feeder !=null &&row.data_feeder.includes('from_console')"> {{cutString(row.data_feeder)}} &#9889;</td>
          <td v-else-if="row.data_feeder !=null &&row.data_feeder.includes('creatOnRet')"> {{cutString(row.data_feeder)}} &#9194;</td>
          <td v-else-if="row.data_feeder !=null &&row.data_feeder.includes('expiredBooking')"> {{cutString(row.data_feeder)}} &#9200;</td>
          <td v-else-if="row.data_feeder !=null &&row.data_feeder.includes('autoCreatedOnReception')"> {{cutString(row.data_feeder)}} &#128667;</td>
          <td v-else-if="row.data_feeder !=null &&row.data_feeder.includes('from_user')"> {{cutString(row.data_feeder)}} &#128101;</td>
          <td v-else > {{row.data_feeder}} </td>
          <td>{{row.ref}}</td>
          <td v-if="row.item_id === '-1'" >&#10134;</td>
          <td v-else-if="row.item_id === '-2'">&#10133;</td>
          <td v-else-if="row.item_id === '-3'">&#128273;</td>
          <td v-else >{{row.item_id}}</td>
          
          
          <!--td>{{row.color}}</td!-->
          <td :style="[row.qtty ? {}:{'backgroundColor' : 'grey'}]">
             <span v-if="row.qtty > 0">+</span>
             {{row.qtty}}</td>
          <td :style="[(!row.storage_location) ? {'backgroundColor' : 'grey'}:{}]" >{{row.storage_location}}</td>
          

          <td v-if="!showHour" class="date" @click="copyFn(dateFormat(row.date))">{{dateFormat(row.date)}} </td>
          <td v-else >{{dateFormatH(row.date)}} </td>

          <td v-if="row.status_ && row.status_ === 'created'">&#128035;</td>
          <td v-else-if="row.status_ && row.status_ === 'modified'">&#9997;</td>
          <td v-else-if="row.status_ && row.status_ === 'deleted'">&#10060;</td>
          <td v-else>{{row.status_}}</td>

          <td :style="[row.modified ? {}:{'backgroundColor' : 'grey'}]">{{row.modified}}</td>
          <td :style="[row.before_ ? {}:{'backgroundColor' : 'grey'}]">{{row.before_}}</td>
          <td :style="[row.after_ ? {}:{'backgroundColor' : 'grey'}]">{{row.after_ }}</td>
          </tr>
      </tbody>
</table>
<div v-else class="loader" >Recherche de données... &#8987;</div>
  </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
const XLSX = require("xlsx");
const { saveAs } = require('file-saver');

export default {
    name : "HistoryAdmin",
    props : ["connectedUser"],
    data() {
        return {
            showHour : false,
            choosedDate : '',
            choosedHour : '',
            choosedStartDate : '',
            choosedEndDate : '',
            controlPanel : false,
            showLegend : false,
            
            
        }
    },
      mounted() {
    this.$store.state.intervalDate = [];
    this.$store.commit("isConnected");
    this.getadminChangesHistory();
  },

      computed: {
    //STORE
    ...mapState({
      adminChangesHistory: "adminChangesHistory",
      intervalDate : "intervalDate"
    }),


    multiFilters(){
      var obj = [];
      let miror;
      if(Object.values(this.intervalDate).length != 0){
        miror = this.intervalDate;
      }
      else{
        miror = this.adminChangesHistory;
      };
      //var totalPrice = 0;
      if(this.choosedDate != ''){
        miror.forEach(element => {
          if(this.dateFormat(element.date) === this.choosedDate){
            obj.push(element);
          }
        });
        miror = obj;
        obj = [];
      }
      if(this.choosedHour != ''){
        miror.forEach(element => {
          if(this.dateFormatH(element.date).split(',')[1].includes(' '+this.choosedHour)){
            obj.push(element);
          }
        });
        miror = obj;
        obj = [];
      }
      return miror;
    },
    translateForExcel(){
      var jsonObj = [];
      var miror = JSON.parse(JSON.stringify(this.multiFilters));
        //var start = new Date();
        miror.forEach(element => {
          delete Object.assign(element, {['Ligne']: element['id'] })['id'];
          delete Object.assign(element, {['Utilisateur']: element['data_feeder'] })['data_feeder'];
          delete Object.assign(element, {['Réf']: element['ref'] })['ref'];
          delete Object.assign(element, {['Id_objet']: element['item_id'] })['item_id'];
          delete Object.assign(element, {['Qtté']: element['qtty'] })['qtty'];
          delete Object.assign(element, {['Dépôt']: element['storage_location'] })['storage_location'];
          element.date = this.dateFormat(element.date);
          delete Object.assign(element, {['Date']: element['date'] })['date'];
          delete Object.assign(element, {['Statut']: element['status_'] })['status_'];
          delete Object.assign(element, {['Modification']: element['modified'] })['modified'];
          delete Object.assign(element, {['Avant']: element['before_'] })['before_'];
          delete Object.assign(element, {['Après']: element['after_'] })['after_'];
          delete element.color;
          jsonObj.push(element);
        });
        // 7 milliseconds
        //var time = new Date() - start;
        //console.log(time);
        return jsonObj;
    }
 },
  methods : {
      getadminChangesHistory(){
        if (Object.keys(this.connectedUser).length != 0) {
        this.$store.dispatch("getAdminChangesHistory", this.connectedUser.id_user);
      } else {
        alert("Accès bloqué, l'utilisateur n'est pas reconnu !");
      }
      },
      dateFormat(data){
       // console.log( data);
       // console.log(typeof data);
        const dateObject = new Date(data);
        //console.log( dateObject);
        //console.log(typeof dateObject);
        let humanDateFormat;
        if(dateObject.toLocaleString().includes(',')){
        humanDateFormat = dateObject.toLocaleString().split(',')[0];}
        else{
        humanDateFormat = dateObject.toLocaleString().split(' ')[0];  
        }
        //console.log( humanDateFormat.split(',')[0]);
        //console.log(typeof humanDateFormat);
        return humanDateFormat;
      },
      dateFormatH(data){
        const dateObject = new Date(data);
        const humanDateFormat = dateObject.toLocaleString();
        return humanDateFormat;
      },
      newDateInterval(){
        if(this.choosedStartDate != '' && this.choosedEndDate != ''){

         var j = this.choosedStartDate.split('/')[0];
         var m = this.choosedStartDate.split('/')[1];
         var a = this.choosedStartDate.split('/')[2];
         const choosedStartDate = a+'-'+m+'-'+j;
         j = this.choosedEndDate.split('/')[0];
         m = this.choosedEndDate.split('/')[1];
         a = this.choosedEndDate.split('/')[2];
         const choosedEndDate = a+'-'+m+'-'+j;

         //console.log(j);console.log(m);console.log(a);
         
         const startDate = new Date(choosedStartDate+'Z');
         var endDate0 = new Date(choosedEndDate+'Z');
         endDate0.setUTCHours(22);
         endDate0.setUTCMinutes(59);
         endDate0.setUTCSeconds(59);
         //endDate0 = endDate0.setUTCMinutes(59);
         //console.log(endDate0);
         const endDate = endDate0;
         if(isNaN(Date.parse(startDate))  || isNaN(Date.parse(endDate))){
           alert("erreur de format de date, veuillez respecter ce format : jj/mm/aaaa");
         }
         
         if (Object.keys(this.connectedUser).length != 0) {
           
           const historyData = {
           date : [startDate,endDate],
           compOrigin :'history_admin',
           username : this.connectedUser.username,
           user_id : this.connectedUser.id_user
         }

        this.$store.dispatch("putIntervalDate", historyData);
         } else {
        alert("Accès bloqué, l'utilisateur n'est pas reconnu !");}

        }else{
          alert("Veuillez remplir tous les champs d'interval de date !");
        }
      },
      defaultInterval(){
        this.choosedStartDate = '';
        this.choosedEndDate = '';
        this.$store.state.intervalDate = [];
      },
      copyFn(number) {
  var copyText = number.toString();
  const searchInput = this.$refs['date_filter'];
  searchInput.value = copyText;
  searchInput.focus();
  this.choosedDate = copyText;
      },
      cutString(username){
        if(username != null && username.includes('from_console')){
        return username.split(' from_console')[0];}
        else if(username != null && username.includes(' creatOnRet')){
        return username.split(' creatOnRet')[0];}
        else if(username != null && username.includes('expiredBooking')){
        return username.split('expiredBooking')[0]+'Expirée';}
        else if(username != null && username.includes('autoCreatedOnReception')){
        return username.split('autoCreatedOnReception')[0]+'Reception';}
        else if(username != null && username.includes('from_user')){
        return username.split('from_user')[0];}
        return username;
        		
      },
      exportToExcel(){
        var miror = JSON.parse(JSON.stringify(this.translateForExcel));
        //console.log(miror);
        this.downloadAsExcel(miror);
      },
      downloadAsExcel(data){
        let worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = {
          Sheets : {
            'data':worksheet
          },
          SheetNames: ['data']
        };
        const excelBuffer = XLSX.write(workbook, {booktype : 'xlsx', type : 'array'});
        //console.log(excelBuffer);
        this.saveAsExcel(excelBuffer, 'myFile');
      },
      saveAsExcel(buffer,filename){
        const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([buffer],{type:EXCEL_TYPE});
        saveAs(data,filename+'_export_'+new Date().getTime()+EXCEL_EXTENSION);
      }
  }

}
</script>

<style scoped>
table{
  margin : auto;
  overflow: scroll;
}
.table-container{/**/
  margin-top: 80px;
  margin-bottom: 60px;
  max-width: 1400px;
  overflow-x: scroll;
}
thead {
  font-size: 20px;
  font-weight: bold;
}
thead td{
  border: 1px solid black;
  text-overflow: ellipsis;
}
tbody tr:hover{
  background-color: rgb(116, 223, 223);
}
.global-history-table{
margin: 10px;
}
.global-history-table td{
margin: 10px;
}
.search-container {
  display: flex;
  margin: 30px;
  justify-content: space-evenly;
}
.search-container div input{
  width: 100px;
  margin-left : 10px;
}
.search-container div label{
  font-size: 22px;
}
.interval-container{
  display: flex;
  font-size: 19px;
  align-content: center;
  padding-left: 10px;
  border: 1px black solid;
}
.interval-container p{
margin-right: 20px;
line-height: 30px;
}
.interval-container div{
margin: 20px;
}
.interval-container button{
margin: 20px;
font-size: 19px;
}
.date{
  cursor: pointer;
}
.date:hover{
  background-color: greenyellow;
}
.excel-div img {
width : 50px;
height: 50px;
margin-left: 20px ;
}
.excel-div {
    display: flex;
    justify-content: center;
}
.excel-div button{
    font-size : 20px;
    color: white;
    background-color: green;
    opacity: 1;
}
.excel-div button:active {
	transform: scale(1.1);
	transition: .1s;
}

.excel-div button:hover {
  opacity : 0.7;
}
.loader {
  margin-top: 80px;
  font-size: 50px;
}
.hideOptions {
  margin-bottom: 20px;
  font-size: 18px;
}
.showOptions{
  font-size: 18px;
}
.legend-container {
  margin : 25px auto 0px auto;
  width : auto;
  max-width: 300px;
  padding : 10px 0px;
  border: 1px black solid;
  position : relative;
  cursor : pointer;
}
.legend-container:hover{
  background-color: aquamarine;
}
.show-legend{
  position : absolute;
  background-color: white;
  z-index : 2;
  border: 1px solid black;
}
.show-legend ul {
  padding: 8px;
  width : 282px;
}
.show-legend ul li {
  list-style: none;
}
@media screen and (max-width: 1100px) {
table{
  overflow-x: scroll;
}
.table-container{
  margin-top: 50px;
  max-width: 280px;
  overflow-x: scroll;
}
.history-container{
  max-width: 280px;
  overflow-x: hidden;
}
.control-panel-div{
  max-width: 280px;
  overflow-x: scroll;
}
.search-container {
  flex-direction: column;
  margin: 20px;
}
.search-container div label{
  font-size: 18px;
}
.interval-container{
  flex-direction: column;
}
.loader {
  margin-top: 50px;
  font-size: 20px;
}
.filters-container {
  flex-direction: column;
  height: 140px;
  margin-bottom : 10px;
}
div select{
  width: 240px;
  height: 35px;
}

}
</style>