const timerAcces = {
verifyAccess(key){
try {
    //console.log('launched function');
    //console.log(key);
        let keyNotProxy;
                if(key){
        keyNotProxy = JSON.parse(JSON.stringify(key));
        }
        //console.log(keyNotProxy);
        // seule une connexion admin confere une t_key
if(JSON.parse(localStorage.getItem('t_key'))){
            const T_KEY = JSON.parse(localStorage.getItem('t_key'));
            const strKey = keyNotProxy['timer_access'].split("_")[0];
            const timing = keyNotProxy['timer_access'].split("_")[1];
            //console.log(T_KEY);
            //console.log(strKey);
            //console.log(typeof T_KEY);
            //console.log(typeof strKey);
            
            // comparaison BDD et key obtenue à l'authentification
            if(T_KEY.toString() === strKey && timing){
              const timestampSecond = Math.floor(Date.now() / 1000);
              if(parseInt(timestampSecond) > parseInt(timing) + 
                28800){
                alert("Connecté depuis + de 8h, SESSION EXPIREE");
                //QUITTER LA CONNEXION
                return false
              }
              console.log('Accès validé...')
            }
            else {
              alert('ACCES REFUSE :2');
               //QUITTER LA CONNEXION
               return false
            }
      }
      else {
        alert('ACCES REFUSE :1');
        //QUITTER LA CONNEXION
        return false
      }
      return true

    } catch (error) {
    alert('Erreur inattendue lors de la vérification des accès...');
    return false;
    }
}
}
export default timerAcces;

